import React, { FC, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedProps, connect } from "react-redux";
import { getUserSessionToken } from "store/auth/actions";
import sessionData from "services/sessionStorage";
import SignInPage from "./sign-in/sign-in-page";
import ForgottenPage from "./forgotten/forgotten";
import RegisterPage from "./register/register-page";
import Layout from "../../components/auth/layout";
import { AUTH_ROUTES_URL } from "../../constant/routers";
import { StoreTypes } from "../../store/types";
import { Loading } from "../../components/ui/loading";

const AuthPage: FC<ReduxProps> = ({ loading, getSessionToken }) => {
  useEffect(() => {
    if (!sessionData.get("session_token")) {
      getSessionToken();
    }
  }, []);
  return (
    <Layout>
      <Loading isLoading={loading}>
        <Switch>
          <Route exact path={AUTH_ROUTES_URL.SIGN_IN} component={SignInPage} />
          <Route exact path={AUTH_ROUTES_URL.REGISTER} component={RegisterPage} />
          <Route exact path={AUTH_ROUTES_URL.FORGOTTEN} component={ForgottenPage} />
          <Redirect exact from="/auth" to={AUTH_ROUTES_URL.SIGN_IN} />
        </Switch>
      </Loading>
    </Layout>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  loading: store.auth.isLoading
});
const mapDispatchToProps = {
  getSessionToken: getUserSessionToken
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AuthPage);
