import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { resetErrorAndStatus } from "store/actions";
import AddedThirdPartyForm from "components/admin/added-third-party/added-third-party-form";
import { StoreTypes } from "store/types";
import { thirdPartyInfoRequest } from "store/third-party/actions";

type Props = {
  children?: React.ReactElement;
  isEdit?: boolean;
};

const AddedThirdParty: FC<ReduxProps & Props> = ({ resetErrorsAndStatus, children }) => {
  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
    };
  }, [resetErrorsAndStatus]);

  return <>{children || <AddedThirdPartyForm />}</>;
};

const mapStateToProps = ({ thirdParty }: StoreTypes) => ({
  dataThirdParty: thirdParty.editThirdParty
});

const mapDispatchToProps = {
  resetErrorsAndStatus: resetErrorAndStatus,
  thirdPartyInfo: thirdPartyInfoRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddedThirdParty);
