import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { forgottenStep } from "store/auth/actions";
import { STEP_FORGOTTEN } from "constant/step-auth";
import { StoreTypes } from "store/types";
import ForgottenTel from "components/auth/steps/forgotten/forgotten-tel";
import ForgottenCode from "components/auth/steps/forgotten/forgotten-code";
import ForgottenEmail from "components/auth/steps/forgotten/forgotten-email";
import ForgottenSuccess from "components/auth/steps/forgotten/forgotten-success";
import ForgottenNewPassword from "components/auth/steps/forgotten/forgotten-new_password";

const ForgottenForm: FC<ReduxProps> = ({ step, forgottenStep: resetStep }) => {
  useEffect(() => {
    return (): void => {
      resetStep(STEP_FORGOTTEN.TELEPHONE);
    };
  }, [resetStep]);

  switch (step) {
    case STEP_FORGOTTEN.EMAIL: {
      return <ForgottenEmail />;
    }
    case STEP_FORGOTTEN.CODE: {
      return <ForgottenCode />;
    }
    case STEP_FORGOTTEN.NEW_PASSWORD: {
      return <ForgottenNewPassword />;
    }
    case STEP_FORGOTTEN.SUCCESS: {
      return <ForgottenSuccess />;
    }
    default: {
      return <ForgottenTel />;
    }
  }
};

const mapStateToProps = (store: StoreTypes) => ({
  step: store.auth.stepForgot
});

const mapDispatchToProps = {
  forgottenStep
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ForgottenForm);
