import React, { FC, ReactNode, useEffect, useState } from "react";
import { connect } from "react-redux";
import { linkTimerLink } from "components/ui/switch-themes";
import { StoreTypes } from "store/types";
import { authUpdateTtl } from "store/auth/actions";

import { Text } from "../text";
import { Link } from "../link";

interface TimerTypes {
  children?: ReactNode;
  onClick?: () => void;
  start: boolean;
  setShowTimer: (arg: boolean) => void;
  time: string | null;
  clear: (arg: string) => void;
}

const Timer: FC<TimerTypes> = ({ time, start, onClick, setShowTimer, clear }) => {
  const [countdown, setCountdown] = useState<number>(Number(time));

  useEffect(() => {
    if (time !== null) {
      setCountdown(Number(time));
      setShowTimer(true);
    }
  }, [time]);

  useEffect(() => {
    if (start) {
      let counter = countdown;
      const timerId = setInterval(() => {
        counter -= 1;
        setCountdown(counter);
        if (counter <= 0) {
          setShowTimer(false);
          clearTimeout(timerId);
          clear("0");
        }
      }, 1000);
      return () => {
        clearTimeout(timerId);
        setShowTimer(false);
      };
    }
    return () => {
      return null;
    };
  }, [start]);

  return (
    <>
      {start ? (
        <Text align="center" theme={linkTimerLink}>
          Отправить повторно через
          <span>{` ${countdown} `}</span>
          секунд
        </Text>
      ) : (
        <Link onClick={onClick} href="/" isButton type="text" weight="regular" theme={linkTimerLink} underline>
          Отправить повторно
        </Link>
      )}
    </>
  );
};
const mapStateToProps = (store: StoreTypes) => ({
  time: store.auth.ttl
});
const mapDispatchToProps = {
  clear: authUpdateTtl
};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
