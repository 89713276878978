import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { RadioButton } from "components/ui/radio-button";
import { FiltersType } from "store/employees/types";
import { BlockType, EmployeeType } from "constant/radiobutton-values";
import { omit } from "lodash";
import { buttonRadioSize, nameCompanyDivision } from "components/ui/switch-themes";
import { NameButtonList } from "pages/admin/employees/employees";
import styles from "./employees-tab-panel.module.scss";
import TeamName from "../../../constant/team-name";
import EmployeeRoles from "../../../constant/eployee-roles";

const { adminDC, adminMain, employeeDC, employeeMain } = EmployeeRoles;

type Props = {
  rowsPerPage: number;
  teamName: TeamName;
  filters: FiltersType;
  setFilters: (arg: FiltersType) => void;
  setButtonName: Dispatch<SetStateAction<NameButtonList>>;
};

const teamDisplayName = {
  DC: "ДЦ",
  MAIN: `${nameCompanyDivision}`,
  ALL: "ALL"
};

const EmployeesTabPanel: FC<Props> = ({ setButtonName, teamName, filters, setFilters }) => {
  const [employeeType, setEmployeeType] = useState<string>(EmployeeType.all);
  const [isBlocked, setBlocked] = useState<string | boolean>(BlockType.all);

  const currentValues: any = {
    DC: {
      employees: { dealer_center_employee: true, role: [employeeDC] },
      admins: { dealer_center_employee: true, role: [adminDC] }
    },
    MAIN: {
      employees: { main_employee: true, role: [employeeMain] },
      admins: { main_employee: true, role: [adminMain] }
    }
  };

  const MAINorDC = teamName === TeamName.DC ? { dealer_center_employee: true } : { main_employee: true };
  const dinamicNameEmployee = teamName !== TeamName.DC ? "dealer_center_employee" : "main_employee";

  useEffect(() => {
    if (employeeType === EmployeeType.all) {
      setFilters({ ...omit(filters, "dealer_center_employee", "main_employee", "role"), ...MAINorDC });
    } else if (employeeType === EmployeeType.employees) {
      setFilters({ ...omit(filters, dinamicNameEmployee), ...currentValues[teamName].employees });
    } else if (employeeType === EmployeeType.admins) {
      setFilters({ ...omit(filters, dinamicNameEmployee), ...currentValues[teamName].admins });
    }
  }, [employeeType, teamName]);

  useEffect(() => {
    if (isBlocked === BlockType.all) {
      setFilters({ ...omit(filters, ["is_deleted"]) });
      setButtonName(NameButtonList.edit);
    } else if (isBlocked === BlockType.deleted) {
      setFilters({ ...omit(filters), is_deleted: true });
      setButtonName(NameButtonList.restore);
    } else {
      setButtonName(NameButtonList.edit);
      setFilters({ ...omit(filters, "is_deleted") });
    }
  }, [isBlocked]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.panel}>
        <RadioButton
          size={buttonRadioSize}
          noBorder
          initialSelect={EmployeeType.all}
          onChange={(value: string): void => setEmployeeType(value)}
          options={[
            { value: EmployeeType.all, text: "Все роли" },
            { value: EmployeeType.employees, text: `Сотрудники ${teamDisplayName[teamName]}` },
            { value: EmployeeType.admins, text: `Администраторы ${teamDisplayName[teamName]}` }
          ]}
        />
      </div>
      <div className={styles.panel}>
        <RadioButton
          size={buttonRadioSize}
          noBorder
          initialSelect={BlockType.all}
          onChange={(value: string): void => setBlocked(value)}
          options={[{ value: BlockType.all, text: "Все" }]}
        />
      </div>
    </div>
  );
};

export default EmployeesTabPanel;
