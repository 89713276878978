import React, { FC, InputHTMLAttributes } from "react";
import cn from "classnames";
import styles from "./checkbox.module.scss";

export interface PropsTypeCheckbox
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name?: string;
  id?: string;
  checked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  color?: "gray" | "blue" | "black" | "prime";
  errorMessages?: string | null;
  invalid?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  style?: any;
}

export const Checkbox: FC<PropsTypeCheckbox> = ({
  onClick,
  invalid,
  errorMessages,
  children,
  id,
  checked,
  color = "gray",
  disabled,
  onChange,
  style
}) => {
  const mainClass = cn(styles.checkbox, { [styles.children]: children, [styles[color]]: color });

  const checkmarkClass = cn(styles.checkmark, {
    [styles.danger]: invalid,
    [styles[color]]: color
  });

  return (
    <>
      <label htmlFor={id} className={mainClass}>
        {children}
        <input
          disabled={disabled}
          id={id}
          type="checkbox"
          className={checkmarkClass}
          onClick={onClick}
          onChange={onChange}
          checked={checked}
          name="checkbox"
        />
        <span className={checkmarkClass} />
      </label>
      {errorMessages && (
        <div className={styles.error} style={style}>
          {errorMessages}
        </div>
      )}
    </>
  );
};
