import { put, takeLatest, call, select } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { notificationErrors } from "components/ui/notification/notification-errors";
import {
  createThirdPartyRequest,
  createThirdPartySuccess,
  updateThirdPartyRequest,
  thirdPartyInfoRequest,
  thirdPartyInfoSuccess,
  thirdPartyInfoLoadStop,
  setThirdPartyEditMode,
  setOpenThirdPartyModalWindow
} from "./actions";
import { TYPES_THIRD_PARTY } from "./constants";
import { ThirdPartiesApi } from "../../services/api/third-parties";

// Создание Третьего лица
function* createThirdParty(action: ReturnType<typeof createThirdPartyRequest>): SagaIterator {
  try {
    const { payload } = action;
    const response = yield call(ThirdPartiesApi.createThirdPartiesEntity, payload);
    yield put(createThirdPartySuccess(response));
    yield put(setOpenThirdPartyModalWindow(true));
  } catch (e) {
    notificationErrors({});
  }
}

// Обновление Третьего лица
function* updateThirdParty(action: ReturnType<typeof updateThirdPartyRequest>): SagaIterator {
  try {
    const { id } = yield select(store => store.thirdParty.editThirdParty);
    const { payload } = action;
    yield call(ThirdPartiesApi.updateThirdPartiesEntity, id, payload);
    yield put(setOpenThirdPartyModalWindow(true));
  } catch (e) {
    notificationErrors({});
  }
}

// Получение подробной информации о Третьем лице по id
function* getThirdPartyInfo(action: ReturnType<typeof thirdPartyInfoRequest>): SagaIterator {
  try {
    const { payload } = action;
    const response = yield call(ThirdPartiesApi.getThirdPartyById, payload);
    yield put(setThirdPartyEditMode());
    yield put(thirdPartyInfoSuccess(response));
  } catch (e) {
    notificationErrors({});
  } finally {
    yield put(thirdPartyInfoLoadStop());
  }
}

function* thirdPartySaga(): SagaIterator {
  yield takeLatest(TYPES_THIRD_PARTY.THIRD_PARTY_CREATE_REQUEST, createThirdParty);
  yield takeLatest(TYPES_THIRD_PARTY.THIRD_PARTY_UPDATE_REQUEST, updateThirdParty);
  yield takeLatest(TYPES_THIRD_PARTY.THIRD_PARTY_INFO_REQUEST, getThirdPartyInfo);
}

export { thirdPartySaga };
