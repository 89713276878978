import React, { FC, useState, useEffect } from "react";
import cn from "classnames";
import mainTheme from "components/ui/switch-themes";
import styles from "./radio-button.module.scss";
import { Button } from "../button";

export type OptionsRadioButton = {
  value: string;
  text: string;
};

export interface PropsType {
  options: OptionsRadioButton[];
  onChange?: (select: string) => void;
  initialSelect?: string;
  label?: string;
  theme?: "button" | "circle";
  size?: "small" | "normal" | "xs";
  noBorder?: boolean;
  currentValue?: string;
}

export const RadioButton: FC<PropsType> = ({
  options,
  onChange,
  initialSelect,
  theme,
  label,
  size = "small",
  noBorder = false,
  currentValue
}) => {
  const [selectValue, setSelectValue] = useState(initialSelect);

  const onSelect = (value: string): void => {
    setSelectValue(value);
    if (onChange) {
      onChange(value);
    }
  };
  useEffect(() => {
    if (currentValue === initialSelect) {
      setSelectValue(initialSelect);
    }
  }, [currentValue]);

  let buttonList;
  if (theme === "circle") {
    buttonList = options.map(item => (
      <button
        className={cn(styles.circle, {
          [styles[mainTheme]]: mainTheme,
          [styles.active]: selectValue === item.value
        })}
        type="button"
        key={item.value}
        onClick={(): void => onSelect(item.value)}
      >
        {item.text}
      </button>
    ));
  } else {
    buttonList = (
      <div className={styles.wrapper}>
        {options.map(item => (
          <Button
            size={size}
            noBorder={noBorder}
            theme={selectValue === item.value ? "light" : "white"}
            key={item.value}
            onClick={(): void => onSelect(item.value)}
          >
            {item.text}
          </Button>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.radiobutton}>
      {label && <div className={styles.label}>{label}</div>}
      {buttonList}
    </div>
  );
};
RadioButton.defaultProps = {
  theme: "button"
};
