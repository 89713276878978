import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { resetErrorAndStatus } from "store/actions";
import AddedPersonalDataForm from "components/admin/added-personal-data/added-personal-data-form";
import { StoreTypes } from "store/types";
import { personalDataInfoRequest } from "store/personal-data/actions";

type Props = {
  children?: React.ReactElement;
  isEdit?: boolean;
};

const AddedPersonalData: FC<ReduxProps & Props> = ({ resetErrorsAndStatus, children }) => {
  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
    };
  }, [resetErrorsAndStatus]);

  return <>{children || <AddedPersonalDataForm />}</>;
};

const mapStateToProps = ({ personalData }: StoreTypes) => ({
  dataPersonalData: personalData.editPersonalData
});

const mapDispatchToProps = {
  resetErrorsAndStatus: resetErrorAndStatus,
  personalDataInfo: personalDataInfoRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddedPersonalData);
