import React from "react";
import { Wrapper } from "../../../ui";
import styles from "./employee-dealers.module.scss";

const EmployeeDealers = () => {
  return (
    <Wrapper>
      <p className={styles.desc}>
        Ни один сотрудник не прикреплен к дилерскому центру.
        <br />
        Вы можете добавить их из «Базы сотрудников»
      </p>
      <div className={styles.wrap_btn}>
        {/* <Button size="small">Добавить</Button> */}
        {/* <Button disabled size="small"> */}
        {/*  Открепить */}
        {/* </Button> */}
      </div>
    </Wrapper>
  );
};

export default EmployeeDealers;
