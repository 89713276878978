import { ActionsType, Store } from "./types";
import { TYPES_DELETED_PERSONAL_DATA } from "./constants";
import { RESET_ERROR_AND_STATUS } from "../constants";

const initialState: Store = {
  isLoading: false,
  error: null,
  data: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
    data: []
  },
  services: []
};

export const deletePersonalDataReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_REQUEST: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload };
    }
    case TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_ERROR: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_SERVICES_REQUEST: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_SERVICES_SUCCESS: {
      return { ...state, isLoading: false, services: action.payload };
    }
    case TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_SERVICES_ERROR: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null };
    }
    default: {
      return state;
    }
  }
};
