import React, { FC } from "react";
import cn from "classnames";
import styles from "./connection.module.scss";
import getDate from "./getDate";

interface Connect {
  browser: string | null;
  country: string | null;
  created_at: string | null;
  id: number | null;
  ip: string | null;
  updated_at: string | null;
  user_id: string | null;
  service?: string | null;
}
type PropsType = {
  connections: Array<Connect>;
  isOpen: boolean;
};

const Connection: FC<PropsType> = ({ connections, isOpen }) => {
  const mainClass = cn(styles.list, { [styles.isOpen]: isOpen && connections.length > 3 });
  const lightClass = cn(styles.row, styles.rowLight);

  return (
    <ul className={mainClass}>
      {connections
        .map(({ created_at: date, service = "", country, browser }) => {
          const parsedDate = getDate(date);
          return (
            <li key={date} className={styles.connection}>
              <div className={styles.row}>
                <div className={styles.dark}>{parsedDate}</div>
                <div className={styles.dark}>{service}</div>
              </div>
              <div className={lightClass}>
                <div className={styles.light}>{`${country},`}</div>
                <div className={styles.light}>{`браузер ${browser}`}</div>
              </div>
            </li>
          );
        })
        .sort(() => -1)}
    </ul>
  );
};

export default Connection;
