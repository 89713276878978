import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { resetErrorAndStatus } from "store/actions";
import AuthService from "components/lk/auth-service";
import ChangeEmail from "components/lk/change-email";
import ChangePhone from "components/lk/change-phone";
import MarketingComm from "components/lk/marketing-comm";
import styles from "./safety.module.scss";
import Container from "../../../components/lk/container";
import SafetyForm from "../../../components/lk/safety-form";
import DeleteAccount from "../../../components/lk/delete-account";
import { isHyundaiTheme } from "../../../components/ui/switch-themes";

type DispatchProps = {
  resetErrorsAndStatus: () => void;
};

const SafetyPage: FC<DispatchProps> = ({ resetErrorsAndStatus }) => {
  const [isMarketingConsentDialog, setIsMarketingConsentDialog] = useState(false);
  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
    };
  }, []);

  return (
    <div className={styles.safety}>
      <Container>
        <div className={styles.title}>Безопасность и вход</div>
        <SafetyForm />
        {/* <AuthService /> */}
        <ChangeEmail />
        <ChangePhone />
        {isHyundaiTheme ? (
          <MarketingComm
            isMarketingConsentDialogOpen={isMarketingConsentDialog}
            openMarketingConsentDialog={setIsMarketingConsentDialog}
          />
        ) : null}
        {isHyundaiTheme ? <DeleteAccount openMarketingConsentDialog={setIsMarketingConsentDialog} /> : null}
      </Container>
    </div>
  );
};

const mapDispatchToProps = {
  resetErrorsAndStatus: resetErrorAndStatus
};

export default connect(null, mapDispatchToProps)(SafetyPage);
