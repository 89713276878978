export default (
  currentRowsData: Array<any>,
  selectedRowsIds: Array<string | number>,
  stateData: Array<any>
): Array<any> => {
  const newRowsData = stateData.filter(item => selectedRowsIds.includes(item.id));
  const filteredUnselect = currentRowsData.filter(item => selectedRowsIds.includes(item.id));
  const union = [...newRowsData, ...filteredUnselect];
  const result = [...new Set(union.map(data => JSON.stringify(data)))].map(string => JSON.parse(string));
  return result;
};
