export const TOKEN = "token";
export const REFRESH_TOKEN = "refresh_token";
export const SESSION_TOKEN = "session_token";
export const ADMIN_TOKEN = "admin_token";
export const ADMIN_REFRESH_TOKEN = "admin_refresh_token";
export const ADMIN_SESSION_TOKEN = "admin_session_token";
export const AUTH_TOKEN_REQUEST =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5MWZmMjM0Ny1lMWEwLTQyZjgtYTNlZC0wNmE4MDYxYjNkOGMiLCJqdGkiOiJhMzg1MmMwM2ZmMTk0NjgwZGY4N2ViZjBkZjM0NzllNGUyYjcxYjljOGNiY2E0NjUyMjBiMTNjOGRiNTFkNjJmN2VkODhlNTEyZDc5ZGMyNiIsImlhdCI6MTYwNTI1MjkzNiwibmJmIjoxNjA1MjUyOTM2LCJleHAiOjE2MDc4NDQ5MzYsInN1YiI6ImEwNzZjM2IzLTEyMDMtNDhhNS04MjFiLTYyOWY1YTk2MThmNSIsInNjb3BlcyI6WyJhdXRoeiIsIm9wZW5pZCJdfQ.czgXp3KS2a4fhvBLr3B-CrzNeIYpjXbKp7-AkxRCknUc8MM2Gyskfjuc6qU82TpaJCtOuqHXSm_IP3UXaHVs0ETIqBmarTREIqBf_N-8odbaQba79ViG4gdZU8CU7VkJsxvKAHB6O_jIhdeSaMoO_ADBSJ5SK9ro5b983NOXAEjda7dGYzqHMIobpHcTLpZkk1sW932ItrmMINx3g8eAHKnaS7cqrv_1C37umW-6VB43hSSM2ukmp1LU43m_EMmb3TS4B4A5Tx_wSPAyoTRlz71YRaPgc4PECmwgNvYnaFdRMIDf_pbKzfyvims7oshKwh2tUGJhOu4G67y1MaAbOgh8OmmuGyEBFxS_1_nHdDsW-nS750WXLKvYnQ3_vEfSmjyBM2mR7Vbzs3aJCJaTOS5QgBFNNNrLHEMLlF_5M9Uov8PMaeo2FCNVzbrgEKBr8pj8oztmjicb4QO53lUvk5kRcvfxPRCnvdbZqs4xDuWHS2ajVaOmlQWUzOTP_g2tSyOXxwtO3vAlDwYYQcqWhIuoPsTXsnAmAAKPdPpauORoRB4o-lLIz8yp1DWes_coZDzIGV0XmMGSDJuSsMq_VoIPtAO-hcF8SJRYBq3wZzYAZBNWjBhyAwwGobB0ybC-xA_XYNmaDOgolZWLpIyIVQdfuU2SuaDvDdCqTUkVsp0";

export const ROUTERS_API_ON_TOKEN_REQUEST = [
  "/front/authn",
  "/front/authn/password",
  "/front/register-user",
  "/front/restore_password",
  "/front/restore_password/reset",
  "/front/check_code",
  "/front/send_code"
];

export enum OPEN_AUTH_STATUS {
  REDIRECT = "REDIRECT",
  AUTHORIZE = "AUTHORIZE"
}
