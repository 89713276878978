export const TYPES_PHONE_BLACKLIST = {
  PHONE_BLACKLIST_LOAD: "PHONE_BLACKLIST_LOAD",
  PHONE_BLACKLIST_LOAD_STOP: "PHONE_BLACKLIST_LOAD_STOP",
  PHONE_BLACKLIST_REQUEST: "PHONE_BLACKLIST_REQUEST",
  PHONE_BLACKLIST_SUCCESS: "PHONE_BLACKLIST_SUCCESS",
  PHONE_BLACKLIST_ERROR: "PHONE_BLACKLIST_ERROR",

  DELETE_PHONE_BLACKLIST_REQUEST: "DELETE_PHONE_BLACKLIST_REQUEST",
  DELETE_PHONE_BLACKLIST_SUCCESS: "DELETE_PHONE_BLACKLIST_SUCCESS",
  DELETE_PHONE_BLACKLIST_ERROR: "DELETE_PHONE_BLACKLIST_ERROR",

  PHONE_BLACKLIST_STATUS: "PHONE_BLACKLIST_STATUS"
} as const;
