import React, { FC, ReactElement, useCallback, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import NotificationPopup from "components/ui/notification-popup";
import { connect, ConnectedProps } from "react-redux";
import { StoreTypes } from "store/types";
import { Input, Text, Wrapper, Button, CustomSelect } from "components/ui";
import history from "services/history";
import { resetErrorAndStatus } from "store/actions";
import {
  createMarketingMailingsRequest,
  marketingMailingsServicesError,
  marketingMailingsServicesRequest,
  modalWindowToggle
} from "store/marketing-mailings/actions";
import TeamName from "constant/team-name";
import {
  buttonAddAndRemoveTheme,
  isHyundaiTheme,
  sizeTitleCreateUsers,
  inputAdminTheme,
  paddingCreateUser,
  company
} from "components/ui/switch-themes";
import styles from "./add-marketing-mailings-form.module.scss";
import trimValueOnBlur from "../../../helpers/trimValueOnBlur";
import UploadFile from "../../../components/admin/upload-file";
import validationSchema from "./validationSchema";
import { FileHint } from "../../../components/ui/fileHint";

type Props = {
  isEdit?: boolean;
  teamName: TeamName;
};

export type ErrorType = {
  [key: string]: string;
} | null;

type FormikAction = {
  resetForm: () => void;
};

const AddMarketingMailingsForm: FC<Props & ReduxProps> = ({
  createMarketingMailings,
  services,
  getServices,
  setOpen,
  isOpen,
  isLoading,
  setMMError
}) => {
  const [reset, setReset] = useState(false);

  useEffect(() => {
    getServices();
  }, []);

  const setErrorServerHandler = ({ data: error, status }: any): void => {
    if (error.errors) {
      if (status === 400) {
        const errorsValues = Object.entries(error.errors).reduce((acc, item: Array<any>) => {
          const [key, values] = item;
          const [value] = values;
          return { ...acc, [key]: value };
        }, {});
        setMMError(errorsValues);
      } else {
        setMMError({ updateUser: "Не удалось изменить данные" });
      }
    }
    setMMError({ updateUser: "Не удалось изменить данные" });
  };

  const submitHandler = (values: any, { resetForm }: FormikAction): void => {
    const currentValues = values;

    const resetAllForm = (): void => {
      resetForm();
      setReset(!reset);
    };

    const fd = new FormData();
    fd.append("name", currentValues.name);
    fd.append("service_id", currentValues.serviceId);
    fd.append("users", currentValues.users);
    if (currentValues.redirect_to) {
      fd.append("redirect_to", currentValues.redirect_to);
    }

    createMarketingMailings({
      body: fd,
      resetForm: resetAllForm,
      customErrorSetter: setErrorServerHandler
    });
  };

  const isHIDSelected = useCallback(
    (values: any) => {
      if (values.serviceId) {
        const currentService = services.find(service => service.id === values.serviceId);
        if (currentService && currentService.code === "hid") {
          return false;
        }
        return true;
      }
      return true;
    },
    [services]
  );

  return (
    <div className={styles.wrapper}>
      <NotificationPopup
        title="Данные успешно сохранены"
        isOpen={isOpen}
        action={() => {
          history.push("/admin/marketing-mailings");
          setOpen(false);
        }}
      />
      <Formik
        initialValues={{ name: undefined, serviceId: undefined, redirect_to: undefined }}
        onSubmit={submitHandler}
        validateOnBlur={false}
        validateOnChange
        validationSchema={validationSchema()}
      >
        {({ values, setFieldTouched, setFieldValue, errors, touched, handleChange }): ReactElement => (
          <Form>
            <div className={styles.wrapper}>
              <div className={styles.block1}>
                <div>
                  <Wrapper pt={48} pb={24}>
                    <Text uppercase={!isHyundaiTheme} size={sizeTitleCreateUsers} theme="black">
                      Новая маркетинговая рассылка
                    </Text>
                  </Wrapper>
                </div>
                <div className={styles.wrap}>
                  <div className={styles.blockColumn}>
                    <Wrapper pb={paddingCreateUser}>
                      <Input
                        name="name"
                        label="Название"
                        type="text"
                        theme={inputAdminTheme}
                        placeholder="Название"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={(): void => {
                          trimValueOnBlur("name", values.name, setFieldValue, setFieldTouched);
                        }}
                        errorMessages={(touched.name && errors.name) || (values.name && errors.name)}
                        invalid={Boolean(errors.name && touched.name)}
                      />
                    </Wrapper>
                  </div>
                  <div className={styles.blockColumn}>
                    <Wrapper pb={paddingCreateUser}>
                      <CustomSelect
                        options={services}
                        name="serviceId"
                        label="Сервис"
                        theme={inputAdminTheme}
                        placeholder="Выберите сервис"
                        value={values.serviceId}
                        onChange={(e): void => {
                          setFieldValue("serviceId", e.target.value);
                        }}
                        errorMessages={
                          (touched.serviceId && errors.serviceId) || (values.serviceId && errors.serviceId)
                        }
                        invalid={Boolean(errors.serviceId && touched.serviceId)}
                      />
                    </Wrapper>
                  </div>
                </div>
                <div className={styles.wrap}>
                  <div className={styles.blockColumn}>
                    <Wrapper pb={paddingCreateUser}>
                      <Input
                        disabled={isHIDSelected(values)}
                        name="redirect_to"
                        label="Ссылка на страницу в HID"
                        type="text"
                        theme={inputAdminTheme}
                        placeholder="Например: /home"
                        value={values.redirect_to}
                        onChange={handleChange}
                        onBlur={(): void => {
                          trimValueOnBlur("redirect_to", values.redirect_to, setFieldValue, setFieldTouched);
                        }}
                        errorMessages={
                          (touched.redirect_to && errors.redirect_to) || (values.name && errors.redirect_to)
                        }
                        invalid={Boolean(errors.redirect_to && touched.redirect_to)}
                      />
                    </Wrapper>
                  </div>
                  <div className={styles.blockColumn}>
                    <Wrapper pt={paddingCreateUser} pb={paddingCreateUser}>
                      <FileHint
                        isHyundaiTheme
                        text={`Загрузите файл формата CSV, содержащий список ${company} или номеров телефона или email`}
                      >
                        <UploadFile setFieldValue={setFieldValue} theme="light" />
                      </FileHint>
                    </Wrapper>
                  </div>
                </div>
              </div>
              <div className={styles.block2}>
                <Wrapper pt={32} pb={16}>
                  <div className={styles.btnBlock}>
                    <Button
                      theme={buttonAddAndRemoveTheme}
                      disabled={isLoading}
                      onClick={(): void => {
                        // scrollUp(errors, setUseScrollUp)
                      }}
                      type="submit"
                    >
                      Сохранить
                    </Button>
                    <Button
                      theme="white"
                      onClick={(): void => {
                        history.push("./");
                      }}
                      noBorder
                    >
                      Отменить
                    </Button>
                  </div>
                </Wrapper>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ marketingMailings }: StoreTypes) => ({
  services: marketingMailings.services,
  isOpen: marketingMailings.modalWindow,
  isLoading: marketingMailings.isLoading
});

const mapDispatchToProps = {
  createMarketingMailings: createMarketingMailingsRequest,
  resetErrorsAndStatus: resetErrorAndStatus,
  resetError: resetErrorAndStatus,
  getServices: marketingMailingsServicesRequest,
  setOpen: modalWindowToggle,
  setMMError: marketingMailingsServicesError
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(React.memo(AddMarketingMailingsForm));
