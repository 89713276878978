import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import { formatPhoneNumber } from "helpers/format-phone-number";
import { PHONE_MASK } from "constant/phone-mask";
import { StoreTypes } from "store/types";
import { authAddedPhone, authUpdateStepSignIn, serverError } from "store/auth/actions";
import { STEP_SIGN_IN } from "constant/step-auth";
import { handlerPaste } from "helpers/handlePastePhone";
import { Button, Input, Link, Text, Wrapper } from "components/ui";
import { authLogo } from "components/ui/helpersComponents";
import { isHyundaiTheme, labelPhone, sizeTitleAuth, titleCompanyTheme } from "components/ui/switch-themes";
import { InvisibleSmartCaptcha } from "@yandex/smart-captcha";

const validationSchema = yup.object().shape({
  phone: yup
    .string()
    .required("Необходимо заполнить данные")
    .transform(value => value.replace(/[^\d]/g, ""))
    .min(11, "Номер телефона должен состоять из 11 символов")
});

const SingInAddedPhone: FC<ReduxProps> = ({ warning, errorServer, onStep, onUpdate }: ReduxProps) => {
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [validCaptchaSuccess, setValidCaptchaSuccess] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [resetCaptcha, setResetCaptcha] = useState(0);
  const handleReset = () => {
    setValidCaptcha(false);
    setResetCaptcha(prev => prev + 1);
  };

  const formik = useFormik({
    initialValues: {
      phone: ""
    },
    validationSchema,
    onSubmit: () => {
      setValidCaptcha(true);
    }
  });

  useEffect(() => {
    if (validCaptchaSuccess) {
      warning(null);
      const value = formatPhoneNumber(String(formik.values));
      onUpdate({ username: value, phone: value, type: "phone", token: captchaToken });
    }
    handleReset();
    setValidCaptchaSuccess(false);
  }, [validCaptchaSuccess]);

  useEffect(() => {
    if (errorServer) {
      formik.setFieldError("phone", errorServer);
    }
  }, [errorServer]);

  return (
    <div className="text-center">
      <form onSubmit={formik.handleSubmit}>
        {authLogo}
        <Wrapper pb={48}>
          <Text uppercase={!isHyundaiTheme} size={sizeTitleAuth} align="center" theme={titleCompanyTheme}>
            Введите ваш номер телефона
          </Text>
        </Wrapper>
        <Wrapper pb={32}>
          <Input
            type="tel"
            name="phone"
            placeholder="Введите телефон"
            label={labelPhone}
            mask={PHONE_MASK}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            errorMessages={formik.errors.phone && formik.touched.phone ? formik.errors.phone : null}
            invalid={Boolean(formik.errors.phone && formik.touched.phone)}
            onPaste={(elem: React.ClipboardEvent<HTMLInputElement>) => handlerPaste(elem, formik.setFieldValue)}
          />
        </Wrapper>
        <InvisibleSmartCaptcha
          key={resetCaptcha}
          sitekey={process.env.REACT_APP_YANDEX_CAPTCHA_ID ?? ""}
          onSuccess={token => {
            setCaptchaToken(token);
            setValidCaptchaSuccess(true);
          }}
          visible={validCaptcha}
          onChallengeHidden={() => handleReset()}
        />
        <Wrapper pb={48}>
          <Button fullWidth type="submit">
            Продолжить
          </Button>
        </Wrapper>
        <Wrapper pb={48}>
          <Link href="/" size="big" isButton onClick={() => onStep(STEP_SIGN_IN.LOGIN)}>
            На главную
          </Link>
        </Wrapper>
      </form>
    </div>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  errorServer: store.auth.serverError
});

const mapDispatchToProps = {
  onStep: authUpdateStepSignIn,
  onUpdate: authAddedPhone,
  warning: serverError
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SingInAddedPhone);
