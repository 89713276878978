import React, { FC } from "react";
import cn from "classnames";

import { renderIcons } from "components/ui/social/images/icons";
import { buttonTheme } from "components/ui/switch-themes";
import styles from "./social.module.scss";

export type PropsType = {
  onClick?: () => void;
  icon: string;
  theme?: "blue" | "gold";
};

export const Social: FC<PropsType> = ({ theme = buttonTheme, onClick, icon }) => {
  const cnTheme = cn(styles.social, {
    [styles[theme]]: theme
  });

  return (
    <button type="button" className={cnTheme} onClick={onClick}>
      {renderIcons(icon)}
    </button>
  );
};
