import React, { FC } from "react";
import cn from "classnames";
import { spinnerCompanyTheme } from "components/ui/switch-themes";
import styles from "./loading.module.scss";

export type PropsType = {
  isLoading: boolean;
  color?: "white" | "black";
};

export const Loading: FC<PropsType> = ({ color = spinnerCompanyTheme, isLoading, children }) => {
  const cnSpinner = cn(styles.loading, {
    [styles[color]]: color
  });
  const cnBackground = cn(styles.background, {
    [styles[color]]: color
  });
  const spinner = (
    <div className={cnSpinner}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
  return (
    <div className={styles.wrapper}>
      {children}
      {isLoading && <div className={cnBackground}>{spinner}</div>}
    </div>
  );
};
