export const TYPES_DEALERS = {
  DEALERS_LOAD: "DEALERS_LOAD",
  DEALERS_LOAD_STOP: "DEALERS_LOAD_STOP",
  DEALERS_REQUEST: "DEALERS_REQUEST",
  DEALERS_SUCCESS: "DEALERS_SUCCESS",
  DEALERS_ERROR: "DEALERS_ERROR",
  CREATE_DEALERS: "CREATE_DEALERS",
  UPDATE_DEALERS: "UPDATE_DEALERS",
  GET_EMPLOYEES_FILTER: "GET_EMPLOYEES_FILTER",
  GET_BASE_EMPLOYEES: "GET_BASE_EMPLOYEES",
  ADDED_EMPLOYEES_DC: "ADDED_EMPLOYEES_DC",
  DELETE_EMPLOYEES_DC: "DELETE_EMPLOYEES_DC",
  ATTACH_EMPLOYEES_DC: "ATTACH_EMPLOYEES_DC",
  DEALERSHIP_INFO: "DEALERSHIP_INFO",
  DEALERS_EMPLOYEE_UPDATE: "DEALERS_EMPLOYEE_UPDATE",
  DEALERSHIP_ENTRY_EDITING: "DEALERSHIP_ENTRY_EDITING",
  DEALERSHIP_EDITING_VALUE: "DEALERSHIP_EDITING_VALUE",
  PUT_EMPLOYEES_DC: "PUT_EMPLOYEES_DC",
  SERVER_ERROR_DC: "SERVER_ERROR_DC",
  MODAL_OPEN_DC: "MODAL_OPEN_DC",
  USERS_LIST_DC: "USERS_LIST_DC"
} as const;
