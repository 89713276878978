import { Role, AttachRolesType } from "./types";
import { TYPES_ROLES } from "./constants";

export const rolesLoad = () => ({ type: TYPES_ROLES.ROLES_LOAD });

export const rolesLoadStop = () => ({
  type: TYPES_ROLES.ROLES_LOAD_STOP
});
/// todo проставить типы
export const rolesRequest = (params?: any) => ({
  type: TYPES_ROLES.ROLES_REQUEST,
  payload: params
});

export const rolesSuccess = (roles: Role[]) => ({
  type: TYPES_ROLES.ROLES_SUCCESS,
  payload: roles
});

export const rolesError = (
  error: {
    [key: string]: string;
  } | null
) => ({
  type: TYPES_ROLES.ROLES_ERROR,
  payload: error
});

export const attachRolesRequest = (body: any) => ({
  type: TYPES_ROLES.ATTACH_ROLES_REQUEST,
  payload: body
});

export const detachRolesRequest = (body: AttachRolesType) => ({
  type: TYPES_ROLES.DETACH_ROLES_REQUEST,
  payload: body
});
