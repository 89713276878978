import React, { FC } from "react";
import Row from "components/ui/row";
import { Button, Text, Wrapper } from "components/ui";
import { connect, ConnectedProps } from "react-redux";
import { usersRequest } from "store/users/actions";
import history from "../../services/history";
import UserManual from "./user-manual";

type Props = {
  message?: string;
};

const UserManualPage: FC<Props & ReduxProps> = ({ userInfo }) => {
  const reLoad = () => {
    history.push("/home");
    window.location.reload();
  };
  return (
    <div>
      <UserManual />
    </div>
  );
};

const mapDispatchToProps = {
  userInfo: usersRequest
};

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(UserManualPage);
