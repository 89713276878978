import React, { FC, useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import history from "services/history";
import { Text, Wrapper } from "components/ui";
import SearchForm from "components/admin/search-form/search-form";
import { Table } from "components/ui/table";
import { Loading } from "components/ui/loading";
import Pagination from "components/ui/pagination/pagination";
import Select from "components/ui/select";
import { StoreTypes } from "store/types";
import { thirdPartiesRequest, deleteThirdPartyRequest, setThirdPartiesRows } from "store/third-parties-list/actions";
import selectOptions from "constant/quantity-rows-per-admin-page";
import ButtonAdd from "components/admin/button-add";
import DeleteRows from "components/admin/delete-rows";
import Row from "components/ui/row";
import { ThirdPartiesEntity } from "store/third-parties-list/types";
import debounce from "helpers/debounce";
import { resetErrorAndStatus } from "store/actions";
import setRowsDataHandler from "helpers/setRowsData";
import NotificationPopup from "components/ui/notification-popup";
import usePermissions from "helpers/usePermissions";
import { superadminPermissions } from "constant/permissions";
import { paginationTheme } from "components/ui/switch-themes";
import { getThirdPartiesSuggestion } from "helpers/function-suggest";
import { columns, deleteRowsColumn } from "./columns";
import styles from "./thirdPartiesPage.module.scss";
import Warning from "../../../components/ui/warning";

const ThirdPartiesPage: FC<ReduxProps> = ({
  status,
  isLoading,
  thirdPartiesRequest: getThirdPartiesList,
  thirdPartiesEntities: { data: thirdPartiesEntities, last_page: lastPage },
  deleteThirdPartyRequest: deleteThirdParty,
  deleteError,
  resetErrorsAndStatus,
  setRows,
  selectedRows
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRowsData, setRowsData] = useState<Array<ThirdPartiesEntity>>([]);
  const [notification, setNotification] = useState<boolean>(false);

  const isPermissionAdminMain = usePermissions(superadminPermissions);

  useEffect(() => {
    setRowsData(setRowsDataHandler(selectedRowsData, selectedRows, thirdPartiesEntities));
  }, [selectedRows, thirdPartiesEntities, setRowsData]);

  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
      setRows([]);
    };
  }, []);

  const getSuggestionByDebounce = debounce(getThirdPartiesSuggestion, 500);

  const getThirdPartyID = (id: string | number): void => {
    history.push(`/admin/third-parties/added/${id}`);
  };

  useEffect(() => {
    if (status) {
      setNotification(true);
    }
  }, [status]);

  return (
    <>
      <div className={styles.header}>
        <NotificationPopup
          title="Данные успешно удалены"
          isOpen={notification}
          action={(): void => setNotification(false)}
        />
        <Wrapper pb={20}>
          <Text size="h3" theme="black">
            Третьи лица
          </Text>
        </Wrapper>
        {isPermissionAdminMain ? (
          <Row justifyContent="spaceBetween">
            <Row wrap>
              <Wrapper pr={10}>
                <ButtonAdd path="/admin/third-parties/added" />
              </Wrapper>
              <DeleteRows
                selectedRows={selectedRows}
                deleteRowsHandler={deleteThirdParty}
                error={deleteError?.deleteThirdParty || ""}
                resetErrorAndStatus={resetErrorsAndStatus}
                columns={deleteRowsColumn}
                selectedRowsData={selectedRowsData}
                disabled={isLoading}
              />
            </Row>
            <SearchForm
              rowsPerPage={rowsPerPage}
              action={getThirdPartiesList}
              suggestionOnClick={getThirdPartyID}
              getSuggestion={getSuggestionByDebounce}
              placeholder="Поиск по названию и email"
              currentPage={currentPage}
              typeData="ThirdParties"
            />
          </Row>
        ) : null}
      </div>
      <div className={styles.wrapper}>
        <Loading isLoading={isLoading}>
          <Table
            columns={columns}
            data={thirdPartiesEntities}
            rowSelection={(rows): any => setRows(rows)}
            rowEdit={getThirdPartyID}
            selectedRows={selectedRows}
          />
        </Loading>

        {!isLoading && thirdPartiesEntities.length === 0 && (
          <Wrapper pt={16}>
            <Warning color="black">Ничего не найдено</Warning>
          </Wrapper>
        )}
        <Wrapper pt={32}>
          <div className={styles.bottomBlock}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
            <Select options={selectOptions} onClick={setRowsPerPage} />
          </div>
        </Wrapper>
      </div>
    </>
  );
};

const mapStateToProps = ({ thirdPartiesList }: StoreTypes) => ({
  isLoading: thirdPartiesList.isLoading,
  thirdPartiesEntities: thirdPartiesList.thirdPartiesEntities,
  deleteError: thirdPartiesList.deleteError,
  selectedRows: thirdPartiesList.selectedRows,
  status: thirdPartiesList.status
});

const mapDispatchToProps = {
  thirdPartiesRequest,
  deleteThirdPartyRequest,
  resetErrorsAndStatus: resetErrorAndStatus,
  setRows: setThirdPartiesRows
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ThirdPartiesPage);
