export const TYPES_MARKETING_MAILINGS = {
  MARKETING_MAILINGS_LOAD: "MARKETING_MAILINGS_LOAD",
  MARKETING_MAILINGS_LOAD_STOP: "MARKETING_MAILINGS_LOAD_STOP",
  MARKETING_MAILINGS_REQUEST: "MARKETING_MAILINGS_REQUEST",
  MARKETING_MAILINGS_SUCCESS: "MARKETING_MAILINGS_SUCCESS",
  MARKETING_MAILINGS_START_REPEAT: "MARKETING_MAILINGS_START_REPEAT",
  MARKETING_MAILINGS_STOP_REPEAT: "MARKETING_MAILINGS_STOP_REPEAT",
  MARKETING_MAILINGS_REPEAT: "MARKETING_MAILINGS_REPEAT",
  SET_MARKETING_MAILINGS_ROWS: "SET_MARKETING_MAILINGS_ROWS",
  CREATE_MARKETING_MAILINGS_REQUEST: "CREATE_MARKETING_MAILINGS_REQUEST",
  DELETE_MARKETING_MAILINGS_REQUEST: "DELETE_MARKETING_MAILINGS_REQUEST",
  DELETE_MARKETING_MAILINGS_SUCCESS: "DELETE_MARKETING_MAILINGS_SUCCESS",
  DELETE_MARKETING_MAILINGS_ERROR: "DELETE_MARKETING_MAILINGS_ERROR",
  FILTER_MM_ROWS: "FILTER_MM_ROWS",
  DOWNLOAD_MARKETING_MAILINGS_REQUEST: "DOWNLOAD_MARKETING_MAILINGS_REQUEST",
  MARKETING_MAILINGS_ERROR: "MARKETING_MAILINGS_ERROR",
  MARKETING_MAILINGS_STATUS: "MARKETING_MAILINGS_STATUS",
  RESET_ERROR_AND_STATUS: "RESET_ERROR_AND_STATUS",
  MODAL_TOGGLE_MM: "MODAL_TOGGLE_MM",
  MARKETING_MAILINGS_SERVICES_REQUEST: "MARKETING_MAILINGS_SERVICES_REQUEST",
  MARKETING_MAILINGS_SERVICES_LOAD: "MARKETING_MAILINGS_SERVICES_LOAD",
  MARKETING_MAILINGS_SERVICES_SUCCESS: "MARKETING_MAILINGS_SERVICES_SUCCESS",
  MARKETING_MAILINGS_SERVICES_ERROR: "MARKETING_MAILINGS_SERVICES_ERROR"
} as const;

export enum MarketingMailingsRequestParams {
  page = "page",
  limit = "limit"
}
