import React, { FC } from "react";
import { Logo, Text, Wrapper } from "components/ui";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./user-manual.module.scss";
import iconWarning from "./images/ic_warn.svg";
import iconInfo from "./images/ic_info.svg";
import {
  MANUAL_BLOCK_TYPES,
  manualInstruction,
  ManualListBlock,
  ManualSection,
  ManualTextBlock,
  ManualWarningBlock
} from "./manual-constants";
import { TextSize } from "../../components/ui/text/text";
import Row from "../../components/ui/row";
import { isHyundaiTheme } from "../../components/ui/switch-themes";
import Footer from "../../components/lk/footer";
import FooterGns from "../../components/lk/footer-gns";

const mainTheme = isHyundaiTheme ? "hyundai" : "genesis";

const getTextSize = (level?: number): TextSize => {
  switch (level) {
    case 1:
      return "h3";
    case 2:
      return "h4";
    default:
      return "h5";
  }
};

const createTextBlock = (block: ManualTextBlock) => {
  return (
    <Wrapper pt={12}>
      <Text size="main" theme={isHyundaiTheme ? "blue" : "black"}>
        {block.content}
      </Text>
    </Wrapper>
  );
};

const createListBlock = (block: ManualListBlock) => {
  return (
    <Text size="main" theme={isHyundaiTheme ? "blue" : "black"}>
      <ol>
        {block.content.map((item, index) => (
          <li key={2}>{item}</li>
        ))}
      </ol>
    </Text>
  );
};

const createWarningBlock = (block: ManualWarningBlock) => {
  const cnBackground = cn(styles.warningBackground, {
    [styles[block.color]]: block.color
  });
  return (
    <div className={cnBackground}>
      <div>
        <img src={block.color === "red" ? iconWarning : iconInfo} alt="" />
      </div>
      <div>
        <Text size="main" theme={isHyundaiTheme ? "blue" : "black"}>
          <b>{block.title}</b>
        </Text>
        <Text size="main" theme={isHyundaiTheme ? "blue" : "black"}>
          {block.text}
        </Text>
      </div>
    </div>
  );
};

const createSection = (section: ManualSection) => {
  const content = section.children.map(children => {
    switch (children.type) {
      case MANUAL_BLOCK_TYPES.SECTION: {
        return createSection(children);
      }
      case MANUAL_BLOCK_TYPES.TEXT: {
        return createTextBlock(children);
      }
      case MANUAL_BLOCK_TYPES.LIST: {
        return createListBlock(children);
      }
      case MANUAL_BLOCK_TYPES.WARNING: {
        return createWarningBlock(children);
      }
      default: {
        return null;
      }
    }
  });
  return (
    <Wrapper pt={32 / section.level}>
      <Text size={getTextSize(section.level)} theme={isHyundaiTheme ? "blue" : "black"}>
        {section.title}
      </Text>
      {content}
    </Wrapper>
  );
};

const createManual = (manual: Array<ManualSection>) => manual.map(section => createSection(section));

const UserManual: FC = () => {
  const cnBanner = cn(styles.banner, {
    [styles[mainTheme]]: mainTheme
  });

  const cnFooter = cn(styles.footer, {
    [styles[mainTheme]]: mainTheme
  });

  const cnWrapper = cn(styles.wrapper, {
    [styles[mainTheme]]: mainTheme
  });

  return (
    <div className={cnBanner}>
      <NavLink to="/" className={styles.logo}>
        <Logo type={isHyundaiTheme ? "logo3" : "logo5"} />
      </NavLink>
      <Wrapper pt={64} pb={64} pl={64} pr={64} className={cnWrapper}>
        <Row justifyContent="justifyCenter" alignItems="alignCenter">
          <Text size="h2" theme={isHyundaiTheme ? "blue" : "black"}>
            Инструкция пользователя
          </Text>
        </Row>
        {createManual(manualInstruction)}
      </Wrapper>
      <div className={cnFooter}>{isHyundaiTheme ? <Footer /> : <FooterGns />}</div>
    </div>
  );
};

export default UserManual;
