import { Employee } from "store/employees/types";
import { normalizePhone } from "helpers/format-phone-number";
import { Service as ServiceType } from "../../../store/services/types";

type InitValuesType = {
  [key: string]: any;
};

const getInitialValues = (isEdit: boolean, currentEmployee: Employee | null, employeeId: string): InitValuesType => {
  if (isEdit) {
    if (currentEmployee) {
      const employeeConnectedServices = currentEmployee.connected_services;
      const employeeConnectedServicesFront = currentEmployee.connected_services_front;
      const employeeUnconnectedServices = currentEmployee.unconnected_services;

      const initConnectedServices = employeeConnectedServices
        ? (employeeConnectedServices as Array<ServiceType>).map(service => service.id)
        : [];
      const initConnectedServicesFront = employeeConnectedServicesFront
        ? (employeeConnectedServicesFront as Array<ServiceType>).map(service => service.id)
        : [];
      const initUnconnectedServices = employeeUnconnectedServices
        ? (employeeUnconnectedServices as Array<ServiceType>).map(service => service.id)
        : [];

      const editUserInitialValues = {
        id: employeeId,
        is_deleted: currentEmployee.is_deleted,
        roles:
          currentEmployee.roles &&
          ((Array.isArray(currentEmployee.roles) ? currentEmployee.roles : Object.values(currentEmployee.roles)) || []),
        name: currentEmployee.name || "",
        lastname: currentEmployee.lastname || "",
        team: currentEmployee.team ? currentEmployee.team.id : "", /// todo зачем?
        teams: currentEmployee.teams || [],
        birthdate: currentEmployee.birthdate,
        email: currentEmployee.email || "",
        connectedServices: initConnectedServices.sort(),
        connectedServicesFront: initConnectedServicesFront.sort(),
        unconnectedServices: initUnconnectedServices.sort(),
        phone: currentEmployee.phone ? normalizePhone(currentEmployee.phone) : "",
        picture: currentEmployee.picture || "",
        position: currentEmployee.position ? currentEmployee.position.title : "",
        position_id: currentEmployee.position ? currentEmployee.position.id : "",
        department: currentEmployee.department?.title || "",
        department_id: currentEmployee.department?.id || null
      };
      return editUserInitialValues;
    }
  }
  const addUserInitialValues = {
    isEmployee: false,
    roles: [],
    name: "",
    lastname: "",
    birthdate: null,
    team: "",
    teams: [],
    email: "",
    connectedServices: [],
    connectedServicesFront: [],
    unconnectedServices: [],
    password: "",
    confirmedPassword: "",
    phone: "",
    picture: "",
    position: "",
    position_id: "",
    department: "",
    department_id: null
  };
  return addUserInitialValues;
};

export default getInitialValues;
