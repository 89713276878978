/* eslint-disable no-shadow */
import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { deleteAccountCheckRequest, deleteAccountRequest, sendCodeRequest } from "store/users/actions";
import { StoreTypes } from "store/types";
import { Button, Input, Popup, Table, Wrapper } from "components/ui";
import { company, labelCode } from "components/ui/switch-themes";
import { useFormik } from "formik";
import RestoreAccount from "components/lk/restore-account";
import * as yup from "yup";
import { InvisibleSmartCaptcha } from "@yandex/smart-captcha";
import styles from "./delete-account.module.scss";
import PopupConfirm from "../../ui/popup-confirm";
import Timer from "../../ui/timer/timer";
import { authUpdateTtl, serverError } from "../../../store/auth/actions";
import { resetErrorAndStatus } from "../../../store/actions";
import history from "../../../services/history";
import { ServicesType } from "../../../store/users/types";

const validationSchema = yup.object().shape({
  code: yup
    .string()
    .transform(value => value.replace(/[^\d]/g, ""))
    .required("Необходимо ввести код")
    .min(4, "Код должен состоять из 4 символов")
    .max(4, "Код должен состоять из 4 символов")
});

type Props = {
  id: string;
  phone: string;
  username: string | null;
  isLoading: boolean;
  isDeleted: boolean | null;
  marketingConsent: boolean | null;
  error: {
    [key: string]: string;
  } | null;
  status: {
    [key: string]: string;
  } | null;
};

type ComponentProps = {
  openMarketingConsentDialog: any;
};

const columns = [
  {
    title: "Название сервиса",
    dataIndex: "name",
    render: ({ name }: ServicesType): string => name
  },
  {
    title: "Ссылка на сервис",
    dataIndex: "link",
    // eslint-disable-next-line react/display-name
    render: ({ link }: ServicesType): JSX.Element => (
      <a target="_blank" rel="noreferrer" href={link}>
        {link}
      </a>
    )
  }
];

const DeleteAccount: FC<Props & ReduxProps & ComponentProps> = ({
  id,
  phone,
  isDeleted,
  isLoading,
  marketingConsent,
  error,
  status,
  updateTime,
  resetStatus,
  warning,
  onSubmitCode,
  deleteAccountCheckRequest,
  openMarketingConsentDialog,
  sendCodeRequest: onDeleteAccepted
}) => {
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [isServicesPopupOpen, setServicesPopupOpen] = useState(false);
  const [isCodePopupOpen, setIsCodePopupOpen] = useState(false);

  const [showTimer, setShowTimer] = useState(false);

  const [tableData, setTableData] = useState<ServicesType[]>([]);

  const [validCaptcha, setValidCaptcha] = useState(false);
  const [validCaptchaSuccess, setValidCaptchaSuccess] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const handleReset = () => {
    setValidCaptcha(false);
    setResetCaptcha(prev => prev + 1);
  };

  const closeServicesPopup = (): void => {
    setServicesPopupOpen(false);
  };

  const closeCodePopup = (): void => {
    setIsCodePopupOpen(false);
  };

  const closeServicePopup = (): void => {
    setServicesPopupOpen(false);
    history.push("/home");
  };

  const deleteCheck = () => {
    deleteAccountCheckRequest();
  };

  useEffect(() => {
    return () => setShowTimer(false);
  }, []);

  const resendCode = () => {
    onDeleteAccepted({ phone, type: "phone", username: phone, reason: "delete-profile", token: captchaToken });
  };

  const formik = useFormik({
    initialValues: {
      code: ""
    },
    validateOnChange: true,
    validationSchema,
    onSubmit: values => {
      const data = String(values.code);
      warning(null);
      onSubmitCode({ code: data, id });
    }
  });

  const deleteAccepted = (): void => {
    setConfirmOpen(true);
  };

  const deleteConfirmed = (): void => {
    setConfirmOpen(false);
    onDeleteAccepted({ phone, type: "phone", username: null, reason: "delete-profile", token: captchaToken });
    setIsCodePopupOpen(true);
    formik.validateField("code");
  };

  const openMarketingCommunicationModal = (): void => {
    setConfirmOpen(false);
    openMarketingConsentDialog(true);
  };

  useEffect(() => {
    if (validCaptchaSuccess) {
      deleteConfirmed();
    }
    handleReset();
    setValidCaptchaSuccess(false);
  }, [validCaptchaSuccess]);

  useEffect(() => {
    if (status?.needToDeleteServices === "Необходимо удалить свои данные в других сервисах") {
      setConfirmOpen(false);
      setServicesPopupOpen(true);
      setIsCodePopupOpen(false);
      if (status?.services) {
        setTableData((status.services as unknown) as ServicesType[]);
      }
    } else if (status?.deleteAccount === "Аккаунт готов к удалению") {
      deleteAccepted();
    }
    if (status?.sendCode === "Код отправлен") {
      resetStatus();
      updateTime("120");
    }
    if (status?.deleteAccount === "Заявка на удаление принята") {
      setIsCodePopupOpen(false);
    }
  }, [status, error]);

  if (isDeleted) {
    return <RestoreAccount />;
  }
  return (
    <div className={styles.deleteAcc}>
      <Button theme="link" onClick={(): void => deleteCheck()}>{`Удалить аккаунт ${company.toUpperCase()}`}</Button>
      <PopupConfirm
        isOpen={isConfirmOpen}
        setOpen={setConfirmOpen}
        action={() => setValidCaptcha(true)}
        desc=""
        title="Вы точно хотите удалить аккаунт?"
        disabled={isLoading}
      >
        <div className={styles.desc}>
          <div>{`Удаление аккаунта ${company} приведёт к уничтожению персональных данных и полному удалению аккаунта`}</div>
          {marketingConsent ? (
            <div className={styles.cancelFont}>
              Возможно, Вы хотите отозвать согласие на коммуникацию с ООО «Хендэ Мотор СНГ»? Для этого нажмите
              <button type="button" onClick={() => openMarketingCommunicationModal()}>
                {" "}
                cюда
              </button>
            </div>
          ) : null}
          <div className={styles.smallFont}>
            <div>
              Сроки удаления согласно
              <a href="https://www.hyundai.ru/Legal"> пользовательскому соглашению</a>
            </div>
          </div>
        </div>
        <InvisibleSmartCaptcha
          key={resetCaptcha}
          sitekey={process.env.REACT_APP_YANDEX_CAPTCHA_ID ?? ""}
          onSuccess={token => {
            setCaptchaToken(token);
            setValidCaptchaSuccess(true);
          }}
          visible={validCaptcha}
          onChallengeHidden={() => handleReset()}
        />
      </PopupConfirm>
      <Popup isOpen={isServicesPopupOpen} onClick={closeServicesPopup}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.title}>Удалите свои данные из приведенных ниже сервисов</div>
          </div>
          <Table columns={columns} data={tableData.map((el, id) => ({ name: el.name, link: el.link, id }))} />
          <div className={styles.submitBtnContainer}>
            <Button onClick={closeServicePopup}>Ок</Button>
          </div>
        </div>
      </Popup>
      <Popup isOpen={isCodePopupOpen} onClick={closeCodePopup}>
        <div className={styles.row}>
          <div className={styles.title}>Прослушайте сообщение оператора и введите 4 цифры в поле</div>
        </div>
        <div className={styles.subtitle}>Код дополнительно выслан на Вашу почту</div>
        <div className={styles.form}>
          <form onSubmit={formik.handleSubmit} className={styles.formStyle}>
            <Input
              name="code"
              label={labelCode}
              placeholder="Введите код"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.code}
              errorMessages={formik.errors.code && formik.touched.code ? formik.errors.code : null}
              invalid={Boolean(formik.errors.code && formik.touched.code)}
              onKeyDown={() => formik.setFieldTouched("code")}
            />
            <Wrapper pb={32} className={styles.submitBtnContainer}>
              <Timer setShowTimer={isShowed => setShowTimer(isShowed)} start={showTimer} onClick={resendCode} />
            </Wrapper>
            <div className={styles.submitBtnContainer}>
              <Button disabled={!(formik.isValid && formik.dirty) || isLoading} type="submit">
                {`Удалить аккаунт ${company.toUpperCase()}`}
              </Button>
            </div>
          </form>
        </div>
      </Popup>
    </div>
  );
};

const mapStateToProps = ({ users: { user, isLoading }, users }: StoreTypes): Props => ({
  id: user.id,
  phone: user.phone,
  username: user.username,
  isDeleted: user.is_deleted,
  isLoading,
  marketingConsent: user.marketing_consent,
  status: users.status,
  error: users.error
});

const mapDispatchToProps = {
  sendCodeRequest,
  deleteAccountCheckRequest,
  onSubmitCode: deleteAccountRequest,
  updateTime: authUpdateTtl,
  warning: serverError,
  resetStatus: resetErrorAndStatus
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(DeleteAccount);
