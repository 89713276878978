import { isEqual } from "lodash";
import { Role } from "store/roles/types";

export const isChangedValues = (initialValues: any, values: any): boolean => {
  let sortedValues;
  if (values.roles) {
    sortedValues = { ...values, roles: [...(values.roles as Array<Role>)].sort((a, b) => (a.id > b.id ? 1 : -1)) };
  } else {
    sortedValues = values;
  }
  return isEqual(initialValues, sortedValues);
};
