export const TYPES_LE = {
  LEGAL_ENTITY_INFO_REQUEST: "LEGAL_ENTITY_INFO_REQUEST",
  LEGAL_ENTITY_INFO_SUCCESS: "LEGAL_ENTITY_INFO_SUCCESS",
  LEGAL_ENTITY_INFO_LOAD_STOP: "LEGAL_ENTITY_INFO_LOAD_STOP",
  LEGAL_ENTITY_INFO_CLEAR: "LEGAL_ENTITY_INFO_CLEAR",

  SET_LEGAL_ENTITY_EDIT_MODE: "SET_LEGAL_ENTITY_EDIT_MODE",

  LEGAL_ENTITY_CREATE_REQUEST: "LEGAL_ENTITY_CREATE_REQUEST",
  LEGAL_ENTITY_CREATE_SUCCESS: "LEGAL_ENTITY_CREATE_SUCCESS",
  LEGAL_ENTITY_CREATE_ERROR: "LEGAL_ENTITY_CREATE_ERROR",

  LEGAL_ENTITY_UPDATE_REQUEST: "LEGAL_ENTITY_UPDATE_REQUEST",
  LEGAL_ENTITY_UPDATE_SUCCESS: "LEGAL_ENTITY_UPDATE_SUCCESS",
  LEGAL_ENTITY_UPDATE_ERROR: "LEGAL_ENTITY_UPDATE_ERROR",

  GET_LEGAL_USERS_LOAD: "GET_LEGAL_USERS_LOAD",
  GET_LEGAL_USERS_LOAD_STOP: "GET_LEGAL_USERS_LOAD_STOP",
  GET_LEGAL_USERS_SUCCESS: "GET_LEGAL_USERS_SUCCESS",
  GET_LEGAL_USERS_REQUEST: "GET_LEGAL_USERS_REQUEST",
  GET_LEGAL_USERS_ERROR: "GET_LEGAL_USERS_ERROR",

  ATTACH_USERS_REQUEST: "ATTACH_USERS_REQUEST",
  DETACH_USERS_REQUEST: "DETACH_USERS_REQUEST",

  GET_LEGAL_CARS_LOAD: "GET_LEGAL_CARS_LOAD",
  GET_LEGAL_CARS_LOAD_STOP: "GET_LEGAL_CARS_LOAD_STOP",
  GET_LEGAL_CARS_REQUEST: "GET_LEGAL_CARS_REQUEST",
  GET_LEGAL_CARS_SUCCESS: "GET_LEGAL_CARS_SUCCESS",
  GET_LEGAL_CARS_ERROR: "GET_LEGAL_CARS_ERROR",

  ATTACH_CARS_REQUEST: "ATTACH_CARS_REQUEST",
  DETACH_CARS_REQUEST: "DETACH_CARS_REQUEST",

  MODAL_OPEN_LE: "MODAL_OPEN_LE",

  SERVER_ERROR_LE: "SERVER_ERROR_LE"
} as const;
