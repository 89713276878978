import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { LegalEntitiesListApi } from "services/api/legalEntitiesList";
import { TYPES_LEL } from "store/legalEntitiesList/constants";
import {
  legalEntityError,
  legalEntitySuccess,
  legalEntityLoad,
  legalEntityLoadStop,
  legalEntityByIdSuccess,
  deleteLegalEntitySuccess,
  filterLERows,
  deleteLegalEntityError,
  legalEntityStatus,
  legalEntityRequest,
  deleteLegalEntityRequest,
  legalEntityByIdRequest,
  clearDealersRows
} from "./actions";

function* getLegalEntities(actions: ReturnType<typeof legalEntityRequest>): SagaIterator {
  const { payload } = actions;
  yield put(legalEntityLoad());
  try {
    const response = yield call(LegalEntitiesListApi.getLegalEntities, payload);

    yield put(legalEntitySuccess(response));
  } catch (e) {
    const result = { total: 0, per_page: 10, current_page: 1, last_page: 1, data: [] };
    yield put(legalEntitySuccess(result));
    yield put(legalEntityError({ legalEntity: "Не удалось загрузить данные" }));
  } finally {
    yield put(legalEntityLoadStop());
  }
}

function* deleteLegalEntity(actions: ReturnType<typeof deleteLegalEntityRequest>): SagaIterator {
  const {
    payload: { id }
  } = actions;
  yield put(legalEntityLoad());
  try {
    yield call(LegalEntitiesListApi.deleteLegalEntity, id);
    yield put(filterLERows(id));
    yield put(deleteLegalEntitySuccess(id));
    yield put(legalEntityStatus({ deleteLegalEntity: "Успешно" }));
  } catch (e) {
    if (e.status === 400) {
      yield put(deleteLegalEntityError({ deleteLegalEntity: "Можно удалить только пустое юридическое лицо" }));
      yield put(clearDealersRows());
    } else {
      yield put(deleteLegalEntityError({ deleteLegalEntity: "Не удалось удалить юридическое лицо" }));
    }
  } finally {
    yield put(legalEntityLoadStop());
  }
}

function* getLegalEntityById(actions: ReturnType<typeof legalEntityByIdRequest>): SagaIterator {
  const { payload } = actions;
  yield put(legalEntityLoad());
  try {
    const response = yield call(LegalEntitiesListApi.getLegalEntityById, payload);
    yield put(legalEntityByIdSuccess(response));
  } catch (e) {
    yield put(legalEntityError({ legalEntityById: "Не удалось загрузить данные" }));
  } finally {
    yield put(legalEntityLoadStop());
  }
}

function* legalEntitiesListSaga(): SagaIterator {
  yield takeLatest(TYPES_LEL.LEGAL_ENTITIES_REQUEST, getLegalEntities);
  yield takeEvery(TYPES_LEL.DELETE_LEGAL_ENTITY_REQUEST, deleteLegalEntity);
  yield takeEvery(TYPES_LEL.LEGAL_ENTITY_BY_ID_REQUEST, getLegalEntityById);
}

export { legalEntitiesListSaga };
