import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import DeleteRows from "components/admin/delete-rows";
import { Text, Wrapper, CustomSelect } from "components/ui";
import { Table } from "components/ui/table";
import { Loading } from "components/ui/loading";
import { StoreTypes } from "store/types";
import { clientRequest, deleteEmployeeRequest, setEmployeesRows } from "store/employees/actions";
import { Employee, FiltersType } from "store/employees/types";
import getPrettyPhone from "helpers/pretty-phone-format";
import debounce from "helpers/debounce";
import { resetErrorAndStatus } from "store/actions";
import setRowsDataHandler from "helpers/setRowsData";
import history from "services/history";
import { Clients } from "services/api/clients";
import NotificationPopup from "components/ui/notification-popup";
import getEmployees from "store/employees/selectors/getEmployeesSelector";
import getSelectedRows from "store/employees/selectors/getSelectedRowsSelector";
import getEmployeesStatus from "store/employees/selectors/getStatusSelector";
import { paginationTheme } from "components/ui/switch-themes";
import { NameButtonList } from "pages/admin/employees/employees";
import { restoreUser, banDeletionClientsRequest, getUsersServicesRequest } from "store/users/actions";
import { BanDeletionType } from "store/users/types";
import { getBanDeletionClientsSuggestion } from "helpers/function-suggest";
import Pagination from "../../../components/ui/pagination/pagination";
import Select from "../../../components/ui/select";
import SearchForm from "../../../components/admin/search-form/search-form";
import styles from "./users-page.module.scss";
import UsersTabPanel from "../../../components/admin/users-tab-panel";
import selectOptions from "../../../constant/quantity-rows-per-admin-page";
import ButtonAdd from "../../../components/admin/button-add";
import Row from "../../../components/ui/row";
import Warning from "../../../components/ui/warning";
import { columns, deleteRowsColumn } from "./columns";

const UsersPage: FC<ReduxProps> = ({
  status,
  users: { data: users, last_page: lastPage },
  getUsers,
  isLoading,
  setRows,
  selectedRows,
  resetErrorsAndStatus,
  getBanDeletionUsers,
  services,
  banDeletionUsers: { data: banDeletionUsers, last_page: lastBanDeletionPage },
  getUsersServices
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRowsData, setRowsData] = useState<Array<Employee>>([]);

  const [filters, setFilters] = useState<FiltersType>({});
  const [sort, setSort] = useState<{ [key: string]: string }>({ created_at: "desc" });
  const [notification, setNotification] = useState<boolean>(false);
  const [buttonName, setButtonName] = useState(NameButtonList.edit);
  const [isBanDeletionTab, setIsBanDeletionTab] = useState(false);
  const [serviceFilter, setServiceFilter] = useState<FiltersType>({ service: "" });

  useEffect(() => {
    setRowsData(setRowsDataHandler(selectedRowsData, selectedRows, users));
  }, [selectedRows]);

  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
      setRows([]);
    };
  }, []);

  const getInitials = (str: string | null): string => {
    if (str && str !== "null") {
      if (str.trim()) {
        return `${str.trim()[0]}.`;
      }
    }
    return "";
  };

  const getSuggestion = async (
    value: string,
    setSuggestions: (data: Array<{ id: string; data: string }>) => void
  ): Promise<void> => {
    try {
      const { data } = await Clients.getClients({
        search: value,
        filters
      });
      const firstSuggestions = data.slice(0, rowsPerPage);
      const extractData = firstSuggestions.map(({ id, email, name, lastname, patronymic, phone }: Employee) => {
        const fullName = `${lastname} ${getInitials(name)} ${getInitials(patronymic)}`;
        const prettyPhone = getPrettyPhone(phone);
        return {
          id,
          data: `${fullName} ${email} ${prettyPhone}`
        };
      });
      setSuggestions(extractData);
    } catch (error) {
      console.error(error);
    }
  };

  const getSuggestionByDebounce = debounce(getSuggestion, 500);
  const getBanDeletionSuggestionByDebounce = debounce(getBanDeletionClientsSuggestion, 500);

  const getUserID = (id: string | number) => {
    history.push(`/admin/users/added/${id}`);
  };

  const getBanDeletionUserID = (banDeletionID: string | number) => {
    const userId = banDeletionUsers.find(item => String(item.id) === String(banDeletionID))?.user_id;
    if (userId) {
      history.push(`/admin/users/added/${userId}`);
    }
  };

  useEffect(() => {
    if (status) {
      setNotification(true);
    }
  }, [status]);

  useEffect(() => {
    if (isBanDeletionTab) {
      getUsersServices();
    }
  }, [isBanDeletionTab]);

  function changeServiceFilterHandle(e: React.ChangeEvent<HTMLSelectElement>) {
    setServiceFilter({ ...serviceFilter, service: e.target.value });
  }

  const banDeletionColumns = [
    {
      title: "ID",
      dataIndex: "ID",
      render: ({ user_id }: BanDeletionType): string => user_id
    },
    {
      title: "ФИО",
      dataIndex: "name",
      render: ({ user }: BanDeletionType): string =>
        `${user.lastname} ${(user.name && user.name[0]) || ""}. ${(user.patronymic && user.patronymic[0]) ||
          ""}${(user.patronymic && ".") || ""}`
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      render: ({ user }: BanDeletionType): string => getPrettyPhone(user.phone)
    },
    {
      title: "Email",
      dataIndex: "email",
      render: ({ user }: BanDeletionType): string => user.email || ""
    },
    {
      title: (
        <div>
          <CustomSelect
            options={services}
            name="service_id"
            theme="white"
            placeholder="Сервис"
            value={serviceFilter.service}
            onChange={changeServiceFilterHandle}
          />
        </div>
      ),
      dataIndex: "service_name",
      width: 15,
      render({ service }: BanDeletionType): string {
        return service.name;
      }
    },
    {
      title: "Дата создания",
      dataIndex: "createdDate",
      render: ({ created_at }: BanDeletionType): string => (created_at ? moment(created_at).format("DD.MM.YYYY") : "")
    },
    {
      title: "Комментарий",
      dataIndex: "reason",
      render: ({ reason }: BanDeletionType): string => reason || ""
    }
  ];

  return (
    <>
      <div className={styles.header}>
        <NotificationPopup
          title="Данные успешно удалены"
          isOpen={notification}
          action={(): void => setNotification(false)}
        />
        <Wrapper pb={20}>
          <Text size="h3" theme="black">
            Пользователи
          </Text>
        </Wrapper>
        <Row justifyContent="spaceBetween">
          <Row wrap>
            <Wrapper pr={10}>
              <ButtonAdd path="/admin/users/added" />
            </Wrapper>
          </Row>
          {isBanDeletionTab ? (
            <SearchForm
              rowsPerPage={rowsPerPage}
              action={getBanDeletionUsers}
              suggestionOnClick={getUserID}
              getSuggestion={getBanDeletionSuggestionByDebounce}
              placeholder="Поиск по email, телефону, ФИО"
              filters={serviceFilter}
              currentPage={currentPage}
              sort={sort}
              typeData="BanDeletionClients"
            />
          ) : (
            <SearchForm
              rowsPerPage={rowsPerPage}
              action={getUsers}
              suggestionOnClick={getUserID}
              getSuggestion={getSuggestionByDebounce}
              placeholder="Поиск по ID, email, телефону, ФИО"
              filters={filters}
              currentPage={currentPage}
              sort={sort}
              typeData="Client"
            />
          )}
        </Row>
      </div>
      <div className={styles.wrapper}>
        <UsersTabPanel
          setButtonName={setButtonName}
          setFilters={setFilters}
          filters={filters}
          setIsBanDeletionTab={setIsBanDeletionTab}
        />
        <Wrapper pt={26}>
          {isBanDeletionTab ? (
            <>
              <Table
                columns={banDeletionColumns}
                rowEdit={getBanDeletionUserID}
                data={!isLoading ? banDeletionUsers : []}
                buttonName={buttonName}
              />
              {!isLoading && banDeletionUsers.length === 0 && (
                <Wrapper pt={16}>
                  <Warning color="black">Ничего не найдено</Warning>
                </Wrapper>
              )}
            </>
          ) : (
            <>
              <Table
                selectedRows={selectedRows}
                columns={columns}
                rowEdit={getUserID}
                data={!isLoading ? users : []}
                rowSelection={(rows): any => setRows(rows)}
                buttonName={buttonName}
              />
              {!isLoading && users.length === 0 && (
                <Wrapper pt={16}>
                  <Warning color="black">Ничего не найдено</Warning>
                </Wrapper>
              )}
            </>
          )}
          <Loading isLoading={isLoading} />
        </Wrapper>
        <Wrapper pt={32}>
          <div className={styles.bottomBlock}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={isBanDeletionTab ? lastBanDeletionPage : lastPage}
            />
            <Select options={selectOptions} onClick={setRowsPerPage} />
          </div>
        </Wrapper>
      </div>
    </>
  );
};

const mapStateToProps = ({ users, employees }: StoreTypes) => ({
  isLoading: users.isLoading || employees.isLoading,
  users: getEmployees(employees),
  selectedRows: getSelectedRows(employees),
  status: getEmployeesStatus(employees),
  services: users.services,
  banDeletionUsers: users.banDeletionClients
});

const mapDispatchToProps = {
  getUsers: clientRequest,
  setRows: setEmployeesRows,
  resetErrorsAndStatus: resetErrorAndStatus,
  restore: restoreUser,
  getBanDeletionUsers: banDeletionClientsRequest,
  getUsersServices: getUsersServicesRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(UsersPage);
