import { TYPES_PHONE_BLACKLIST } from "./constants";
import { PhoneBlacklistType, Store, ActionsType } from "./types";
import { RESET_ERROR_AND_STATUS } from "../constants";

const initialState: Store = {
  isLoading: false,
  error: null,
  phoneBlacklist: {
    current_page: 0,
    data: [],
    last_page: 0,
    per_page: 0,
    total: 0
  },
  deleteError: null,
  status: null
};

export const phoneBlacklistReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_PHONE_BLACKLIST.PHONE_BLACKLIST_LOAD: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_PHONE_BLACKLIST.PHONE_BLACKLIST_LOAD_STOP: {
      return { ...state, isLoading: false };
    }
    case TYPES_PHONE_BLACKLIST.PHONE_BLACKLIST_SUCCESS: {
      return {
        ...state,
        phoneBlacklist: {
          ...action.payload,
          data: action.payload.data.map((item: PhoneBlacklistType) => ({ ...item, id: item.phone }))
        }
      };
    }
    case TYPES_PHONE_BLACKLIST.PHONE_BLACKLIST_ERROR: {
      return { ...state, error: { ...state.error, ...action.payload } };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null, deleteError: null, status: null };
    }
    case TYPES_PHONE_BLACKLIST.DELETE_PHONE_BLACKLIST_SUCCESS: {
      return {
        ...state,
        phoneBlacklist: {
          ...state.phoneBlacklist,
          data: state.phoneBlacklist.data.filter((item: PhoneBlacklistType) => item.id !== action.payload)
        }
      };
    }
    case TYPES_PHONE_BLACKLIST.DELETE_PHONE_BLACKLIST_ERROR: {
      return { ...state, deleteError: action.payload };
    }
    case TYPES_PHONE_BLACKLIST.PHONE_BLACKLIST_STATUS: {
      return { ...state, status: action.payload };
    }
    default: {
      return state;
    }
  }
};
