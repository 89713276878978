import React, { FC } from "react";
import cn from "classnames";
import styles from "./row.module.scss";

type PropsType = {
  justifyContent?: "justifyCenter" | "spaceBetween" | "normal";
  alignItems?: "alignCenter" | "normal";
  wrap?: boolean;
};

const Row: FC<PropsType> = ({ children, justifyContent = "normal", alignItems = "normal", wrap }) => {
  const className = cn(styles.row, {
    [styles[justifyContent]]: justifyContent,
    [styles[alignItems]]: alignItems,
    [styles.wrap]: wrap
  });
  return <div className={className}>{children}</div>;
};

export default Row;
