import { TYPES_LEL } from "store/legalEntitiesList/constants";
import { ActionsType, LegalEntity, Store } from "./types";
import { RESET_ERROR_AND_STATUS } from "../constants";

const initialState: Store = {
  isLoading: false,
  error: null,
  currentLegalEntity: null,
  legalEntities: {
    current_page: 0,
    data: [],
    last_page: 0,
    per_page: 0,
    total: 0
  },
  selectedRows: [],
  deleteError: null,
  status: null
};

export const legalEntitiesListReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_LEL.LEGAL_ENTITIES_LOAD: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_LEL.LEGAL_ENTITIES_LOAD_STOP: {
      return { ...state, isLoading: false };
    }
    case TYPES_LEL.LEGAL_ENTITIES_SUCCESS: {
      return { ...state, legalEntities: action.payload };
    }
    case TYPES_LEL.LEGAL_ENTITIES_ERROR: {
      return { ...state, error: { ...state.error, ...action.payload } };
    }
    case TYPES_LEL.LEGAL_ENTITY_BY_ID_SUCCESS: {
      return { ...state, currentLegalEntity: action.payload };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null, deleteError: null, status: null };
    }
    case TYPES_LEL.DELETE_LEGAL_ENTITY_SUCCESS: {
      return {
        ...state,
        legalEntities: {
          ...state.legalEntities,
          data: state.legalEntities.data.filter((le: LegalEntity) => le.id !== action.payload)
        }
      };
    }
    case TYPES_LEL.SET_LE_ROWS: {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
    case TYPES_LEL.LE_CLEAR_ROWS: {
      return {
        ...state,
        selectedRows: []
      };
    }
    case TYPES_LEL.FILTER_LE_ROWS: {
      return {
        ...state,
        selectedRows: state.selectedRows.filter(item => item !== action.payload)
      };
    }
    case TYPES_LEL.DELETE_LEGAL_ENTITY_ERROR: {
      return { ...state, deleteError: action.payload };
    }
    case TYPES_LEL.LEGAL_ENTITY_STATUS: {
      return { ...state, status: action.payload };
    }
    default: {
      return state;
    }
  }
};
