import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { buttonAddAndRemoveTheme } from "components/ui/switch-themes";
import { Button } from "components/ui";

type Props = {
  path: string;
  title?: string;
  onClick?: any;
};

const ButtonAdd: FC<Props> = ({ path, title }) => {
  return (
    <NavLink to={path}>
      <Button theme={buttonAddAndRemoveTheme} size="small">
        {title}
      </Button>
    </NavLink>
  );
};

export default ButtonAdd;

ButtonAdd.defaultProps = {
  title: "Добавить"
};
