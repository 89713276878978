import React, { FC, ReactNode } from "react";
import cn from "classnames";
import styles from "./text.module.scss";

export type TextSize =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h5_normal"
  | "h6"
  | "sub_lin1"
  | "sub_lin2"
  | "main"
  | "main_medium"
  | "main_small"
  | "h1L"
  | "h1M"
  | "h2M"
  | "h3M";

export type PropsType = {
  uppercase?: boolean;
  /**
   * Размер шрифта
   */
  size?: TextSize;
  /**
   * слева справа по центру
   */
  align?: "left" | "right" | "center";
  theme?: "gray" | "blue" | "white" | "black" | "red" | "gold";
  /**
   * Содержимое может быть и html
   */
  children: string | React.ReactNode;
};

export const Text: FC<PropsType> = ({ uppercase, size = "main", children, theme = "blue", align = "left" }) => {
  const cl = cn(styles.text, {
    [styles[size]]: size,
    [styles[align]]: align,
    [styles[theme]]: theme,
    [styles.uppercase]: uppercase
  });
  if (!children) {
    return null;
  }
  return <div className={cl}>{children}</div>;
};

Text.defaultProps = {
  size: "main",
  align: "left",
  theme: "blue"
};
