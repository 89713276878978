import moment from "moment";
import { BanDeletionType } from "store/users/types";

export const columnsBanDeletion = [
  {
    title: "Сервис",
    dataIndex: "service_name",
    render: ({ service }: BanDeletionType): string => service?.name || ""
  },
  {
    title: "Дата создания",
    dataIndex: "createdDate",
    render: ({ created_at }: BanDeletionType): string => (created_at ? moment(created_at).format("DD.MM.YYYY") : "")
  },
  {
    title: "Примечание",
    dataIndex: "reason",
    render: ({ reason }: BanDeletionType): string => reason || ""
  }
];

export const columnsBanDeleted = [
  {
    title: "№",
    dataIndex: "n",
    render: (entity: BanDeletionType, index: string): string => index
  },
  {
    title: "Сервис",
    dataIndex: "service_name",
    render: ({ service }: BanDeletionType): string => service?.name || ""
  },
  {
    title: "Дата создания",
    dataIndex: "createdDate",
    render: ({ created_at }: BanDeletionType): string => (created_at ? moment(created_at).format("DD.MM.YYYY") : "")
  },
  {
    title: "Примечание",
    dataIndex: "reason",
    render: ({ reason }: BanDeletionType): string => reason || ""
  }
];
