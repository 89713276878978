import React from "react";

const iconEyes = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="#fff" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M7.99993 5.81812C6.79628 5.81812 5.81812 6.79629 5.81812 7.99993C5.81812 9.20358 6.79628 10.1818 7.99993 10.1818C9.20357 10.1818 10.1817 9.20358 10.1817 7.99993C10.1817 6.79629 9.20354 5.81812 7.99993 5.81812Z"
        fill="#B7B7B7"
      />
      <path
        d="M8.00002 2.54541C4.36364 2.54541 1.25819 4.80721 0 7.99996C1.25819 11.1927 4.36364 13.4545 8.00002 13.4545C11.64 13.4545 14.7418 11.1927 16 7.99996C14.7418 4.80721 11.64 2.54541 8.00002 2.54541ZM8.00002 11.6363C5.99274 11.6363 4.36364 10.0072 4.36364 7.99993C4.36364 5.99265 5.99274 4.36358 8.00002 4.36358C10.0073 4.36358 11.6364 5.99269 11.6364 7.99996C11.6364 10.0072 10.0073 11.6363 8.00002 11.6363Z"
        fill="#B7B7B7"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default iconEyes;
