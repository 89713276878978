import React, { FC, useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import getPrettyPhone from "helpers/pretty-phone-format";
import { handlerPasteForSearch } from "helpers/handlePastePhone";
import { formatPhoneNumber } from "helpers/format-phone-number";
import { Text, Wrapper, Input, Button } from "components/ui";
import { Table } from "components/ui/table";
import { Loading } from "components/ui/loading";
import Pagination from "components/ui/pagination/pagination";
import Select from "components/ui/select";
import PopupConfirm from "components/ui/popup-confirm";
import Row from "components/ui/row";
import { useDebounce } from "hooks/useDebounce";
import { StoreTypes } from "store/types";
import { deletePhoneBlacklistRequest, phoneBlacklistRequest } from "store/phone-blacklist/actions";
import selectOptions from "constant/quantity-rows-per-admin-page";
import { PhoneBlacklistType } from "store/phone-blacklist/types";
import { resetErrorAndStatus } from "store/actions";
import NotificationPopup from "components/ui/notification-popup";
import { paginationTheme, inputAdminTheme, buttonEditTheme } from "components/ui/switch-themes";
import styles from "./phoneBlacklist.module.scss";
import Warning from "../../../components/ui/warning";

const PhoneBlacklist: FC<ReduxProps> = ({
  status,
  isLoading,
  phoneBlacklistRequest: getPhoneBlacklist,
  phoneBlacklist: { data: phoneBlacklist, last_page: lastPage },
  deletePhoneBlacklistRequest: deletePhoneBlacklist,
  resetErrorsAndStatus
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [notification, setNotification] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search);
  const [isOpen, setOpen] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState<string>("");

  useEffect(() => {
    getPhoneBlacklist({
      "pagination[page]": currentPage,
      "pagination[count]": rowsPerPage,
      search: formatPhoneNumber(debouncedSearch)
    });
  }, [currentPage, rowsPerPage, debouncedSearch]);

  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
    };
  }, []);

  useEffect(() => {
    if (status) {
      setNotification(true);
    }
  }, [status]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedPhone("");
    }
  }, [isOpen]);

  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleOpenConfirmModal = (phone: string) => {
    setSelectedPhone(phone);
    setOpen(true);
  };

  const handleDeletePhone = () => {
    deletePhoneBlacklist(selectedPhone);
    setOpen(false);
  };

  const columns = [
    {
      title: (
        <div>
          <Input
            name="phone"
            type="tel"
            maskChar={null}
            mask="9 999 999 99 99"
            theme={inputAdminTheme}
            placeholder="Номер телефона"
            value={search}
            onChange={changeSearch}
            onPaste={(elem: React.ClipboardEvent<HTMLInputElement>): void => setSearch(handlerPasteForSearch(elem))}
          />
        </div>
      ),
      dataIndex: "phone",
      render({ phone }: PhoneBlacklistType): string {
        return getPrettyPhone(phone);
      }
    },
    {
      title: "Дата",
      dataIndex: "date",
      render: ({ date }: PhoneBlacklistType): string => (date ? moment(date).format("DD.MM.YYYY") : "")
    },
    {
      title: "Заблокирован",
      dataIndex: "is_blocked",
      render: ({ is_blocked }: PhoneBlacklistType): string => (is_blocked ? "Да" : "Нет")
    },
    {
      title: "Удалить",
      dataIndex: "action",
      width: 1,
      render({ phone }: PhoneBlacklistType) {
        return (
          <Button size="xs" theme={buttonEditTheme} onClick={(): void => handleOpenConfirmModal(phone)}>
            Удалить
          </Button>
        );
      }
    }
  ];

  return (
    <>
      <div className={styles.header}>
        <PopupConfirm
          isOpen={isOpen}
          action={handleDeletePhone}
          setOpen={setOpen}
          title="Вы точно хотите удалить телефон из черного списка?"
          desc=""
        >
          <Row justifyContent="justifyCenter">
            <h3>{getPrettyPhone(selectedPhone)}</h3>
          </Row>
        </PopupConfirm>
        <NotificationPopup
          title="Данные успешно удалены"
          isOpen={notification}
          action={(): void => setNotification(false)}
        />
        <Wrapper pb={20}>
          <Text size="h3" theme="black">
            ЧС телефонов
          </Text>
        </Wrapper>
      </div>
      <div className={styles.wrapper}>
        <Loading isLoading={isLoading}>
          <Table columns={columns} data={phoneBlacklist} />
        </Loading>

        {!isLoading && phoneBlacklist.length === 0 && (
          <Wrapper pt={16}>
            <Warning color="black">Ничего не найдено</Warning>
          </Wrapper>
        )}
        <Wrapper pt={32}>
          <div className={styles.bottomBlock}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
            <Select options={selectOptions} onClick={setRowsPerPage} />
          </div>
        </Wrapper>
      </div>
    </>
  );
};

const mapStateToProps = ({ phoneBlacklist }: StoreTypes) => ({
  isLoading: phoneBlacklist.isLoading,
  phoneBlacklist: phoneBlacklist.phoneBlacklist,
  deleteError: phoneBlacklist.deleteError,
  status: phoneBlacklist.status
});

const mapDispatchToProps = {
  phoneBlacklistRequest,
  deletePhoneBlacklistRequest,
  resetErrorsAndStatus: resetErrorAndStatus
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PhoneBlacklist);
