import axios from "axios";
import {
  PersonalDataListType,
  GetPersonalDataListParams,
  PersonalDataType
} from "../../store/personal-data-list/types";
import { CreatePersonalData } from "../../store/personal-data/types";

export const PersonalDataApi = {
  getPersonalDataList: (params?: Partial<GetPersonalDataListParams>): Promise<PersonalDataListType> =>
    axios.get("/front/personal-data-list", { params }),
  deletePersonalData: (id: number | string): Promise<void> => axios.delete(`/front/personal-data-list/${id}`),
  getPersonalDataById: (id: number | string): Promise<PersonalDataType> => axios.get(`/front/personal-data-list/${id}`),
  createPersonalData: (body: CreatePersonalData): Promise<PersonalDataType> =>
    axios.post("/front/personal-data-list", body),
  updatePersonalData: (id: number | string, body: CreatePersonalData): Promise<PersonalDataType> =>
    axios.put(`/front/personal-data-list/${id}`, body)
};
