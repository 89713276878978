import React from "react";
import { PersonalDataType, ServicesType } from "store/personal-data-list/types";
import styles from "./personalDataListPage.module.scss";

const renderServiceItem = (serviceItem: ServicesType): string | JSX.Element => {
  if (!serviceItem || serviceItem.items.length === 0) {
    return "";
  }
  const servicesList = serviceItem.items.map(item => item.name).join(", ");
  return <p key={serviceItem.label} className={styles.serviceText}>{`${serviceItem.label}: ${servicesList}`}</p>;
};

const renderServices = (services: Array<ServicesType>): string | JSX.Element => {
  if (!services || services.length === 0) {
    return "";
  }
  return <>{services.map(service => renderServiceItem(service))}</>;
};

export const columns = [
  {
    title: "Название",
    dataIndex: "name",
    render: ({ name }: PersonalDataType): string => name
  },
  {
    title: "Список сервисов",
    dataIndex: "services",
    render: ({ services }: PersonalDataType) => renderServices(services)
  }
];

export const deleteRowsColumn = [
  {
    title: "№",
    dataIndex: "n",
    render: (entity: PersonalDataType, index: string): string => index
  },
  {
    title: "Название",
    dataIndex: "name",
    render: ({ name }: PersonalDataType): string => name
  }
];
