import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { StoreTypes } from "store/types";
import styles from "./user-info.module.scss";
import { Avatar } from "../ui";

type Props = {
  theme?: "dark" | "white";
  collapse?: boolean;
};

const getImageLink = (id: string) => `/front/user/${id}/avatar?timestamp=${Date.now()}`;

const UserInfo: FC<ReduxProps & Props> = ({
  collapse,
  theme = "dark",
  name,
  lastname,
  roles = [],
  id,
  avatarIsChanged
}) => {
  const mainClass = cn(styles.userInfo, {
    [styles.collapse]: collapse,
    [styles[theme]]: theme
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState<any>(id ? getImageLink(id) : "");
  const roleDisplayName = roles?.length > 0 ? roles.map(role => role.display_name).join(", ") : "";

  useEffect(() => {
    if (avatarIsChanged && id) {
      setImagePreviewUrl(getImageLink(id));
    }
  }, [avatarIsChanged]);

  return (
    <div className={mainClass}>
      <NavLink to="/" className={styles.row}>
        <div className={styles.avatar}>
          <Avatar src={imagePreviewUrl} size="small" />
        </div>
        <div className={styles.col}>
          <div className={styles.userName}>{`${name}`}</div>
          <div className={styles.userName}>{`${lastname}`}</div>
          {roleDisplayName && <div className={styles.userRole}>{roleDisplayName}</div>}
          <div className={styles.userRole}>{`${id}`}</div>
        </div>
      </NavLink>
    </div>
  );
};

const mapStateToProps = ({ users: { user, avatarIsLoaded } }: StoreTypes) => ({
  id: user.id,
  name: user.name,
  lastname: user.lastname,
  roles: user.roles,
  avatarIsChanged: avatarIsLoaded
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(UserInfo);
