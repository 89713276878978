import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
// import "animate.css";

/// Настройки можно посмотерь в документации
// react-notifications-component

/// todo helpers
type NotificationsErrorsTypes = {
  title: string;
  message: string | null;
  type: "success" | "danger" | "info" | "warning";
  container: "top-left" | "top-right" | "top-center" | "center" | "bottom-left" | "bottom-right" | "bottom-center";
};

export const notificationErrors = ({
  title = "Произошла ошибка",
  message = " ",
  type = "danger",
  container = "bottom-right"
}: Partial<NotificationsErrorsTypes>): void => {
  store.addNotification({
    title,
    message,
    type,
    container,
    dismiss: {
      duration: 5000,
      pauseOnHover: true
    }
  });
};
