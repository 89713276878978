import React, { FC, ReactElement, useEffect } from "react";
import { Formik, useFormikContext, Form } from "formik";
import { Input } from "components/ui/input";
import { GetEmployeesParams, FiltersType } from "store/employees/types";
import fp from "lodash";
import DealersAutoSuggest from "components/admin/autosaggest";
import styles from "./search-form.module.scss";
import { Wrapper } from "../../ui";
import SearchIcon from "./icons/search.svg";
import TeamName from "../../../constant/team-name";

type Props = {
  sort?: { [key: string]: string };
  action: any;
  suggestionOnClick?: (id: string | number) => any;
  getSuggestion?: (value: string, setSuggestion: (data: Array<{ id: string; data: string }>) => void) => Promise<void>;
  rowsPerPage: number;
  placeholder: string;
  filters?: FiltersType;
  currentPage: number;
  teamName?: TeamName;
  disableAutosuggest?: boolean;
  typeData?: "Dealership" | "Client" | "LegalEntity" | "Car" | "ThirdParties" | "PersonalData" | "BanDeletionClients";
};

type UpdateSearchProps = Pick<Props, "rowsPerPage" | "filters" | "currentPage" | "typeData">;

type SubmitProps = GetEmployeesParams;

const UpdateSearch = ({ typeData, rowsPerPage, filters, currentPage }: UpdateSearchProps): null => {
  const { submitForm } = useFormikContext();
  useEffect(() => {
    if (!fp.isEmpty(filters) || typeData) {
      submitForm();
    }
  }, [rowsPerPage, filters, currentPage, typeData]);
  return null;
};

const SearchForm: FC<Props> = ({
  typeData,
  sort,
  rowsPerPage,
  action,
  placeholder,
  getSuggestion,
  filters: filterValues,
  currentPage,
  teamName,
  disableAutosuggest = false,
  suggestionOnClick
}) => {
  const submitHandler = ({ search }: SubmitProps): void => {
    let body;
    if (typeData === "ThirdParties" || typeData === "PersonalData" || typeData === "BanDeletionClients") {
      body = {
        search,
        "pagination[page]": currentPage,
        "pagination[count]": rowsPerPage
      };
      if (filterValues?.service) {
        body = { ...body, "filters[service]": filterValues?.service };
      }
    } else {
      body = {
        sort,
        search,
        filters: { ...filterValues },
        pagination: { page: currentPage, count: rowsPerPage }
      };
    }
    action(body);
  };

  return (
    <Wrapper>
      <div className={styles.block}>
        <div className={styles.searchWrap}>
          <Formik
            initialValues={{
              search: ""
            }}
            onSubmit={submitHandler}
          >
            {({ values, handleChange }): ReactElement => {
              return (
                <Form className={styles.form}>
                  {!disableAutosuggest && getSuggestion && (
                    <DealersAutoSuggest
                      getSuggestion={getSuggestion}
                      placeholder={placeholder}
                      fieldName="search"
                      suggestionOnClick={suggestionOnClick}
                      switchPage={teamName}
                    />
                  )}

                  {disableAutosuggest && (
                    <Input name="search" type="text" theme="gray" placeholder={placeholder} onChange={handleChange} />
                  )}
                  <img src={SearchIcon} alt="search" className={styles.searchIcon} />
                  <UpdateSearch
                    typeData={typeData}
                    rowsPerPage={rowsPerPage}
                    filters={filterValues}
                    currentPage={currentPage}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Wrapper>
  );
};

export default SearchForm;
