import React, { FC, useState } from "react";
import { Popup } from "components/ui/popup";
import styles from "./info-popup.module.scss";

type Props = {
  popupTitle: string;
};

const InfoPopup: FC<Props> = ({ children, popupTitle }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openPopup = (): void => {
    setIsOpen(true);
  };

  const closePopup = (): void => {
    setIsOpen(false);
  };

  return (
    <>
      <button type="button" onClick={openPopup} className={styles.openBtn}>
        ...
      </button>
      <Popup isOpen={isOpen} onClick={closePopup} height="400px">
        <h3 className={styles.popupTitle}>{popupTitle}</h3>
        <div className={styles.popupContent}>{children}</div>
      </Popup>
    </>
  );
};

export default InfoPopup;
