import axios from "axios";
import {
  UserType,
  UploadAvatarTypes,
  ChangePasswordTypes,
  UploadAvatarPayload,
  ChangePasswordPayload,
  UpdateUserPayload,
  AttachTeamBodyType,
  UserTeamAttachType,
  CreateUserBody,
  SendUserEmailType,
  UserNewEmailType,
  ChangeUserPhoneType,
  SendCodeResponseType,
  DeleteUserPayload,
  CreateBanDeletion
} from "store/users/types";
import { Employee } from "store/employees/types";
import { LoginType } from "store/loginHistory/types";

interface SendAddedPhoneTypes {
  phone: string | null;
  type: string | null;
}

type AddedPhoneTypes = {
  code?: number;
  message: string;
  status: string;
};
export const User = {
  restoreUser: (id: string | number): Promise<any> => axios.post(`/front/user/${id}/restore`),

  getUserInfo: (): Promise<UserType> => axios.get("/front/user-info"),

  uploadAvatar: (body: UploadAvatarTypes): Promise<UploadAvatarPayload> =>
    axios.post(`/front/user/${body.id}/upload_avatar`, body.data),

  updateUser: (body: Partial<UpdateUserPayload>): Promise<Partial<UserType>> =>
    axios.put(`/front/user/${body.id}`, body),

  getLoginHistory: (id: string): Promise<LoginType> => axios.get(`/front/user/${id}/login_history?limit=15&order=desc`),

  deleteAccount: (body: DeleteUserPayload): Promise<void> => axios.post(`/front/user/${body.id}/force-delete`, body),

  deleteUserAccount: (body: DeleteUserPayload): Promise<void> => axios.post(`/front/user/force-delete`, body),

  deleteAccountCheck: (): Promise<void> => axios.get(`/front/user/force-delete/check`),

  restoreAccount: (id: string): Promise<void> => axios.delete(`/front/user/${id}/restore`),

  changePassword: (body: ChangePasswordTypes): Promise<ChangePasswordPayload> =>
    axios.post(`/front/user/change_password`, body),

  createUser: (body: Partial<CreateUserBody>): Promise<Employee> => axios.post("/front/user", body),

  serviceSignIn: (service: string): Promise<any> => axios.get(`/front/sso_login/${service}`),

  attachTeam: ({ userId, teamIds, role }: AttachTeamBodyType): Promise<void> =>
    axios.post(`/front/user/${userId}/attach_team`, { team_ids: teamIds, role }),

  getUserById: (id: string): Promise<Employee> => axios.get(`/front/user/${id}`),

  detachTeam: ({ userId, teamIds, role }: UserTeamAttachType): Promise<void> =>
    axios.post(`/front/user/${userId}/detach_team`, { team_ids: teamIds, role }),

  attachPosition: (id: number, body: { position_id: string | number }): Promise<Employee> =>
    axios.put(`/front/user/${id}`, body),

  sendPhoneCode: (body: SendAddedPhoneTypes): Promise<SendCodeResponseType> => {
    return axios.post("/front/send-code", body);
  },
  changePhoneNumber: (body: ChangeUserPhoneType): Promise<AddedPhoneTypes> => {
    return axios.post("/front/update-phone", body);
  },
  changeEmailAddress: (body: UserNewEmailType): Promise<AddedPhoneTypes> => {
    return axios.post("/front/send-email-code", body);
  },
  sendEmailCode: (body: SendUserEmailType): Promise<AddedPhoneTypes> => {
    return axios.post("/front/update-email", body);
  },
  deleteMarketingConsent: (): Promise<any> => axios.delete("/front/personal-data/marketing-consent"),
  acceptMarketingConsent: (): Promise<any> => axios.post("/front/personal-data/marketing-consent"),
  acceptMarketingConsentForService: (): Promise<any> =>
    axios.post("/front/personal-data/marketing-consent-for-services"),
  getUserToken: (id: string): Promise<any> => axios.get(`/front/user/${id}/token`),
  sendEmailVerify: (): Promise<any> => {
    return axios.post("/front/user/send-verification-email");
  },
  createBanDeletion: (body: CreateBanDeletion): Promise<any> => axios.post("/front/ban-deletion", body),
  updateBanDeletion: (id: string | number, body: Partial<CreateBanDeletion>): Promise<any> =>
    axios.put(`/front/ban-deletion/${id}`, body),
  deleteBanDeletion: (id: string | number): Promise<void> => axios.delete(`/front/ban-deletion/${id}`)
};
