import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { RolesApi } from "services/api/roles";
import addTypesRole from "helpers/addedTypesRoles";
import { TYPES_ROLES } from "./constants";
import {
  rolesLoad,
  rolesLoadStop,
  rolesSuccess,
  rolesError,
  rolesRequest,
  attachRolesRequest,
  detachRolesRequest
} from "./actions";
import { employeeUpdateRoleSuccess } from "../employees/actions";

function* getRoles(actions: ReturnType<typeof rolesRequest>): SagaIterator {
  const { payload } = actions;
  yield put(rolesLoad());
  try {
    let response = yield call(RolesApi.getRoles, payload);
    response = addTypesRole(response);
    yield put(rolesSuccess(response));
  } catch (e) {
    yield put(rolesError({ roles: "Не удалось загрузить данные" }));
  } finally {
    yield put(rolesLoadStop());
  }
}

export function* attachRoles(actions: ReturnType<typeof attachRolesRequest>): SagaIterator {
  const { payload } = actions;
  yield put(rolesLoad());
  try {
    const response = yield call(RolesApi.attachRoles, payload);
    yield put(employeeUpdateRoleSuccess(response));
  } catch (e) {
    yield put(rolesError({ attachRoles: "Не удалось прикрепить роль" }));
  } finally {
    yield put(rolesLoadStop());
  }
}

export function* detachRoles(actions: ReturnType<typeof detachRolesRequest>): SagaIterator {
  const { payload } = actions;

  yield put(rolesLoad());
  try {
    const response = yield call(RolesApi.detachRoles, payload);
    yield put(employeeUpdateRoleSuccess(response));
  } catch (e) {
    yield put(rolesError({ detachRoles: "Не удалось открепить роль" }));
  } finally {
    yield put(rolesLoadStop());
  }
}

function* rolesSaga(): SagaIterator {
  yield takeEvery(TYPES_ROLES.ROLES_REQUEST, getRoles);
  yield takeEvery(TYPES_ROLES.ATTACH_ROLES_REQUEST, attachRoles);
  yield takeEvery(TYPES_ROLES.DETACH_ROLES_REQUEST, detachRoles);
}

export { rolesSaga };
