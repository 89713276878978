import { TYPES_EMPLOYEES } from "store/employees/constants";
import {
  GetEmployeesParams,
  EmployeesType,
  Employee,
  EmployeeUpdatePayloadType,
  DeleteEmployeePayloadType,
  TeamsType
} from "./types";

import { Role } from "../users/types";

export const employeesLoad = () => ({
  type: TYPES_EMPLOYEES.EMPLOYEES_LOAD
});
export const employeesLoadStop = () => ({
  type: TYPES_EMPLOYEES.EMPLOYEES_LOAD_STOP
});
export const employeesRequest = (body: GetEmployeesParams) => ({
  type: TYPES_EMPLOYEES.EMPLOYEES_REQUEST,
  payload: body
});

export const clientRequest = (body: GetEmployeesParams) => ({
  type: TYPES_EMPLOYEES.CLIENT_REQUEST,
  payload: body
});
export const employeesSuccess = (payload: EmployeesType) => ({
  type: TYPES_EMPLOYEES.EMPLOYEES_SUCCESS,
  payload
});

export const employeesError = (error: { [key: string]: string }) => ({
  type: TYPES_EMPLOYEES.EMPLOYEES_ERROR,
  payload: error
});

export const employeesStatus = (status: { [key: string]: string } | null) => ({
  type: TYPES_EMPLOYEES.EMPLOYEES_STATUS,
  payload: status
});

export const deleteEmployeeRequest = (payload: DeleteEmployeePayloadType) => ({
  type: TYPES_EMPLOYEES.DELETE_EMPLOYEE_REQUEST,
  payload
});

export const deleteEmployeeSuccess = (id: string) => ({
  type: TYPES_EMPLOYEES.DELETE_EMPLOYEE_SUCCESS,
  payload: id
});

export const employeesByIdRequest = (id: string) => ({
  type: TYPES_EMPLOYEES.EMPLOYEES_BY_ID_REQUEST,
  payload: id
});

export const employeesByIdSuccess = (payload: Employee | null) => ({
  type: TYPES_EMPLOYEES.EMPLOYEES_BY_ID_SUCCESS,
  payload
});

export const employeeUpdateRequest = (payload: EmployeeUpdatePayloadType) => ({
  type: TYPES_EMPLOYEES.EMPLOYEE_UPDATE_REQUEST,
  payload
});

export const employeeUpdateRoleSuccess = (payload: Array<Role>) => ({
  type: TYPES_EMPLOYEES.EMPLOYEE_UPDATE_ROLES_SUCCESS,
  payload
});

export const setEmployeesRows = (payload: Array<string | number>) => ({
  type: TYPES_EMPLOYEES.SET_EMPLOYEES_ROWS,
  payload
});

export const filterEmployeesRows = (payload: string) => ({
  type: TYPES_EMPLOYEES.FILTER_EMPLOYEES_ROWS,
  payload
});

export const deleteEmployeeError = (error: { [key: string]: string }) => ({
  type: TYPES_EMPLOYEES.DELETE_EMPLOYEE_ERROR,
  payload: error
});

export const updateTeams = (teams: TeamsType[]) => ({
  type: TYPES_EMPLOYEES.UPDATE_TEAMS,
  payload: teams
});

export const createBanDeletionRequest = (payload: any) => ({
  type: TYPES_EMPLOYEES.CREATE_BAN_DELETION_REQUEST,
  payload
});

export const createBanDeletionSuccess = (payload: any) => ({
  type: TYPES_EMPLOYEES.CREATE_BAN_DELETION_SUCCESS,
  payload
});

export const updateBanDeletionRequest = (id: number | string, payload: any) => ({
  type: TYPES_EMPLOYEES.UPDATE_BAN_DELETION_REQUEST,
  id,
  payload
});

export const updateBanDeletionSuccess = (payload: any) => ({
  type: TYPES_EMPLOYEES.UPDATE_BAN_DELETION_SUCCESS,
  payload
});

export const deleteBanDeletionRequest = (payload: any) => ({
  type: TYPES_EMPLOYEES.DELETE_BAN_DELETION_REQUEST,
  payload
});

export const deleteBanDeletionSuccess = (payload: any) => ({
  type: TYPES_EMPLOYEES.DELETE_BAN_DELETION_SUCCESS,
  payload
});

export const deleteBanDeletionError = (error: { [key: string]: string }) => ({
  type: TYPES_EMPLOYEES.DELETE_BAN_DELETION_ERROR,
  payload: error
});

export const setBanDeletionRows = (payload: Array<string | number>) => ({
  type: TYPES_EMPLOYEES.SET_BAN_DELETION_ROWS,
  payload
});

export const filterBanDeletionRows = (payload: number) => ({
  type: TYPES_EMPLOYEES.FILTER_BAN_DELETION_ROWS,
  payload
});
