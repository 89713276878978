export const TYPES_ROLES = {
  ROLES_LOAD: "ROLES_LOAD",
  ROLES_LOAD_STOP: "ROLES_LOAD_STOP",
  ROLES_REQUEST: "ROLES_REQUEST",
  ROLES_SUCCESS: "ROLES_SUCCESS",
  ATTACH_ROLES_REQUEST: "ATTACH_ROLES_REQUEST",
  DETACH_ROLES_REQUEST: "DETACH_ROLES_REQUEST",
  ROLES_ERROR: "ROLES_ERROR",
  RESET_ERROR_AND_STATUS: "RESET_ERROR_AND_STATUS"
} as const;

export enum RequestParams {
  dc = "dc",
  main = "main"
}
