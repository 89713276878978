import * as yup from "yup";
import { isValidFirstAndLastChar, isValidMMName } from "helpers/validate";

const validationSchema = (): any => {
  return yup.object().shape({
    name: yup
      .string()
      .required("Необходимо заполнить данные")
      .min(1, "Используйте от 1 до 40 символов")
      .max(40, "Используйте от 1 до 40 символов")
      .test("name", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
      .matches(isValidMMName, "Используйте кириллицу или латиницу, цифры и символы: ~@#$%^-_(){}'`"),
    redirect: yup
      .string()
      .required("Необходимо заполнить данные")
      .min(11, "Используйте от 11 до 255 символов")
      .max(255, "Используйте от 1 до 255 символов")
  });
};

export default validationSchema;
