import React, { FC, ReactElement, useEffect, useState } from "react";
import { Button, Table, Text, Wrapper } from "components/ui";
import AddRoles from "components/admin/add-roles/add-roles";
import { Role } from "store/roles/types";
import Warning from "components/ui/warning";
import { rolesRequest } from "store/roles/actions";
import { connect, ConnectedProps } from "react-redux";
import { StoreTypes } from "store/types";
import { uniqify } from "helpers/uniqify";
import { inputAdminTheme, isHyundaiTheme, sizeTitleAuth } from "components/ui/switch-themes";
import PopupConfirm from "components/ui/popup-confirm";
import { deleteRowsColumn } from "pages/admin/employees/colunms";
import { useHistory } from "react-router-dom";
import { deleteEmployeeRequest } from "store/employees/actions";
import getEmployeesStatus from "store/employees/selectors/getStatusSelector";
import styles from "../added-user-form/added-user-form.module.scss";
import { Services, ServicesType } from "../../../../store/users/types";
import { signInOnUserRequest } from "../../../../store/auth/actions";
import usePermissions from "../../../../helpers/usePermissions";
import { superadminPermissions } from "../../../../constant/permissions";

type Props = {
  setEmployeeDC: (arg: boolean) => void;
  setClient: (arg: boolean) => void;
  isEdit?: boolean;
  initialValues: any;
  setFieldValue: (field: string, value: any) => void;
  errors: any;
  touched: any;
  setFieldTouched: (field: string, isTouched?: boolean) => void;
  values: any;
  isLoading: boolean;
  connectedService?: Array<Services>;
};

const columns = [
  {
    title: "Название сервиса",
    dataIndex: "name",
    render: ({ name }: ServicesType): string => name
  },
  {
    title: "Ссылка на сервис",
    dataIndex: "link",
    // eslint-disable-next-line react/display-name
    render: ({ link }: ServicesType): JSX.Element => (
      <a target="_blank" rel="noreferrer" href={link}>
        {link}
      </a>
    )
  }
];

const UserAvatar: FC<Props & ReduxProps> = ({
  status,
  setEmployeeDC,
  setClient,
  touched,
  errors,
  isEdit,
  initialValues,
  setFieldValue,
  setFieldTouched,
  deleteAccount,
  singInOnUser,
  values,
  isLoading,
  connectedService
}) => {
  const [isRolesPopupOpen, setRolesPopupOpen] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const isAdminMain = usePermissions(superadminPermissions);
  const [isServicePopupOpen, setServicePopupOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(connectedService ? Array.from(connectedService.keys()) : []);
  const title = isEdit ? "Редактирование данных пользователя" : " Добавление нового пользователя";
  const hist = useHistory();
  useEffect(() => {
    if (status) {
      hist.goBack();
    }
  }, [status]);

  const deleteUserCheck = (id: string): void => {
    if (connectedService && connectedService?.length > 0) {
      setDeletePopupOpen(false);
      setServicePopupOpen(true);
    } else {
      deleteAccount({ id });
    }
  };

  const deleteUserWithService = (id: string) => {
    if (connectedService) {
      deleteAccount({
        id,
        services: selectedService.map(index => {
          return connectedService[index].id;
        })
      });
      setServicePopupOpen(false);
    }
  };

  const selectServices = (rows: any) => {
    setSelectedService(rows);
  };

  const isAdmin = Boolean(values.roles.find((role: any) => role.id === 1));

  return (
    <div className={styles.block1}>
      <Wrapper pt={48} pb={24}>
        <Text size={sizeTitleAuth} uppercase={!isHyundaiTheme} theme="black">
          {title}
        </Text>
      </Wrapper>
      <div className={styles.rowTop}>
        <div className={styles.half}>
          <div className={styles.wrapRoles}>
            <Wrapper pb={20}>
              <div className={styles.row}>
                <div className={styles.label}>Роль </div>
                <div className={styles.addRolesButton}>
                  <Button size="xs" theme="light" onClick={(): void => setRolesPopupOpen(true)}>
                    Добавить роль
                  </Button>
                </div>
              </div>
              <AddRoles
                setEmployeeDC={setEmployeeDC}
                isEdit={isEdit}
                setClient={setClient}
                isOpen={isRolesPopupOpen}
                setOpen={setRolesPopupOpen}
                setFieldValue={setFieldValue}
                addedRoles={values.roles}
                setFieldTouched={setFieldTouched}
              />
            </Wrapper>
            <Wrapper pt={17} pb={40}>
              <div className={styles.row}>
                <div>
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                  <div id="roles" tabIndex={0} className={styles.rolesLabel}>
                    Добавленные роли
                  </div>
                  <div className={styles.roles}>
                    {uniqify<Role>(values.roles)?.map(
                      (role: Role): ReactElement => (
                        <div key={role.id}>{role.display_name}</div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <Wrapper pt={20}>
                {touched.roles && errors.roles && <Warning color="red">{errors.roles}</Warning>}
              </Wrapper>
            </Wrapper>
          </div>
        </div>
        <div className={styles.deleteBtnContainer}>
          <Wrapper pb={17}>
            <div className={styles.row}>
              {!isAdmin && isAdminMain ? (
                <Button className={styles.guestBtn} size="xs" theme="light" onClick={() => singInOnUser(values.id)}>
                  Авторизоваться под клиентом
                </Button>
              ) : null}
              {isHyundaiTheme ? (
                <Button
                  size="xs"
                  theme="light"
                  disabled={!!values.is_deleted}
                  onClick={(): void => setDeletePopupOpen(true)}
                >
                  Удалить
                </Button>
              ) : null}
              <PopupConfirm
                isOpen={isDeletePopupOpen}
                setOpen={setDeletePopupOpen}
                action={() => deleteUserCheck(values.id)}
                title="Вы точно хотите удалить запись?"
                desc=""
                disabled={isLoading}
              >
                <Table columns={deleteRowsColumn} data={[values]} />
              </PopupConfirm>
              <PopupConfirm
                isOpen={isServicePopupOpen}
                setOpen={setServicePopupOpen}
                action={() => deleteUserWithService(values.id)}
                title="Удалить данные пользователя из приведенных ниже сервисов"
                desc=""
                disabled={isLoading}
                disabledAccept={selectedService.length === 0}
              >
                <Table
                  columns={columns}
                  data={
                    connectedService ? connectedService.map((el, id) => ({ name: el.name, link: el.link, id })) : []
                  }
                  rowSelection={rows => selectServices(rows)}
                  selectedRows={selectedService}
                  lockedIndex={connectedService?.findIndex(service => service.code === "hid")}
                />
              </PopupConfirm>
            </div>
          </Wrapper>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ roles, users: { isLoading, user }, employees }: StoreTypes) => ({
  roles: roles.roles,
  selfRoles: user.roles,
  isLoading,
  status: getEmployeesStatus(employees)
});
const mapDispatchToProps = {
  getRoles: rolesRequest,
  deleteAccount: deleteEmployeeRequest,
  singInOnUser: signInOnUserRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(UserAvatar);
