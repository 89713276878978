import { Employee, GetEmployeesParams } from "store/employees/types";
import { TYPES_DEALERS } from "store/dealers/constants";
import employees from "pages/admin/employees";
import { CreateDealersData, DealershipTypes } from "./types";

export const dealersLoad = () => ({
  type: TYPES_DEALERS.DEALERS_LOAD
});

export const dealersLoadStop = () => ({
  type: TYPES_DEALERS.DEALERS_LOAD_STOP
});

export const getEmployeesFilter = (data: GetEmployeesParams) => ({
  type: TYPES_DEALERS.GET_EMPLOYEES_FILTER,
  payload: data
});
export const getBaseEmployee = (data: GetEmployeesParams) => ({
  type: TYPES_DEALERS.GET_BASE_EMPLOYEES,
  payload: data
});

export const dealersSuccess = (data: any) => ({
  type: TYPES_DEALERS.DEALERS_SUCCESS,
  payload: data
});

export const createDealers = (data: CreateDealersData) => ({
  type: TYPES_DEALERS.CREATE_DEALERS,
  payload: data
});

export const addedEmployeesDC = (data: any) => ({
  type: TYPES_DEALERS.ADDED_EMPLOYEES_DC,
  payload: data
});

export const deleteEmployeesDC = (data: Array<string | number>) => ({
  type: TYPES_DEALERS.DELETE_EMPLOYEES_DC,
  payload: data
});

export const dealershipInfo = (id: string | number) => ({
  type: TYPES_DEALERS.DEALERSHIP_INFO,
  payload: id
});

export const dealershipEntryEditing = (data: DealershipTypes | null) => ({
  type: TYPES_DEALERS.DEALERSHIP_ENTRY_EDITING,
  payload: data
});

export const dealersEmployeeUpdate = (employeesList: Employee[]) => ({
  type: TYPES_DEALERS.DEALERS_EMPLOYEE_UPDATE,
  payload: employeesList
});

export const dealershipEditingValue = (toggle: boolean) => ({
  type: TYPES_DEALERS.DEALERSHIP_EDITING_VALUE,
  payload: toggle
});

export const updateDealership = (data: Partial<CreateDealersData>) => ({
  type: TYPES_DEALERS.UPDATE_DEALERS,
  payload: data
});

export const putEmployeesDC = (data: any) => ({
  type: TYPES_DEALERS.PUT_EMPLOYEES_DC,
  payload: data
});
export const serverErrorDealer = (error: string) => ({
  type: TYPES_DEALERS.SERVER_ERROR_DC,
  payload: error
});

export const modalWindowOpen = (toggle: boolean) => ({
  type: TYPES_DEALERS.MODAL_OPEN_DC,
  payload: toggle
});

export const userList = (users: Array<Employee>) => ({
  type: TYPES_DEALERS.USERS_LIST_DC,
  payload: users
});
