import React, { FC } from "react";
import AddedUserForm from "components/admin/added-users/added-user-form";
import TeamName from "constant/team-name";

type Props = {
  isEdit?: boolean;
  teamName: TeamName;
};

const AddedUsers: FC<Props> = ({ isEdit, teamName }) => {
  return <AddedUserForm teamName={teamName} isEdit={isEdit} />;
};

export default AddedUsers;
