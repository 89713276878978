import React, { FC, useEffect, useState, useContext } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, Table, Wrapper } from "components/ui";
import SearchForm from "components/admin/search-form";
import { Loading } from "components/ui/loading";
import Pagination from "components/ui/pagination/pagination";
import Select from "components/ui/select";
import getPrettyPhone from "helpers/pretty-phone-format";
import { StoreTypes } from "store/types";
import selectOptions from "constant/quantity-rows-per-admin-page";
import Row from "components/ui/row";
import debounce from "helpers/debounce";
import PopupConfirm from "components/ui/popup-confirm";
import declOfNum from "helpers/declensionOfNum";
import { paginationTheme } from "components/ui/switch-themes";
import styles from "./legalEntityCurrentUserBase.module.scss";
import { columnsUser, columnsPopup } from "../columns";
import { detachUsersRequest, getLegalUsersRequest } from "../../../../store/legalEntity/actions";
import setRowsHandler from "../../../../helpers/setRowsData";
import { FiltersType } from "../../../../store/employees/types";

const LegalEntityCurrentUserBase: FC<ReduxProps> = ({
  getUsers,
  currentUsersData: { data: currentUsers, last_page: lastPage },
  dataLegalEntity,
  detachUsers,
  isLoading
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedRows, setRows] = useState<Array<string | number>>([]);
  const [selectedRowsCount, setSelectedRowsCount] = useState(selectedRows.length);
  const [filters, setFilters] = useState<FiltersType>(dataLegalEntity ? { legalEntity: dataLegalEntity.id } : {});

  const [detachedUsers, setDetachedUsers] = useState<Array<any>>([]);
  const [isOpen, setOpen] = useState(false);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    setDetachedUsers(setRowsHandler(detachedUsers, selectedRows, currentUsers));
    setClear(false);
  }, [selectedRows]);

  useEffect(() => {
    setSelectedRowsCount(selectedRows.length);
  }, [isOpen]);

  const detachUsersHandler = (): void => {
    detachUsers(detachedUsers.map(user => user.id));
    setDetachedUsers([]);
    setSelectedRowsCount(0);
    setRows([]);
    setClear(true);
    setOpen(false);
  };

  if (dataLegalEntity && dataLegalEntity.users.length === 0) {
    return (
      <Wrapper>
        <p className={styles.desc}>
          Ни один пользователь не прикреплен к юридическому лицу.
          <br />
          Вы можете добавить их из «База пользователей».
        </p>
      </Wrapper>
    );
  }

  return (
    <div>
      <Wrapper pt={40}>
        <PopupConfirm
          isOpen={isOpen}
          action={detachUsersHandler}
          setOpen={setOpen}
          title={`Вы  хотите открепить  ${selectedRowsCount} ${declOfNum(selectedRows.length, [
            "пользователя",
            "пользователей",
            "пользователей"
          ])}?`}
          desc=""
        >
          <Table columns={columnsPopup} data={detachedUsers} />
        </PopupConfirm>
        <Row justifyContent="spaceBetween">
          <div>
            <Button size="small" disabled={!detachedUsers.length} onClick={(): void => setOpen(true)}>
              Открепить
            </Button>
          </div>
          <SearchForm
            rowsPerPage={rowsPerPage}
            action={getUsers}
            filters={filters}
            placeholder="Поиск по ID, email, телефону, ФИО"
            currentPage={currentPage}
            typeData="Client"
            disableAutosuggest
          />
        </Row>
        <Wrapper pt={26}>
          <Loading isLoading={isLoading}>
            <Table
              onClear={clear}
              columns={columnsUser}
              data={currentUsers}
              rowSelection={(rows): void => setRows(rows)}
              selectedRows={selectedRows}
            />
          </Loading>
        </Wrapper>
        <Wrapper pb={32} pt={32}>
          <div className={styles.select_wrap}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
            <Select options={selectOptions} onClick={setRowsPerPage} />
          </div>
        </Wrapper>
      </Wrapper>
    </div>
  );
};

const mapStateToProps = ({ legalEntity }: StoreTypes) => ({
  dataLegalEntity: legalEntity.editLegalEntity,
  currentUsersData: legalEntity.users,
  isLoading: legalEntity.isUsersLoading,
  editing: legalEntity.edit
});

const mapDispatchToProps = {
  getUsers: getLegalUsersRequest,
  detachUsers: detachUsersRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(LegalEntityCurrentUserBase);
