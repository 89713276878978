export enum AUTH_ROUTES_URL {
  ROOT = "/auth",
  PREAUTH = "/marketing-token",
  SIGN_IN = "/auth/signIn",
  REGISTER = "/auth/register",
  FORGOTTEN = "/auth/forgotten",
  OPEN_AUTH = "/authorize",
  RESET_PASSWORD = "/password_reset",
  VERIFY_EMAIL = "/verify",
  ERROR_PAGE = "/error-page",
  USER_MANUAL = "/doc",
  SSO = "/sso",
  RULES = "/rules"
}
export enum LK_ROUTES_URL {
  HOME = "/home"
}
export enum ADMIN_ROUTES_URL {}
