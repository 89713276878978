import axios from "axios";
import { Role, AttachRolesType } from "store/roles/types";
import { RequestParams } from "store/roles/constants";

export const RolesApi = {
  getRoles: (type?: RequestParams): Promise<Array<Role>> => axios.get("/front/role", { params: { type } }),
  attachRoles: (data: AttachRolesType): Promise<any> =>
    axios.post(`/front/user/${data.userId}/attach_roles`, data.roles),
  detachRoles: ({ userId, roles, team_id }: any): Promise<any> =>
    axios.post(`/front/user/${userId}/detach_roles`, { roles, team_id })
};
