export enum EmployeeType {
  all = "all",
  employees = "employees",
  admins = "admins"
}

export enum BlockType {
  all = "all",
  deleted = "deleted",
  banDeletion = "2"
}

export enum AddedDealersEmployee {
  employeesDC = "employees",
  baseEmployees = "baseEmployees"
}
