type setFieldValueType = (field: string, value: string) => void;
type setFieldTouchedType = (field: string, value: boolean) => void;

const trimValueOnBlur = (
  filed: string,
  value: string | undefined,
  setFieldValue: setFieldValueType,
  setFieldTouched: setFieldTouchedType
): void => {
  if (value) {
    setFieldValue(filed, value.trim());
    setFieldTouched(filed, true);
  } else {
    setFieldTouched(filed, true);
  }
};

export default trimValueOnBlur;
