import { CarType } from "store/employees/types";
import moment from "moment";

export const columnsCar = [
  {
    title: "Модель",
    dataIndex: "model_name",
    render: ({ model_name }: CarType): string => model_name
  },
  {
    title: "VIN номер",
    dataIndex: "vin",
    render: ({ vin }: CarType): string => vin
  },
  {
    title: "ГРЗ",
    dataIndex: "license_plate",
    render: ({ license_plate }: CarType): string => license_plate
  },
  {
    title: "Дата создания",
    dataIndex: "created_at",
    render: ({ created_at }: CarType): string => (created_at ? moment(created_at).format("DD.MM.YYYY") : "")
  }
];
