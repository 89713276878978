import React, { FC, useRef, useState, ChangeEvent } from "react";
import Warning from "components/ui/warning";
import cn from "classnames";
import { buttonMainTheme, isHyundaiTheme } from "components/ui/switch-themes";
import styles from "./upload-file.module.scss";
import genesis from "./styles/genesis-upload.module.scss";
import hyundai from "./styles/hyundai-upload.module.scss";

type Props = {
  setFieldValue: (field: string, value: File) => void;
  theme?: "light" | "blue" | "black" | "white" | "transparent" | "link";
  /**
   * How large should the button be?
   */
  size?: "small" | "normal" | "xs";
  /**
   * Optional click handler
   */
  fullWidth?: boolean;
  noBorder?: boolean;
  notHover?: boolean;
};

const UploadFile: FC<Props> = ({
  setFieldValue,
  notHover = true,
  theme = buttonMainTheme,
  size = "normal",
  fullWidth,
  noBorder = false
}) => {
  const [sizeError, setError] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const mainTheme = isHyundaiTheme ? hyundai : genesis;

  const cl = cn(mainTheme.button, {
    [mainTheme[theme]]: theme,
    [mainTheme[size]]: size,
    [mainTheme.fullWidth]: fullWidth,
    [mainTheme.noBorder]: noBorder,
    [mainTheme.notHover]: notHover
  });

  const clickHandler = (): void => {
    inputRef.current?.click();
  };

  const fileSelectionHandler = ({ target: { files } }: ChangeEvent<HTMLInputElement>): void => {
    setError(false);
    const file = files && files[0];
    if (file) {
      if (file.size > 100000000) {
        setError(true);
      } else {
        setFieldValue("users", file);
        setFileName(file.name);
      }
    }
  };
  return (
    <div className={styles.wrap}>
      <button onClick={clickHandler} type="button" className={cl}>
        {fileName || "Загрузите файл"}
      </button>
      <input type="file" accept="text/csv" className={styles.input} ref={inputRef} onChange={fileSelectionHandler} />
      {sizeError && <Warning color="red">Размер файла не должен превышать 10mb</Warning>}
    </div>
  );
};

export default UploadFile;
