import React, { FC } from "react";
import logoSrc2 from "./images/logo2.svg";
import logoSrc3 from "./images/logo3.svg";
import logoSrc4 from "./images/logo4.svg";
import logoSrc5 from "./images/logo5.svg";
import logoSrc6 from "./images/logo6.svg";
import logoSrc7 from "./images/logo7.svg";

export type PropsType = {
  type: "logo2" | "logo3" | "logo4" | "logo5" | "logo6" | "logo7";
};

export const Logo: FC<PropsType> = ({ type }) => {
  switch (type) {
    case "logo3": {
      return <img src={logoSrc3} alt="HYUNDAI-ID" />;
    }
    case "logo4": {
      return <img src={logoSrc4} alt="HYUNDAI-ID" />;
    }
    case "logo5": {
      return <img src={logoSrc5} alt="GENESIS-ID" />;
    }
    case "logo6": {
      return <img src={logoSrc6} alt="GENESIS-ID" />;
    }
    case "logo7": {
      return <img src={logoSrc7} alt="GENESIS-ID" />;
    }
    default: {
      return <img src={logoSrc2} alt="HYUNDAI-ID" />;
    }
  }
};
