import { Employee } from "store/employees/types";
import getPrettyPhone from "helpers/pretty-phone-format";
import { Role } from "store/users/types";

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    render: ({ id }: Employee): string => `${id.substring(0, 4)}...${id.substring(id.length - 4, id.length)}`
  },
  {
    title: "ФИО",
    dataIndex: "name",
    render: ({ name, lastname, patronymic }: Employee): string =>
      `${lastname} ${(name && name[0]) || ""}. ${(patronymic && patronymic[0]) || ""}${(patronymic && ".") || ""}`
  },
  {
    title: "Роль",
    dataIndex: "role",
    render: ({ roles }: Employee): string =>
      roles?.length > 0 ? (roles as Array<Role>).map((role: Role): string => role.display_name).join(", ") : ""
  },
  {
    title: "Дилерский центр",
    dataIndex: "DC",
    render: ({ team }: Employee): string => (team ? team.display_name : "")
  },
  {
    title: "Город",
    dataIndex: "city",
    render: ({ city }: Employee): string => city || ""
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    render: ({ phone }: Employee): string => getPrettyPhone(phone)
  },
  {
    title: "Email",
    dataIndex: "email",
    render: ({ email }: Employee): string => email || ""
  }
];

export const columnsDealership = [
  {
    title: "ФИО",
    dataIndex: "name",
    render: ({ name, lastname, patronymic }: Employee): string =>
      `${lastname} ${(name && name[0]) || ""}. ${(patronymic && patronymic[0]) || ""}${(patronymic && ".") || ""}`
  },
  {
    title: "Роль",
    dataIndex: "role",
    render: ({ roles }: Employee): string =>
      roles?.length > 0 ? (roles as Array<Role>).map((role: Role): string => role.display_name).join(", ") : ""
  },
  {
    title: "Город",
    dataIndex: "city",
    render: ({ city }: Employee): string => city || ""
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    render: ({ phone }: Employee): string => getPrettyPhone(phone)
  },
  {
    title: "Email",
    dataIndex: "email",
    render: ({ email }: Employee): string => email || ""
  }
];

export const columnsPopup = [
  {
    title: "ФИО",
    dataIndex: "name",
    render: ({ name, lastname, patronymic }: Employee): string =>
      `${lastname} ${(name && name[0]) || ""}. ${(patronymic && patronymic[0]) || ""}${(patronymic && ".") || ""}`
  },
  {
    title: "Роль",
    dataIndex: "role",
    render: ({ roles }: Employee): string =>
      roles?.length > 0 ? (roles as Array<Role>).map((role: Role): string => role.display_name).join(", ") : ""
  },

  {
    title: "Телефон",
    dataIndex: "phone",
    render: ({ phone }: Employee): string => getPrettyPhone(phone)
  }
];
