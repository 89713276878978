import React from "react";
import InfoPopup from "components/admin/info-popup";
import { Service } from "../../../store/services/types";
import styles from "./ServicesPage.module.scss";

type ListType = {
  id: string | number;
  name: string;
  email?: string;
};

const createItemTitle = (name: string, email?: string) => {
  return email ? `${name} (${email})` : name;
};

const renderList = <T extends ListType>(
  list: Array<T>,
  serviceName: string,
  columnTitle: string
): string | JSX.Element => {
  if (!list || list.length === 0) return "";
  if (list.length <= 2) {
    return (
      <ul className={styles.columnList}>
        {list.map(item => (
          <li key={item.id}>{createItemTitle(item.name, item.email)}</li>
        ))}
      </ul>
    );
  }
  return (
    <ul className={styles.columnList}>
      {list.slice(0, 2).map(item => (
        <li key={item.id}>{createItemTitle(item.name, item.email)}</li>
      ))}
      <li key="dots" className={styles.popupItem}>
        <InfoPopup popupTitle={`${columnTitle} сервиса ${serviceName}:`}>
          <ul className={styles.columnList}>
            {list.map(elem => (
              <li key={elem.id} className={styles.columnItem}>
                {createItemTitle(elem.name, elem.email)}
              </li>
            ))}
          </ul>
        </InfoPopup>
      </li>
    </ul>
  );
};

const renderPdList = <T extends ListType>(
  listUser: Array<T>,
  listTenant: Array<T>,
  serviceName: string,
  columnTitle: string
): string | JSX.Element => {
  return (
    <>
      {listUser?.length > 0 && (
        <>
          <h6 className={styles.columnTitle}>Пользователь:</h6>
          {renderList(listUser, serviceName, `${columnTitle} (Пользователь)`)}
        </>
      )}
      {listTenant?.length > 0 && (
        <>
          <h6 className={styles.columnTitle}>Арендатор:</h6>
          {renderList(listTenant, serviceName, `${columnTitle} (Арендатор)`)}
        </>
      )}
    </>
  );
};

export const columns = [
  {
    title: "Название",
    dataIndex: "name",
    render({ name }: Service): string {
      return `${name}`;
    }
  },
  {
    title: "Код",
    dataIndex: "code",
    render({ code }: Service): string {
      return `${code}`;
    }
  },
  {
    title: "Данные валидированы",
    dataIndex: "validated_data",
    width: 1,
    render({ validated_data }: Service) {
      return validated_data ? "Да" : "Нет";
    }
  },
  {
    title: "Третьи лица",
    dataIndex: "thirdParties",
    width: 20,
    render({ thirdParties, name }: Service) {
      return renderList(thirdParties, name, "Третьи лица");
    }
  },
  {
    title: "Категории ПДн",
    dataIndex: "personalDataList",
    width: 20,
    render({ personalDataListUser, personalDataListTenant, name }: Service) {
      return renderPdList(personalDataListUser, personalDataListTenant, name, "Категории ПДн");
    }
  }
];

export const deleteRowsColumn = [
  {
    title: "Название",
    dataIndex: "name",
    render({ name }: Service): string {
      return `${name}`;
    }
  },
  {
    title: "Код",
    dataIndex: "code",
    render({ code }: Service): string {
      return `${code}`;
    }
  },
  {
    title: "Данные валидированы",
    dataIndex: "validated_data",
    width: 1,
    render({ validated_data }: Service) {
      return validated_data ? "Да" : "Нет";
    }
  }
];
