import { put, takeEvery, call, all, debounce, takeLatest, delay } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { DeletedPersonalDataApi } from "services/api/deleted-personal-data";
import { TYPES_DELETED_PERSONAL_DATA } from "./constants";
import {
  deletedPersonalDataError,
  deletedPersonalDataRequest,
  deletedPersonalDataSuccess,
  getDeletedPersonalDataServicesError,
  getDeletedPersonalDataServicesSuccess
} from "./actions";
import { DeletedPersonalData } from "./types";

function* getDeletePersonalData(action: ReturnType<typeof deletedPersonalDataRequest>): SagaIterator {
  if (action.payload.isFilterChanged) {
    yield delay(500);
  }

  try {
    const [services, response] = yield all([
      call(DeletedPersonalDataApi.getServices),
      call(DeletedPersonalDataApi.getDeletedPersonalData, action.payload)
    ]);
    yield put(getDeletedPersonalDataServicesSuccess(services));
    const deletedPersonalData = response.data.map((item: DeletedPersonalData) => {
      const service = services.find((service: any) => item.service_id === service.id);
      return { ...item, service_name: service.name };
    });
    yield put(deletedPersonalDataSuccess({ ...response, data: deletedPersonalData }));
  } catch (e) {
    yield put(deletedPersonalDataError({ deletedPersonalData: "Не удалось загрузить данные по удаленным ПД" }));
  }
}

function* getDeletePersonalDataServices(): SagaIterator {
  try {
    const response = yield call(DeletedPersonalDataApi.getServices);
    yield put(getDeletedPersonalDataServicesSuccess(response));
  } catch (e) {
    yield put(
      getDeletedPersonalDataServicesError({ deletedPersonalDataServices: "Не удалось загрузить список сервисов" })
    );
  }
}

function* deletePersonalDataSaga(): SagaIterator {
  yield takeLatest(TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_REQUEST, getDeletePersonalData);
  yield takeEvery(
    TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_SERVICES_REQUEST,
    getDeletePersonalDataServices
  );
}

export { deletePersonalDataSaga };
