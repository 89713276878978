import React, { FC, useEffect, useState, useContext } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, Table, Wrapper } from "components/ui";
import SearchForm from "components/admin/search-form";
import { Loading } from "components/ui/loading";
import Pagination from "components/ui/pagination/pagination";
import Select from "components/ui/select";
import { Employee, FiltersType } from "store/employees/types";
import getPrettyPhone from "helpers/pretty-phone-format";
import { StoreTypes } from "store/types";
import selectOptions from "constant/quantity-rows-per-admin-page";
import Row from "components/ui/row";
import { Employees as EmployeesApi } from "services/api/employees";
import debounce from "helpers/debounce";
import { addedEmployeesDC, dealersEmployeeUpdate, getBaseEmployee, putEmployeesDC } from "store/dealers/actions";
import { EmployeeType } from "constant/radiobutton-values";
import EmployeeRoles from "constant/eployee-roles";
import { DealershipContext } from "context/Dealership";
import setRowsHandler from "helpers/setRowsData";
import PopupConfirm from "components/ui/popup-confirm";
import declOfNum from "helpers/declensionOfNum";
import { columns, columnsPopup } from "components/admin/added-dealership/columns";
import { paginationTheme } from "components/ui/switch-themes";
import styles from "./employee-base.module.scss";

type PropsTypes = {
  employeeType: string;
};

const EmployeeBase: FC<ReduxProps & PropsTypes> = ({
  onUpdateList,
  editEmployeeDC,
  employeeType,
  addedEmployee,
  onRequest,
  employees: { data: employees, last_page: lastPage },
  isLoading
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRows, setRows] = useState<Array<string | number>>([]);
  const [filters, setFilters] = useState<FiltersType>({});
  const [selectedRowsCount, setSelectedRowsCount] = useState(selectedRows.length);
  const [employeeAttach, setEmployeeAttach] = useState<Array<any>>([]);
  const [isOpen, setOpen] = useState(false);
  const [clear, setClear] = useState(false);

  const { setSelectedRows, isEdit, setEmployeesChange } = useContext(DealershipContext);

  useEffect(() => {
    if (clear) {
      setClear(false);
    }
    setSelectedRows(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    if (employeeType === EmployeeType.admins) {
      setFilters({ dealer_center_employee: true, role: [EmployeeRoles.adminDC] });
    } else if (employeeType === EmployeeType.employees) {
      setFilters({ dealer_center_employee: true, role: [EmployeeRoles.employeeDC] });
    } else {
      setFilters({ dealer_center_employee: true, role: [EmployeeRoles.adminDC, EmployeeRoles.employeeDC] });
    }
  }, [employeeType]);

  const getSuggestion = async (
    value: string,
    setSuggestions: (data: Array<{ id: string; data: string }>) => void
  ): Promise<void> => {
    try {
      const { data } = await EmployeesApi.getEmployees({
        search: value,
        filters
      });
      const firstSuggestions = data.slice(0, rowsPerPage);
      const extractData = firstSuggestions.map(({ id, email, name, lastname, patronymic, phone }: Employee) => {
        const fullName = `${lastname} ${name && name[0]}. ${patronymic && patronymic[0]}.`;
        const prettyPhone = getPrettyPhone(phone);
        return {
          id,
          data: `${fullName} ${email} ${prettyPhone}`
        };
      });
      setSuggestions(extractData);
    } catch (error) {
      console.error(error);
    }
  };

  const getSuggestionByDebounce = debounce(getSuggestion, 500);

  const sortIdsRoles = () => {
    if (employeeAttach.length) {
      const admin: string[] = [];
      const employeesDC: string[] = [];

      employeeAttach.forEach(employee => {
        if (employee.roles[0].name === EmployeeRoles.adminDC) {
          admin.push(employee.id);
        } else if (employee.roles[0].name === EmployeeRoles.employeeDC) {
          employeesDC.push(employee.id);
        }
      });

      if (admin.length && employeesDC.length) {
        return [
          { role: EmployeeRoles.adminDC, user_id: admin },
          { role: EmployeeRoles.employeeDC, user_id: employeesDC }
        ];
      }
      if (admin.length) {
        return [{ role: EmployeeRoles.adminDC, user_id: admin }];
      }
      if (employeesDC.length) {
        return [{ role: EmployeeRoles.employeeDC, user_id: employeesDC }];
      }
    }
    return null;
  };

  const addedEmployeeDC = (): void => {
    if (isEdit) {
      editEmployeeDC(sortIdsRoles());
    } else {
      addedEmployee(sortIdsRoles());
    }
    const newListDealers = employees.filter(employee => !selectedRows.includes(employee.id));
    onUpdateList(newListDealers);
    setClear(true);
    setOpen(false);
    setEmployeesChange(true);
  };

  useEffect(() => {
    setEmployeeAttach(setRowsHandler(employeeAttach, selectedRows, employees));
  }, [selectedRows]);

  useEffect(() => {
    setSelectedRowsCount(selectedRows.length);
  }, [isOpen]);

  return (
    <div>
      <Wrapper pt={40}>
        <PopupConfirm
          isOpen={isOpen}
          action={addedEmployeeDC}
          setOpen={setOpen}
          title={`Вы  хотите добавить  ${selectedRowsCount} ${declOfNum(selectedRows.length, [
            "сотрудника",
            "сотрудников",
            "сотрудников"
          ])}?`}
          desc=""
        >
          <Table columns={columnsPopup} data={employeeAttach} />
        </PopupConfirm>
        <Row justifyContent="spaceBetween">
          <div>
            <Button size="small" disabled={!employeeAttach.length} onClick={(): void => setOpen(true)}>
              Прикрепить к ДЦ
            </Button>
          </div>
          <SearchForm
            rowsPerPage={rowsPerPage}
            action={onRequest}
            getSuggestion={getSuggestionByDebounce}
            placeholder="Поиск по ID, email, телефону"
            currentPage={currentPage}
            filters={filters}
            disableAutosuggest
          />
        </Row>
        <Wrapper pt={26}>
          <Loading isLoading={isLoading} />
          <Table
            onClear={clear}
            columns={columns}
            data={employees}
            rowSelection={(rows): void => setRows(rows)}
            selectedRows={selectedRows}
          />
        </Wrapper>
        <Wrapper pb={32} pt={32}>
          <div className={styles.select_wrap}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
            <Select options={selectOptions} onClick={setRowsPerPage} />
          </div>
        </Wrapper>
      </Wrapper>
    </div>
  );
};

const mapStateToProps = ({ dealers }: StoreTypes) => ({
  employees: dealers.dealers,
  isLoading: dealers.isLoading,
  editing: dealers.edit
});

const mapDispatchToProps = {
  onRequest: getBaseEmployee,
  addedEmployee: addedEmployeesDC,
  editEmployeeDC: putEmployeesDC,
  onUpdateList: dealersEmployeeUpdate
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(EmployeeBase);
