import { put, takeLatest, takeEvery, call, cancel, select } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { User } from "services/api/user";
import { Clients } from "services/api/clients";
import { resetUser } from "helpers/resetUser";
import { authUpdateTtl, serverError, updateDataUser } from "store/auth/actions";
import { getAuthSelector } from "store/auth/selectors/getAuthSelector";
import { getAuthUpdateUser } from "store/auth/selectors/getAuthUpdateUserSelector";
import { redirectUriService } from "store/auth/saga";
import { notificationErrors } from "components/ui/notification/notification-errors";
import { AUTH_API_STATUS } from "constant/step-auth";
import { ERROR_MESSAGE } from "constant/step-delete-user";
import { getUserData } from "./selectors/getDataUserSelector";
import history from "../../services/history";
import { TYPES_USER, UpdateType } from "./constants";
import {
  usersError,
  usersStatus,
  usersSuccess,
  usersLoad,
  usersLoadStop,
  uploadAvatarSuccessUserSet,
  updateUserSuccess,
  changePasswordSuccess,
  uploadAvatarSuccessNotSet,
  uploadAvatarSuccessEmployeeSet,
  uploadAvatarRequest,
  updateUserRequest,
  deleteAccountRequest,
  changePasswordRequest,
  attachTeamRequest,
  detachTeamRequest,
  createUserRequest,
  restoreUser,
  updateUserPhoneRequest,
  sendCodeRequest,
  updateUserEmailRequest,
  sendEmailCodeRequest,
  switchUserConcent,
  deleteAccountSuccess,
  restoreAccountRequest,
  restoreAccountSuccess,
  deleteAccountCheckRequest,
  deleteAccountCheckSuccess,
  sendEmailVerifyRequest,
  banDeletionClientsRequest,
  banDeletionClientsSuccess,
  getUsersServicesSuccess
} from "./actions";
import { attachRoles as attachRolesSaga } from "../roles/saga";
import { deleteEmployeeSuccess, employeesByIdSuccess } from "../employees/actions";
import { TYPES_ROLES as RolesTYPES } from "../roles/constants";
import { SendCodeResponseType } from "./types";

export function* userInfo(): SagaIterator {
  const userHasData = yield select(getUserData);
  if (!userHasData.name) {
    yield put(usersLoad());
    try {
      const response = yield call(User.getUserInfo);
      yield put(usersSuccess(response));
    } catch (e) {
      /// Чистить куки
      if (![401].includes(e.status) && !e.statusText.includes("Unauthorized")) {
        history.push("/error-page");
      }
    } finally {
      yield put(usersLoadStop());
    }
  }
}

export function* uploadAvatar(action: ReturnType<typeof uploadAvatarRequest>): SagaIterator {
  const { payload } = action;
  yield put(usersLoad());
  try {
    const response = yield call(User.uploadAvatar, payload);
    if (payload.setAvatar === "user") {
      yield put(uploadAvatarSuccessUserSet(response));
    } else if (payload.setAvatar === "currentEmployee") {
      yield put(uploadAvatarSuccessEmployeeSet(response));
    } else if (payload.setAvatar === "notSet") {
      yield put(uploadAvatarSuccessNotSet());
    }
  } catch (e) {
    yield put(usersError({ avatar: "Не удалось загрузить аватар" }));
  } finally {
    yield put(usersLoadStop());
  }
}

export function* updateUser(action: ReturnType<typeof updateUserRequest>): SagaIterator {
  const {
    payload: { updateType, errorName, customErrorSetter, body }
  } = action;
  const successAction = {
    [UpdateType.UpdateMainUser]: updateUserSuccess,
    [UpdateType.UpdateCurrentEmployee]: employeesByIdSuccess
  };
  yield put(usersLoad());
  try {
    const { openAuthUrl: redirectUrl } = yield select(getAuthSelector);
    const isUserUpdateAuth = yield select(getAuthUpdateUser);

    const response = yield call(User.updateUser, body);
    yield put(successAction[updateType](response));
    if (isUserUpdateAuth) {
      redirectUriService(redirectUrl);
      yield put(updateDataUser(false));
    } else {
      yield put(usersStatus({ updateUser: "Данные успешно изменены" }));
    }
  } catch (error) {
    if (customErrorSetter) {
      customErrorSetter(error);
    } else if ([400].includes(error.status)) {
      const { email } = error.data.errors;
      const [errorMessage] = email;
      yield put(usersError({ email: errorMessage }));
    } else {
      const key = errorName || "message";
      yield put(usersError({ [key]: "Не удалось изменить данные" }));
    }
    yield cancel();
  } finally {
    yield put(usersLoadStop());
  }
}

export function* deleteAccount(action: ReturnType<typeof deleteAccountRequest>): SagaIterator {
  const { payload } = action;
  yield put(usersLoad());
  try {
    yield call(User.deleteUserAccount, payload);
    yield put(deleteAccountSuccess());
    yield put(usersStatus({ deleteAccount: "Заявка на удаление принята" }));
    notificationErrors({ title: "Заявка на удаление принята", type: "success" });
  } catch (e) {
    yield put(usersError({ message: "Не удалось удалить аккаунт" }));
    if (e.data.error === ERROR_MESSAGE.OTHER_SERVICES) {
      yield put(
        usersStatus({
          needToDeleteServices: "Необходимо удалить свои данные в других сервисах",
          services: e.data.services
        })
      );
    }
  } finally {
    yield put(usersLoadStop());
  }
}

export function* deleteAccountCheck(): SagaIterator {
  yield put(usersLoad());
  try {
    yield call(User.deleteAccountCheck);
    yield put(deleteAccountCheckSuccess());
    yield put(usersStatus({ deleteAccount: "Аккаунт готов к удалению" }));
  } catch (error) {
    if ([400].includes(error.status)) {
      yield put(
        usersStatus({
          needToDeleteServices: "Необходимо удалить свои данные в других сервисах",
          services: error.data.services
        })
      );
    } else {
      yield put(usersError({ message: "Не удалось получить данные по связанным сервисам" }));
    }
  } finally {
    yield put(usersLoadStop());
  }
}

function* restoreAccount(action: ReturnType<typeof restoreAccountRequest>): SagaIterator {
  yield put(usersLoad());
  try {
    const { payload } = action;
    yield call(User.restoreUser, payload);
    yield put(restoreAccountSuccess());
    yield put(usersStatus({ restoreAccount: "Заявка на удаление отозвана" }));
    notificationErrors({ title: "Заявка на удаление отозвана", type: "success" });
  } catch (e) {
    yield put(usersError({ message: "Не удалось восстановить аккаунт" }));
  } finally {
    yield put(usersLoadStop());
  }
}

export function* sendPhoneCode(action: ReturnType<typeof sendCodeRequest>): SagaIterator {
  const { payload } = action;

  yield put(usersLoad());
  try {
    yield put(authUpdateTtl(null));
    const response: SendCodeResponseType = yield call(User.sendPhoneCode, payload);
    if (response.status === AUTH_API_STATUS.CODE_ALREADY_SENT) {
      notificationErrors({ message: response.error });
      yield put(authUpdateTtl(response.sms_code_ttl));
    } else {
      yield put(usersStatus({ sendCode: "Код отправлен" }));
    }
  } catch (e) {
    if (e.data.message) {
      notificationErrors({ message: e.data.message });
    } else {
      yield put(serverError(e.data.error));
    }
  } finally {
    yield put(usersLoadStop());
  }
}

export function* updateUserEmail(action: ReturnType<typeof updateUserEmailRequest>): SagaIterator {
  const { payload } = action;
  yield put(usersLoad());
  try {
    const response = yield call(User.changeEmailAddress, payload);
    if (response.status === AUTH_API_STATUS.CODE_ALREADY_SENT) notificationErrors({ message: response.error });
    else {
      yield put(usersStatus({ updateUserEmail: "Код отправлен" }));
    }
  } catch (e) {
    if (e.data.message) {
      notificationErrors({ message: e.data.message });
    } else {
      yield put(serverError(e.data.error));
    }
  } finally {
    yield put(usersLoadStop());
  }
}

export function* updateUserPhoneNumber(action: ReturnType<typeof updateUserPhoneRequest>): SagaIterator {
  const { payload } = action;
  yield put(usersLoad());
  try {
    const response = yield call(User.changePhoneNumber, payload);
    if (response.phone) {
      yield put(usersStatus({ updateUserPhone: "Номер телефона обновлен" }));
      yield put(usersSuccess(response));
      notificationErrors({ title: "Номер успешно изменен", type: "success" });
    }
  } catch (e) {
    if (e.data.message) {
      notificationErrors({ message: e.data.message });
    } else {
      yield put(serverError(e.data.error));
    }
  } finally {
    yield put(usersLoadStop());
  }
}

export function* sendNewEmailCode(action: ReturnType<typeof sendEmailCodeRequest>): SagaIterator {
  const { payload } = action;
  yield put(usersLoad());
  try {
    const response = yield call(User.sendEmailCode, payload);
    if (response.phone) {
      yield put(usersStatus({ sendCode: "Email обновлен" }));
      yield put(usersSuccess(response));
      notificationErrors({ title: "Email успешно изменен", type: "success" });
    }
  } catch (e) {
    if (e.data.message) {
      notificationErrors({ message: e.data.message });
    } else {
      yield put(serverError(e.data.error));
    }
  } finally {
    yield put(usersLoadStop());
  }
}

export function* deleteMarketConcentAgreement(): SagaIterator {
  yield put(usersLoad());
  try {
    yield call(User.deleteMarketingConsent);
    yield put(usersStatus({ success: "Согласие отозвано" }));
    yield put(switchUserConcent());
    notificationErrors({ title: "Согласие на маркетинговую коммуникацию отозвано", type: "success" });
  } catch (e) {
    if (e.data.message) {
      notificationErrors({ message: e.data.message });
    } else {
      yield put(serverError(e.data.error));
    }
  } finally {
    yield put(usersLoadStop());
  }
}

export function* acceptMarketConcentAgreement(): SagaIterator {
  yield put(usersLoad());
  try {
    yield call(User.acceptMarketingConsent);
    yield put(usersStatus({ success: "Согласие принято" }));
    yield put(switchUserConcent());
    notificationErrors({ title: "Cогласие на маркетинговую коммуникацию получено", type: "success" });
  } catch (e) {
    if (e.data.message) {
      notificationErrors({ message: e.data.message });
    } else {
      yield put(serverError(e.data.error));
    }
  } finally {
    yield put(usersLoadStop());
  }
}

export function* acceptMarketConcentAgreementForService(): SagaIterator {
  yield put(usersLoad());
  try {
    yield call(User.acceptMarketingConsentForService);
    yield put(usersStatus({ success: "Согласие принято" }));
    yield put(switchUserConcent());
    notificationErrors({ title: "Cогласие на маркетинговую коммуникацию получено", type: "success" });
  } catch (e) {
    if (e.data.message) {
      notificationErrors({ message: e.data.message });
    } else {
      yield put(serverError(e.data.error));
    }
  } finally {
    yield put(usersLoadStop());
  }
}

function* changePassword(action: ReturnType<typeof changePasswordRequest>): SagaIterator {
  const {
    payload: {
      values,
      values: { password, newPassword, confirmedPassword },
      setFieldError,
      resetForm
    }
  } = action;

  const passwords = {
    [TYPES_USER.OLD_PASSWORD]: password,
    [TYPES_USER.NEW_PASSWORD]: newPassword,
    [TYPES_USER.REPEAT_PASSWORD]: confirmedPassword
  };
  yield put(usersLoad());
  try {
    const { message } = yield call(User.changePassword, passwords);
    yield put(changePasswordSuccess());
    resetForm({
      values: {
        ...values,
        password: "",
        newPassword: "",
        confirmedPassword: ""
      }
    });
    setFieldError("password", "");
    yield put(usersStatus({ password: message }));
  } catch (e) {
    const {
      data: { error }
    } = e;
    if (!error) {
      setFieldError("password", "Не удалось изменить пароль");
    } else {
      setFieldError("password", error);
    }
  } finally {
    yield put(usersLoadStop());
  }
}

export function* attachTeam(action: ReturnType<typeof attachTeamRequest>): SagaIterator {
  const { payload } = action;
  yield put(usersLoad());
  try {
    yield call(User.attachTeam, payload);
  } catch (e) {
    yield put(usersError({ attachTeam: "Не удалось прикрепить поле team" }));
  } finally {
    yield put(usersLoadStop());
  }
}

export function* detachTeam(action: ReturnType<typeof detachTeamRequest>): SagaIterator {
  const { payload } = action;
  yield put(usersLoad());
  try {
    yield call(User.detachTeam, payload);
  } catch (e) {
    yield put(usersError({ detachTeam: "Не удалось открепить поле team" }));
  } finally {
    yield put(usersLoadStop());
  }
}

function* createUser(action: ReturnType<typeof createUserRequest>): SagaIterator {
  const {
    payload: {
      body: { position_id: positionId, picture, teams, roles, ...body },
      teamsRole,
      resetForm,
      setOpen,
      customErrorSetter
    }
  } = action;
  const [team] = teams;
  yield put(usersLoad());
  try {
    const { id } = yield call(User.createUser, body);
    if (roles.length && teams.length) {
      yield call(attachRolesSaga, {
        type: RolesTYPES.ATTACH_ROLES_REQUEST,
        payload: { userId: id, roles, team_id: team }
      });
    } else {
      yield call(attachRolesSaga, {
        type: RolesTYPES.ATTACH_ROLES_REQUEST,
        payload: { userId: id, roles }
      });
    }
    if (picture) {
      yield call(uploadAvatar, {
        type: TYPES_USER.UPLOAD_AVATAR_REQUEST,
        payload: { id, data: picture, setAvatar: "notSet" }
      });
    }

    if (positionId) {
      yield call(User.attachPosition, id, {
        position_id: positionId
      });
    }
    resetForm();
    yield put(usersStatus({ createUser: "Пользователь создан" }));
    if (setOpen) {
      setOpen(true);
    }
  } catch (error) {
    if (customErrorSetter) {
      customErrorSetter(error);
    } else {
      yield put(usersError({ createUser: "Не удалось создать пользователя" }));
    }
  } finally {
    yield put(usersLoadStop());
  }
}

function* restoreUsers(action: ReturnType<typeof restoreUser>): SagaIterator {
  yield put(usersLoad());
  try {
    const { payload } = action;
    yield call(User.restoreUser, payload);
    yield put(deleteEmployeeSuccess(String(payload)));
  } catch (e) {
    console.error("Ошибка восстановления");
  } finally {
    yield put(usersLoadStop());
  }
}

function* sendEmailVerify(action: ReturnType<typeof sendEmailVerifyRequest>): SagaIterator {
  try {
    const response = yield call(User.sendEmailVerify);
    notificationErrors({
      title: "Письмо для подтверждения email отправлено",
      message: response.message,
      type: "success"
    });
  } catch (e) {
    notificationErrors({ message: e.data.message });
  }
}

function* getBanDeletionClients(action: ReturnType<typeof banDeletionClientsRequest>): SagaIterator {
  const { payload } = action;
  yield put(usersLoad());
  try {
    const response = yield call(Clients.getBanDeletionClients, payload);
    yield put(banDeletionClientsSuccess(response));
  } catch (e) {
    notificationErrors({ message: "Не удалось загрузить данные" });
  } finally {
    yield put(usersLoadStop());
  }
}

function* getUsersServices(): SagaIterator {
  try {
    const response = yield call(Clients.getServices);
    yield put(getUsersServicesSuccess(response));
  } catch (e) {
    notificationErrors({ message: "Не удалось загрузить список сервисов" });
  }
}

function* usersSaga(): SagaIterator {
  yield takeLatest(TYPES_USER.USERS_REQUEST, userInfo);
  yield takeEvery(TYPES_USER.UPLOAD_AVATAR_REQUEST, uploadAvatar);
  yield takeLatest(TYPES_USER.DELETE_ACCOUNT_REQUEST, deleteAccount);
  yield takeLatest(TYPES_USER.DELETE_ACCOUNT_CHECK_REQUEST, deleteAccountCheck);
  yield takeLatest(TYPES_USER.RESTORE_ACCOUNT_REQUEST, restoreAccount);
  yield takeEvery(TYPES_USER.CHANGE_PASSWORD_REQUEST, changePassword);
  yield takeEvery(TYPES_USER.CREATE_USER_REQUEST, createUser);
  yield takeEvery(TYPES_USER.ATTACH_TEAM_REQUEST, attachTeam);
  yield takeEvery(TYPES_USER.DETACH_TEAM_REQUEST, detachTeam);
  yield takeEvery(TYPES_USER.UPDATE_USER_REQUEST, updateUser);
  yield takeEvery(TYPES_USER.USERS_RESTORE, restoreUsers);
  yield takeEvery(TYPES_USER.USER_SEND_CODE, sendPhoneCode);
  yield takeEvery(TYPES_USER.UPDATE_USER_PHONE_REQUEST, updateUserPhoneNumber);
  yield takeEvery(TYPES_USER.UPDATE_USER_EMAIL_REQUEST, updateUserEmail);
  yield takeEvery(TYPES_USER.USER_SEND_EMAIL_CODE, sendNewEmailCode);
  yield takeEvery(TYPES_USER.USER_DELETE_MARKET_CONS, deleteMarketConcentAgreement);
  yield takeEvery(TYPES_USER.USER_ACCEPT_MARKET_CONS, acceptMarketConcentAgreement);
  yield takeEvery(TYPES_USER.USER_ACCEPT_MARKET_CONS_FOR_SERVICE, acceptMarketConcentAgreementForService);
  yield takeEvery(TYPES_USER.USER_SEND_EMAIL_VERIFY_REQUEST, sendEmailVerify);
  yield takeEvery(TYPES_USER.BAN_DELETION_CLIENTS_REQUEST, getBanDeletionClients);
  yield takeEvery(TYPES_USER.GET_USERS_SERVICES_REQUEST, getUsersServices);
}

export { usersSaga };
