import { TYPES_SERVICES } from "store/services/constants";
import {
  Permission,
  PersonalData,
  Service,
  UpdateServiceRequest,
  ThirdPartiesData,
  CreateServiceType,
  ServiceCredentialsType,
  ServiceDeleteType,
  GetServiceListParams,
  ServiceListType
} from "./types";

export const getServicesRequest = (body: GetServiceListParams) => ({
  type: TYPES_SERVICES.GET_SERVICES_REQUEST,
  payload: body
});

export const getServicesSuccess = (payload: ServiceListType) => ({
  type: TYPES_SERVICES.GET_SERVICES_SUCCESS,
  payload
});

export const getServicesError = (error: { [key: string]: string }) => ({
  type: TYPES_SERVICES.GET_SERVICES_ERROR,
  payload: error
});

export const getServiceByIdRequest = (id: string) => ({
  type: TYPES_SERVICES.GET_SERVICE_BY_ID_REQUEST,
  payload: id
});

export const getServiceByIdSuccess = (payload: Service) => ({
  type: TYPES_SERVICES.GET_SERVICE_BY_ID_SUCCESS,
  payload
});

export const getServiceByIdError = (error: { [key: string]: string }) => ({
  type: TYPES_SERVICES.GET_SERVICE_BY_ID_ERROR,
  payload: error
});

export const getPersonalDataTypeRequest = () => ({
  type: TYPES_SERVICES.GET_PERSONAL_DATA_TYPE_REQUEST
});

export const getPersonalDataTypeSuccess = (payload: Array<PersonalData>) => ({
  type: TYPES_SERVICES.GET_PERSONAL_DATA_TYPE_SUCCESS,
  payload
});

export const getPersonalDataTypeError = (error: { [key: string]: string }) => ({
  type: TYPES_SERVICES.GET_PERSONAL_DATA_TYPE_ERROR,
  payload: error
});

export const getPermissionListRequest = () => ({
  type: TYPES_SERVICES.GET_PERMISSION_LIST_REQUEST
});

export const getPermissionListSuccess = (payload: Array<Permission>) => ({
  type: TYPES_SERVICES.GET_PERMISSION_LIST_SUCCESS,
  payload
});

export const getPermissionListError = (error: { [key: string]: string }) => ({
  type: TYPES_SERVICES.GET_PERMISSION_LIST_ERROR,
  payload: error
});

export const updateServiceRequest = (id: string, body: UpdateServiceRequest) => ({
  type: TYPES_SERVICES.UPDATE_SERVICE_REQUEST,
  payload: {
    id,
    body
  }
});

export const updateServiceSuccess = (payload: Service) => ({
  type: TYPES_SERVICES.UPDATE_SERVICE_SUCCESS,
  payload
});

export const updateServiceError = (error: { [key: string]: string }) => ({
  type: TYPES_SERVICES.UPDATE_SERVICE_ERROR,
  payload: error
});

export const openServiceSuccessModal = () => ({
  type: TYPES_SERVICES.OPEN_SERVICE_SUCCESS_MODAL
});

export const closeServiceSuccessModal = () => ({
  type: TYPES_SERVICES.CLOSE_SERVICE_SUCCESS_MODAL
});

export const getThirdPartiesListSuccess = (payload: Array<ThirdPartiesData>) => ({
  type: TYPES_SERVICES.GET_THIRD_PARTIES_LIST_SUCCESS,
  payload
});

export const getThirdPartiesListRequest = () => ({
  type: TYPES_SERVICES.GET_THIRD_PARTIES_LIST_REQUEST
});

export const getThirdPartiesListError = (error: { [key: string]: string }) => ({
  type: TYPES_SERVICES.GET_THIRD_PARTIES_LIST_ERROR,
  payload: error
});

export const servicesLoad = () => ({
  type: TYPES_SERVICES.SERVICES_LOAD
});

export const servicesLoadStop = () => ({
  type: TYPES_SERVICES.SERVICES_LOAD_STOP
});

export const setServicesRows = (payload: Array<string | number>) => ({
  type: TYPES_SERVICES.SET_SERVICES_ROWS,
  payload
});

export const filterServicesRows = (payload: number | string) => ({
  type: TYPES_SERVICES.FILTER_SERVICES_ROWS,
  payload
});

export const deleteServiceRequest = (payload: ServiceDeleteType) => ({
  type: TYPES_SERVICES.DELETE_SERVICE_REQUEST,
  payload
});

export const deleteServiceSuccess = (payload: string | number) => ({
  type: TYPES_SERVICES.DELETE_SERVICE_SUCCESS,
  payload
});

export const deleteServiceError = (error: { [key: string]: string }) => ({
  type: TYPES_SERVICES.DELETE_SERVICE_ERROR,
  payload: error
});

export const createServiceRequest = (data: CreateServiceType) => ({
  type: TYPES_SERVICES.SERVICE_CREATE_REQUEST,
  payload: data
});

export const createServiceSuccess = (data: any) => ({
  type: TYPES_SERVICES.SERVICE_CREATE_SUCCESS,
  payload: data
});

export const setOpenServiceModalWindow = (toggle: boolean) => ({
  type: TYPES_SERVICES.MODAL_OPEN_SERVICE,
  payload: toggle
});

export const getServiceCredentialsRequest = (id: string | number) => ({
  type: TYPES_SERVICES.GET_SERVICE_CREDENTIALS_REQUEST,
  payload: id
});

export const getServiceCredentialsSuccess = (payload: ServiceCredentialsType) => ({
  type: TYPES_SERVICES.GET_SERVICE_CREDENTIALS_SUCCESS,
  payload
});

export const clearServiceCredentials = () => ({
  type: TYPES_SERVICES.CLEAR_SERVICE_CREDENTIALS
});

export const setOpenCredentialsPopup = (toggle: boolean) => ({
  type: TYPES_SERVICES.POPUP_OPEN_CREDENTIALS,
  payload: toggle
});

export const servicesStatus = (status: { [key: string]: string } | null) => ({
  type: TYPES_SERVICES.SERVICES_STATUS,
  payload: status
});
