import React, { FC } from "react";
import mainTheme, { logoCompany } from "components/ui/switch-themes";
import cn from "classnames";
import styles from "./layout.module.scss";
import Nav from "../nav";
import { Logo } from "../../ui";
import UserInfo from "../../user-info";
import Logout from "../../logout";

const Layout: FC = ({ children }) => {
  const cnNav = cn(styles.nav, {
    [styles[mainTheme]]: mainTheme
  });

  return (
    <div className={styles.layout}>
      <div className={styles.column}>
        <div className={styles.sector}>
          <div className={cnNav}>
            <div className={styles.logo}>
              <Logo type={logoCompany} />
            </div>
            <div className={styles.menu}>
              <Nav />
            </div>
            <div className={styles.logout}>
              <UserInfo theme="white" />
              <Logout iconTheme="white" />
            </div>
          </div>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
