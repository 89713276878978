import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { AUTH_ROUTES_URL } from "constant/routers";
import { forgottenNewPasswordAction, serverError } from "store/auth/actions";
import { isValidPassword, isValidPasswordSpace } from "helpers/validate";
import { StoreTypes } from "store/types";
import { getAuthServerError } from "store/auth/selectors/getAuthErrorSelector";
import { Button, Text, Wrapper, Link, Input } from "components/ui";
import history from "services/history";
import { authLogo } from "components/ui/helpersComponents";
import { isHyundaiTheme, sizeTitleAuth, titleCompanyTheme } from "components/ui/switch-themes";

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .matches(isValidPasswordSpace, "Не используйте несколько пробелов подряд")
    .matches(isValidPassword, "Некорректные данные")
    .required("Необходимо заполнить данные")
    .min(6, "Пароль должен быть от 6 до 20 символов")
    .max(20, "Пароль должен быть от 6 до 20 символов")
});

const ForgottenNewPassword: FC<ReduxProps> = ({ warning, errorServer, password: onSubmit }) => {
  const nextStep = (values: { password: string }): void => {
    warning(null);
    onSubmit(values);
  };

  const formik = useFormik({
    initialValues: {
      password: ""
    },
    onSubmit: values => {
      nextStep(values);
    },
    validationSchema
  });

  useEffect(() => {
    if (typeof errorServer === "string") {
      formik.setFieldError("password", errorServer);
    }
  }, [errorServer]);

  return (
    <div className="text-center">
      <form onSubmit={formik.handleSubmit}>
        {authLogo}
        <Wrapper pb={48}>
          <Text uppercase={!isHyundaiTheme} size={sizeTitleAuth} align="center" theme={titleCompanyTheme}>
            Восстановление пароля
          </Text>
        </Wrapper>
        <Wrapper pb={24}>
          <Text size="h5" align="left" theme={titleCompanyTheme}>
            Придумайте новый пароль
          </Text>
        </Wrapper>
        <Wrapper pb={32}>
          <Input
            type="password"
            maxLength={35}
            name="password"
            placeholder="Пароль"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            invalid={Boolean(formik.errors.password && formik.touched.password)}
            errorMessages={formik.errors.password && formik.touched.password ? formik.errors.password : null}
          />
        </Wrapper>
        <Wrapper pb={40}>
          <Button fullWidth type="submit">
            Продолжить
          </Button>
        </Wrapper>
        <Wrapper>
          <Link href="/" size="big" theme="gray" isButton onClick={() => history.push(AUTH_ROUTES_URL.SIGN_IN)}>
            На главную
          </Link>
        </Wrapper>
      </form>
    </div>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  errorServer: getAuthServerError(store)
});

const mapDispatchToProps = {
  password: forgottenNewPasswordAction,
  warning: serverError
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ForgottenNewPassword);
