const getPrettyPhone = (phone: string): string => {
  if (!phone) {
    return "";
  }
  const regexPhone = /(\d?)(\d{3})(\d{3})(\d{2})(\d{2})/g;
  let substPhone = "+ $1 ($2) $3-$4-$5";
  if (phone.length < 11) {
    substPhone = "$1 $2 $3-$4-$5";
  }
  const prettyPhone = phone.replace(regexPhone, substPhone);
  return prettyPhone;
};

export default getPrettyPhone;
