import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Text, Wrapper } from "components/ui";
import { Table } from "components/ui/table";
import { Loading } from "components/ui/loading";
import { deleteEmployeeRequest, employeesRequest, setEmployeesRows } from "store/employees/actions";
import { resetErrorAndStatus } from "store/actions";
import { StoreTypes } from "store/types";
import { Employee, FiltersType } from "store/employees/types";
import Warning from "components/ui/warning";
import setRowsDataHandler from "helpers/setRowsData";
import NotificationPopup from "components/ui/notification-popup";
import { columnsDC, deleteRowsColumn } from "pages/admin/employees/colunms";
import { paginationTheme } from "components/ui/switch-themes";
import { restoreUser } from "store/users/actions";
import Select from "../../../components/ui/select";
import Pagination from "../../../components/ui/pagination/pagination";
import EmployeesTabPanel from "../../../components/admin/employees-tab-panel/employees-tab-panel";
import SearchForm from "../../../components/admin/search-form/search-form";
import styles from "./employees.module.scss";
import debounce from "../../../helpers/debounce";
import { Employees as EmployeesApi } from "../../../services/api/employees";
import getPrettyPhone from "../../../helpers/pretty-phone-format";
import TeamName from "../../../constant/team-name";
import selectOptions from "../../../constant/quantity-rows-per-admin-page";
import ButtonAdd from "../../../components/admin/button-add";
import DeleteRows from "../../../components/admin/delete-rows";
import Row from "../../../components/ui/row";
import history from "../../../services/history";

const columnsMain = columnsDC.filter(column => column.dataIndex !== "DC");

type Props = {
  title: string;
  teamName: TeamName;
};

export enum NameButtonList {
  restore = "Восстановить",
  edit = "Редактировать"
}

const Employees: FC<ReduxProps & Props> = ({
  status,
  employees: { data: employees, last_page: lastPage },
  getEmployees,
  isLoading,
  title,
  teamName,
  resetErrorsAndStatus,
  setRows,
  selectedRows,
  restore
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState<FiltersType>({});
  const columns = teamName === TeamName.DC ? columnsDC : columnsMain;
  const [selectedRowsData, setRowsData] = useState<Array<Employee>>([]);
  const [notification, setNotification] = useState<boolean>(false);
  const [buttonName, setButtonName] = useState(NameButtonList.edit);

  useEffect(() => {
    if (teamName === TeamName.DC) {
      setFilters({ dealer_center_employee: true });
    } else {
      setFilters({ main_employee: true });
    }
  }, []);

  useEffect(() => {
    setRowsData(setRowsDataHandler(selectedRowsData, selectedRows, employees));
  }, [selectedRows]);

  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
      setRows([]);
    };
  }, []);

  useEffect(() => {
    setRows([]);
  }, [teamName]);

  const getInitials = (str: string | null): string => {
    if (str && str !== "null") {
      if (str.trim()) {
        return `${str.trim()[0]}.`;
      }
    }
    return "";
  };

  const getSuggestion = async (
    value: string,
    setSuggestions: (data: Array<{ id: string; data: string }>) => void
  ): Promise<void> => {
    try {
      const { data } = await EmployeesApi.getEmployees({
        search: value,
        filters
      });
      const firstSuggestions = data.slice(0, rowsPerPage);
      const extractData = firstSuggestions.map(({ id, email, name, lastname, patronymic, phone }: Employee) => {
        const fullName = `${lastname} ${getInitials(name)} ${getInitials(patronymic)}`;
        const prettyPhone = getPrettyPhone(phone);
        return {
          id: String(id),
          data: `${fullName} ${email} ${prettyPhone}`
        };
      });
      setSuggestions(extractData);
    } catch (error) {
      console.log(error);
    }
  };

  const getEmployeeID = (id: string | number): void => {
    if (filters.is_deleted) {
      restore(id);
    } else if (teamName === TeamName.DC) {
      history.push(`/admin/employees/dc/edit-employee/${id}`);
    } else if (teamName === TeamName.MAIN) {
      history.push(`/admin/employees/main/edit-employee/${id}`);
    }
  };

  useEffect(() => {
    if (status) {
      setNotification(true);
    }
  }, [status, setNotification]);

  const getSuggestionByDebounce = debounce(getSuggestion, 500);

  const path = teamName === TeamName.DC ? "/admin/employees/dc/add-employee" : "/admin/employees/main/add-employee";
  return (
    <>
      <div className={styles.header}>
        <NotificationPopup
          title="Данные успешно удалены"
          isOpen={notification}
          action={(): void => setNotification(false)}
        />
        <Wrapper pb={20}>
          <Text size="h3" theme="black">
            {title}
          </Text>
        </Wrapper>
        <Row justifyContent="spaceBetween">
          <Row wrap>
            <Wrapper pr={10}>
              <ButtonAdd path={path} />
            </Wrapper>
          </Row>

          <SearchForm
            rowsPerPage={rowsPerPage}
            action={getEmployees}
            suggestionOnClick={getEmployeeID}
            getSuggestion={getSuggestionByDebounce}
            placeholder="Поиск по ID, email, телефону, ФИО"
            filters={filters}
            currentPage={currentPage}
            teamName={teamName}
          />
        </Row>
      </div>
      <div className={styles.wrapper}>
        <EmployeesTabPanel
          setButtonName={setButtonName}
          rowsPerPage={rowsPerPage}
          teamName={teamName}
          filters={filters}
          setFilters={setFilters}
        />
        <Wrapper pt={26}>
          <Loading isLoading={isLoading} />
          {!isLoading && (
            <Table
              columns={columns}
              data={employees}
              rowSelection={(rows): any => setRows(rows)}
              rowEdit={getEmployeeID}
              selectedRows={selectedRows}
              buttonName={buttonName}
            />
          )}
        </Wrapper>
        {!isLoading && employees.length === 0 && (
          <Wrapper pt={16}>
            <Warning color="black">Ничего не найдено</Warning>
          </Wrapper>
        )}
        <Wrapper pt={32}>
          <div className={styles.bottomBlock}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
            <Select options={selectOptions} onClick={setRowsPerPage} />
          </div>
        </Wrapper>
      </div>
    </>
  );
};

const mapStateToProps = ({ employees }: StoreTypes) => ({
  employees: employees.employees,
  isLoading: employees.isLoading,
  selectedRows: employees.selectedRows,
  status: employees.status
});

const mapDispatchToProps = {
  getEmployees: employeesRequest,
  resetErrorsAndStatus: resetErrorAndStatus,
  setRows: setEmployeesRows,
  restore: restoreUser
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Employees);
