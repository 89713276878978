import axios from "axios";
import {
  CreateMarketingMailingType,
  MailingsPaginationParams,
  MarketingMailingsType
} from "store/marketing-mailings/types";
import { MarketingMailingsRequestParams } from "store/marketing-mailings/constants";

export const MarketingMailingsApi = {
  getMarketingMailings: ({ page = "1", limit = "10" }: MailingsPaginationParams): Promise<MarketingMailingsType> =>
    axios.get(`/front/marketing-campaign?page=${page}&limit=${limit}`),
  createMarketingMailings: (data: any): Promise<any> => axios.post(`/front/marketing-campaign`, data.body),
  deleteMarketingMailings: (data: any): Promise<any> => axios.delete(`/front/marketing-campaign/${data}`),
  downloadMarketingMailings: (data: any): Promise<any> => axios.get(`/front/marketing-campaign/download/${data}`),
  getServices: (): Promise<any> => axios.get(`/front/marketing-campaign/service-list`)
};
