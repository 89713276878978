import { TYPES_USER } from "store/users/constants";
import { RESET_ERROR_AND_STATUS } from "../constants";
import { ActionsType, Store } from "./types";

const initialState: Store = {
  isLoading: false,
  userInfoIsLoaded: false,
  avatarIsLoaded: false,
  error: null,
  status: null,
  codeTtl: "120",
  user: {
    address: "",
    birthdate: "",
    children: null,
    city: "",
    created_at: "",
    email: "",
    email_verified_at: "",
    gender: null,
    id: "",
    is_deleted: null,
    lastname: "",
    married: null,
    name: "",
    patronymic: "",
    phone: "",
    picture: "",
    roles: [],
    services: [],
    connected_services: [],
    connected_services_front: [],
    unconnected_services: [],
    updated_at: "",
    updated_date: "",
    permissions: [],
    password_changed_at: "",
    team_id: null,
    auth_type_id: null,
    mir_id: null,
    username: null,
    language_code: "RU",
    language: {
      code: "RU",
      name: "Russian",
      display_name: "Русский"
    },
    team: null,
    marketing_consent: null,
    update_required: false,
    cars: [],
    ban_deletions: []
  },
  services: [],
  banDeletionClients: {
    current_page: 0,
    data: [],
    last_page: 0,
    per_page: 0,
    total: 0
  }
};

export const userReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_USER.USERS_LOAD: {
      return { ...state, isLoading: true, error: null, avatarIsLoaded: false, status: null };
    }
    case TYPES_USER.USERS_LOAD_STOP: {
      return { ...state, isLoading: false };
    }
    case TYPES_USER.USERS_SUCCESS: {
      return { ...state, user: { ...state.user, ...action.payload }, userInfoIsLoaded: true };
    }
    case TYPES_USER.USERS_ERROR: {
      return { ...state, error: { ...state.error, ...action.payload } };
    }
    case TYPES_USER.USERS_STATUS: {
      return { ...state, status: { ...state.status, ...action.payload } };
    }
    case TYPES_USER.UPLOAD_AVATAR_SUCCESS_USER_SET: {
      return { ...state, user: { ...state.user, picture: action.payload.path }, avatarIsLoaded: true };
    }
    case TYPES_USER.UPLOAD_AVATAR_SUCCESS_EMPLOYEE_SET: {
      return { ...state, avatarIsLoaded: true };
    }
    case TYPES_USER.UPLOAD_AVATAR_SUCCESS_NOT_SET: {
      return { ...state, avatarIsLoaded: true };
    }
    case TYPES_USER.UPDATE_USER_SUCCESS: {
      return { ...state, user: { ...state.user, ...action.payload } };
    }
    case TYPES_USER.AUTH_RESET_ALL: {
      return initialState;
    }
    case TYPES_USER.SEND_CODE_SUCCESS: {
      return { ...state, codeTtl: action.payload.sms_code_ttl };
    }
    case TYPES_USER.CHANGE_PASSWORD_SUCCESS: {
      return { ...state, user: { ...state.user, password_changed_at: String(new Date()) } };
    }
    case TYPES_USER.DELETE_ACCOUNT_SUCCESS: {
      return { ...state, user: { ...state.user, is_deleted: true } };
    }
    case TYPES_USER.RESTORE_ACCOUNT_SUCCESS: {
      return { ...state, user: { ...state.user, is_deleted: false } };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null, status: null };
    }
    case TYPES_USER.SWITCH_USER_CONCENT: {
      return { ...state, user: { ...state.user, marketing_consent: !state.user.marketing_consent } };
    }
    case TYPES_USER.GET_USERS_SERVICES_REQUEST: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_USER.GET_USERS_SERVICES_SUCCESS: {
      return { ...state, isLoading: false, services: action.payload };
    }
    case TYPES_USER.BAN_DELETION_CLIENTS_SUCCESS: {
      return { ...state, banDeletionClients: action.payload };
    }
    default: {
      return state;
    }
  }
};
