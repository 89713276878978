import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import { Button, Input, Wrapper } from "components/ui";
import { Formik, Form } from "formik";
import * as yup from "yup";
import CustomAutosuggest, { FieldName } from "components/ui/autosuggest";
import { getAddressHandler, getCityHandler } from "services/api/dadata";
import { isValidNameDealers, isValidFirstAndLastChar } from "helpers/validate";
import AddedFormPanel from "components/admin/added-dealership/added-form-panel/added-form-panel";
import { connect, ConnectedProps } from "react-redux";
import {
  addedEmployeesDC,
  createDealers,
  dealershipEditingValue,
  dealershipEntryEditing,
  dealershipInfo,
  updateDealership,
  modalWindowOpen,
  userList
} from "store/dealers/actions";
import { DealershipContext } from "context/Dealership";
import { StoreTypes } from "store/types";
import { formatPhoneNumber } from "helpers/format-phone-number";
import { useParams } from "react-router-dom";
import { Loading } from "components/ui/loading";
import Row from "components/ui/row";
import { handlerPaste } from "helpers/handlePastePhone";
import NotificationPopup from "components/ui/notification-popup";
import { isChangedValues } from "helpers/formik-handlers";
import { resetErrorAndStatus } from "store/actions";
import styles from "./added-form.module.scss";
import history from "../../../../services/history";

const validationSchema = yup.object().shape({
  display_name: yup
    .string()
    .test("display_name", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
    .matches(isValidNameDealers, 'Используйте символы a-z A-Z а-я А-Я 0-9 - (пробел) «» "')
    .required("Необходимо заполнить данные")
    .min(2, "Используйте от 2 до 30 символов")
    .max(30, "Используйте от 2 до 30 символов")
    .required("Необходимо заполнить данные"),
  address: yup.string().required("Выберите значение из выпадающего списка"),
  website: yup.string().notRequired(),
  city: yup.string().required("Выберите значение из выпадающего списка"),
  phone: yup
    .string()
    .transform(value => value.replace(/[^\d]/g, ""))
    .min(11, "Номер телефона должен состоять из 11 символов")
    .required("Необходимо заполнить данные")
});

type ValuesTypes = {
  display_name: string;
  address: string;
  link?: string;
  city: string;
  phone: string;
};

type Props = {
  isEdit?: boolean;
};

const AddedForm: FC<ReduxProps & Props> = ({
  resetError,
  editDealers,
  getDealersInfo,
  isEdit = false,
  errorServer,
  onUpdate,
  dataDealership,
  addedEmployee,
  onCreate,
  isOpen,
  clearUsersList,
  setOpen
}) => {
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [employeesChange, setEmployeesChange] = useState(false);
  const { id: dealersId } = useParams<any>();
  const formikRef = useRef<any>();

  useEffect(() => {
    if (isEdit) {
      getDealersInfo(dealersId);
    }
    return (): void => {
      editDealers(null);
      resetError();
      setEmployeesChange(false);
    };
  }, []);

  const getInitialValues = (): ValuesTypes => {
    if (isEdit) {
      if (dataDealership) {
        const {
          display_name: dealersName,
          address: dealersAddress,
          link: dealersSite,
          city: dealersCity,
          phone: dealersPhone
        } = dataDealership;
        const editValues = {
          display_name: dealersName || "",
          address: dealersAddress || "",
          link: dealersSite || "",
          city: dealersCity || "",
          phone: dealersPhone || ""
        };
        return editValues;
      }
    }
    const initialValues = {
      display_name: "",
      address: "",
      link: "",
      city: "" || "Москва",
      phone: ""
    };
    return initialValues;
  };

  const initValue = getInitialValues();
  const isDisplayContent = !isEdit || (isEdit && dataDealership);

  const inputsBlur = (item: string, value: string): void => {
    if (value) {
      formikRef.current.setFieldValue(item, value.trim());
      formikRef.current.setTouched({ [item]: true });
    }
  };

  useEffect(() => {
    if (errorServer) {
      formikRef.current.setFieldError("phone", errorServer);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [errorServer]);

  const guard = (): void => {
    if (Object.keys(formikRef.current.errors).length !== 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const submitHandler = (values: any): void => {
    addedEmployee(selectedRows);
    const dataUser = values;
    dataUser.phone = formatPhoneNumber(dataUser.phone);
    if (isEdit) {
      onUpdate(dataUser);
    } else {
      onCreate(dataUser);
    }
  };

  const notificationTitle = isEdit ? "Данные успешно изменены" : "Данные успешно сохранены";
  return (
    <DealershipContext.Provider
      value={{
        setEmployeesChange,
        setSelectedRows,
        isEdit
      }}
    >
      {isDisplayContent ? (
        <div className={styles.container}>
          <NotificationPopup
            title={notificationTitle}
            isOpen={isOpen}
            action={() => {
              history.push("/admin/dealers");
              clearUsersList([]);
              setOpen(false);
            }}
          />
          <Formik
            validationSchema={validationSchema}
            innerRef={formikRef}
            onSubmit={submitHandler}
            initialValues={initValue}
          >
            {({ values, touched, setFieldValue, errors, handleBlur, setFieldTouched, handleChange }): ReactElement => (
              <Form>
                <Wrapper pt={30} pl={40}>
                  {isEdit ? (
                    <h3 className={styles.title}>Редактирование дилерского центра</h3>
                  ) : (
                    <h3 className={styles.title}>Добавление дилерского центра</h3>
                  )}
                  <div className={styles.form_input}>
                    <div>
                      <div>
                        <Input
                          name="display_name"
                          placeholder="Обязательное поле"
                          label="Название ДЦ"
                          onChange={handleChange}
                          onBlur={(): void => inputsBlur("display_name", values.display_name)}
                          value={values.display_name}
                          invalid={Boolean(errors.display_name && touched.display_name)}
                          errorMessages={errors.display_name && touched.display_name ? errors.display_name : null}
                        />
                      </div>
                      <CustomAutosuggest
                        label="Адрес"
                        getData={getAddressHandler}
                        placeholder="Ваш полный адрес"
                        field={FieldName.address}
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        invalid={Boolean(errors.address && touched.address)}
                        errorMessages={errors.address && touched.address ? errors.address : null}
                        setFieldTouched={setFieldTouched}
                      />
                      <div>
                        <Input
                          name="link"
                          placeholder="Укажите ссылку на сайт ДЦ"
                          label="Сайт"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.link}
                        />
                      </div>
                    </div>
                    <div>
                      <CustomAutosuggest
                        label="Город"
                        getData={getCityHandler}
                        placeholder="Обязательное поле"
                        field={FieldName.city}
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        invalid={Boolean(errors.city && touched.city)}
                        errorMessages={errors.city && touched.city ? errors.city : null}
                        setFieldTouched={setFieldTouched}
                        interconnected
                      />
                      <div>
                        <Input
                          type="tel"
                          name="phone"
                          label="Телефон"
                          mask="+7 999 999 99 99"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          invalid={Boolean(errors.phone && touched.phone)}
                          errorMessages={errors.phone && touched.phone ? errors.phone : null}
                          onPaste={(elem: React.ClipboardEvent<HTMLInputElement>): void => {
                            handlerPaste(elem, setFieldValue);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Wrapper>
                <div>
                  <AddedFormPanel />
                  <Wrapper className={styles.form_btn} pl={40} pb={20} pt={20}>
                    <div>
                      <Button
                        onClick={guard}
                        type="submit"
                        disabled={isChangedValues(initValue, values) && !employeesChange}
                      >
                        Сохранить
                      </Button>
                    </div>
                    <div>
                      <Button noBorder theme="transparent" onClick={(): void => history.push("/admin/dealers")}>
                        Отменить
                      </Button>
                    </div>
                  </Wrapper>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <Loading isLoading>
          <Wrapper pt={300}>
            <Row justifyContent="justifyCenter" alignItems="alignCenter">
              <h1>Идет загрузка</h1>
            </Row>
          </Wrapper>
        </Loading>
      )}
    </DealershipContext.Provider>
  );
};

const mapStateToProps = ({ dealers }: StoreTypes) => ({
  editing: dealers.edit,
  dataDealership: dealers.editDealership,
  employees: dealers.dealers.data,
  errorServer: dealers.serverError,
  isOpen: dealers.modalWindow
});

const mapDispatchToProps = {
  onCreate: createDealers,
  addedEmployee: addedEmployeesDC,
  onUpdate: updateDealership,
  EditingValue: dealershipEditingValue,
  getDealersInfo: dealershipInfo,
  editDealers: dealershipEntryEditing,
  setOpen: modalWindowOpen,
  clearUsersList: userList,
  resetError: resetErrorAndStatus
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddedForm);
