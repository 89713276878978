import { TYPES_LE } from "store/legalEntity/constants";
import { CreateLegalEntityData, GetUsersParams, LegalEntityTypes, UpdateLegalEntityData } from "./types";
import { GetParams, WithPagination } from "../types";
import { UserType } from "../users/types";

export const createLegalEntityRequest = (data: CreateLegalEntityData) => ({
  type: TYPES_LE.LEGAL_ENTITY_CREATE_REQUEST,
  payload: data
});

export const createLegalEntitySuccess = (data: LegalEntityTypes) => ({
  type: TYPES_LE.LEGAL_ENTITY_CREATE_SUCCESS,
  payload: data
});

export const createLegalEntityError = () => ({
  type: TYPES_LE.LEGAL_ENTITY_CREATE_ERROR
});

export const updateLegalEntityRequest = (data: UpdateLegalEntityData) => ({
  type: TYPES_LE.LEGAL_ENTITY_UPDATE_REQUEST,
  payload: data
});

export const updateLegalEntitySuccess = (data: LegalEntityTypes) => ({
  type: TYPES_LE.LEGAL_ENTITY_UPDATE_SUCCESS,
  payload: data
});

export const updateLegalEntityError = () => ({
  type: TYPES_LE.LEGAL_ENTITY_UPDATE_ERROR
});

export const legalEntityInfoRequest = (id: string) => ({
  type: TYPES_LE.LEGAL_ENTITY_INFO_REQUEST,
  payload: id
});

export const legalEntityInfoSuccess = (data: LegalEntityTypes) => ({
  type: TYPES_LE.LEGAL_ENTITY_INFO_SUCCESS,
  payload: data
});

export const legalEntityInfoLoadStop = () => ({
  type: TYPES_LE.LEGAL_ENTITY_INFO_LOAD_STOP
});

export const legalEntityInfoClear = () => ({
  type: TYPES_LE.LEGAL_ENTITY_INFO_CLEAR,
});

export const setLegalEntityEditMode = () => ({
  type: TYPES_LE.SET_LEGAL_ENTITY_EDIT_MODE
});

export const getLegalUsersLoad = () => ({
  type: TYPES_LE.GET_LEGAL_USERS_LOAD
});

export const getLegalUsersLoadStop = () => ({
  type: TYPES_LE.GET_LEGAL_USERS_LOAD_STOP
});

export const getLegalUsersRequest = (data: GetParams) => ({
  type: TYPES_LE.GET_LEGAL_USERS_REQUEST,
  payload: data
});

export const getLegalUsersSuccess = (data: WithPagination<UserType>) => ({
  type: TYPES_LE.GET_LEGAL_USERS_SUCCESS,
  payload: data
});

export const attachUsersRequest = (userIds: Array<string>) => ({
  type: TYPES_LE.ATTACH_USERS_REQUEST,
  payload: userIds
});

export const detachUsersRequest = (userIds: Array<string>) => ({
  type: TYPES_LE.DETACH_USERS_REQUEST,
  payload: userIds
});

export const getLegalCarsRequest = (data: GetParams) => ({
  type: TYPES_LE.GET_LEGAL_CARS_REQUEST,
  payload: data
});

export const getLegalCarsSuccess = (data: WithPagination<any>) => ({
  type: TYPES_LE.GET_LEGAL_CARS_SUCCESS,
  payload: data
});

export const getLegalCarsLoad = () => ({
  type: TYPES_LE.GET_LEGAL_CARS_LOAD
});

export const getLegalCarsLoadStop = () => ({
  type: TYPES_LE.GET_LEGAL_CARS_LOAD_STOP
});

export const attachCarsRequest = (carIds: Array<string>) => ({
  type: TYPES_LE.ATTACH_CARS_REQUEST,
  payload: carIds
});

export const detachCarsRequest = (carIds: Array<string>) => ({
  type: TYPES_LE.DETACH_CARS_REQUEST,
  payload: carIds
});

export const serverErrorLegalEntity = (error: string) => ({
  type: TYPES_LE.SERVER_ERROR_LE,
  payload: error
});

export const setOpenLEModalWindow = (toggle: boolean) => ({
  type: TYPES_LE.MODAL_OPEN_LE,
  payload: toggle
});
