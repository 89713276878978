export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/^(\+)|\D+/g, "");
};

export const formatPhoneNumberMask = (phoneNumber: string) => {
  return phoneNumber.replace(/^(\+7|7|8)|\D+/g, "");
};

export const normalizePhone = (phone: string): string => {
  if (phone.length < 11) {
    return `7${phone}`;
  }
  return phone;
};
