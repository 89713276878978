enum SuperadminPermissions {
  "clients-create" = "clients-create",
  "clients-read" = "clients-read",
  "clients-update" = "clients-update",
  "clients-delete" = "clients-delete",
  "dealer_centers-create" = "dealer_centers-create",
  "dealer_centers-read" = "dealer_centers-read",
  "dealer_centers-update" = "dealer_centers-update",
  "dealer_centers-delete" = "dealer_centers-delete",
  "teams-create" = "teams-create",
  "teams-read" = "teams-read",
  "teams-update" = "teams-update",
  "teams-delete" = "teams-delete",
  "employees-create" = "employees-create",
  "employees-read" = "employees-read",
  "employees-update" = "employees-update",
  "employees-delete" = "employees-delete",
  "roles-create" = "roles-create",
  "roles-read" = "roles-read",
  "roles-update" = "roles-update",
  "roles-delete" = "roles-delete",
  "permissions-create" = "permissions-create",
  "permissions-read" = "permissions-read",
  "permissions-update" = "permissions-update",
  "permissions-delete" = "permissions-delete",
  "emails-create" = "emails-create",
  "emails-read" = "emails-read",
  "emails-update" = "emails-update",
  "emails-delete" = "emails-delete",
  "templates-create" = "templates-create",
  "templates-read" = "templates-read",
  "templates-update" = "templates-update",
  "templates-delete" = "templates-delete"
}

enum AdminDealershipPermission {
  "dc_employees-create" = "dc_employees-create",
  "dc_employees-read" = "dc_employees-read",
  "dc_employees-update" = "dc_employees-update",
  "dc_employees-delete" = "dc_employees-delete"
}

enum DefaultPermissions {
  "profile-update" = "profile-update",
  "profile-read" = "profile-read"
}

export const superadminPermissions = Object.keys(SuperadminPermissions);
export const adminDealershipPermission = Object.keys(AdminDealershipPermission);
export const defaultPermissions = Object.keys(DefaultPermissions);
