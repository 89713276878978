import React, { FC, useMemo } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import mainTheme from "components/ui/switch-themes";
import styles from "./nav.module.scss";
import { superadminPermissions, defaultPermissions, adminDealershipPermission } from "../../../constant/permissions";
import usePermissions from "../../../helpers/usePermissions";

type PropsType = {
  collapse?: boolean;
  onClick?: () => void;
};

interface Item {
  name: string;
  path: string;
  id: number;
}

const Nav: FC<PropsType> = ({ collapse, onClick = (): void => {} }) => {
  const items = [
    { name: "Главная", path: "/home", id: 0 },
    { name: "Личные данные", path: "/personal", id: 1 },
    { name: "Безопасность и вход", path: "/safety", id: 2 },
    { name: "Панель администратора", path: "/admin", id: 3 }
  ];

  const isAdmin = usePermissions(superadminPermissions);
  const isUser = usePermissions(defaultPermissions);
  const isAdminDC = usePermissions(adminDealershipPermission);

  const getAllowedItems = (): Array<Item> => {
    if (isAdmin || isAdminDC) {
      return items;
    }
    if (isUser) {
      return items.slice(0, 3);
    }
    return items.slice(0, 1);
  };

  const allowedItems = getAllowedItems();
  const cnItem = cn(styles.item, { [styles[mainTheme]]: mainTheme });

  const navItems = useMemo(
    () =>
      allowedItems.map(({ name, path, id }) => (
        <NavLink to={path} key={id} activeClassName={styles.active} className={cnItem} onClick={onClick}>
          {name}
        </NavLink>
      )),
    [allowedItems, onClick]
  );
  const className = cn(styles.nav, {
    [styles.collapse]: collapse
  });
  return <div className={className}>{navItems}</div>;
};

export default Nav;
