/* eslint no-underscore-dangle: 0 */
import axios from "axios";
import { Authentication } from "services/api/authentication ";
import { REFRESH_TOKEN, SESSION_TOKEN, TOKEN } from "constant/api";
import { notificationErrors } from "components/ui/notification/notification-errors";
import { resetUser } from "helpers/resetUser";
import storageCookie from "services/storageCookie";
import sessionData from "./sessionStorage";

// axios.defaults.baseURL = "http://localhost:82";
const setupInterceptors = (store: any) => {
  let isRefreshing = false;
  const subscribers: any[] = [];

  function subscribeTokenRefresh(cb: any): void {
    subscribers.push(cb);
  }

  function onRefreshed(token: string): void {
    /// Выполняет запр
    subscribers.map(cb => cb(token));
  }

  axios.interceptors.request.use(
    config => {
      const token = storageCookie.get(TOKEN);
      const sessionToken = sessionData.get("session_token");
      const newConfig = config;
      /* else if (config.url && ROUTERS_API_ON_TOKEN_REQUEST.includes(config.url)) {
        newConfig.headers.common.Authorization = `Bearer ${AUTH_TOKEN_REQUEST}`;
      } */
      if (sessionToken) {
        newConfig.headers.common["X-CSRF-TOKEN"] = sessionToken;
      }
      if (isRefreshing) {
        delete newConfig.headers.common.Authorization;
      } else if (token) {
        newConfig.headers.common.Authorization = `Bearer ${token}`;
      }
      return newConfig;
    },
    error => Promise.reject(error)
  );
  axios.interceptors.response.use(
    response => {
      return response.data;
    },
    async error => {
      const {
        config,
        response: { status, statusText, data },
        response
      } = error;

      if (
        [401].includes(status) &&
        data &&
        !data.error &&
        !config.url.includes("/refresh") &&
        (statusText.includes("Unauthorized") || statusText.includes(""))
      ) {
        const refreshToken = storageCookie.get(REFRESH_TOKEN);
        if (refreshToken) {
          if (!isRefreshing) {
            isRefreshing = true;
            Authentication.refreshAuth({ refresh_token: refreshToken }).then(
              ({ access_token, refresh_token }: any) => {
                isRefreshing = false;
                onRefreshed(access_token);
                // storage.set(TOKEN, access_token);
                // storage.set(REFRESH_TOKEN, refresh_token);
                storageCookie.set(TOKEN, access_token);
                storageCookie.set(REFRESH_TOKEN, refresh_token);
                subscribers.length = 0;
              },
              () => {
                resetUser();
                isRefreshing = false;
              }
            );
          }
          return new Promise(resolve => {
            subscribeTokenRefresh((token: string) => {
              config.headers.Authorization = `Bearer ${token}`;
              resolve(axios(config));
            });
          });
        }
      }

      if (
        [401].includes(status) &&
        data.message &&
        !config.url.includes("/oauth/authorize/token") &&
        data.message.includes("Unauthenticated.")
      ) {
        resetUser();
      }

      if (
        ![401].includes(status) &&
        ![404].includes(status) &&
        ![419].includes(status) &&
        ![422].includes(status) &&
        data.status !== "invalid_password"
      ) {
        if (data.errors) {
          /// Errors приходит на создание или редактирование
          Object.values(data.errors).forEach(item => {
            if (Array.isArray(item)) {
              notificationErrors({ message: item[0] });
            } else {
              notificationErrors({});
            }
          });
        } else if (data.error) {
          /// Error на определенный роут
          notificationErrors({ message: data.error });
        } else {
          /// Отлов системных ошибок
          notificationErrors({});
        }
      }
      if ([419].includes(status) && data.message === "CSRF token mismatch." && !config._retry) {
        config._retry = true;
        const newTokenResponse = await Authentication.sessionToken();
        await sessionData.set(SESSION_TOKEN, newTokenResponse.token);
        const newSessionToken = await sessionData.get(SESSION_TOKEN);
        config.headers["X-CSRF-TOKEN"] = newSessionToken;
        return axios(config);
      }
      /*  для рефреш токена
       if (
          [401].includes(status) &&
          !config.url.includes("/refresh") &&
          (statusText.includes("Unauthorized") || statusText.includes(""))
        ) {
          const refreshToken = storage.get(REFRESH_TOKEN);
          if (refreshToken) {
            if (!isRefreshing) {
              isRefreshing = true;
              Auth.refreshToken({ refresh_token: refreshToken }).then(
                ({ token, refresh_token }: any) => {
                  isRefreshing = false;
                  onRefreshed(token);
                  storage.set(TOKEN, token);
                  storage.set(REFRESH_TOKEN, refresh_token);
                  subscribers.length = 0;
                },
                // eslint-disable-next-line
                () => cleanStorage()
              );
          }
          return new Promise(resolve => {
            subscribeTokenRefresh((token: string) => {
              config.headers.Authorization = `Bearer ${token}`;
              resolve(axios(config));
            });
          });
        }
        cleanStorage();
      }
      if (status === 503 && window.location.pathname !== "/error-page") {
        // store.dispatch(setError({ status, statusText }));
      } */

      return Promise.reject(error.response);
    }
  );
};

export default setupInterceptors;
