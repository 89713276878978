import React, { forwardRef, InputHTMLAttributes, ReactElement, Ref, useState } from "react";
import cn from "classnames";
import InputMask from "react-input-mask";
import fp from "lodash";

import { PHONE_MASK, PHONE_PLACEHOLDER } from "constant/phone-mask";
import iconEyes from "components/ui/input/icons/eyes";
import { FormikErrors } from "formik";
import hyundai from "./styles/input-hyandai.module.scss";
import genesis from "./styles/input-genesis.module.scss";
import { isHyundaiTheme } from "../switch-themes";

export interface PropsType extends InputHTMLAttributes<HTMLInputElement> {
  mask?: string;
  maskChar?: string | null;
  htmlFor?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string | number;
  label?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: "text" | "tel" | "password" | "search";
  bordered?: boolean;
  theme?: "white" | "black" | "gray" | "genesis_white";
  invalid?: boolean;
  name?: string;
  errorMessages?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | null;
  statusMessages?: string | null;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  maxLength?: number | undefined;
  autoComplete?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  send?: (() => void) | null;
  back?: (() => void) | null;
  title?: any;
}

export const Input = forwardRef(
  (
    {
      autoComplete,
      type,
      disabled,
      label,
      title,
      bordered,
      theme = "gray",
      invalid,
      errorMessages,
      send,
      back,
      statusMessages,
      mask = PHONE_MASK,
      ...args
    }: PropsType,
    ref: Ref<HTMLInputElement>
  ): ReactElement => {
    const [showPassword, setShowPassword] = useState(true);

    const stylesCompany = isHyundaiTheme ? hyundai : genesis;

    const cnInput = cn(stylesCompany.input, {
      [stylesCompany[theme]]: theme,
      [stylesCompany.bordered]: bordered,
      [stylesCompany.invalid]: invalid
    });

    const cnLabel = cn(stylesCompany.label, {
      [stylesCompany[theme]]: theme
    });

    const backLink = cn(stylesCompany.link, { [stylesCompany.link_back]: send });
    const classEyes = cn(stylesCompany.eyes, { [stylesCompany.label]: label });

    const inputPhoneMask = (): React.ReactNode => {
      return (
        <InputMask
          disabled={disabled}
          placeholder={PHONE_PLACEHOLDER}
          id={args.htmlFor}
          className={cnInput}
          mask={mask}
          maskChar=" "
          {...args}
        />
      );
    };

    const inputText = (): React.ReactNode => {
      return <input id={args.htmlFor} disabled={disabled} {...args} ref={ref} className={cnInput} />;
    };

    const inputPassword = (): React.ReactNode => {
      return (
        <>
          <input
            maxLength={20}
            className={cnInput}
            autoComplete={showPassword ? "new-password" : "false"}
            type={showPassword ? "password" : "text"}
            {...args}
          />
          <button type="button" onClick={(): void => setShowPassword(!showPassword)} className={classEyes}>
            {iconEyes}
          </button>
        </>
      );
    };

    const isPhoneType = fp.isEqual(type, "tel");
    const isPasswordType = fp.isEqual(type, "password");

    const renderInputs = (): React.ReactNode => {
      if (isPasswordType) return inputPassword();
      if (isPhoneType) return inputPhoneMask();
      return inputText();
    };

    return (
      <div>
        {label && (
          <label htmlFor={args.htmlFor} className={cnLabel}>
            {label}
          </label>
        )}
        <div className={stylesCompany.container}>
          {renderInputs()}
          {errorMessages && <div className={stylesCompany.error}>{errorMessages}</div>}
          {statusMessages && <div className={stylesCompany.status}>{statusMessages}</div>}
          {send && (
            <button type="button" onClick={send} className={stylesCompany.link}>
              {title}
            </button>
          )}
          {back && (
            <button type="button" onClick={back} className={backLink}>
              Изменить номер
            </button>
          )}
        </div>
      </div>
    );
  }
);

Input.defaultProps = {
  autoComplete: "off",
  placeholder: "",
  disabled: false,
  label: "",
  type: "text",
  bordered: isHyundaiTheme,
  theme: "gray",
  title: "Получить код"
};
