import React, { FC, ReactElement } from "react";
import { buttonAddAndRemoveTheme } from "components/ui/switch-themes";
import styles from "./popup-confirm.module.scss";
import { Popup } from "../popup";
import { Button } from "../button";
import Warning from "../warning";

type PropsTypes = {
  isOpen: boolean;
  action?: any;
  setOpen: (arg: boolean) => void;
  title: string;
  desc: string;
  error?: string;
  disabled?: boolean;
  disabledAccept?: boolean;
  status?: string;
  displaySuccess?: boolean;
  successTitle?: string;
};

const PopupConfirm: FC<PropsTypes> = ({
  isOpen,
  action,
  setOpen,
  title,
  successTitle = "Успешно",
  desc,
  error,
  disabled,
  disabledAccept,
  status,
  children,
  displaySuccess
}) => {
  const renderTitle = (): string => {
    if (displaySuccess) {
      return successTitle;
    }
    return title;
  };

  const renderButton = (): ReactElement => {
    if (displaySuccess) {
      return (
        <div className={styles.btnContainerSuccess}>
          <div>
            <Button onClick={(): void => setOpen(false)}>Закрыть</Button>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.btnContainer}>
        <div className={styles.yesBtn}>
          <Button theme={buttonAddAndRemoveTheme} disabled={disabledAccept || disabled} onClick={action}>
            Да
          </Button>
        </div>
        <Button theme={buttonAddAndRemoveTheme} disabled={disabled} onClick={(): void => setOpen(false)}>
          Нет
        </Button>
      </div>
    );
  };

  const renderChildren = (): ReactElement => {
    if (displaySuccess) {
      return <div />;
    }

    return <div className={styles.children}>{children}</div>;
  };
  return (
    <Popup isOpen={isOpen} onClick={(): void => setOpen(false)}>
      <div className={styles.popupContainer}>
        <div>
          <div className={styles.popupTitle}>{renderTitle()}</div>
          <div className={styles.desc}>{desc}</div>
          {children && renderChildren()}
        </div>
        <div>
          {error && <Warning color="red">{error}</Warning>}
          {status && <Warning color="black">{status}</Warning>}
          {renderButton()}
        </div>
      </div>
    </Popup>
  );
};

export default PopupConfirm;
