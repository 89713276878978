import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps, useSelector } from "react-redux";
import DeleteRows from "components/admin/delete-rows";
import { Button, Text, Wrapper } from "components/ui";
import { Table } from "components/ui/table";
import { Loading } from "components/ui/loading";
import { StoreTypes } from "store/types";
import {
  setMarketingMailingsRows,
  deleteMarketingMailingsRequest,
  marketingMailingsRequest,
  modalWindowToggle,
  downloadMarketingMailingsRequest,
  startRepeatMarketingMailings,
  stopRepeatMarketingMailings,
} from "store/marketing-mailings/actions";
import { MarketingMailings } from "store/marketing-mailings/types";
import { resetErrorAndStatus } from "store/actions";
import setRowsDataHandler from "helpers/setRowsData";
import NotificationPopup from "components/ui/notification-popup";
import { buttonEditTheme, paginationTheme } from "components/ui/switch-themes";
import {
  getMarketingMailings,
  getMarketingMailingsError,
  getMarketingMailingsSelectedRows,
  getMarketingMailingsStatus
} from "store/marketing-mailings/selectors/marketingMailingsSelector";
import Pagination from "../../../components/ui/pagination/pagination";
import Select from "../../../components/ui/select";
import styles from "./marketing-mailings-page.module.scss";
import selectOptions from "../../../constant/quantity-rows-per-admin-page";
import ButtonAdd from "../../../components/admin/button-add";
import Row from "../../../components/ui/row";
import Warning from "../../../components/ui/warning";
import { columns, deleteRowsColumn } from "./columns";
import history from "../../../services/history";

const getColumns = (rowAction: (id: string) => void) => {
  return [
    {
      title: "Название",
      dataIndex: "name",
      render({ name }: MarketingMailings): string {
        return `${name}`;
      }
    },
    {
      title: "",
      dataIndex: "status",
      width: 2,
      render({ status, id }: MarketingMailings) {
        if (status === 4) {
          return <div className={styles.genTextError}>Ошибка генерации</div>;
        }
        if (status === 6) {
          return (
            <Button size="xs" theme={buttonEditTheme} onClick={(): void => rowAction(id)}>
              Скачать
            </Button>
          );
        }
        return <div className={styles.genText}>Файл генерируется...</div>;
      }
    }
  ];
};

const MarketingMailingsPage: FC<ReduxProps> = ({
  status,
  mailings: { data: mailings, last_page: lastPage },
  startGetMailingsRepeat,
  getMailings,
  stopGetMailingsRepeat,
  isLoading,
  deleteMarketingMailings,
  setRows,
  selectedRows,
  downloadMarketingMailings,
  mailingsError,
  resetErrorsAndStatus
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRowsData, setRowsData] = useState<Array<MarketingMailings>>([]);
  const [notification, setNotification] = useState<boolean>(false);

  useEffect(() => {
    startGetMailingsRepeat();
    getMailings({ page: currentPage, limit: rowsPerPage });
  }, [rowsPerPage, currentPage]);

  useEffect(() => {
    setRowsData(setRowsDataHandler(selectedRowsData, selectedRows, mailings));
  }, [selectedRows]);

  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
      stopGetMailingsRepeat({ page: currentPage, limit: rowsPerPage });
      setRows([]);
    };
  }, []);

  useEffect(() => {
    if (status) {
      setNotification(true);
    }
  }, [status]);

  return (
    <>
      <div className={styles.header}>
        <Wrapper pb={20}>
          <Text size="h3" theme="black">
            Маркетинговые рассылки
          </Text>
        </Wrapper>
        <Row justifyContent="spaceBetween">
          <Row wrap>
            <Wrapper pr={10}>
              <ButtonAdd path="/admin/marketing-mailings/create" title="Создать" />
            </Wrapper>
            <DeleteRows
              selectedRows={selectedRows}
              deleteRowsHandler={deleteMarketingMailings}
              error={mailingsError?.deleteMarketingMailings || ""}
              disabled={isLoading}
              resetErrorAndStatus={resetErrorsAndStatus}
              selectedRowsData={selectedRowsData}
              columns={deleteRowsColumn}
            />
          </Row>
        </Row>
      </div>
      <div className={styles.wrapper}>
        <Wrapper pt={26}>
          <Loading isLoading={isLoading} />
          {!isLoading && (
            <Table
              selectedRows={selectedRows}
              columns={getColumns(downloadMarketingMailings)}
              data={mailings}
              rowSelection={(rows): any => setRows(rows)}
            />
          )}
        </Wrapper>
        {!isLoading && mailings.length === 0 && (
          <Wrapper pt={16}>
            <Warning color="black">Ничего не найдено</Warning>
          </Wrapper>
        )}
        <Wrapper pt={32}>
          <div className={styles.bottomBlock}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
          </div>
        </Wrapper>
      </div>
    </>
  );
};

const mapStateToProps = ({ marketingMailings }: StoreTypes) => ({
  isLoading: marketingMailings.isLoading,
  mailings: getMarketingMailings(marketingMailings),
  selectedRows: getMarketingMailingsSelectedRows(marketingMailings),
  mailingsError: getMarketingMailingsError(marketingMailings),
  status: getMarketingMailingsStatus(marketingMailings)
});

const mapDispatchToProps = {
  deleteMarketingMailings: deleteMarketingMailingsRequest,
  getMailings: marketingMailingsRequest,
  startGetMailingsRepeat: startRepeatMarketingMailings,
  stopGetMailingsRepeat: stopRepeatMarketingMailings,
  downloadMarketingMailings: downloadMarketingMailingsRequest,
  setRows: setMarketingMailingsRows,
  resetErrorsAndStatus: resetErrorAndStatus
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(MarketingMailingsPage);
