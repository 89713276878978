import React, { FC, useEffect, useState } from "react";
import { Text, Wrapper } from "components/ui";
import { Social } from "components/ui/social";
import { connect, ConnectedProps } from "react-redux";
import { dataUserSSO, getLinksSSO, setLinkSSO } from "store/auth/actions";
import storage from "services/storage";
import { StoreTypes } from "store/types";
import { getAuthSelector } from "store/auth/selectors/getAuthSelector";
import { getSSOLinks } from "store/auth/selectors/getSSOLinksSelector";
import { AUTH_ROUTES_URL } from "constant/routers";
import { getDataUserSSO } from "store/auth/selectors/getSSOdataUserSelector";
import history from "../../../services/history";

const AuthSocialNetwork: FC<ReduxProps> = ({ getLinks, links, setLinks, setDataSSO, getDataSSO }) => {
  const [linksNetwork, setLinksNetwork] = useState<any>(null);
  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    const arr = storage.get("SSO");
    if (arr) {
      const newDate = new Date().toLocaleDateString();
      const { links: socLink, date } = JSON.parse(arr);
      setLinksNetwork(socLink);
      if (newDate !== date) {
        storage.remove("SSO");
        getLinks();
      } else if (!links) {
        setLinks(socLink);
      }
    } else if (links) {
      setLinksNetwork(links);
    } else {
      getLinks();
    }
  }, [links]);

  // todo описать тип
  const gettingDataSSO = (dataUser: any): void => {
    if (dataUser.data?.access_token || dataUser.data?.social_id || dataUser.data?.error) {
      setDataSSO({ ...dataUser.data });
      setIsRedirect(true);
      window.removeEventListener("message", gettingDataSSO);
    }
  };

  const openLogin = (provaider: string): void => {
    window.addEventListener("message", event => {
      gettingDataSSO(event);
    });
    const parentLink = window.location.origin;
    // const parentLink = "https://hid-dev.simbirsoft.dev";
    /// для отладки const parentLink = "http://localhost:8888"
    const url = `${parentLink}/oauth/proxy?provider=${provaider}`;
    window.open(url, "Login", "width=1000, height=700");
  };

  useEffect(() => {
    if (getDataSSO && isRedirect) {
      history.push(AUTH_ROUTES_URL.SSO);
    }
    return () => {
      window.removeEventListener("message", gettingDataSSO);
    };
  }, [getDataSSO, isRedirect]);

  return (
    <>
      <Wrapper pb={8}>
        <Text size="main_small" theme="gray" align="center">
          Войти с помощью социальных сетей
        </Text>
      </Wrapper>
      <div className="display-flex justify-content-center">
        {linksNetwork &&
          linksNetwork.length &&
          linksNetwork
            // временно скрыл иконку fb
            .filter((item: string) => item === "yandex")
            .map((provaider: string) => {
              return (
                <Wrapper key={Math.random()} pr={8}>
                  <Social onClick={(): void => openLogin(provaider)} icon={provaider} />
                </Wrapper>
              );
            })}
      </div>
    </>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  links: getSSOLinks(store),
  urlRedirect: getAuthSelector(store).openAuthUrl,
  /// Данные из dataUserSSO
  getDataSSO: getDataUserSSO(store)
});

const mapDispatchToProps = {
  getLinks: getLinksSSO,
  setLinks: setLinkSSO,
  setDataSSO: dataUserSSO
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AuthSocialNetwork);
