import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, Form } from "formik";
import cn from "classnames";
import * as yup from "yup";
import trimValueOnBlur from "helpers/trimValueOnBlur";
import {
  buttonAddAndRemoveTheme,
  inputAdminTheme,
  isHyundaiTheme,
  linkThemePassword
} from "components/ui/switch-themes";
import { Button, Wrapper } from "components/ui";
import styles from "./safety-form.module.scss";
import { Input } from "../../ui/input";
import Connections from "../../connections";
import { isValidPassword, isValidEmail } from "../../../helpers/validate";
import {
  updateUserRequest,
  changePasswordRequest,
  usersStatus,
  sendEmailVerifyRequest
} from "../../../store/users/actions";
import { StoreTypes } from "../../../store/types";
import { UpdateType } from "../../../store/users/constants";
import getDate from "./getDate";
import { isChangedValues } from "../../../helpers/formik-handlers";
import { AUTH_ROUTES_URL } from "../../../constant/routers";
import { Link } from "../../ui/link";
import history from "../../../services/history";
import { authResetAll } from "../../../store/auth/actions";

const validationSchema = yup.object().shape({
  password: yup.string().test("newPassword", "Необходимо ввести старый пароль", function(value) {
    const { newPassword, confirmedPassword } = this.parent;
    return !(!value && newPassword) || (!value && !newPassword && !confirmedPassword);
  }),
  newPassword: yup
    .string()
    .matches(
      isValidPassword,
      "Используйте символы a-z A-Z 0-9 (пробел)! \"# $% & '() * +, -. /:; <=>? @ [\\] ^ _` {|} ~"
    )
    .min(6, "Пароль должен быть от 6 до 20 символов")
    .max(20, "Пароль должен быть от 6 до 20 символов")
    .when("password", (password: string, passSchema: any) =>
      password
        ? passSchema
            .required("Необходимо заполнить данные")
            .notOneOf([yup.ref("password")], "Новый пароль должен отличаться от старого")
        : passSchema
    ),
  confirmedPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Пароли не совпадают")
    .when("newPassword", (newPassword: string, passSchema: any) =>
      newPassword ? passSchema.required("Необходимо заполнить данные") : passSchema
    ),
  email: yup
    .string()
    .required("Необходимо заполнить данные")
    .matches(isValidEmail, "Недопустимый формат email")
});

type PayloadType = {
  confirmedPassword: string;
  email: string;
  newPassword: string;
  password: string;
  phone?: string;
};

const SafetyForm: FC<ReduxProps> = ({
  id,
  email,
  phone,
  emailVerifiedAt,
  updateUserRequest: updateUser,
  sendEmailVerifyRequest: sendEmailVerify,
  changePasswordRequest: changePassword,
  isLoading,
  passwordChangedAt,
  authResetAll: logout,
  status,
  setStatus
}) => {
  const leftColClass = cn(styles.col, styles.colWrapper);
  const emailLabelClass = cn(styles.label, { [styles.genesis]: !isHyundaiTheme });
  const updated = getDate(passwordChangedAt) ? `Обновлено ${getDate(passwordChangedAt)}` : "";

  const formikRef = useRef<any>();

  const resetStatusHandler = (name: string): void => {
    setStatus({ [name]: "" });
  };

  const submitHandler = (
    { confirmedPassword, newPassword, password, email: newEmail }: PayloadType,
    { setFieldError, resetForm }: any
  ): void => {
    if (password) {
      changePassword({
        values: { password, newPassword, confirmedPassword, email: newEmail, phone },
        setFieldError,
        resetForm
      });
    }
  };

  return (
    <div className={styles.form}>
      <Formik
        validationSchema={validationSchema}
        innerRef={formikRef}
        initialValues={{
          email,
          phone,
          password: "",
          newPassword: "",
          confirmedPassword: ""
        }}
        onSubmit={submitHandler}
      >
        {({
          values,
          errors,
          touched,
          isValid,
          initialValues,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched
        }): ReactElement => {
          return (
            <Form>
              <div className={styles.row}>
                <div className={leftColClass}>
                  <div className={styles.wrapper}>
                    <Input
                      theme={inputAdminTheme}
                      label="Номер телефона"
                      value={phone}
                      maskChar=" "
                      disabled
                      type="tel"
                    />
                  </div>
                  <div className={styles.wrapper}>
                    <div className={styles.password}>
                      <Input
                        type="password"
                        name="password"
                        label="Изменить пароль"
                        theme={inputAdminTheme}
                        placeholder="Введите старый пароль"
                        value={values.password}
                        onChange={(event): void => {
                          resetStatusHandler("password");
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        errorMessages={errors.password}
                        statusMessages={status ? status.password : ""}
                        invalid={!!errors.password}
                      />

                      <div className={styles.updated}>{updated}</div>
                    </div>
                  </div>
                  <div className={styles.wrapper}>
                    <Input
                      type="password"
                      name="newPassword"
                      theme={inputAdminTheme}
                      placeholder="Введите новый пароль"
                      value={values.newPassword}
                      onChange={(event): void => {
                        resetStatusHandler("password");
                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                      errorMessages={touched.password ? errors.newPassword : ""}
                      invalid={!!errors.newPassword && (touched.password || touched.newPassword)}
                      disabled={!values.password && !values.newPassword && !errors.newPassword}
                    />
                  </div>
                  <div className={styles.wrapper2x}>
                    <Input
                      type="password"
                      name="confirmedPassword"
                      theme={inputAdminTheme}
                      placeholder="Повторите пароль"
                      value={values.confirmedPassword}
                      onChange={(event): void => {
                        resetStatusHandler("password");
                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                      errorMessages={touched.newPassword ? errors.confirmedPassword : ""}
                      invalid={!!errors.confirmedPassword && touched.newPassword}
                      disabled={!values.newPassword && !values.confirmedPassword && !errors.confirmedPassword}
                    />
                  </div>
                </div>
                <div className={styles.col}>
                  <div className={styles.wrapper}>
                    <label className={emailLabelClass} htmlFor="email">
                      {!emailVerifiedAt ? (
                        <span>
                          Email не подтвержден.
                          <Button theme="lightLink" onClick={sendEmailVerify}>
                            Подтвердить
                          </Button>
                        </span>
                      ) : (
                        "Email"
                      )}
                    </label>
                    <Input id="email" name="email" type="text" theme={inputAdminTheme} value={email} disabled />
                  </div>
                  <div className={styles.wrapper}>
                    <Connections id={id} />
                  </div>
                </div>
              </div>
              <div className={styles.wrapper}>
                <Link
                  theme={linkThemePassword}
                  type="text"
                  weight="regular"
                  underline
                  href="/"
                  isButton
                  onClick={(): void => {
                    logout();
                    history.push(AUTH_ROUTES_URL.FORGOTTEN);
                  }}
                >
                  Забыли пароль?
                </Link>
              </div>
              <div className={styles.column}>
                <Wrapper pb={32}>
                  <Button
                    type="submit"
                    disabled={isLoading || !isValid || isChangedValues({ ...initialValues, email }, values)}
                    theme={buttonAddAndRemoveTheme}
                  >
                    Сохранить
                  </Button>
                </Wrapper>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ users: { user }, users }: StoreTypes) => ({
  id: user.id,
  phone: user.phone,
  email: user.email,
  passwordChangedAt: user.password_changed_at,
  emailVerifiedAt: user.email_verified_at,
  isLoading: users.isLoading,
  status: users.status,
  serverError: users.error
});

const mapDispatchToProps = {
  sendEmailVerifyRequest,
  updateUserRequest,
  changePasswordRequest,
  authResetAll,
  setStatus: usersStatus
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SafetyForm);
