import React, { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { StoreTypes } from "store/types";
import { Role } from "store/users/types";
import { headerLogo } from "components/ui/helpersComponents";
import mainTheme, { isHyundaiTheme, logOutTheme } from "components/ui/switch-themes";
import styles from "./header.module.scss";
import { Burger, Close } from "./icons";
import Nav from "../nav";
import UserInfo from "../../user-info";
import Logout from "../../logout";
import Container from "../container";

type HeaderType = {
  isOpen: boolean;
  onClick: (data: boolean) => void;
};
type Props = {
  phone: string;
  email: string;
  roles: Array<Role> | [];
};

type PropsType = HeaderType & Partial<ReduxProps>;

const Header: FC<PropsType> = ({ onClick, isOpen, roles = [] }) => {
  const sideMenuClass = cn(styles.sideMenu, {
    [styles.isOpen]: isOpen,
    [styles.isClose]: !isOpen,
    [styles.genesis]: !isHyundaiTheme
  });

  const cnHeader = cn(styles.header, { [styles[mainTheme]]: mainTheme });
  const cnBlock = cn(styles.block, { [styles.genesis]: !isHyundaiTheme });
  const cnLogo = cn(styles.logo, { [styles.genesis]: !isHyundaiTheme });
  const cnIcon = cn({ [styles.icon]: !isHyundaiTheme });
  const cnMenuFooter = cn(styles.menuFooter, { [styles.genesis]: !isHyundaiTheme });

  return (
    <div className={cnHeader}>
      <Container row>
        <div className={cnBlock}>
          <NavLink to="/" className={cnLogo}>
            {headerLogo}
          </NavLink>
          <button type="button" className={styles.burger} onClick={(): void => onClick(true)}>
            <Burger className={cnIcon} />
          </button>
          <Nav collapse />
        </div>
        <div className={styles.row}>
          <UserInfo theme={logOutTheme} collapse />
          <Logout iconTheme={logOutTheme} collapse />
        </div>
      </Container>
      <div className={sideMenuClass}>
        <div className={styles.row}>
          <div className={styles.logo}>{headerLogo}</div>
          <button type="button" className={styles.closeBtn} onClick={(): void => onClick(false)}>
            <Close className={cnIcon} />
          </button>
        </div>
        <Nav onClick={(): void => onClick(false)} />
        <div className={cnMenuFooter}>
          <div className={styles.row}>
            <UserInfo theme={logOutTheme} />
            <Logout iconTheme={logOutTheme} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ users: { user } }: StoreTypes): Props => ({
  phone: user.name,
  email: user.email,
  roles: user.roles
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Header);
