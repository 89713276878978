enum EmployeeRoles {
  adminMain = "admin",
  employeeMain = "employee",
  employeeDC = "dc_employee",
  adminDC = "dc_admin",
  client = "client"
}

export enum EmployeeIdRoles {
  "admin" = 1,
  "employee",
  "dc_admin",
  "dc_employee",
  "client"
}

export default EmployeeRoles;
