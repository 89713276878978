import React, { SelectHTMLAttributes, ReactElement } from "react";
import cn from "classnames";
import { FormikErrors } from "formik";
import hyundai from "./styles/select-hyandai.module.scss";
import genesis from "./styles/select-genesis.module.scss";
import { isHyundaiTheme } from "../switch-themes";

export interface PropsType extends SelectHTMLAttributes<HTMLSelectElement> {
  placeholder?: string;
  disabled?: boolean;
  value?: string | number;
  label?: string | null;
  isMulti?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  bordered?: boolean;
  theme?: "white" | "black" | "gray" | "genesis_white";
  invalid?: boolean;
  name?: string;
  errorMessages?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | null;
  statusMessages?: string | null;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLSelectElement>) => void;
  options: any[];
}

export const CustomSelect = ({
  autoComplete,
  isMulti,
  disabled,
  label,
  bordered,
  theme = "gray",
  invalid,
  value,
  errorMessages,
  statusMessages,
  options,
  placeholder,
  ...args
}: PropsType): ReactElement => {
  const stylesCompany = isHyundaiTheme ? hyundai : genesis;

  const cnInput = cn(stylesCompany.select, {
    [stylesCompany[theme]]: theme,
    [stylesCompany.bordered]: bordered,
    [stylesCompany.invalid]: invalid
  });

  const cnLabel = cn(stylesCompany.label, {
    [stylesCompany[theme]]: theme
  });

  return (
    <div>
      {label && <span className={cnLabel}>{label}</span>}
      <div className={stylesCompany.container}>
        <select className={cnInput} {...args} placeholder={placeholder}>
          <option key={-1}>Выберите сервис</option>
          {options.map((option: any): any => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        {errorMessages && <div className={stylesCompany.error}>{errorMessages}</div>}
        {statusMessages && <div className={stylesCompany.status}>{statusMessages}</div>}
      </div>
    </div>
  );
};

CustomSelect.defaultProps = {
  placeholder: "",
  disabled: false,
  label: "",
  isMulti: false,
  bordered: isHyundaiTheme,
  theme: "gray"
};
