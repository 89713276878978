import moment from "moment";
import "moment/locale/ru";

const getDate = (date: string | null): string | null => {
  if (!date) {
    return date;
  }
  const currentDate = moment().format("L");
  const yesterday = moment()
    .subtract(1, "day")
    .format("L");
  const connectedDate = moment(date).format("L");
  if (connectedDate === currentDate) {
    return moment().calendar(null, {
      sameDay: "[сегодня]"
    });
  }
  if (connectedDate === yesterday) {
    return moment().calendar(null, {
      sameDay: "[день назад]"
    });
  }
  return moment(date).format("DD.MM.YYYY");
};

export default getDate;
