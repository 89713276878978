import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { STEP_SIGN_IN } from "constant/step-auth";
import { StoreTypes } from "store/types";
import { authUpdateStepSignIn } from "store/auth/actions";
import SignInLogin from "components/auth/steps/sign-n/sign-in-login";
import SignInTel from "components/auth/steps/sign-n/sign-in-tel";
import SignInPassword from "components/auth/steps/sign-n/sign-in-password";
import SingInAddedPhone from "components/auth/steps/sign-n/sign-in-added-phone";
import SingInAddedPhoneCode from "components/auth/steps/sign-n/sign-in-added-phone-code";
import UserNoAccess from "components/auth/notification/user-no-access";

const SignInForm: FC<ReduxProps> = ({ step, authUpdateStepSignIn: resetStep }) => {
  useEffect(() => {
    return (): void => {
      if (step !== STEP_SIGN_IN.LOGIN) resetStep(STEP_SIGN_IN.LOGIN);
    };
  }, [resetStep, step]);

  switch (step) {
    case STEP_SIGN_IN.PASSWORD: {
      return <SignInPassword />;
    }
    case STEP_SIGN_IN.TELEPHONE: {
      return <SignInTel />;
    }
    case STEP_SIGN_IN.ADDED_PHONE: {
      return <SingInAddedPhone />;
    }
    case STEP_SIGN_IN.ADDED_PHONE_CODE: {
      return <SingInAddedPhoneCode />;
    }
    case STEP_SIGN_IN.USER_NO_ACCESS: {
      return <UserNoAccess />;
    }
    default: {
      return <SignInLogin />;
    }
  }
};

const mapStateToProps = (store: StoreTypes) => ({
  step: store.auth.stepSignIn
});

const mapDispatchToProps = {
  authUpdateStepSignIn
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SignInForm);
