import { TYPES_LEL } from "store/legalEntitiesList/constants";

export const legalEntityLoad = () => ({
  type: TYPES_LEL.LEGAL_ENTITIES_LOAD
});

export const legalEntityLoadStop = () => ({
  type: TYPES_LEL.LEGAL_ENTITIES_LOAD_STOP
});

export const legalEntityRequest = (body: any) => ({
  type: TYPES_LEL.LEGAL_ENTITIES_REQUEST,
  payload: body
});

export const legalEntitySuccess = (payload: any) => ({
  type: TYPES_LEL.LEGAL_ENTITIES_SUCCESS,
  payload
});

export const legalEntityError = (error: { [key: string]: string }) => ({
  type: TYPES_LEL.LEGAL_ENTITIES_ERROR,
  payload: error
});

export const deleteLegalEntityRequest = (payload: any) => ({
  type: TYPES_LEL.DELETE_LEGAL_ENTITY_REQUEST,
  payload
});

export const deleteLegalEntitySuccess = (payload: string) => ({
  type: TYPES_LEL.DELETE_LEGAL_ENTITY_SUCCESS,
  payload
});

export const clearDealersRows = () => ({
  type: TYPES_LEL.LE_CLEAR_ROWS
});

export const legalEntityByIdRequest = (id: number | string) => ({
  type: TYPES_LEL.LEGAL_ENTITY_BY_ID_REQUEST,
  payload: id
});

export const legalEntityByIdSuccess = (payload: any | null) => ({
  type: TYPES_LEL.LEGAL_ENTITY_BY_ID_SUCCESS,
  payload
});

export const setLERows = (payload: Array<string | number>) => ({
  type: TYPES_LEL.SET_LE_ROWS,
  payload
});

export const filterLERows = (payload: number) => ({
  type: TYPES_LEL.FILTER_LE_ROWS,
  payload
});

export const deleteLegalEntityError = (error: { [key: string]: string }) => ({
  type: TYPES_LEL.DELETE_LEGAL_ENTITY_ERROR,
  payload: error
});

export const legalEntityStatus = (status: { [key: string]: string } | null) => ({
  type: TYPES_LEL.LEGAL_ENTITY_STATUS,
  payload: status
});
