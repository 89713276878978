import React, { FC } from "react";
import { exclamation, alertClose } from "./icons";
import styles from "./alert.module.scss";
import { companyName } from "../switch-themes";

export type PropsType = {
  onClick: (data: boolean) => void;
};

export const Alert: FC<PropsType> = ({ onClick }) => {
  return (
    <div className={styles.alert}>
      <div className={styles.row}>
        <img className={styles.exclamation} src={exclamation} alt="prompt" />
        <div>
          {`Когда вы изменяете свои личные данные, они автоматически обновляются во всех сервисах мира ${companyName}`}
        </div>
      </div>
      <button type="button" className={styles.closeBtn} onClick={(): void => onClick(false)}>
        <img src={alertClose} alt="close" />
      </button>
    </div>
  );
};
