import React, { FC } from "react";
import cn from "classnames";
import { connect, ConnectedProps } from "react-redux";
import { logOutUser, signOutOnUserRequest } from "store/auth/actions";
import logout from "./icons/index";
import logoutWhite from "./icons/logout-white.svg";
import styles from "./logout.module.scss";
import history from "../../services/history";
import storageCookie from "../../services/storageCookie";
import { ADMIN_TOKEN } from "../../constant/api";
import { StoreTypes } from "../../store/types";
import getEmployeesStatus from "../../store/employees/selectors/getStatusSelector";

type PropsType = {
  collapse?: boolean;
  iconTheme?: "dark" | "white";
};

const Logout: FC<ReduxProps & PropsType> = ({
  logOutUser: logOutInServices,
  signOutOnUserRequest: signOutOnUser,
  isGuestAuth,
  guestId,
  collapse,
  iconTheme
}) => {
  const mainClass = cn(styles.logout, {
    [styles.collapse]: collapse
  });
  const handleClick = (): void => {
    const adminToken = storageCookie.get(ADMIN_TOKEN);
    if (isGuestAuth || adminToken) {
      signOutOnUser();
    } else {
      history.push("/auth");
      logOutInServices();
    }
  };
  let srcLogout = logout;
  if (iconTheme === "white") {
    srcLogout = logoutWhite;
  }

  return (
    <button type="button" className={mainClass} onClick={handleClick}>
      <img src={srcLogout} alt="logout" />
    </button>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  isGuestAuth: store.auth.guestAuth.isAuth,
  guestId: store.auth.guestAuth.id
});

const connector = connect(mapStateToProps, { logOutUser, signOutOnUserRequest });
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Logout);
