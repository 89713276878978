import { REFRESH_TOKEN, TOKEN } from "constant/api";

type OptionsTypes = {
  expires: Date;
  path?: string;
  httpOnly?: boolean;
  secure?: boolean;
  SameSite: "none" | "strict";
};

const storageCookie = {
  get(key: string): void | string {
    const matches = document.cookie.match(
      new RegExp(`(?:^|; )${key.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1")}=([^;]*)`)
    );
    if (matches?.length) {
      return decodeURIComponent(matches[1]);
    }
    return undefined;
  },

  set(
    key: string,
    value: any,
    options: OptionsTypes = { expires: new Date(), path: "/", SameSite: "none", secure: false }
  ): void {
    if (options.expires instanceof Date) {
      const hoursLife = options.expires.getHours() + 2;
      options.expires.setHours(hoursLife);
      options.expires.toUTCString();
    }
    let updatedCookie = `${encodeURIComponent(key)}=${encodeURIComponent(value)}; `;
    const newOptions: { [key: string]: string } = Object.assign(options);
    Object.keys(newOptions).forEach(optionKey => {
      updatedCookie += `${optionKey}=${newOptions[optionKey]}; `;
    });
    document.cookie = updatedCookie;
  },
  delete(key: string): void {
    return this.set(key, null, { expires: new Date(0), path: "/", SameSite: "none", secure: false });
  }
};

export function removeTokens() {
  storageCookie.set(TOKEN, null, { expires: new Date(0), path: "/", SameSite: "none", secure: false });
  storageCookie.set(REFRESH_TOKEN, null, { expires: new Date(0), path: "/", SameSite: "none", secure: false });
}

export default storageCookie;
