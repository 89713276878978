/* Функция для склонения слова в зависимости от числа, например:
1 запись,
2 записи,
5 записей

Функция принимает первым аргументом на вход число, вторым аргументом массив склоняемых слов, например [запись, записи, записей]
*/

export default (n: number, textCollection: Array<string>): string => {
  const num = Math.abs(n) % 100;
  const n1 = num % 10;
  if (num > 10 && num < 20) {
    return textCollection[2];
  }
  if (n1 > 1 && n1 < 5) {
    return textCollection[1];
  }
  if (n1 === 1) {
    return textCollection[0];
  }
  return textCollection[2];
};
