import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { authResetAll } from "store/auth/actions";
import Layout from "../../components/lk/layout";
import HomePage from "./home";
import PersonalPage from "./personal";
import SafetyPage from "./safety";

const LkPage: FC<ReduxProps> = ({ resetUser }) => {
  return (
    <Layout>
      <Switch>
        <Route exact path="/home" component={HomePage} />
        <Route exact path="/personal" component={PersonalPage} />
        <Route exact path="/safety" component={SafetyPage} />
        <Redirect exact from="/" to="/home" />
      </Switch>
    </Layout>
  );
};

const mapDispatchToProps = {
  resetUser: authResetAll
};

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(LkPage);
