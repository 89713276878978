import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { DealerCenterApi } from "services/api/dealer-center";
import { TYPES_DC } from "store/dealer-center/constants";
import {
  dealerCenterError,
  dealerCenterSuccess,
  dealerCenterLoad,
  dealerCenterLoadStop,
  dealerCenterByIdSuccess,
  deleteDealerCenterSuccess,
  filterDcRows,
  deleteDealerCenterError,
  dealerCenterStatus,
  dealerCenterRequest,
  deleteDealerCenterRequest,
  dealerCenterByIdRequest,
  clearDealersRows
} from "./actions";

function* getDealerCenter(actions: ReturnType<typeof dealerCenterRequest>): SagaIterator {
  const { payload } = actions;
  yield put(dealerCenterLoad());
  try {
    const response = yield call(DealerCenterApi.getDealerCenter, payload);

    yield put(dealerCenterSuccess(response));
  } catch (e) {
    const result = { total: 0, per_page: 10, current_page: 1, last_page: 1, data: [] };
    yield put(dealerCenterSuccess(result));
    yield put(dealerCenterError({ dealerCenter: "Не удалось загрузить данные" }));
  } finally {
    yield put(dealerCenterLoadStop());
  }
}

function* deleteDealerCenter(actions: ReturnType<typeof deleteDealerCenterRequest>): SagaIterator {
  const {
    payload: { id }
  } = actions;
  yield put(dealerCenterLoad());
  try {
    yield call(DealerCenterApi.deleteDealerCenter, id);
    yield put(filterDcRows(id));
    yield put(deleteDealerCenterSuccess(id));
    yield put(dealerCenterStatus({ deleteDealerCenter: "Успешно" }));
  } catch (e) {
    if (e.status === 400) {
      yield put(deleteDealerCenterError({ deleteDealerCenter: "Можно удалить только пустой диллерский центр" }));
      yield put(clearDealersRows());
    } else {
      yield put(deleteDealerCenterError({ deleteDealerCenter: "Не удалось удалить диллерский центр" }));
    }
  } finally {
    yield put(dealerCenterLoadStop());
  }
}

function* getDealerCenterById(actions: ReturnType<typeof dealerCenterByIdRequest>): SagaIterator {
  const { payload } = actions;
  yield put(dealerCenterLoad());
  try {
    const response = yield call(DealerCenterApi.getDealerCenterById, payload);
    yield put(dealerCenterByIdSuccess(response));
  } catch (e) {
    yield put(dealerCenterError({ dealerCenterById: "Не удалось загрузить данные" }));
  } finally {
    yield put(dealerCenterLoadStop());
  }
}

function* dealerCenterSaga(): SagaIterator {
  yield takeLatest(TYPES_DC.DEALER_CENTER_REQUEST, getDealerCenter);
  yield takeEvery(TYPES_DC.DELETE_DEALER_CENTER_REQUEST, deleteDealerCenter);
  yield takeEvery(TYPES_DC.DEALER_CENTER_BY_ID_REQUEST, getDealerCenterById);
}

export { dealerCenterSaga };
