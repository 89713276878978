import { useSelector } from "react-redux";
import { StoreTypes } from "../store/types";
import { PermissionType } from "../store/users/types";

const usePermissions = (availablePermissions: string[]): boolean => {
  const permissions = useSelector((state: StoreTypes) => state.users.user.permissions);

  return permissions.some((permission: PermissionType) => availablePermissions.includes(permission.name));
};

export default usePermissions;
