import { TYPES } from "./constants";
import { ActionsType, Store } from "./types";
import { RESET_ERROR_AND_STATUS } from "../constants";

const initialState: Store = {
  isLoginHistoryLoading: false,
  error: null,
  loginHistory: []
};

export const loginHistoryReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES.LOGIN_HISTORY_LOAD: {
      return { ...state, isLoginHistoryLoading: true, error: null };
    }
    case TYPES.LOGIN_HISTORY_LOAD_STOP: {
      return { ...state, isLoginHistoryLoading: false };
    }
    case TYPES.LOGIN_HISTORY_SUCCESS: {
      return { ...state, loginHistory: action.payload };
    }
    case TYPES.LOGIN_HISTORY_ERROR: {
      return { ...state, error: { ...state.error, ...action.payload } };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null };
    }
    default: {
      return state;
    }
  }
};
