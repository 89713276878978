import { createSelector } from "reselect";
import { Store } from "../types";

const getMarketingMailingsStore = (store: Store) => store;

export const getMarketingMailings = createSelector([getMarketingMailingsStore], mailings => mailings.mailingsData);

export const getMarketingMailingsServices = createSelector([getMarketingMailingsStore], mailings => mailings.services);

export const getMarketingMailingsError = createSelector([getMarketingMailingsStore], mailings => mailings.error);

export const getMarketingMailingsSelectedRows = createSelector(
  [getMarketingMailingsStore],
  mailings => mailings.selectedRows
);

export const getMarketingMailingsStatus = createSelector([getMarketingMailingsStore], mailings => mailings.status);
