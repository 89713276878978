import React, { FC, useState } from "react";
import cn from "classnames";
import styles from "./hint.module.scss";

export type PropsType = {
  text?: string | React.ReactNode;
  isHyundaiTheme: boolean;
};

export const Hint: FC<PropsType> = ({ text, isHyundaiTheme }) => {
  const [toggleHint, setToggleHint] = useState(false);

  return (
    <div className={styles.Hint}>
      <div
        className={cn(styles.Hint__icon, {
          [styles.Hint__icon_genesis]: !isHyundaiTheme,
          [styles.Hint__icon_hyundai]: isHyundaiTheme
        })}
        onFocus={() => setToggleHint(true)}
        onMouseOver={() => setToggleHint(true)}
        onMouseLeave={() => setToggleHint(false)}
      >
        ?
      </div>
      {toggleHint && (
        <div
          className={cn(styles.Hint__text, {
            [styles.Hint__text_genesis]: !isHyundaiTheme,
            [styles.Hint__text_hyundai]: isHyundaiTheme
          })}
        >
          {text}
        </div>
      )}
    </div>
  );
};
