import React, { FC } from "react";

import { Text, Wrapper, Button } from "components/ui";
import history from "services/history";
import { AUTH_ROUTES_URL } from "constant/routers";
import { authLogo } from "components/ui/helpersComponents";
import { company, titleCompanyTheme } from "components/ui/switch-themes";

const RegisterExists: FC<any> = () => {
  return (
    <div className="text-center">
      {authLogo}
      <Wrapper pb={32}>
        <Text size="main" align="left" theme={titleCompanyTheme}>
          {`Аккаунт с указанным номером телефона уже зарегистрирован в ${company}. Войдите, используя логин от уже
          существующей учетной записи`}
        </Text>
      </Wrapper>
      <Wrapper pb={24}>
        <Button onClick={() => history.push(AUTH_ROUTES_URL.SIGN_IN)} fullWidth>
          Войти
        </Button>
      </Wrapper>
    </div>
  );
};

export default RegisterExists;
