import React, { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { updateStepRegister } from "store/auth/actions";
import { STEP_REGISTER } from "constant/step-auth";
import { Button, Text, Wrapper } from "components/ui";
import { authLogo } from "components/ui/helpersComponents";
import { titleCompanyTheme } from "components/ui/switch-themes";

const RegisterInvalid: FC<ReduxProps> = ({ stepRegister }) => {
  return (
    <div className="text-center">
      {authLogo}
      <Wrapper pb={32}>
        <Text size="h4" align="center" theme={titleCompanyTheme}>
          Время сессии истекло.
          <br />
          Пройдите регистрацию повторно.
        </Text>
      </Wrapper>
      <Wrapper pb={24}>
        <Button onClick={() => stepRegister(STEP_REGISTER.TELEPHONE)} fullWidth>
          Регистрация
        </Button>
      </Wrapper>
    </div>
  );
};

const mapDispatchToProps = {
  stepRegister: updateStepRegister
};
const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(RegisterInvalid);
