import axios from "axios";

type City = {
  city: string;
};
type Address = {
  city: string;
  address: string;
};
type Response = {
  suggestions: { [key: string]: any }[];
};
export const Location = {
  getCity: (body: City): Promise<Response> => axios.post("/front/suggest/city", body),
  getAddress: (body: Address): Promise<Response> => axios.post("/front/suggest/address", body)
};
