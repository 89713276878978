import React, { FC, useState, useEffect, useRef } from "react";
import cn from "classnames";

import { isHyundaiTheme, paginationTheme } from "components/ui/switch-themes";
import styles from "./select.module.scss";
import hyundaiArrow from "./icons/select-arrow.svg";
import genesisArrow from "./icons/arrow-gns.svg";

type Option = {
  id: number;
  name: string;
  value: number;
};

type PropsType = {
  options: Array<Option>;
  onClick: React.Dispatch<React.SetStateAction<number>>;
  color?: string;
};

const Select: FC<PropsType> = ({ color = paginationTheme, options, onClick }) => {
  const defaultSelect = options[0]?.name;
  const [optionName, setOptionName] = useState(defaultSelect);
  const [isOpen, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: Event): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return (): void => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const dropdownHandler = (): void => {
    setOpen(!isOpen);
  };

  const cnSelect = cn(styles.select, {
    [styles[color]]: color
  });
  const cnOption = cn(styles.option, {
    [styles[color]]: color
  });

  const renderSelect = options.map(({ id, name, value }) => (
    <button
      type="button"
      key={id}
      onClick={(): void => {
        setOpen(false);
        setOptionName(name);
        onClick(value);
      }}
      className={cnOption}
    >
      {name}
    </button>
  ));

  return (
    <div ref={ref}>
      <div className={styles.container}>
        <div className={styles.selectWrapper}>
          <button type="button" className={styles.arrow} onClick={dropdownHandler}>
            <div className={cnSelect}>{optionName}</div>
            <img src={isHyundaiTheme ? hyundaiArrow : genesisArrow} alt="arrow" />
          </button>
        </div>
        <div className={isOpen ? styles.options : styles.optionsHidden}>{renderSelect}</div>
      </div>
    </div>
  );
};

export default Select;
