import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { STEP_SIGN_IN } from "constant/step-auth";
import { addError, createControl, deleteError, validateForm } from "helpers/form-bulder";
import { StoreTypes } from "store/types";
import { authAddedPhone, authAddedPhoneCode, authUpdateStepSignIn } from "store/auth/actions";
import { getAuthError } from "store/auth/selectors/getAuthErrorSelector";
import { getAuthData } from "store/auth/selectors/getAuthDataSelector";
import Timer from "components/ui/timer/timer";
import { Input, Text, Wrapper, Link } from "components/ui";
import { isHyundaiTheme, labelCode, sizeTitleAuth, titleCompanyTheme } from "components/ui/switch-themes";
import { authLogo } from "components/ui/helpersComponents";
import { InvisibleSmartCaptcha } from "@yandex/smart-captcha";

const formInitialState = {
  AddedPhoneCode: createControl(
    {
      placeholder: "Введите код",
      value: ""
    },
    {
      required: true
    }
  )
};

const SignInAddedPhoneCode: FC<ReduxProps> = ({ onStep, onUpdate, phoneCode, data, errors }) => {
  const [showTimer, setShowTimer] = useState(false);

  const [validCaptcha, setValidCaptcha] = useState(false);
  const [validCaptchaSuccess, setValidCaptchaSuccess] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const handleReset = () => {
    setValidCaptcha(false);
    setResetCaptcha(prev => prev + 1);
  };

  useEffect(() => {
    if (validCaptchaSuccess) {
      onUpdate({ username: data.username, type: data.type, phone: data.phone, token: captchaToken });
    }
    handleReset();
    setValidCaptchaSuccess(false);
  }, [validCaptchaSuccess]);

  const [form, setForm] = useState({
    isFormValid: false,
    formControls: formInitialState
  });

  useEffect(() => {
    setShowTimer(true);
    const formControls = deleteError<typeof form.formControls>(form.formControls);
    setForm({
      formControls,
      isFormValid: validateForm(formControls)
    });
    return () => setShowTimer(false);
  }, []);

  /**
   * добавление ошибок с сервера
   */
  useEffect(() => {
    if (errors) {
      const formControls = addError<typeof form.formControls>(form.formControls, errors);
      setForm({
        formControls,
        isFormValid: validateForm(formControls)
      });
    }
  }, [errors]);

  const changeHandler = (value: string): void => {
    if (value.length === 4) {
      phoneCode(value);
    }
  };

  return (
    <div className="text-center">
      {authLogo}
      <Wrapper pb={48}>
        <Text uppercase={!isHyundaiTheme} size={sizeTitleAuth} align="center" theme={titleCompanyTheme}>
          Подтверждение входа
        </Text>
      </Wrapper>
      <Wrapper pb={32}>
        <Text size="main" align="left" theme="black">
          Прослушайте сообщение оператора и введите 4 цифры в поле
        </Text>
      </Wrapper>
      <Wrapper pb={16}>
        <Input
          label={labelCode}
          placeholder={form.formControls.AddedPhoneCode.placeholder}
          theme="gray"
          onChange={(e): void => changeHandler(e.target.value)}
          invalid={!form.formControls.AddedPhoneCode.valid && form.formControls.AddedPhoneCode.touched}
          errorMessages={form.formControls.AddedPhoneCode.errorMessage}
        />
      </Wrapper>
      <InvisibleSmartCaptcha
        key={resetCaptcha}
        sitekey={process.env.REACT_APP_YANDEX_CAPTCHA_ID ?? ""}
        onSuccess={token => {
          setCaptchaToken(token);
          setValidCaptchaSuccess(true);
        }}
        visible={validCaptcha}
        onChallengeHidden={() => handleReset()}
      />
      <Wrapper pb={32}>
        <Timer setShowTimer={() => setShowTimer(!showTimer)} start={showTimer} onClick={() => setValidCaptcha(true)} />
      </Wrapper>
      <Wrapper pb={40}>
        <Link
          href="/"
          onClick={() => onStep(STEP_SIGN_IN.ADDED_PHONE)}
          isButton
          type="text"
          weight="regular"
          theme="gray"
          underline
        >
          Назад
        </Link>
      </Wrapper>
    </div>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  errors: getAuthError(store),
  data: getAuthData(store)
});

const mapDispatchToProps = {
  phoneCode: authAddedPhoneCode,
  onUpdate: authAddedPhone,
  onStep: authUpdateStepSignIn
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SignInAddedPhoneCode);
