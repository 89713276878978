import React, { useState } from "react";
import CarsBase from "components/admin/added-legal/legalEntityCarBase";
import LegalCurrentCars from "components/admin/added-legal/legalEntityCurrentCarBase";

import { RadioButton, Wrapper } from "../../../ui";
import styles from "./legalEntityCarsPanel.module.scss";

export enum AddedLegalEntityCars {
  current = "current",
  base = "base"
}

const LegalEntityCarsPanel = () => {
  const [addedCarsType, setAddedCarsType] = useState(AddedLegalEntityCars.base);

  return (
    <Wrapper className={styles.container} pl={40} pt={40} pr={32}>
      <h3 className={styles.title}>Добавление транспорта</h3>
      <div className={styles.wrapper}>
        <div className={styles.panel}>
          <RadioButton
            size="xs"
            onChange={(value: any): void => setAddedCarsType(value)}
            noBorder
            initialSelect={AddedLegalEntityCars.base}
            options={[
              { value: AddedLegalEntityCars.current, text: "Список ТС ЮЛ" },
              { value: AddedLegalEntityCars.base, text: "База ТС" }
            ]}
          />
        </div>
      </div>
      {addedCarsType === AddedLegalEntityCars.base ? <CarsBase /> : <LegalCurrentCars />}
    </Wrapper>
  );
};

export default LegalEntityCarsPanel;
