import React, { FC } from "react";
import cn from "classnames";
import { isHyundaiTheme } from "components/ui/switch-themes";
import styles from "./show-button.module.scss";

type PropsType = {
  onClick?: () => void;
  type: "mobile" | "desktop";
};

const ShowButton: FC<PropsType> = ({ onClick, type }) => {
  const cl = cn(styles.button, {
    [styles[type]]: type,
    [styles.genesis]: !isHyundaiTheme
  });
  return (
    <button className={cl} type="button" onClick={onClick}>
      Смотреть все
    </button>
  );
};

export default ShowButton;
