import { authResetAll } from "store/auth/actions";
import storage from "services/storage";
import history from "services/history";
import store from "store/store";

export const resetUser = () => {
  store.dispatch(authResetAll());
  storage.clear();
  history.push("/auth");
};
