import React, { forwardRef, TextareaHTMLAttributes, ReactElement, Ref } from "react";
import cn from "classnames";
import { FormikErrors } from "formik";
import hyundai from "./styles/textarea-hyundai.module.scss";
import genesis from "./styles/textarea-genesis.module.scss";
import { isHyundaiTheme } from "../switch-themes";

export interface PropsType extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  htmlFor?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  label?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  bordered?: boolean;
  theme?: "white" | "black" | "gray" | "genesis_white";
  invalid?: boolean;
  name?: string;
  errorMessages?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | null;
  statusMessages?: string | null;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  maxLength?: number | undefined;
  autoComplete?: string;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  title?: any;
  rows?: number;
}

export const Textarea = forwardRef(
  (
    {
      autoComplete,
      disabled,
      label,
      title,
      bordered,
      theme = "gray",
      invalid,
      errorMessages,
      statusMessages,
      ...args
    }: PropsType,
    ref: Ref<HTMLTextAreaElement>
  ): ReactElement => {
    const stylesCompany = isHyundaiTheme ? hyundai : genesis;

    const cnTextarea = cn(stylesCompany.input, {
      [stylesCompany[theme]]: theme,
      [stylesCompany.bordered]: bordered,
      [stylesCompany.invalid]: invalid
    });

    const cnLabel = cn(stylesCompany.label, {
      [stylesCompany[theme]]: theme
    });

    return (
      <div>
        {label && (
          <label htmlFor={args.htmlFor} className={cnLabel}>
            {label}
          </label>
        )}
        <div className={stylesCompany.container}>
          <textarea id={args.htmlFor} disabled={disabled} {...args} ref={ref} className={cnTextarea} />
          {errorMessages && <div className={stylesCompany.error}>{errorMessages}</div>}
          {statusMessages && <div className={stylesCompany.status}>{statusMessages}</div>}
        </div>
      </div>
    );
  }
);

Textarea.defaultProps = {
  autoComplete: "off",
  placeholder: "",
  disabled: false,
  label: "",
  bordered: isHyundaiTheme,
  theme: "gray"
};
