import React, { FC, ReactElement, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import NotificationPopup from "components/ui/notification-popup";
import UserAvatar from "components/admin/added-users/user-avatar/user-avatar";
import { connect, ConnectedProps } from "react-redux";
import { StoreTypes } from "store/types";
import { rolesRequest } from "store/roles/actions";
import PersonalInfo from "components/admin/personal-info/personal-info";
import { Button, Text, Wrapper, Table } from "components/ui";
import Service from "components/admin/service";
import { getServicesRequest } from "store/services/actions";
import SafetyBlock from "components/admin/safety-block";
import Connections from "components/connections/сonnections";
import history from "services/history";
import { formatPhoneNumber } from "helpers/format-phone-number";
import { AttachRole, Role } from "store/roles/types";
import getDiffArray from "helpers/getDiffArray";
import { employeesByIdRequest, employeesByIdSuccess, employeeUpdateRequest } from "store/employees/actions";
import { resetErrorAndStatus } from "store/actions";
import { Loading } from "components/ui/loading";
import Row from "components/ui/row";
import { useParams, useRouteMatch } from "react-router-dom";
import { attachTeamRequest, createUserRequest, usersError } from "store/users/actions";
import { isChangedValues } from "helpers/formik-handlers";
import { matchServices } from "helpers/match-services";
import { CreateUserBody, Services } from "store/users/types";
import EmployeeRoles, { EmployeeIdRoles } from "constant/eployee-roles";
import TeamName from "constant/team-name";
import { DetachTeamsTypes, TeamsType, TeamType } from "store/employees/types";
import fp from "lodash";
import { isDetachTeamsTypes } from "helpers/guards";
import { buttonAddAndRemoveTheme, isHyundaiTheme, sizeTitleCreateUsers } from "components/ui/switch-themes";
import { scrollUp } from "helpers/scrollUp";
import getInitialValues from "../getInitialValues";
import styles from "./added-user-form.module.scss";
import validationSchema from "../validationSchema";
import BanDeletionPanel from "../ban-deletion-panel";
import { columnsCar } from "./columns";

type Props = {
  isEdit?: boolean;
  teamName: TeamName;
};

export type ErrorType = {
  [key: string]: string;
} | null;

type FormikAction = {
  resetForm: () => void;
};

const AddedUserForm: FC<Props & ReduxProps> = ({
  teamName,
  teamsCurrentEmployees,
  isUsersLoading,
  isEmployeesLoading,
  isRolesLoading,
  setUsersError,
  userError,
  rolesError,
  resetError,
  setCurrentEmployee,
  getUserById,
  employeeUpdate,
  createUser,
  editingUser,
  connectedServices,
  connectedServicesFront,
  unconnectedServices,
  getRoles,
  roles,
  isEdit = false,
  banDeletions
}) => {
  const [reset, setReset] = useState(false);
  const [isDefaultRole, setIsDefaultRole] = useState<any>();
  const [useScrollUp, setUseScrollUp] = useState(true);
  const [client, setClient] = useState(false);
  const [isEmployeeDC, setEmployeeDC] = useState(false);
  const [isOpenNotification, setOpenNotification] = useState(false);

  const { id: userId } = useParams<any>();
  const { path: userPath } = useRouteMatch();
  const conditionNotEmployee =
    userPath !== "/admin/employees/dc/edit-employee/:id" && userPath !== "/admin/employees/main/edit-employee/:id";
  const notificationTitle = isEdit ? "Данные успешно изменены" : "Данные успешно сохранены";
  const viewContent = !isEdit || (isEdit && editingUser);

  const currentEmployeeId = editingUser ? editingUser.id : null;
  const currentServices = editingUser ? editingUser.services : null;

  const initialState = getInitialValues(isEdit, editingUser, userId);

  const filterServicesByCode = (services: Services[] | []): Services[] | [] => {
    const isRoleClient = editingUser?.roles?.length && editingUser?.roles?.every((item: any) => item.name === "client");
    if (isRoleClient) {
      return services?.filter((elem: Services) =>
        ["hyundai-mobility", "hyundai-dos", "hyundai-mir"].includes(elem.code)
      );
    }
    return services;
  };

  useEffect(() => {
    if (isEdit && !editingUser) {
      getUserById(userId);
    }
    if (teamName && teamName !== TeamName.ALL) {
      getRoles(teamName.toLowerCase());
    } else {
      getRoles();
    }
    return (): void => {
      resetError();
      setCurrentEmployee(null);
    };
  }, []);

  useEffect(() => {
    if (roles?.length > 0) {
      const defaultRoles = roles?.filter(elem => elem.name === "client");
      setIsDefaultRole([...defaultRoles]);
    }
  }, [roles]);

  const setErrorServerHandler = ({ data: error, status }: any): void => {
    if (error.errors) {
      if (status === 400) {
        const errorsValues = Object.entries(error.errors).reduce((acc, item: Array<any>) => {
          const [key, values] = item;
          const [value] = values;
          return { ...acc, [key]: value };
        }, {});
        setUsersError(errorsValues);
      } else {
        setUsersError({ updateUser: "Не удалось изменить данные" });
      }
    } else {
      setUsersError({ updateUser: "Не удалось изменить данные" });
    }
  };

  const submitHandler = (values: any, { resetForm }: FormikAction): void => {
    setUseScrollUp(true);
    const currentValues = values;
    currentValues.phone = formatPhoneNumber(String(values.phone));

    const resetAllForm = (): void => {
      resetForm();
      setReset(!reset);
    };
    if (!isEdit) {
      const requestBody = fp.omit(currentValues, ["confirmedPassword"]);
      requestBody.teams = requestBody.teams.map((team: TeamsType) => team.id);
      const { roles: userRoles, teams } = requestBody;
      const isEmployeeMain = requestBody.roles.some(
        (role: Role) => role.name === EmployeeRoles.adminMain || role.name === EmployeeRoles.employeeMain
      );
      if (requestBody?.services?.length === 0) {
        delete requestBody?.services;
      }
      const [teamsRole] = userRoles.map((role: Role): string => role.name);

      if (userRoles?.length) {
        const out: AttachRole[] = [];
        if (!isEmployeeMain && teams?.length) {
          requestBody.roles = teams?.reduce((acc: AttachRole[], current: string) => {
            out?.push({ name: teamsRole, team_id: current });
            return { roles: [...out] };
          }, {});
        } else {
          requestBody.roles = { roles: [{ name: teamsRole }] };
        }
      }
      createUser({
        body: requestBody as CreateUserBody,
        teamsRole,
        resetForm: resetAllForm,
        setOpen: setOpenNotification,
        customErrorSetter: setErrorServerHandler
      });
    } else {
      let picture = null;
      let { name: teamsRole } = currentValues?.roles[0];
      let requestBody = fp.cloneDeep(currentValues);
      let teams = requestBody.teams.map((item: TeamsType) => item.id);
      let attachRoles = null;
      let detachTeams = null;

      if (currentValues.picture && initialState.picture !== currentValues.picture) {
        picture = currentValues.picture;
      }
      const pivotTeams = (teamsCurrentEmployees as TeamType[])?.map((item: TeamType) => item.pivot);
      const valuesRoleNames = currentValues.roles.map((role: Role): string => role.name);
      const initValuesRoleNames = initialState.roles.map((role: Role): string => role.name);

      const isChangedRoles = !fp.isEqual(initValuesRoleNames, valuesRoleNames);
      const initValuesTeamsIds = initialState.teams.map((team: TeamType) => team.id);
      const attachTeams = getDiffArray(teams, initValuesTeamsIds);
      const detachTeamsIds = getDiffArray(initValuesTeamsIds, teams);

      if (detachTeamsIds?.length) {
        const ids: number[] = [];
        const dataRequest = pivotTeams.reduce((acc, current): DetachTeamsTypes => {
          if (detachTeamsIds.includes(current.team_id)) ids.push(current.team_id);
          return {
            teamIds: ids,
            role: EmployeeIdRoles[Number(current.role_id)]
          };
        }, {});
        if (isDetachTeamsTypes(dataRequest)) detachTeams = dataRequest;
      }

      if (valuesRoleNames?.length) {
        /// изменение ролей
        [teamsRole] = valuesRoleNames;
        if (
          (teamsRole === EmployeeRoles.employeeDC || teamsRole === EmployeeRoles.adminDC) &&
          currentValues?.teams?.length
        ) {
          const { teams: arrTeams } = requestBody;

          const out: AttachRole[] = [];
          attachRoles = arrTeams.reduce((acc: AttachRole[], current: TeamsType) => {
            out.push({ name: teamsRole, team_id: current.id });
            return { roles: [...out] };
          }, {});
        } else {
          attachRoles = { roles: [{ name: teamsRole }] };
        }
      }

      if (values?.teams?.length) {
        const arrayIds = (teamsCurrentEmployees as TeamType[])?.map(team => team.id);
        teams = values?.teams?.filter((team: number) => !arrayIds.includes(team));
      }

      const excludeValues = ["picture", "roles", "team", "teams", "position"];
      Object.keys(requestBody).map((item: string): string => {
        if (currentValues[item] === initialState[item] && item !== "id") {
          excludeValues.push(item);
        }
        return item;
      });

      if (currentServices?.length && values.services?.length) {
        const isChangeServices = matchServices<Services, string>(currentServices, values.services);
        if (isChangeServices) {
          requestBody = fp.omit(requestBody, ["services", ...excludeValues]);
        } else {
          requestBody = fp.omit(requestBody, [...excludeValues]);
        }
      } else {
        requestBody = fp.omit(requestBody, ["services", ...excludeValues]);
      }
      requestBody = Object.keys(requestBody)?.length > 1 ? requestBody : null;

      employeeUpdate({
        body: {
          requestBody,
          attachTeams,
          detachTeams,
          teamsRole,
          picture,
          teams,
          attachRoles,
          id: userId
        },
        setOpen: setOpenNotification,
        customErrorSetter: setErrorServerHandler
      });
    }
  };

  useEffect(() => {
    if (useScrollUp) {
      scrollUp({ ...userError, ...rolesError }, setUseScrollUp);
    }
  }, [userError, rolesError]);

  const notificationAction = isEdit
    ? (): void => {
        history.push("../");
        setOpenNotification(false);
        resetError();
      }
    : (): void => {
        setOpenNotification(false);
        resetError();
      };

  return viewContent ? (
    <div className={styles.wrapper}>
      <NotificationPopup title={notificationTitle} isOpen={isOpenNotification} action={notificationAction} />
      <Formik
        initialValues={initialState}
        onSubmit={submitHandler}
        validateOnBlur={false}
        validateOnChange
        validationSchema={validationSchema(isEdit, client)}
      >
        {({
          values,
          setFieldError,
          setFieldTouched,
          setFieldValue,
          errors,
          touched,
          handleChange,
          handleBlur
        }): ReactElement => (
          <Form>
            <UserAvatar
              setEmployeeDC={setEmployeeDC}
              setClient={setClient}
              values={values}
              setFieldTouched={setFieldTouched}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              isEdit={isEdit}
              initialValues={initialState}
              connectedService={editingUser?.connected_services}
            />
            <PersonalInfo
              defaultRole={isDefaultRole}
              isClient
              setFieldError={setFieldError}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errors={errors}
              values={values}
              isEdit={isEdit}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              reset={reset}
            />
            {connectedServicesFront?.length ? (
              <div className={styles.block3}>
                <Wrapper pt={40} pb={32}>
                  <Text uppercase={!isHyundaiTheme} size={sizeTitleCreateUsers} theme="black">
                    Активные сервисы
                  </Text>
                </Wrapper>
                <Wrapper pb={40}>
                  <Service
                    services={connectedServicesFront || []}
                    setFieldValue={setFieldValue}
                    initValues={initialState.connectedServicesFront}
                    reset={reset}
                  />
                </Wrapper>
              </div>
            ) : null}
            {unconnectedServices?.length ? (
              <div className={styles.block3}>
                <Wrapper pt={40} pb={32}>
                  <Text uppercase={!isHyundaiTheme} size={sizeTitleCreateUsers} theme="black">
                    Сервисы
                  </Text>
                </Wrapper>
                <Wrapper pb={40}>
                  <Service
                    services={unconnectedServices || []}
                    setFieldValue={setFieldValue}
                    initValues={initialState.unconnectedServices}
                    reset={reset}
                  />
                </Wrapper>
              </div>
            ) : null}
            <div className={styles.block2}>
              {!isEdit && (
                <SafetyBlock
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                />
              )}
              <Wrapper pt={32} pb={16}>
                <div className={styles.btnBlock}>
                  <Button
                    theme={buttonAddAndRemoveTheme}
                    disabled={
                      isEmployeesLoading || isRolesLoading || isUsersLoading || isChangedValues(initialState, values)
                    }
                    onClick={(): void => scrollUp(errors, setUseScrollUp)}
                    type="submit"
                  >
                    Сохранить
                  </Button>
                  <Button
                    theme="white"
                    onClick={(): void => {
                      if (isEdit) {
                        history.push("../");
                      } else {
                        history.push("./");
                      }
                    }}
                    noBorder
                  >
                    Отменить
                  </Button>
                </div>
              </Wrapper>
            </div>
          </Form>
        )}
      </Formik>
      {isEdit && conditionNotEmployee && isHyundaiTheme && (
        <Wrapper className={styles.banDeletionBlock} pt={32} pb={32} pl={32}>
          <h3 className={styles.title}>Запреты на удаление</h3>
          <BanDeletionPanel userId={currentEmployeeId} banDeletions={banDeletions || []} />
        </Wrapper>
      )}
      {isEdit && conditionNotEmployee && editingUser?.cars && editingUser?.cars.length > 0 && (
        <Wrapper pt={40} pb={32} pl={32}>
          <Wrapper pb={32}>
            <Text uppercase={!isHyundaiTheme} size={sizeTitleCreateUsers} theme="black">
              Автомобили
            </Text>
          </Wrapper>
          <Table columns={columnsCar} data={editingUser?.cars} />
        </Wrapper>
      )}
      {isEdit && (
        <div className={styles.safetyBlock}>
          <Wrapper pt={40} pb={32}>
            <Text uppercase={!isHyundaiTheme} size={sizeTitleCreateUsers} theme="black">
              Безопасность и вход
            </Text>
          </Wrapper>
          <Connections id={currentEmployeeId} />
        </div>
      )}
    </div>
  ) : (
    <Loading isLoading>
      <Wrapper pt={300}>
        <Row justifyContent="justifyCenter" alignItems="alignCenter">
          <h1>Идет загрузка</h1>
        </Row>
      </Wrapper>
    </Loading>
  );
};

const mapStateToProps = ({ roles, employees, users }: StoreTypes) => ({
  editingUser: employees.currentEmployee,
  connectedServices: employees.currentEmployee?.connected_services,
  connectedServicesFront: employees.currentEmployee?.connected_services_front,
  unconnectedServices: employees.currentEmployee?.unconnected_services,
  roles: roles.roles,
  rolesError: roles.error,
  userError: users.error,
  isUsersLoading: users.isLoading,
  isEmployeesLoading: employees.isLoading,
  isRolesLoading: roles.isLoadingRoles,
  teamsCurrentEmployees: employees.currentEmployee?.teams,
  banDeletions: employees.currentEmployee?.ban_deletions
});

const mapDispatchToProps = {
  getServices: getServicesRequest,
  getRoles: rolesRequest,
  createUser: createUserRequest,
  employeeUpdate: employeeUpdateRequest,
  resetErrorsAndStatus: resetErrorAndStatus,
  getUserById: employeesByIdRequest,
  setCurrentEmployee: employeesByIdSuccess,
  resetError: resetErrorAndStatus,
  setUsersError: usersError,
  attachTeam: attachTeamRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddedUserForm);
