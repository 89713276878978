import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { STEP_SIGN_IN } from "constant/step-auth";
import { addError, createControl, deleteError, validateForm } from "helpers/form-bulder";
import { authRequestCode, authRequestLogin, authUpdateStepSignIn } from "store/auth/actions";
import { StoreTypes } from "store/types";
import { getAuthData } from "store/auth/selectors/getAuthDataSelector";
import { getAuthError } from "store/auth/selectors/getAuthErrorSelector";
import Timer from "components/ui/timer/timer";
import { Input, Text, Wrapper, Link } from "components/ui";
import { authLogo, subTitleCode } from "components/ui/helpersComponents";
import { isHyundaiTheme, labelCode, sizeTitleAuth, titleCompanyTheme } from "components/ui/switch-themes";
import { InvisibleSmartCaptcha } from "@yandex/smart-captcha";

const formInitialState = {
  code: createControl(
    {
      placeholder: "Введите код",
      value: ""
    },
    {
      required: true
    }
  )
};

const SignInTel: FC<ReduxProps> = ({
  onStep,
  authRequestLogin: onSubmits,
  data,
  authRequestCode: onSubmit,
  errors
}: ReduxProps) => {
  const [showTimer, setShowTimer] = useState(false);
  const [form, setForm] = useState({
    isFormValid: false,
    formControls: formInitialState
  });

  const [validCaptcha, setValidCaptcha] = useState(false);
  const [validCaptchaSuccess, setValidCaptchaSuccess] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const handleReset = () => {
    setValidCaptcha(false);
    setResetCaptcha(prev => prev + 1);
  };

  const { username, type } = data;

  useEffect(() => {
    if (validCaptchaSuccess) {
      onSubmits({ username, type, token: captchaToken });
    }
    handleReset();
    setValidCaptchaSuccess(false);
  }, [validCaptchaSuccess]);

  useEffect(() => {
    setShowTimer(true);
    const formControls = deleteError<typeof form.formControls>(form.formControls);
    setForm({
      formControls,
      isFormValid: validateForm(formControls)
    });
    return (): void => {
      setShowTimer(false);
    };
  }, []);
  /**
   * добавление ошибок с сервера
   */
  useEffect(() => {
    if (errors) {
      const formControls = addError<typeof form.formControls>(form.formControls, errors);
      setForm({
        formControls,
        isFormValid: validateForm(formControls)
      });
    }
  }, [errors]);

  const changeHandler = (value: string): void => {
    if (value.length === 4) {
      onSubmit(value);
    }
  };

  return (
    <div className="text-center">
      {authLogo}
      <Wrapper pb={48}>
        <Text uppercase={!isHyundaiTheme} size={sizeTitleAuth} align="center" theme={titleCompanyTheme}>
          Подтверждение входа
        </Text>
      </Wrapper>
      {subTitleCode}
      <Wrapper pb={16}>
        <Input
          label={labelCode}
          placeholder={form.formControls.code.placeholder}
          theme="gray"
          onChange={(e): void => changeHandler(e.target.value)}
          invalid={!form.formControls.code.valid && form.formControls.code.touched}
          errorMessages={form.formControls.code.errorMessage}
        />
      </Wrapper>
      <InvisibleSmartCaptcha
        key={resetCaptcha}
        sitekey={process.env.REACT_APP_YANDEX_CAPTCHA_ID ?? ""}
        onSuccess={token => {
          setCaptchaToken(token);
          setValidCaptchaSuccess(true);
        }}
        visible={validCaptcha}
        onChallengeHidden={() => handleReset()}
      />
      <Wrapper pb={32}>
        <Timer setShowTimer={() => setShowTimer(!showTimer)} start={showTimer} onClick={() => setValidCaptcha(true)} />
      </Wrapper>
      <Wrapper pb={40}>
        <Link
          href="/"
          isButton
          onClick={() => onStep(STEP_SIGN_IN.LOGIN)}
          type="text"
          weight="regular"
          theme="gray"
          underline
        >
          Назад
        </Link>
      </Wrapper>
    </div>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  data: getAuthData(store),
  errors: getAuthError(store)
});

const mapDispatchToProps = {
  authRequestCode,
  authRequestLogin,
  onStep: authUpdateStepSignIn
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SignInTel);
