import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { resetErrorAndStatus } from "store/actions";
import { StoreTypes } from "store/types";
import { Store, UserType } from "store/users/types";
import UploadPhoto from "../../../components/lk/upload-photo";
import styles from "./personal.module.scss";
import PersonalForm from "../../../components/lk/personal-form";
import Container from "../../../components/lk/container";
import getPrettyPhone from "../../../helpers/pretty-phone-format";

type PropTypes = Partial<UserType> & Partial<Store>;

type DispatchProps = {
  resetErrorsAndStatus: () => void;
};

const PersonalPage: FC<PropTypes & DispatchProps> = ({
  name,
  phone,
  email,
  lastname,
  userInfoIsLoaded,
  id,
  picture,
  resetErrorsAndStatus
}) => {
  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
    };
  }, []);

  const prettyPhone = phone && getPrettyPhone(phone);
  return (
    <div className={styles.personal}>
      <Container>
        <div className={styles.title}>Личные данные</div>
        <div className={styles.wrap}>
          <div className={styles.infoContainer}>
            <div className={styles.uploadWrap}>
              <UploadPhoto userId={id} picture={picture} />
            </div>
            <div className={styles.col}>
              <div className={styles.userName}>{`${name} ${lastname}`}</div>
              <div className={styles.details}>
                {phone && <div>{prettyPhone}</div>}
                {email && <div>{email}</div>}
              </div>
            </div>
          </div>
        </div>
        {userInfoIsLoaded && <PersonalForm />}
      </Container>
    </div>
  );
};

const mapStateToProps = ({ users: { user }, users }: StoreTypes): PropTypes => ({
  name: user.name,
  id: user.id,
  picture: user.picture,
  phone: user.phone,
  email: user.email,
  lastname: user.lastname,
  isLoading: users.isLoading,
  userInfoIsLoaded: users.userInfoIsLoaded
});

const mapDispatchToProps = {
  resetErrorsAndStatus: resetErrorAndStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalPage);
