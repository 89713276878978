import { Action, StrictAction } from "../types";
import { TYPES } from "./constants";
import { LoginType } from "./types";
import { RESET_ERROR_AND_STATUS } from "../constants";

export const loginHistoryLoad = (): Action<TYPES.LOGIN_HISTORY_LOAD> => ({ type: TYPES.LOGIN_HISTORY_LOAD });

export const loginHistoryLoadStop = (): Action<TYPES.LOGIN_HISTORY_LOAD_STOP> => ({
  type: TYPES.LOGIN_HISTORY_LOAD_STOP
});

export const loginHistoryRequest = (id: string): StrictAction<TYPES.LOGIN_HISTORY_REQUEST, string> => ({
  type: TYPES.LOGIN_HISTORY_REQUEST,
  payload: id
});

export const loginHistorySuccess = (
  payload: Array<LoginType>
): StrictAction<TYPES.LOGIN_HISTORY_SUCCESS, Array<LoginType>> => ({
  type: TYPES.LOGIN_HISTORY_SUCCESS,
  payload
});

export const loginHistoryError = (
  error: {
    [key: string]: string;
  } | null
): StrictAction<TYPES.LOGIN_HISTORY_ERROR, { [key: string]: string } | null> => ({
  type: TYPES.LOGIN_HISTORY_ERROR,
  payload: error
});

export const resetErrorAndStatus = (): Action<typeof RESET_ERROR_AND_STATUS> => ({
  type: RESET_ERROR_AND_STATUS
});
