import { Employee, TeamType } from "store/employees/types";
import { Role } from "store/users/types";
import getPrettyPhone from "helpers/pretty-phone-format";
import moment from "moment";

export const columnsDC = [
  {
    title: "ID",
    dataIndex: "id",
    render: ({ id }: Employee): string => id
  },
  {
    title: "ФИО",
    dataIndex: "name",
    render: ({ name, lastname, patronymic }: Employee): string =>
      `${lastname} ${(name && name[0]) || ""}. ${(patronymic && patronymic[0]) || ""}${(patronymic && ".") || ""}`
  },
  {
    title: "Роль",
    dataIndex: "role",
    render: ({ roles }: Employee): string =>
      roles?.length > 0 ? (roles as Array<Role>).map((role: Role): string => role.display_name).join(", ") : ""
  },
  {
    title: "Дилерский центр",
    dataIndex: "DC",
    render: ({ teams }: Employee): string =>
      teams.length > 0 ? (teams as TeamType[]).map((team: TeamType): string => team.display_name).join(", ") : ""
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    render: ({ phone }: Employee): string => getPrettyPhone(phone)
  },
  {
    title: "Email",
    dataIndex: "email",
    render: ({ email }: Employee): string => email || ""
  },
  {
    title: "Дата последнего посещения",
    dataIndex: "lastLoginIt",
    render: ({ last_login_at }: Employee): string => (last_login_at ? moment(last_login_at).format("DD.MM.YYYY") : "")
  },
  {
    title: "Дата создания",
    dataIndex: "createdDate",
    render: ({ created_at }: Employee): string => (created_at ? moment(created_at).format("DD.MM.YYYY") : "")
  }
];

export const deleteRowsColumn = [
  {
    title: "№",
    dataIndex: "n",
    render: (employee: Employee, index: string): string => index
  },
  {
    title: "ФИО",
    dataIndex: "name",
    render: ({ name, lastname, patronymic }: Employee): string => `${lastname || ""} ${name || ""} ${patronymic || ""}`
  },
  {
    title: "Роль",
    dataIndex: "role",
    render: ({ roles }: Employee): string =>
      roles?.length > 0 ? (roles as Array<Role>).map((role: Role): string => role.display_name).join(", ") : ""
  },
  {
    title: "Дилерский центр",
    dataIndex: "DC",
    render: ({ teams }: Employee): string =>
      teams.length > 0 ? (teams as TeamType[]).map((team: TeamType): string => team.display_name).join(", ") : ""
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    render: ({ phone }: Employee): string => getPrettyPhone(phone)
  }
];
