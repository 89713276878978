import React, { FC } from "react";
import cn from "classnames";
import styles from "./warning.module.scss";

type Props = {
  color?: "red" | "black";
};

const Warning: FC<Props> = ({ children, color = "black" }) => {
  const className = cn(styles.warning, { [styles[color]]: color });
  return <div className={className}>{children}</div>;
};

export default Warning;
