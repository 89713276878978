import { Employee } from "store/employees/types";
import getPrettyPhone from "helpers/pretty-phone-format";

export const columnsUser = [
  {
    title: "ID",
    dataIndex: "id",
    render: ({ id }: Employee): string => id
  },
  {
    title: "ФИО",
    dataIndex: "name",
    render: ({ name, lastname, patronymic }: Employee): string =>
      `${lastname} ${(name && name[0]) || ""}. ${(patronymic && patronymic[0]) || ""}${(patronymic && ".") || ""}`
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    render: ({ phone }: Employee): string => getPrettyPhone(phone)
  },
  {
    title: "Email",
    dataIndex: "email",
    render: ({ email }: Employee): string => email || ""
  }
];

export const columnsCar = [
  {
    title: "Модель",
    dataIndex: "model_name",
    render: ({ model_name }: any): string => model_name
  },
  {
    title: "VIN номер",
    dataIndex: "vin",
    render: ({ vin }: any): string => vin
  }
];

export const columnsPopup = [
  {
    title: "ФИО",
    dataIndex: "name",
    render: ({ name, lastname, patronymic }: Employee): string =>
      `${lastname} ${(name && name[0]) || ""}. ${(patronymic && patronymic[0]) || ""}${(patronymic && ".") || ""}`
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    render: ({ phone }: Employee): string => getPrettyPhone(phone)
  }
];
