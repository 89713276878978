import { TYPES_EMPLOYEES } from "store/employees/constants";
import { RESET_ERROR_AND_STATUS } from "store/constants";
import { TYPES_USER } from "store/users/constants";
import { ActionsType, Store } from "./types";

const initialState: Store = {
  isLoading: false,
  token: null,
  error: null,
  status: null,
  currentEmployee: null,
  employees: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
    data: []
  },
  selectedRows: [],
  deleteError: null,
  selectedBanDeletionRows: []
};

export const employeesReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_EMPLOYEES.EMPLOYEES_LOAD: {
      return { ...state, isLoading: true, error: null, status: null };
    }
    case TYPES_EMPLOYEES.EMPLOYEES_LOAD_STOP: {
      return { ...state, isLoading: false };
    }
    case TYPES_EMPLOYEES.EMPLOYEES_SUCCESS: {
      return { ...state, employees: action.payload };
    }
    case TYPES_EMPLOYEES.EMPLOYEES_ERROR: {
      return { ...state, error: { ...state.error, ...action.payload } };
    }
    case TYPES_EMPLOYEES.EMPLOYEES_STATUS: {
      return { ...state, status: action.payload };
    }
    case TYPES_EMPLOYEES.DELETE_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employees: { ...state.employees, data: state.employees.data.filter(employee => employee.id !== action.payload) }
      };
    }
    case TYPES_EMPLOYEES.EMPLOYEES_BY_ID_SUCCESS: {
      return { ...state, currentEmployee: action.payload };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null, status: null, deleteError: null };
    }
    case TYPES_USER.UPLOAD_AVATAR_SUCCESS_EMPLOYEE_SET: {
      return {
        ...state,
        currentEmployee: state.currentEmployee ? { ...state.currentEmployee, picture: action.payload.path } : null
      };
    }
    case TYPES_EMPLOYEES.EMPLOYEE_UPDATE_ROLES_SUCCESS: {
      return {
        ...state,
        currentEmployee: state.currentEmployee
          ? {
              ...state.currentEmployee,
              roles: action.payload
            }
          : null
      };
    }
    case TYPES_EMPLOYEES.SET_EMPLOYEES_ROWS: {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
    case TYPES_EMPLOYEES.FILTER_EMPLOYEES_ROWS: {
      return {
        ...state,
        selectedRows: state.selectedRows.filter(item => item !== action.payload)
      };
    }
    case TYPES_EMPLOYEES.DELETE_EMPLOYEE_ERROR: {
      return { ...state, deleteError: action.payload };
    }
    case TYPES_EMPLOYEES.UPDATE_TEAMS: {
      return {
        ...state,
        currentEmployee: state.currentEmployee ? { ...state.currentEmployee, teams: [...action.payload] } : null
      };
    }
    case TYPES_EMPLOYEES.CREATE_BAN_DELETION_SUCCESS: {
      return {
        ...state,
        currentEmployee: state.currentEmployee
          ? { ...state.currentEmployee, ban_deletions: [...action.payload, ...state.currentEmployee.ban_deletions] }
          : null
      };
    }
    case TYPES_EMPLOYEES.UPDATE_BAN_DELETION_SUCCESS: {
      return {
        ...state,
        currentEmployee: state.currentEmployee
          ? {
              ...state.currentEmployee,
              ban_deletions: [
                ...state.currentEmployee.ban_deletions.map(item => {
                  if (item.id === action.payload.id) {
                    return action.payload;
                  }
                  return item;
                })
              ]
            }
          : null
      };
    }
    case TYPES_EMPLOYEES.DELETE_BAN_DELETION_SUCCESS: {
      return {
        ...state,
        currentEmployee: state.currentEmployee
          ? {
              ...state.currentEmployee,
              ban_deletions: [...state.currentEmployee.ban_deletions.filter(item => item.id !== action.payload)]
            }
          : null
      };
    }
    case TYPES_EMPLOYEES.DELETE_BAN_DELETION_ERROR: {
      return { ...state, error: { ...state.error, ...action.payload } };
    }
    case TYPES_EMPLOYEES.SET_BAN_DELETION_ROWS: {
      return {
        ...state,
        selectedBanDeletionRows: action.payload
      };
    }
    case TYPES_EMPLOYEES.FILTER_BAN_DELETION_ROWS: {
      return {
        ...state,
        selectedBanDeletionRows: state.selectedBanDeletionRows.filter(item => item !== action.payload)
      };
    }

    default: {
      return state;
    }
  }
};
