import React, { FC, useState } from "react";
import cn from "classnames";

import mainTheme from "components/ui/switch-themes";
import styles from "./tabs.module.scss";

type Props = {
  inputTypes: (types: string) => void;
};

const Tabs: FC<Props> = ({ inputTypes }: Props) => {
  const [active, setActive] = useState("phone");

  const cnTabs = cn(styles.tabs, {
    [styles.active]: active === "phone",
    [styles[mainTheme]]: mainTheme
  });

  const cnTabsEmail = cn(styles.tabs, {
    [styles.active]: active === "email",
    [styles[mainTheme]]: mainTheme
  });

  return (
    <>
      <div className={styles.wrapper}>
        <div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
          <div
            role="button"
            onClick={event => {
              inputTypes(event.currentTarget.id);
              setActive(event.currentTarget.id);
            }}
            id="phone"
            className={cnTabs}
          >
            Телефон
          </div>
        </div>
        <div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
          <div
            role="button"
            onClick={event => {
              inputTypes(event.currentTarget.id);
              setActive(event.currentTarget.id);
            }}
            id="email"
            className={cnTabsEmail}
          >
            Email
          </div>
        </div>
      </div>
    </>
  );
};

export default Tabs;
