import { DataDealershipTypes } from "components/admin/personal-info/personal-info";
import { TeamsType } from "store/employees/types";
import { SuggestionType } from "components/admin/autosaggest/autosuggest";

export function guardsAutoSuggest(arg: string | SuggestionType): arg is SuggestionType {
  return (arg as SuggestionType).id !== undefined && (arg as SuggestionType).data !== undefined;
}

export type onSelectTypes = {
  values?: TeamsType[];
  suggestionValue: SuggestionType;
  dataDealership?: DataDealershipTypes[];
  setDataDealership?: (arg: DataDealershipTypes[]) => void;
  setFieldError?: (field: string, errorMsg: string) => void;
  setFieldValue?: (field: string, value: any) => void;
  setCurrentValue?: (arg: string) => void;
  fieldName?: string;
};

export type onClickTypes = {
  suggestionOnClick: (id: string | number) => void;
  id: string | number;
};

export function autoSuggestOnSelected({
  values,
  suggestionValue,
  setFieldError,
  setFieldValue,
  setCurrentValue,
  fieldName
}: onSelectTypes): void {
  const { id, data } = suggestionValue;
  if (values && setFieldError && setFieldValue && setCurrentValue && fieldName) {
    const excludeDuplicate = values.every(item => Number(item.id) !== Number(id));
    if (excludeDuplicate) {
      setFieldValue(fieldName, [...values, { id: Number(id), data }]);
      setCurrentValue("");
    } else setFieldError(fieldName, "Сотрудник уже прикреплен к данному диллерскому центру");
  }
}

export function onSelectedPosition({
  suggestionValue,
  setCurrentValue,
  setFieldValue,
  fieldName
}: onSelectTypes): void {
  const { data, id } = suggestionValue;
  if (setFieldValue && setCurrentValue && fieldName) {
    setCurrentValue(data);
    setFieldValue(fieldName, String(id));
  }
}

export function onClickSearch({ suggestionOnClick, id }: onClickTypes) {
  suggestionOnClick(id);
}
