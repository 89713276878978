import React, { FC } from "react";
import { Wrapper } from "components/ui";
import { company, companyName, isHyundaiTheme, sizeTitleBanner } from "components/ui/switch-themes";
import cn from "classnames";
import styles from "./banner.module.scss";

import { Logo } from "../../ui/logo";
import { Text } from "../../ui/text";

const Banner: FC = () => {
  const mainTheme = isHyundaiTheme ? "hyundai" : "genesis";

  const cnBanner = cn(styles.banner, {
    [styles[mainTheme]]: mainTheme
  });
  return (
    <div className={cnBanner}>
      {!isHyundaiTheme ? <div className={styles.cover} /> : null}
      <div className={styles.content}>
        <Logo type={isHyundaiTheme ? "logo3" : "logo5"} />
        <Wrapper pt={92}>
          <Text uppercase={!isHyundaiTheme} size={sizeTitleBanner} theme="white">
            Добро пожаловать
          </Text>
          <Text uppercase={!isHyundaiTheme} size={sizeTitleBanner} theme="white">
            {`в ${company}`}
          </Text>
        </Wrapper>
        <Wrapper pt={48}>
          <Text size="main" theme="white">
            {`${company} является вашим личным кабинетом в мире ${companyName} для всех сервисов. Единый аккаунт ${company}
          предоставляет вам возможность редактировать личные и контактные данные, управлять сервисами, способами входа.`}
          </Text>
        </Wrapper>
      </div>
    </div>
  );
};

export default Banner;
