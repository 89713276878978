import React, { FC, useEffect, useState } from "react";

import { connect, ConnectedProps } from "react-redux";
import { STEP_REGISTER } from "constant/step-auth";
import { registerRequestCode, registerSendSmsPhone, updateStepRegister } from "store/auth/actions";
import { StoreTypes } from "store/types";
import { addError, createControl, deleteError, validateForm } from "helpers/form-bulder";
import { getAuthData } from "store/auth/selectors/getAuthDataSelector";
import { getAuthError } from "store/auth/selectors/getAuthErrorSelector";
import { Input, Text, Wrapper, Link } from "components/ui";
import Timer from "components/ui/timer/timer";
import { authLogo, subTitleCode } from "components/ui/helpersComponents";
import { isHyundaiTheme, labelCode, sizeTitleAuth, titleCompanyTheme } from "components/ui/switch-themes";
import { InvisibleSmartCaptcha } from "@yandex/smart-captcha";
/// todo убрать
const formInitialState = {
  code: createControl(
    {
      placeholder: "Введите код",
      value: ""
    },
    {
      required: true
    }
  )
};

const RegisterCode: FC<ReduxProps> = ({ onResend, stepRegister, user, codeRegister: onSubmit, errors }: ReduxProps) => {
  const [form, setForm] = useState({
    isFormValid: false,
    formControls: formInitialState
  });
  const [showTimer, setShowTimer] = useState(false);

  const [validCaptcha, setValidCaptcha] = useState(false);
  const [validCaptchaSuccess, setValidCaptchaSuccess] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const handleReset = () => {
    setValidCaptcha(false);
    setResetCaptcha(prev => prev + 1);
  };

  useEffect(() => {
    if (validCaptchaSuccess) {
      const { username, type, phone } = user;
      if (typeof username === "string" && typeof type === "string" && typeof phone === "string") {
        onResend({ username, type, phone, token: captchaToken });
      }
    }
    handleReset();
    setValidCaptchaSuccess(false);
  }, [validCaptchaSuccess]);

  /**
   * добавление ошибок с сервера
   */

  useEffect(() => {
    if (errors) {
      const formControls = addError<typeof form.formControls>(form.formControls, errors);
      setForm({
        formControls,
        isFormValid: validateForm(formControls)
      });
    }
  }, [errors]);

  useEffect(() => {
    setShowTimer(true);
    const formControls = deleteError<typeof form.formControls>(form.formControls);
    setForm({
      formControls,
      isFormValid: validateForm(formControls)
    });
    return () => setShowTimer(false);
  }, []);

  // EVENT HANDLERS
  const changeHandler = (value: string): void => {
    if (value.length === 4) {
      onSubmit(value);
    }
  };

  return (
    <div className="text-center">
      {authLogo}
      <Wrapper pb={32}>
        <Text uppercase={!isHyundaiTheme} size={sizeTitleAuth} align="center" theme={titleCompanyTheme}>
          {isHyundaiTheme ? null : "Регистрация"}
        </Text>
      </Wrapper>
      {subTitleCode}
      <Wrapper pb={16}>
        <Input
          label={labelCode}
          type="text"
          placeholder={form.formControls.code.placeholder}
          invalid={!form.formControls.code.valid && form.formControls.code.touched}
          errorMessages={form.formControls.code.errorMessage}
          theme="gray"
          onChange={e => changeHandler(e.target.value)}
        />
      </Wrapper>
      <InvisibleSmartCaptcha
        key={resetCaptcha}
        sitekey={process.env.REACT_APP_YANDEX_CAPTCHA_ID ?? ""}
        onSuccess={token => {
          setCaptchaToken(token);
          setValidCaptchaSuccess(true);
        }}
        visible={validCaptcha}
        onChallengeHidden={() => handleReset()}
      />
      <Wrapper pb={32}>
        <Timer setShowTimer={() => setShowTimer(!showTimer)} start={showTimer} onClick={() => setValidCaptcha(true)} />
      </Wrapper>
      <Wrapper pb={40}>
        <Link
          href="/"
          isButton
          type="text"
          weight="regular"
          theme="gray"
          onClick={() => stepRegister(STEP_REGISTER.TELEPHONE)}
          underline
        >
          Назад
        </Link>
      </Wrapper>
    </div>
  );
};
const mapStateToProps = (store: StoreTypes) => ({
  user: getAuthData(store),
  errors: getAuthError(store)
});
const mapDispatchToProps = {
  stepRegister: updateStepRegister,
  codeRegister: registerRequestCode,
  onResend: registerSendSmsPhone
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(RegisterCode);
