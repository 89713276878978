export interface ControlValidateType {
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  email?: boolean;
  correctPhoneOperatorCode?: boolean;
  checked?: boolean;
}

export interface ControlConfigType {
  label?: string;
  type?: "text" | "tel" | "password" | "search";
  errorMessage?: string;
  pattern?: string;
  placeholder?: string;
  value: number | string;
}

export interface ControlType extends ControlConfigType {
  valid: boolean;
  touched: boolean;
  validation: ControlValidateType;
}
export interface FormControls {
  [key: string]: ControlType;
}
/**
 * Функция создание контролла возвращает объект
 * @param config
 * @param validation
 */
export const createControl = (
  config: ControlConfigType = {
    type: "text",
    errorMessage: "",
    value: ""
  },
  validation: ControlValidateType
): ControlType => {
  return {
    ...config,
    validation,
    valid: !validation,
    touched: false
  };
};

export const validate = (value?: number | string | boolean, validation?: ControlValidateType): string => {
  if (!validation) {
    return "";
  }

  let isValid = true;
  let errors = "";

  if (validation.required) {
    isValid = String(value).trim() !== "" && isValid;
    errors = !isValid ? "Необходимо заполнить данные" : errors;
  }

  if (validation.maxLength) {
    isValid = String(value).length <= validation.maxLength && isValid;
    errors = !isValid ? `Символов должно быть не более ${validation.maxLength}` : errors;
  }

  if (validation.minLength) {
    isValid = String(value).length >= validation.minLength && isValid;
    errors = !isValid ? "Пароль должен быть от 6 до 20 символов" : errors;
  }
  if (validation.email && typeof value === "string") {
    const typicalMistakes = "(?!(gmail\\.ru|gmail\\.co|gmail\\.kom)$)";
    const emailRegex = new RegExp(
      `^(([^<>()\\[\\]\\\\.,;:\\s@“]+(\\.[^<>()\\[\\]\\\\.,;:\\s@“]+)*)|(“.+“))@${typicalMistakes}((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$`
    );
    isValid = !!String(value).match(emailRegex) && isValid;
    errors = !isValid ? "Некорректные данные" : errors;
  }
  if (validation.correctPhoneOperatorCode && typeof value === "string") {
    isValid = value[0].search(/[3-6]|8|9/g) === -1 && isValid;
    errors = !isValid ? "Некорректные данные" : errors;
  }
  if (validation.checked) {
    isValid = Boolean(value) && isValid;
    errors = !isValid ? "Поставьте свое согласие" : errors;
  }

  return errors;
};

/**
 * Валидация всей формы возвращает булевого значение
 * @param formControls
 */
export const validateForm = (formControls: FormControls): boolean => {
  let isFormValid = true;

  // eslint-disable-next-line no-restricted-syntax
  for (const control in formControls) {
    // eslint-disable-next-line no-prototype-builtins
    if (formControls.hasOwnProperty(control)) {
      isFormValid = formControls[control].valid && isFormValid;
    }
  }

  return isFormValid;
};

/**
 * Изменение контрола
 * @param formControls
 * @param value
 * @param controlName
 */

export const changeControl = <T extends FormControls>(
  formControls: T,
  value: number | string,
  controlName: keyof T
): T => {
  const control: ControlType = { ...formControls[controlName] };

  control.value = value;
  control.touched = true;
  control.errorMessage = validate(control.value, control.validation);
  control.valid = control.errorMessage?.length === 0;

  return {
    ...formControls,
    [controlName]: control
  };
};

/**
 * Добавление ошибок
 * @param formControls
 * @param errorControl
 */
export const addError = <T extends FormControls>(formControls: T, errorControl: { [key: string]: string }): T => {
  const newFormControls = { ...formControls };
  Object.keys(formControls).forEach(key => {
    if (errorControl[key]) {
      newFormControls[key].errorMessage = errorControl[key];
      newFormControls[key].valid = errorControl[key].length === 0;
      newFormControls[key].touched = true;
    }
  });
  return newFormControls;
};

// Обнуление состояния
export const deleteError = <T extends FormControls>(formControls: T): T => {
  const newFormControls = { ...formControls };
  Object.keys(formControls).forEach(key => {
    newFormControls[key].errorMessage = "";
    newFormControls[key].touched = false;
  });
  return newFormControls;
};

/**
 * Добавление значения в контролл например при монтирование
 * @param formControls
 * @param formControlsValue
 */
export const addValueControl = <T extends FormControls>(
  formControls: T,
  formControlsValue: { [key: string]: string | number }
): T => {
  const newFormControls = { ...formControls };
  Object.keys(formControls).forEach(key => {
    if (formControlsValue[key]) {
      newFormControls[key].value = formControlsValue[key];
      const error = validate(formControlsValue[key], formControls[key].validation);
      newFormControls[key].errorMessage = error;
      newFormControls[key].valid = Boolean(error);
    }
  });
  return newFormControls;
};
