import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { PHONE_MASK } from "constant/phone-mask";
import { useFormik } from "formik";
import { authDataUpdate, authEmailContainsCheck, authRequestLogin, serverError, setVerify } from "store/auth/actions";
import { AUTH_ROUTES_URL } from "constant/routers";
import { formatPhoneNumber } from "helpers/format-phone-number";
import { handlerPaste } from "helpers/handlePastePhone";
import { getAuthServerError } from "store/auth/selectors/getAuthErrorSelector";
import { isHyundaiTheme, sizeTitleAuth, titleCompanyTheme } from "components/ui/switch-themes";
import history from "services/history";
import { Button, Text, Wrapper, Link, Input } from "components/ui";
import AuthSocialNetwork from "components/auth/auth-sso/auth-social-network";
import { authLogo } from "components/ui/helpersComponents";
import { InvisibleSmartCaptcha } from "@yandex/smart-captcha";
import styles from "./sign-in.module.scss";
import Tabs from "../../../ui/tabs";
import { getAuthIsVerify } from "../../../../store/auth/selectors/getAuthIsVerifySelector";
import { isValidEmail } from "../../../../helpers/validate";

const validationSchema = yup.object().shape({
  isPhone: yup.string(),
  phone: yup
    .string()
    .transform(value => value.replace(/[^\d]/g, ""))
    .min(11, "Номер телефона должен состоять из 11 символов")
    .when("isPhone", {
      is: "phone",
      then: yup.string().required("Необходимо заполнить данные"),
      otherwise: yup.string().notRequired()
    }),
  email: yup
    .string()
    .min(1)
    .matches(isValidEmail, "Недопустимый формат email")
    .when("isPhone", {
      is: "email",
      then: yup.string().required("Необходимо заполнить данные"),
      otherwise: yup.string()
    })
});

const SignInLogin: FC = () => {
  const dispatch = useDispatch();

  const [inputTypes, setInputTypes] = useState("phone");
  const [dataPhone, setDataPhone] = useState("");
  const [dataEmail, setDataEmail] = useState("");
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [validCaptchaSuccess, setValidCaptchaSuccess] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const errorServer = useSelector(getAuthServerError);
  const isVerification = useSelector(getAuthIsVerify);

  const formik = useFormik({
    initialValues: {
      isPhone: inputTypes,
      phone: "",
      email: ""
    },
    validationSchema,
    onSubmit: () => {
      setValidCaptcha(true);
    }
  });

  const handleCaptchaReset = () => {
    setValidCaptcha(false);
    setResetCaptcha(prev => prev + 1);
  };

  const handleAuthentication = (formik: any) => {
    if (inputTypes === "phone") {
      formik.setFieldValue("email", "");
      const data = formatPhoneNumber(String(formik.values.phone));
      dispatch(serverError(null));
      dispatch(authRequestLogin({ username: data, type: inputTypes, token: captchaToken }));
    } else if (inputTypes === "email") {
      const data = String(formik.values.email);
      dispatch(serverError(null));
      dispatch(authDataUpdate({ username: data, email: data, type: inputTypes, captchaToken }));
      dispatch(authEmailContainsCheck(data));
    }
  };

  const inputsBlur = (item: string, value: string) => {
    formik.setFieldValue(item, value.trim());
    formik.setTouched({ [item]: true });
  };

  const getKeyDown = (event: any) => {
    if (!isVerification && event.keyCode !== 13) {
      dispatch(setVerify(true));
    }
  };

  useEffect(() => {
    if (validCaptchaSuccess) {
      handleAuthentication(formik);
    }
    handleCaptchaReset();
    setValidCaptchaSuccess(false);
  }, [validCaptchaSuccess]);

  useEffect(() => {
    if (inputTypes === "phone") {
      setDataEmail(formik.values.email);
      formik.setFieldValue("isPhone", "phone");
      formik.setFieldTouched("email", false);
      formik.setFieldValue("email", "");
      if (dataPhone) formik.setFieldValue("phone", dataPhone);
    } else if (inputTypes === "email") {
      setDataPhone(formik.values.phone);
      formik.setFieldValue("isPhone", "email");
      formik.setFieldTouched("phone", false);
      formik.setFieldValue("phone", "");
      if (dataEmail) formik.setFieldValue("email", dataEmail);
    }
    dispatch(setVerify(true));
  }, [inputTypes]);

  useEffect(() => {
    if (errorServer) {
      formik.setFieldError(inputTypes, errorServer);
    }
  }, [errorServer]);

  return (
    <div className="text-center">
      <form onSubmit={formik.handleSubmit}>
        {authLogo}
        <Wrapper pb={55}>
          <Text uppercase={!isHyundaiTheme} size={sizeTitleAuth} align="center" theme={titleCompanyTheme}>
            Авторизация
          </Text>
        </Wrapper>
        <Wrapper pb={50}>
          <Tabs inputTypes={setInputTypes} />
        </Wrapper>
        <Wrapper pb={40}>
          {inputTypes === "email" ? (
            <Input
              name="email"
              placeholder="Введите Ваш email"
              theme="gray"
              onChange={formik.handleChange}
              value={formik.values.email}
              invalid={Boolean(formik.errors.email && formik.touched.email)}
              errorMessages={formik.errors.email && formik.touched.email ? formik.errors.email : null}
              onBlur={(): void => inputsBlur("email", formik.values.email)}
              onKeyDown={getKeyDown}
            />
          ) : (
            <Input
              name="phone"
              type="tel"
              placeholder="Введите Ваш номер телефона"
              mask={PHONE_MASK}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              errorMessages={formik.errors.phone && formik.touched.phone ? formik.errors.phone : null}
              invalid={Boolean(formik.errors.phone && formik.touched.phone)}
              onPaste={(elem: React.ClipboardEvent<HTMLInputElement>): void => handlerPaste(elem, formik.setFieldValue)}
            />
          )}
        </Wrapper>
        <Wrapper pb={48}>
          <Button fullWidth type="submit">
            Продолжить
          </Button>
        </Wrapper>
        <div className={styles.captcha}>
          <InvisibleSmartCaptcha
            key={resetCaptcha}
            sitekey={process.env.REACT_APP_YANDEX_CAPTCHA_ID ?? ""}
            onSuccess={token => {
              setCaptchaToken(token);
              setValidCaptchaSuccess(true);
            }}
            visible={validCaptcha}
            onChallengeHidden={() => handleCaptchaReset()}
          />
        </div>
        <Wrapper pb={50}>
          <Link href="/" size="big" isButton onClick={(): void => history.push(AUTH_ROUTES_URL.REGISTER)}>
            Регистрация
          </Link>
        </Wrapper>
        <Wrapper pb={24}>
          <AuthSocialNetwork />
        </Wrapper>
      </form>
    </div>
  );
};

export default SignInLogin;
