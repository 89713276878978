import React, { FC, useEffect } from "react";
import Modal from "react-modal";
import { alertClose } from "assets/icons";
import { Button, Wrapper } from "components/ui";
import { buttonAddAndRemoveTheme } from "components/ui/switch-themes";
import styles from "./notification-popup.module.scss";

Modal.setAppElement("#root");

export type PropsType = {
  onClick?: () => void;
  isOpen: boolean;
  title: string | undefined;
  action: () => void;
  option?: "one" | "two";
  cancel?: () => void;
};

const NotificationPopup: FC<PropsType> = ({ cancel, option, title, onClick, isOpen, action }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClick} className={styles.modal} overlayClassName={styles.overlay}>
      {onClick && (
        <button type="button" className={styles.closeBtn} onClick={onClick}>
          <img src={alertClose} alt="close" />
        </button>
      )}
      <div className={styles.popupContent}>{title}</div>
      {option === "one" ? (
        <Wrapper>
          <Button theme={buttonAddAndRemoveTheme} onClick={action}>
            ОК
          </Button>
        </Wrapper>
      ) : (
        <div className={styles.wrapBtn}>
          <Wrapper>
            <Button theme={buttonAddAndRemoveTheme} onClick={action}>
              Да
            </Button>
          </Wrapper>
          <Wrapper>
            <Button theme={buttonAddAndRemoveTheme} onClick={cancel}>
              Нет
            </Button>
          </Wrapper>
        </div>
      )}
    </Modal>
  );
};

NotificationPopup.defaultProps = {
  option: "one"
};

export default NotificationPopup;
