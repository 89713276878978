import { DeleteRowsHandlerPayloadType } from "components/admin/delete-rows/delete-rows";
import {
  MarketingMailingsType,
  CreateMarketingMailingType,
  CreateMarketingMailingsPayload,
  MailingsPaginationParams
} from "./types";
import { TYPES_MARKETING_MAILINGS } from "./constants";

export const marketingMailingsLoad = () => ({ type: TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_LOAD });

export const marketingMailingsLoadStop = () => ({
  type: TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_LOAD_STOP
});

export const marketingMailingsSuccess = (mailingsData: MarketingMailingsType) => ({
  type: TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_SUCCESS,
  payload: mailingsData
});

export const marketingMailingsError = (
  error: {
    [key: string]: string;
  } | null
) => ({
  type: TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_ERROR,
  payload: error
});

export const setMarketingMailingsRows = (payload: Array<string | number>) => ({
  type: TYPES_MARKETING_MAILINGS.SET_MARKETING_MAILINGS_ROWS,
  payload
});

export const startRepeatMarketingMailings = () => ({
  type: TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_START_REPEAT
});

export const stopRepeatMarketingMailings = (params: MailingsPaginationParams) => ({
  type: TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_STOP_REPEAT,
  payload: params
});

export const repeatMarketingMailings = (params: MailingsPaginationParams) => ({
  type: TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_REPEAT,
  payload: params
});

export const marketingMailingsRequest = (params: MailingsPaginationParams) => ({
  type: TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_REQUEST,
  payload: params
});

export const createMarketingMailingsRequest = (payload: CreateMarketingMailingsPayload) => ({
  type: TYPES_MARKETING_MAILINGS.CREATE_MARKETING_MAILINGS_REQUEST,
  payload
});

export const deleteMarketingMailingsRequest = (payload: DeleteRowsHandlerPayloadType) => ({
  type: TYPES_MARKETING_MAILINGS.DELETE_MARKETING_MAILINGS_REQUEST,
  payload
});

export const deleteMarketingMailingsSuccess = (payload: string) => ({
  type: TYPES_MARKETING_MAILINGS.DELETE_MARKETING_MAILINGS_SUCCESS,
  payload
});

export const filterMMRows = (payload: number) => ({
  type: TYPES_MARKETING_MAILINGS.FILTER_MM_ROWS,
  payload
});

export const MarketingMailingsStatus = (status: { [key: string]: string } | null) => ({
  type: TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_STATUS,
  payload: status
});

export const modalWindowToggle = (toggle: boolean) => ({
  type: TYPES_MARKETING_MAILINGS.MODAL_TOGGLE_MM,
  payload: toggle
});

export const downloadMarketingMailingsRequest = (campaignId: number | string) => ({
  type: TYPES_MARKETING_MAILINGS.DOWNLOAD_MARKETING_MAILINGS_REQUEST,
  payload: campaignId
});

export const marketingMailingsServicesRequest = () => ({
  type: TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_SERVICES_REQUEST
});

export const marketingMailingsServicesLoad = () => ({
  type: TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_SERVICES_LOAD
});

export const marketingMailingsServicesSuccess = (services: any) => ({
  type: TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_SERVICES_SUCCESS,
  payload: services
});

export const marketingMailingsServicesError = (error: { [key: string]: string }) => ({
  type: TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_SERVICES_ERROR,
  payload: error
});
