import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { StoreTypes } from "store/types";
import { Button } from "components/ui";
import { deleteMarketingConsentRequest, acceptMarketingConsentRequest } from "store/users/actions";
import styles from "./marketing-comm.module.scss";
import PopupConfirm from "../../ui/popup-confirm";

type DispatchProps = {
  deleteMarketConcentAgreement: () => void;
  acceptMarketConcentAgreement: () => void;
};

type Props = {
  isLoading: boolean;
  marketing_consent: null | boolean;
  status: {
    [key: string]: string;
  } | null;
};

type ComponentProps = {
  openMarketingConsentDialog: any;
  isMarketingConsentDialogOpen: boolean;
};

const MarketingComm: FC<Props & ReduxProps & ComponentProps> = ({
  marketing_consent,
  deleteMarketingConsentRequest: deleteAgreement,
  acceptMarketingConsentRequest: acceptAgreement,
  isMarketingConsentDialogOpen,
  openMarketingConsentDialog,
  isLoading,
  status
}) => {
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    if (status?.success) {
      setOpen(false);
    }
  }, [status]);

  useEffect(() => {
    if (isMarketingConsentDialogOpen) {
      openMarketingConsentDialog(false);
      setOpen(true);
    }
  }, [isMarketingConsentDialogOpen]);
  return (
    <div className={styles.deleteAcc}>
      {marketing_consent ? (
        <>
          <Button theme="link" onClick={(): void => setOpen(true)}>
            Отозвать согласие на маркетинговую коммуникацию
          </Button>
          <PopupConfirm
            isOpen={isOpen}
            setOpen={setOpen}
            action={() => deleteAgreement()}
            title="Вы точно хотите отозвать согласие получать рекламные и иные предложения, сообщения от ООО «Хендэ Мотор СНГ»?"
            desc=""
            disabled={isLoading}
          />
        </>
      ) : (
        <>
          <Button theme="link" onClick={(): void => setOpen(true)}>
            Выдать согласие на коммуникацию
          </Button>
          <PopupConfirm
            isOpen={isOpen}
            setOpen={setOpen}
            action={() => acceptAgreement()}
            title="Я согласен получать рекламные и иные предложения, сообщения от ООО «Хендэ Мотор СНГ»"
            desc=""
            disabled={isLoading}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ users: { user, isLoading }, users }: StoreTypes): Props => ({
  marketing_consent: user.marketing_consent,
  isLoading,
  status: users.status
});

const mapDispatchToProps = {
  deleteMarketingConsentRequest,
  acceptMarketingConsentRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(MarketingComm);
