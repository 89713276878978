import React, { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { PersonalData } from "../../../store/services/types";
import { DragVertical } from "./icons";
import styles from "./EditService.module.scss";

type SortableItemProps = {
  personalData: PersonalData;
};

const SortableItem: FC<SortableItemProps> = ({ children, personalData }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: personalData.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div ref={setNodeRef} style={style} className={styles.dragItem}>
      {children}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <button {...attributes} {...listeners} type="button" className={styles.dragBtn}>
        <DragVertical />
      </button>
    </div>
  );
};

export default SortableItem;
