import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { TYPES } from "./constants";
import { LoginHistoryType } from "./types";
import {
  loginHistoryLoad,
  loginHistoryLoadStop,
  loginHistoryRequest,
  loginHistorySuccess,
  loginHistoryError
} from "./actions";
import { User } from "../../services/api/user";

function* loginHistory(action: ReturnType<typeof loginHistoryRequest>): SagaIterator {
  const { payload } = action;
  yield put(loginHistoryLoad());
  try {
    const { login_history }: LoginHistoryType = yield call(User.getLoginHistory, payload);
    const historyToArray = Object.values(login_history).reverse();
    yield put(loginHistorySuccess(historyToArray));
  } catch (e) {
    yield put(loginHistoryError({ loginHistory: "Не удалось загрузить данные" }));
  } finally {
    yield put(loginHistoryLoadStop());
  }
}

function* loginHistorySaga(): SagaIterator {
  yield takeEvery(TYPES.LOGIN_HISTORY_REQUEST, loginHistory);
}

export { loginHistorySaga };
