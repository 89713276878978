import React, { FC, ReactElement, SyntheticEvent, useEffect, useState } from "react";
import cn from "classnames";
import Autosuggest, { ChangeEvent, SuggestionSelectedEventData } from "react-autosuggest";
import { useFormikContext } from "formik";

import { Input } from "components/ui";
import { guardsAutoSuggest, onSelectTypes } from "components/admin/personal-info/helpers-autosugest";
import { TeamsType } from "store/employees/types";
import { useDidUpdate } from "helpers/useDidUpdate";
import styles from "./autosuggest.module.scss";

type PropsType = {
  values?: TeamsType[];
  clear?: boolean;
  autoSuggestOnSelected?: ({ ...args }: onSelectTypes) => void;
  label?: string;
  placeholder?: string;
  invalid?: boolean;
  errorMessages?: string | null;
  initialValues?: string;
  reset?: boolean;
  highlightFirstSuggestion?: boolean;
  fieldName: "position_id" | "teams" | "search" | "department_id";
  suggestionOnClick?: (id: string) => void;
  getSuggestion: (value: string, setSuggestion: (data: Array<{ id: string; data: string }>) => void) => Promise<any>;
  switchPage?: string;
  autoComplete?: string;
  type?: string;
};
export type SuggestionType = {
  id: number | string;
  data: string;
};

const AutoSuggest: FC<PropsType> = ({
  suggestionOnClick,
  values,
  clear,
  autoSuggestOnSelected,
  errorMessages,
  invalid,
  label,
  placeholder,
  initialValues,
  reset,
  fieldName,
  getSuggestion,
  switchPage,
  autoComplete = "on"
}) => {
  const [suggestions, setSuggestions] = useState<[] | Array<SuggestionType>>([]);
  const [currentValue, setCurrentValue] = useState<string>("");
  const { setFieldError, setFieldValue, setFieldTouched } = useFormikContext();

  useDidUpdate(() => {
    setFieldValue(fieldName, "");
    setCurrentValue("");
    setSuggestions([]);
  }, [switchPage]);

  useEffect(() => {
    if (clear) {
      if (fieldName === "teams") {
        setFieldValue(fieldName, []);
        setCurrentValue("");
      } else {
        setFieldValue(fieldName, "");
        setCurrentValue("");
      }
    }
  }, [clear]);

  /// Действия при перезагрузке
  useEffect(() => {
    setCurrentValue(initialValues || "");
  }, [reset, initialValues]);

  const renderInputComponent = (props: any): ReactElement => <Input {...props} />;

  const getSuggestionValue = (value: SuggestionType): any => {
    return value;
  };

  /// Выпадоющий список
  const renderSuggestion = ({ data }: SuggestionType): ReactElement => {
    return <span>{data}</span>;
  };

  const onChange = (event: SyntheticEvent, { newValue }: ChangeEvent) => {
    if (guardsAutoSuggest(newValue)) {
      setCurrentValue(newValue.data);
    } else if (!newValue) {
      if (fieldName === "teams") {
        setCurrentValue("");
      } else {
        setFieldValue(fieldName, "");
        setCurrentValue("");
      }
    } else if (suggestionOnClick) {
      setFieldValue(fieldName, newValue);
      setCurrentValue(newValue);
    } else {
      setCurrentValue(newValue);
      if (fieldName === "department_id") setFieldValue("department", newValue);
    }
  };

  const onSuggestionSelected = (
    event: SyntheticEvent,
    { suggestionValue }: SuggestionSelectedEventData<SuggestionType>
  ) => {
    event.preventDefault();
    if (guardsAutoSuggest(suggestionValue) && autoSuggestOnSelected) {
      if (values) {
        autoSuggestOnSelected({ fieldName, setFieldValue, setFieldError, suggestionValue, values, setCurrentValue });
      } else {
        autoSuggestOnSelected({ suggestionValue, setFieldValue, setCurrentValue, fieldName });
      }
    }
    if (guardsAutoSuggest(suggestionValue) && suggestionOnClick) {
      suggestionOnClick(String(suggestionValue.id));
    }
  };

  const onSuggestionsClearRequested = (): void => {
    setSuggestions([]);
  };

  const onBlur = (): void => {
    setFieldTouched(fieldName, true);
  };

  // Передаем данные в инпут
  const inputProps = {
    name: fieldName,
    value: currentValue,
    autocomplete: autoComplete,
    onChange,
    onBlur,
    theme: "white",
    errorMessages,
    invalid,
    label,
    placeholder
  };

  const suggestionsContainerClass = cn({
    [styles.suggestionsContainer]: true,
    [styles.suggestionsContainerOpen]: suggestions.length > 0
  });
  return (
    <Autosuggest
      /// local state
      suggestions={suggestions}
      /// что передаем в инпут
      inputProps={inputProps}
      /// делаем запрос
      onSuggestionsFetchRequested={({ value }): void => {
        getSuggestion(value, setSuggestions);
      }}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      focusInputOnSuggestionClick
      onSuggestionSelected={onSuggestionSelected}
      highlightFirstSuggestion={false}
      renderInputComponent={renderInputComponent}
      theme={{
        container: styles.container,
        suggestionsContainer: suggestionsContainerClass,
        suggestionsList: styles.suggestionsList,
        suggestion: styles.suggestion,
        suggestionHighlighted: styles.suggestionHighlighted
      }}
    />
  );
};
export default AutoSuggest;

// DealersAutoSuggest.defaultProps = {
//   highlightFirstSuggestion: false
// };
