import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AUTH_ROUTES_URL } from "constant/routers";
import { StoreTypes } from "store/types";
import {
  authDataUpdate,
  authRegisterSSO,
  authSSO,
  isPhoneRequiredSSO,
  openAuthUpdateUrl,
  regSSO
} from "store/auth/actions";
import { notificationErrors } from "components/ui/notification/notification-errors";
import { getDataUserSSO } from "store/auth/selectors/getSSOdataUserSelector";
import { Button, Checkbox, Logo, Popup, Text, Wrapper } from "../../ui";
import Layout from "../layout";
import { Loading } from "../../ui/loading";
import history from "../../../services/history";
import Accept from "../accept";
import styles from "./sso.module.scss";
import { company, isHyundaiTheme } from "../../ui/switch-themes";

const SocialNetwork: FC<ReduxProps> = ({ onSubmit, onRegistration, isLoading, getDataSSO }) => {
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);
  const [isMMAccepted, setIsMMAccepted] = useState(false);

  useEffect(() => {
    const dataUser = getDataSSO;
    if (dataUser?.access_token && dataUser?.refresh_token) {
      const { access_token: accessToken, refresh_token: refreshToken } = dataUser;
      onSubmit({ access_token: String(accessToken), refresh_token: String(refreshToken) });
    } else if (dataUser?.social_id) {
      setIsWelcomeModalOpen(true);
    } else if (dataUser?.message) {
      const { message } = dataUser;
      notificationErrors({ message: String(message || " ") });
      history.push(AUTH_ROUTES_URL.SIGN_IN);
    } else if (dataUser?.error) {
      notificationErrors({});
      history.push(AUTH_ROUTES_URL.SIGN_IN);
    }
  }, [getDataSSO]);

  const registrationSocialNetwork = () => {
    if (isMMAccepted) {
      onRegistration(getDataSSO?.social_id);
    } else {
      history.push(AUTH_ROUTES_URL.SIGN_IN);
    }
  };

  const closeWelcomeModal = () => {
    history.push(AUTH_ROUTES_URL.SIGN_IN);
  };

  return (
    <Layout>
      <Loading isLoading={isLoading}>
        <div className="text-center">
          <Wrapper pb={32}>
            <Logo type={isHyundaiTheme ? "logo2" : "logo5"} />
          </Wrapper>
          <Wrapper pb={48}>
            <Text size="h2" align="center" theme="black">
              Авторизация
            </Text>
          </Wrapper>
          <Wrapper pb={32}>
            <Text size="h4" align="center" theme="black">
              Пожалуйста подождите....
            </Text>
          </Wrapper>
          <Popup isOpen={isWelcomeModalOpen} onClick={() => closeWelcomeModal()}>
            <div className={styles.container}>
              <div className={styles.row}>
                <div className={styles.titlePopup}>{`Добро пожаловать в ${company}`}</div>
              </div>
              <div className={styles.popupContent}>
                <Checkbox
                  checked={isMMAccepted}
                  name="checked"
                  style={{ marginTop: "32px", marginLeft: "32px" }}
                  onChange={() => setIsMMAccepted(!isMMAccepted)}
                />
                <Accept color="black" />
              </div>
              <div className={styles.submitBtnContainer}>
                <Button disabled={!isMMAccepted} onClick={() => registrationSocialNetwork()}>
                  Подтвердить
                </Button>
              </div>
            </div>
          </Popup>
          <Wrapper>
            <Button fullWidth onClick={(): void => history.push(AUTH_ROUTES_URL.SIGN_IN)}>
              На главную
            </Button>
          </Wrapper>
        </div>
      </Loading>
    </Layout>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  isLoading: store.auth.isLoading,
  getDataSSO: getDataUserSSO(store)
});

const mapDispatchToProps = {
  onSubmit: authSSO,
  onRegistration: regSSO,
  recordDataUser: authDataUpdate,
  isPhoneUpdate: isPhoneRequiredSSO,
  isRegisterSSO: authRegisterSSO,
  recordRedirectUrl: openAuthUpdateUrl
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SocialNetwork);
