export const TYPES_THIRD_PARTY = {
  THIRD_PARTY_INFO_REQUEST: "THIRD_PARTY_INFO_REQUEST",
  THIRD_PARTY_INFO_SUCCESS: "THIRD_PARTY_INFO_SUCCESS",
  THIRD_PARTY_INFO_LOAD_STOP: "THIRD_PARTY_INFO_LOAD_STOP",
  THIRD_PARTY_INFO_CLEAR: "THIRD_PARTY_INFO_CLEAR",

  SET_THIRD_PARTY_EDIT_MODE: "SET_THIRD_PARTY_EDIT_MODE",

  THIRD_PARTY_CREATE_REQUEST: "THIRD_PARTY_CREATE_REQUEST",
  THIRD_PARTY_CREATE_SUCCESS: "THIRD_PARTY_CREATE_SUCCESS",
  THIRD_PARTY_CREATE_ERROR: "THIRD_PARTY_CREATE_ERROR",

  THIRD_PARTY_UPDATE_REQUEST: "THIRD_PARTY_UPDATE_REQUEST",
  THIRD_PARTY_UPDATE_SUCCESS: "THIRD_PARTY_UPDATE_SUCCESS",
  THIRD_PARTY_UPDATE_ERROR: "THIRD_PARTY_UPDATE_ERROR",

  MODAL_OPEN_THIRD_PARTY: "MODAL_OPEN_THIRD_PARTY",

  SERVER_ERROR_THIRD_PARTY: "SERVER_ERROR_THIRD_PARTY"
} as const;
