import React, { FC, useState } from "react";
import { connect } from "react-redux";
import { AddedDealersEmployee, EmployeeType } from "constant/radiobutton-values";
import { getEmployeesFilter } from "store/dealers/actions";
import EmployeeBase from "components/admin/added-dealership/employee-base";
import EmployeeDealership from "components/admin/added-dealership/employee-dealership";
import { GetEmployeesParams } from "store/employees/types";
import { StoreTypes } from "store/types";
import { RadioButton, Wrapper } from "../../../ui";
import styles from "./added-form-panel.module.scss";

interface StateProps {
  editing: boolean;
}

type DispatchProps = {
  getEmployeesFilter: (arg: GetEmployeesParams) => void;
};

const AddedFormPanel: FC<DispatchProps & StateProps> = ({ getEmployeesFilter: onRequest }) => {
  const [employeeType, setEmployeeType] = useState(EmployeeType.all);
  const [addedEmployeeType, setAddedEmployeeType] = useState(AddedDealersEmployee.employeesDC);

  return (
    <Wrapper className={styles.container} pl={40} pt={40} pr={32}>
      <h3 className={styles.title}>Добавление сотрудников ДЦ</h3>
      <div className={styles.wrapper}>
        <div className={styles.panel}>
          <RadioButton
            size="xs"
            onChange={(value: any): void => setAddedEmployeeType(value)}
            noBorder
            initialSelect={AddedDealersEmployee.employeesDC}
            options={[
              { value: AddedDealersEmployee.employeesDC, text: "Сотрудники ДЦ" },
              { value: AddedDealersEmployee.baseEmployees, text: "База сотрудников" }
            ]}
          />
        </div>
        <div className={styles.panel}>
          <RadioButton
            size="xs"
            noBorder
            onChange={(value: any): void => setEmployeeType(value)}
            initialSelect={EmployeeType.all}
            options={[
              { value: EmployeeType.all, text: "Весь персонал" },
              { value: EmployeeType.admins, text: "Администраторы" },
              { value: EmployeeType.employees, text: "Сотрудники" }
            ]}
          />
        </div>
      </div>
      {addedEmployeeType === AddedDealersEmployee.baseEmployees ? (
        <EmployeeBase employeeType={employeeType} />
      ) : (
        <EmployeeDealership employeeType={employeeType} />
      )}
    </Wrapper>
  );
};

const mapStateToProps = ({ dealers }: StoreTypes) => ({
  editing: dealers.edit
});
const mapDispatchToProps = {
  getEmployeesFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(AddedFormPanel);
