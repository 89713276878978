import React, { FC } from "react";
import styles from "components/lk/footer-gns/footer-gns.module.scss";
import { Social } from "components/ui/social";
import { Link } from "components/ui";
import { headerLogo as Logo } from "components/ui/helpersComponents";

const FooterGns: FC = () => {
  const dataSocial: { [key: string]: string } = {
    fb: "https://www.facebook.com/genesisrussia/",
    youtube: "https://www.youtube.com/channel/UCVuL0DbEUznYLbGWxxsUlBA",
    instagram: "https://www.instagram.com/genesisrussia/"
    // Отстутсвуют ссылки на соц сети
    // vk: "link",
    // twitter: "link",
  };
  const handleClick = (item: string): void => {
    window.open(dataSocial[item], "_blank");
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        {/* <div className={styles.wrap}> */}
        {/*  <div className={styles.social}> */}
        {/*    <div className={styles.links}> */}
        {/*      <span>Поделиться</span> */}
        {/*      {Object.keys(dataSocial).map((item: string) => { */}
        {/*        return <Social key={Math.random()} icon={item} onClick={(): void => handleClick(item)} />; */}
        {/*      })} */}
        {/*    </div> */}
        {/*    <div> */}
        {/*      <button className={styles.btn} type="button"> */}
        {/*        Наверх */}
        {/*      </button> */}
        {/*      <img src={arrow} alt="arrow" /> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </div> */}
        <div className={styles.desc}>
          <h6>Юридическая информация</h6>
          <p>
            Вся представленная на сайте информация, касающаяся автомобилей и сервисного обслуживания, носит
            информационный характер и не является публичной офертой, определяемой положениями ст. 437 (2) ГК РФ. Все
            цены, сертификаты и специальные предложения, указанные на данном сайте носят информационный характер и
            являются максимально рекомендуемыми розничными ценами по расчетам дистрибьютора (ООО «Хендэ Мотор СНГ»). Для
            получения подробной информации просьба обращаться к ближайшему официальному дилеру ООО «Хендэ Мотор СНГ».
            Опубликованная на данном сайте информация может быть изменена в любое время без предварительного
            уведомления.
          </p>
        </div>
        <div className={styles.connections}>
          <div>
            <div className={styles.connection}>
              <Link theme="uppercase" size="small" target="_blank" href="/doc">
                Инструкция пользователя
              </Link>
            </div>
            <div className={styles.connection}>
              <Link theme="uppercase" size="small" target="_blank" href="mailto:hid@hyundai.ru">
                Служба поддержки
              </Link>
            </div>
            <div className={styles.connection}>
              <Link theme="uppercase" size="small" target="_blank" href="https://www.hyundai.ru/Legal">
                Правовые документы
              </Link>
            </div>
            <div className={styles.connection}>
              <Link theme="uppercase" target="_blank" size="small" href="https://www.hyundai.ru/Privacy">
                Политика конфиденциальности
              </Link>
            </div>
          </div>
          <div className={styles.network}>
            {Object.keys(dataSocial).map((item: string) => {
              return <Social key={Math.random()} icon={item} onClick={(): void => handleClick(item)} />;
            })}
          </div>
        </div>
        <div className={styles.logo}>
          {Logo}
          <div>© Copyright 2020 Hyundai Motor Company. All Rights Reserved</div>
        </div>
      </div>
    </footer>
  );
};

export default FooterGns;
