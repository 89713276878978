import { TYPES_PD } from "./constants";
import { CreatePersonalData } from "./types";
import { PersonalDataType } from "../personal-data-list/types";

export const createPersonalDataRequest = (data: CreatePersonalData) => ({
  type: TYPES_PD.PERSONAL_DATA_CREATE_REQUEST,
  payload: data
});

export const createPersonalDataSuccess = (data: PersonalDataType) => ({
  type: TYPES_PD.PERSONAL_DATA_CREATE_SUCCESS,
  payload: data
});

export const createPersonalDataError = () => ({
  type: TYPES_PD.PERSONAL_DATA_CREATE_ERROR
});

export const updatePersonalDataRequest = (data: CreatePersonalData) => ({
  type: TYPES_PD.PERSONAL_DATA_UPDATE_REQUEST,
  payload: data
});

export const updatePersonalDataSuccess = (data: PersonalDataType) => ({
  type: TYPES_PD.PERSONAL_DATA_UPDATE_SUCCESS,
  payload: data
});

export const updatePersonalDataError = () => ({
  type: TYPES_PD.PERSONAL_DATA_UPDATE_ERROR
});

export const personalDataInfoRequest = (id: string) => ({
  type: TYPES_PD.PERSONAL_DATA_INFO_REQUEST,
  payload: id
});

export const personalDataInfoSuccess = (data: PersonalDataType) => ({
  type: TYPES_PD.PERSONAL_DATA_INFO_SUCCESS,
  payload: data
});

export const personalDataInfoLoadStop = () => ({
  type: TYPES_PD.PERSONAL_DATA_INFO_LOAD_STOP
});

export const personalDataInfoClear = () => ({
  type: TYPES_PD.PERSONAL_DATA_INFO_CLEAR
});

export const setPersonalDataEditMode = () => ({
  type: TYPES_PD.SET_PERSONAL_DATA_EDIT_MODE
});

export const serverErrorPersonalData = (error: string) => ({
  type: TYPES_PD.SERVER_ERROR_PERSONAL_DATA,
  payload: error
});

export const setOpenPersonalDataModalWindow = (toggle: boolean) => ({
  type: TYPES_PD.MODAL_OPEN_PERSONAL_DATA,
  payload: toggle
});
