import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AUTH_ROUTES_URL } from "constant/routers";
import { forgottenStep } from "store/auth/actions";
import { STEP_FORGOTTEN } from "constant/step-auth";
import history from "services/history";
import { Button, Text, Wrapper } from "components/ui";
import { authLogo } from "components/ui/helpersComponents";
import { isHyundaiTheme, sizeTitleAuth, titleCompanyTheme } from "components/ui/switch-themes";

const ForgottenEmail: FC<ReduxProps> = ({ onStep }) => {
  useEffect(() => {
    return () => {
      onStep(STEP_FORGOTTEN.TELEPHONE);
    };
  });
  return (
    <div className="text-center">
      {authLogo}
      <Wrapper pb={48}>
        <Text uppercase={!isHyundaiTheme} size={sizeTitleAuth} align="center" theme={titleCompanyTheme}>
          Восстановление пароля
        </Text>
      </Wrapper>
      <Wrapper pb={24}>
        <Text size="main" align="left" theme={titleCompanyTheme}>
          На указанный вами адрес электронной почты отправлено письмо, содержащее ссылку. Пройдите по ссылке, чтобы
          завершить восстановление пароля.
        </Text>
      </Wrapper>
      <Wrapper pt={20}>
        <Button fullWidth onClick={() => history.push(AUTH_ROUTES_URL.SIGN_IN)}>
          На главную
        </Button>
      </Wrapper>
    </div>
  );
};

const mapDispatchToProps = {
  onStep: forgottenStep
};

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ForgottenEmail);
