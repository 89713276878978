import axios from "axios";
import { ThirdPartiesListType, GetThirdPartiesListParams } from "../../store/third-parties-list/types";
import { CreateThirdParty, ThirdPartyType } from "../../store/third-party/types";

export const ThirdPartiesApi = {
  getThirdPartiesList: (params?: Partial<GetThirdPartiesListParams>): Promise<ThirdPartiesListType> =>
    axios.get("/front/third-parties", { params }),
  deleteThirdParty: (id: number): Promise<void> => axios.delete(`/front/third-parties/${id}`),
  getThirdPartyById: (id: number | string): Promise<ThirdPartyType> => axios.get(`/front/third-parties/${id}`),
  createThirdPartiesEntity: (body: CreateThirdParty): Promise<ThirdPartyType> =>
    axios.post("/front/third-parties", body),
  updateThirdPartiesEntity: (id: string, body: Partial<CreateThirdParty>): Promise<any> =>
    axios.put(`/front/third-parties/${id}`, body)
};
