import React, { FC } from "react";
import Row from "components/ui/row";
import { Button, Text, Wrapper } from "components/ui";
import { connect, ConnectedProps } from "react-redux";
import { usersRequest } from "store/users/actions";
import history from "../../services/history";

type Props = {
  message?: string;
};

const ErrorPage: FC<Props & ReduxProps> = ({ userInfo, message }) => {
  const reLoad = () => {
    history.push("/home");
    window.location.reload();
  };
  return (
    <div>
      <Wrapper pt={60}>
        <Row justifyContent="justifyCenter" alignItems="alignCenter">
          <Text size="h2">{message}</Text>
        </Row>
        <Row justifyContent="justifyCenter" alignItems="alignCenter">
          <Wrapper pt={15}>
            <Button onClick={reLoad}>Перезагрузить</Button>
          </Wrapper>
        </Row>
      </Wrapper>
    </div>
  );
};

const mapDispatchToProps = {
  userInfo: usersRequest
};

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ErrorPage);

ErrorPage.defaultProps = {
  message: "При загрузке данных произошла ошибка"
};
