import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { ThirdPartiesApi } from "services/api/third-parties";
import { TYPES_THIRD_PARTIES } from "./constants";
import {
  thirdPartiesError,
  thirdPartiesSuccess,
  thirdPartiesLoad,
  thirdPartiesLoadStop,
  thirdPartyByIdSuccess,
  deleteThirdPartySuccess,
  filterThirdPartiesRows,
  deleteThirdPartyError,
  thirdPartiesStatus,
  thirdPartiesRequest,
  deleteThirdPartyRequest,
  thirdPartyByIdRequest,
  clearThirdPartiesRows
} from "./actions";

function* getThirdPartiesList(actions: ReturnType<typeof thirdPartiesRequest>): SagaIterator {
  const { payload } = actions;
  yield put(thirdPartiesLoad());
  try {
    const response = yield call(ThirdPartiesApi.getThirdPartiesList, payload);

    yield put(thirdPartiesSuccess(response));
  } catch (e) {
    const result = { total: 0, per_page: 10, current_page: 1, last_page: 1, data: [] };
    yield put(thirdPartiesSuccess(result));
    yield put(thirdPartiesError({ thirdParties: "Не удалось загрузить данные" }));
  } finally {
    yield put(thirdPartiesLoadStop());
  }
}

function* deleteThirdParty(actions: ReturnType<typeof deleteThirdPartyRequest>): SagaIterator {
  const {
    payload: { id }
  } = actions;
  yield put(thirdPartiesLoad());
  try {
    yield call(ThirdPartiesApi.deleteThirdParty, id);
    yield put(filterThirdPartiesRows(id));
    yield put(deleteThirdPartySuccess(id));
    yield put(thirdPartiesStatus({ thirdParty: "Успешно" }));
  } catch (e) {
    if (e.status === 400) {
      yield put(deleteThirdPartyError({ deleteThirdParty: "Можно удалить только пустое третье лицо" }));
      yield put(clearThirdPartiesRows());
    } else {
      yield put(deleteThirdPartyError({ deleteThirdParty: "Не удалось удалить третье лицо" }));
    }
  } finally {
    yield put(thirdPartiesLoadStop());
  }
}

function* getThirdPartyById(actions: ReturnType<typeof thirdPartyByIdRequest>): SagaIterator {
  const { payload } = actions;
  yield put(thirdPartiesLoad());
  try {
    const response = yield call(ThirdPartiesApi.getThirdPartyById, payload);
    yield put(thirdPartyByIdSuccess(response));
  } catch (e) {
    yield put(thirdPartiesError({ thirdPartyById: "Не удалось загрузить данные" }));
  } finally {
    yield put(thirdPartiesLoadStop());
  }
}

function* thirdPartiesListSaga(): SagaIterator {
  yield takeLatest(TYPES_THIRD_PARTIES.THIRD_PARTIES_REQUEST, getThirdPartiesList);
  yield takeEvery(TYPES_THIRD_PARTIES.DELETE_THIRD_PARTY_REQUEST, deleteThirdParty);
  yield takeEvery(TYPES_THIRD_PARTIES.THIRD_PARTY_BY_ID_REQUEST, getThirdPartyById);
}

export { thirdPartiesListSaga };
