import React, { FC } from "react";
import cn from "classnames";
import styles from "./avatar.module.scss";
import defaultAvatar from "./images/default-avatar.svg";

interface Size {
  small: string;
  normal: string;
}

export type PropsType = {
  src: string | null | undefined;
  size?: keyof Size;
};

export const Avatar: FC<PropsType> = ({ src, size = "normal" }: PropsType) => {
  const currentSrc = src || defaultAvatar;
  const cl = cn(styles.avatar, { [styles[size]]: size });
  return <img src={currentSrc} className={cl} alt="avatar" />;
};

Avatar.defaultProps = {
  size: "normal"
};
