import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { StoreTypes } from "store/types";
import { authAddedPhoneCode, authDataUpdate, authResetAll, registerFormUser, serverError } from "store/auth/actions";

import {
  isValidEmail,
  isValidFirstAndLastChar,
  isValidName,
  isValidPassword,
  isValidPasswordSpace
} from "helpers/validate";
import history from "services/history";
import { AUTH_ROUTES_URL } from "constant/routers";
import { getSSO } from "store/auth/selectors/getSSOSelector";
import { getAuthServerError } from "store/auth/selectors/getAuthErrorSelector";
import { getAuthData } from "store/auth/selectors/getAuthDataSelector";
import { Button, Input, Link, Text, Wrapper } from "components/ui";
import { authLogo } from "components/ui/helpersComponents";
import {
  isHyundaiTheme,
  labelEmail,
  labelName,
  labelPassword,
  labelSurname,
  sizeTitleAuth,
  titleCompanyTheme
} from "components/ui/switch-themes";
import { getAuthUpdateUser } from "store/auth/selectors/getAuthUpdateUserSelector";
import { updateUserRequest } from "store/users/actions";
import { UpdateType } from "store/users/constants";
import { getUserData } from "store/users/selectors/getDataUserSelector";

type initValues = {
  name: string;
  lastname: string;
  email: string;
  password: string;
  phone: string;
  code: string;
  checked: boolean;
};

const validationSchema = (isRegister: boolean) => {
  const isRequiredPassword = isRegister ? "notRequired" : "required";

  return yup.object().shape({
    name: yup
      .string()
      .test("name", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
      .matches(isValidName, "Используйте кириллицу или латиницу")
      .min(1, "Используйте от 1 до 20 символов")
      .max(20, "Используйте от 1 до 20 символов")
      .required("Поле обязательно для заполнения"),
    lastname: yup
      .string()
      .test("lastname", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
      .matches(isValidName, "Используйте кириллицу или латиницу")
      .min(1, "Используйте от 1 до 50 символов")
      .max(50, "Используйте от 1 до 50 символов")
      .required("Поле обязательно для заполнения"),
    email: yup
      .string()
      .min(1)
      .matches(isValidEmail, "Недопустимый формат email")
      .required("Поле обязательно для заполнения"),
    password: yup
      .string()
      .matches(isValidPassword, "Некорректные данные")
      .matches(isValidPasswordSpace, "Не используйте несколько пробелов подряд")
      .min(6, "Пароль должен быть от 6 до 20 символов")
      .max(20, "Пароль должен быть от 6 до 20 символов")
      [isRequiredPassword]("Поле обязательно для заполнения")
  });
};

const Register: FC<ReduxProps> = ({
  authReset,
  warning,
  errorServer,
  onPush,
  isRegisterSSO,
  dataUpdate,
  updateProfile,
  dataUser,
  isUpdateUser,
  updateUser,
  userId
}: ReduxProps) => {
  const nextStep = (data: any): void => {
    dataUpdate({ ...data });
    warning(null);
    if (isRegisterSSO) {
      updateProfile(null);
    } else if (isUpdateUser && userId) {
      const body = {
        name: data.name,
        lastname: data.lastname,
        email: data.email,
        id: userId
      };
      updateUser({ body, updateType: UpdateType.UpdateMainUser });
    } else {
      onPush(data);
    }
  };

  const initValues: Partial<initValues> = (function() {
    const { name, lastname, email } = dataUser;
    if (isRegisterSSO || isUpdateUser) {
      return {
        name: name || "",
        lastname: lastname || "",
        email: email || ""
      };
    }
    return {
      name: "",
      lastname: "",
      email: "",
      password: ""
    };
  })();

  const formik = useFormik({
    initialValues: initValues,
    validateOnChange: true,
    validationSchema: validationSchema(isRegisterSSO || isUpdateUser),
    onSubmit: values => {
      nextStep(values);
    }
  });

  useEffect(() => {
    if (errorServer) {
      formik.setFieldError("email", errorServer);
    }
  }, [errorServer]);

  const inputsFocus = (field: string): void => {
    formik.setFieldTouched(field, true);
  };

  useEffect(() => {
    if (isRegisterSSO || isUpdateUser) {
      Object.keys(initValues).forEach(key => {
        formik.setFieldTouched(key, true);
      });
    }
  }, [isRegisterSSO, isUpdateUser]);

  const redirectAuth = () => {
    authReset();
    history.push(AUTH_ROUTES_URL.SIGN_IN);
  };

  const disabledButton = Object.values(formik.values).includes("") || !!Object.keys(formik.errors).length;

  return (
    <div className="text-center">
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        {authLogo}
        <Wrapper pb={60}>
          <Text size={sizeTitleAuth} uppercase={!isHyundaiTheme} align="center" theme={titleCompanyTheme}>
            Регистрация
          </Text>
        </Wrapper>
        <Wrapper pb={32}>
          <Input
            label={labelName}
            name="name"
            placeholder="Имя"
            onChange={formik.handleChange}
            onFocus={(): void => inputsFocus("name")}
            value={formik.values.name}
            invalid={Boolean(formik.errors.name && formik.touched.name)}
            errorMessages={formik.errors.name && formik.touched.name ? formik.errors.name : null}
            onBlur={() => formik.setFieldValue("name", formik.values.name?.trim())}
          />
        </Wrapper>
        <Wrapper pb={32}>
          <Input
            label={labelSurname}
            name="lastname"
            placeholder="Фамилия"
            onChange={formik.handleChange}
            onFocus={(): void => inputsFocus("lastname")}
            value={formik.values.lastname}
            invalid={Boolean(formik.errors.lastname && formik.touched.lastname)}
            errorMessages={formik.errors.lastname && formik.touched.lastname ? formik.errors.lastname : null}
            onBlur={() => formik.setFieldValue("lastname", formik.values.lastname?.trim())}
          />
        </Wrapper>
        <Wrapper pb={32}>
          <Input
            label={labelEmail}
            onChange={formik.handleChange}
            name="email"
            placeholder="Email"
            value={formik.values.email}
            invalid={Boolean(formik.errors.email && formik.touched.email)}
            errorMessages={formik.errors.email && formik.touched.email ? formik.errors.email : null}
            onFocus={(): void => inputsFocus("email")}
            onBlur={() => formik.setFieldValue("email", formik.values.email?.trim())}
          />
        </Wrapper>
        {isRegisterSSO || isUpdateUser ? null : (
          <Wrapper>
            <Input
              label={labelPassword}
              name="password"
              type="password"
              placeholder="Пароль"
              onFocus={(): void => inputsFocus("password")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              invalid={Boolean(formik.errors.password && formik.touched.password)}
              errorMessages={formik.errors.password && formik.touched.password ? formik.errors.password : null}
            />
          </Wrapper>
        )}
        <Wrapper pt={36} pb={24}>
          <Button type="submit" fullWidth disabled={disabledButton}>
            {isRegisterSSO ? "Продолжить" : "Завершить регистрацию"}
          </Button>
        </Wrapper>
        <Wrapper pb={48}>
          <Link href="/" size="big" isButton onClick={redirectAuth}>
            Есть аккаунт? Войти
          </Link>
        </Wrapper>
      </form>
    </div>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  errorServer: getAuthServerError(store),
  dataUser: getAuthData(store),
  isRegisterSSO: getSSO(store).registerViaSSO,
  isUpdateUser: getAuthUpdateUser(store),
  userId: getUserData(store).id
});
const mapDispatchToProps = {
  onPush: registerFormUser,
  warning: serverError,
  dataUpdate: authDataUpdate,
  updateProfile: authAddedPhoneCode,
  authReset: authResetAll,
  updateUser: updateUserRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Register);
