import { ActionsType, Store } from "./types";
import { TYPES_PERSONAL_DATA_DELETE_ORDERS } from "./constants";
import { RESET_ERROR_AND_STATUS } from "../constants";

const initialState: Store = {
  isLoading: false,
  error: null,
  data: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
    data: []
  },
  services: []
};

export const deletePDOrdersReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_REQUEST: {
      return { ...state, isLoading: true, error: null, filters: action.payload.filters };
    }
    case TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload };
    }
    case TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_ERROR: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_SERVICES_REQUEST: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_SERVICES_SUCCESS: {
      return { ...state, isLoading: false, services: action.payload };
    }
    case TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_SERVICES_ERROR: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case TYPES_PERSONAL_DATA_DELETE_ORDERS.RESTORE_PERSONAL_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.map(order => {
            return order.id === action.payload ? { ...order, restored: true } : order;
          })
        }
      };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null };
    }
    default: {
      return state;
    }
  }
};
