import { useEffect, useRef } from "react";

/**
 *  useDidUpdate hooks
 *
 *  Fires a callback on component update
 *  Can take in a list of conditions to fire callback when one of the
 *  conditions changes
 *
 * @param {Function} callback The callback to be called on update
 * @param {Array} dependencies The list of variables which trigger update when they are changed
 * @returns {undefined}
 */

function useDidUpdate(callback: () => any, dependencies: any[]): void {
  const hasMountedRef = useRef(false);

  if (dependencies && !Array.isArray(dependencies)) {
    // eslint-disable-next-line no-param-reassign
    dependencies = [dependencies];
  }

  useEffect(() => {
    if (hasMountedRef.current) {
      callback();
    } else {
      hasMountedRef.current = true;
    }
  }, dependencies);
}

export { useDidUpdate };
