import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { resetErrorAndStatus } from "store/actions";
import AddedLegalForm from "components/admin/added-legal/added-legal-form";
import { StoreTypes } from "store/types";
import { legalEntityInfoRequest } from "store/legalEntity/actions";

type Props = {
  children?: React.ReactElement;
  isEdit?: boolean;
};

const AddedLegalEntity: FC<ReduxProps & Props> = ({ resetErrorsAndStatus, children }) => {
  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
    };
  }, [resetErrorsAndStatus]);

  return <>{children || <AddedLegalForm />}</>;
};

const mapStateToProps = ({ legalEntity }: StoreTypes) => ({
  dataLegalEntity: legalEntity.editLegalEntity
});

const mapDispatchToProps = {
  resetErrorsAndStatus: resetErrorAndStatus,
  legalEntityInfo: legalEntityInfoRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddedLegalEntity);
