import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, Table, Wrapper } from "components/ui";
import SearchForm from "components/admin/search-form";
import { Loading } from "components/ui/loading";
import Pagination from "components/ui/pagination/pagination";
import Select from "components/ui/select";
import getPrettyPhone from "helpers/pretty-phone-format";
import { StoreTypes } from "store/types";
import selectOptions from "constant/quantity-rows-per-admin-page";
import Row from "components/ui/row";
import debounce from "helpers/debounce";
import PopupConfirm from "components/ui/popup-confirm";
import declOfNum from "helpers/declensionOfNum";
import { paginationTheme } from "components/ui/switch-themes";
import styles from "./legalEntityUserBase.module.scss";
import { columnsUser, columnsPopup } from "../columns";
import { LegalEntityApi } from "../../../../services/api/legalEntity";
import { attachUsersRequest, getLegalUsersRequest } from "../../../../store/legalEntity/actions";
import { UserType } from "../../../../store/users/types";
import setRowsHandler from "../../../../helpers/setRowsData";

const LegalEntityUserBase: FC<ReduxProps> = ({
  getUsers,
  baseUsersData: { data: baseUsers, last_page: lastPage },
  attachUsers,
  isLoading
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedRows, setRows] = useState<Array<string | number>>([]);
  const [selectedRowsCount, setSelectedRowsCount] = useState(selectedRows.length);

  const [attachedUsers, setAttachedUsers] = useState<Array<any>>([]);
  const [isOpen, setOpen] = useState(false);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    setAttachedUsers(setRowsHandler(attachedUsers, selectedRows, baseUsers));
    setClear(false);
  }, [selectedRows]);

  useEffect(() => {
    setSelectedRowsCount(selectedRows.length);
  }, [isOpen]);

  const attachUsersHandler = (): void => {
    attachUsers(attachedUsers.map(user => user.id));
    setAttachedUsers([]);
    setRows([]);
    setSelectedRowsCount(0);
    setClear(true);
    setOpen(false);
  };
  return (
    <div>
      <Wrapper pt={40}>
        <PopupConfirm
          isOpen={isOpen}
          action={attachUsersHandler}
          setOpen={setOpen}
          title={`Вы  хотите прикрепить  ${selectedRowsCount} ${declOfNum(selectedRows.length, [
            "пользователя",
            "пользователей",
            "пользователей"
          ])}?`}
          desc=""
        >
          <Table columns={columnsPopup} data={attachedUsers} />
        </PopupConfirm>
        <Row justifyContent="spaceBetween">
          <div>
            <Button size="small" disabled={!attachedUsers.length} onClick={(): void => setOpen(true)}>
              Прикрепить к ЮЛ
            </Button>
          </div>
          <SearchForm
            rowsPerPage={rowsPerPage}
            action={getUsers}
            placeholder="Поиск по ID, email, телефону, ФИО"
            currentPage={currentPage}
            typeData="Client"
            disableAutosuggest
          />
        </Row>
        <Wrapper pt={26}>
          <Loading isLoading={isLoading}>
            <Table
              onClear={clear}
              columns={columnsUser}
              data={baseUsers}
              rowSelection={(rows): void => setRows(rows)}
              selectedRows={selectedRows}
            />
          </Loading>
        </Wrapper>
        <Wrapper pb={32} pt={32}>
          <div className={styles.select_wrap}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
            <Select options={selectOptions} onClick={setRowsPerPage} />
          </div>
        </Wrapper>
      </Wrapper>
    </div>
  );
};

const mapStateToProps = ({ legalEntity }: StoreTypes) => ({
  baseUsersData: legalEntity.users,
  isLoading: legalEntity.isUsersLoading,
  editing: legalEntity.edit
});

const mapDispatchToProps = {
  getUsers: getLegalUsersRequest,
  attachUsers: attachUsersRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(LegalEntityUserBase);
