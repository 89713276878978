import React, { FC, useState, useEffect } from "react";
import { Table } from "components/ui/table";
import { buttonAddAndRemoveTheme } from "components/ui/switch-themes";
import { Button } from "../../ui/button";
import PopupConfirm from "../../ui/popup-confirm";
import declOfNum from "../../../helpers/declensionOfNum";

export type ColumnsTableType = {
  title: string;
  dataIndex: string | number;
  render?: (item: any, index: string) => string;
};

export type DeleteRowsHandlerPayloadType = {
  id: any;
};

type Props = {
  selectedRows: Array<string | number>;
  deleteRowsHandler: (payload: DeleteRowsHandlerPayloadType) => void;
  error?: string;
  disabled?: boolean;
  status?: string;
  resetErrorAndStatus: () => void;
  selectedRowsData: Array<any>;
  columns: Array<ColumnsTableType>;
};

const DeleteRows: FC<Props> = ({
  selectedRows,
  deleteRowsHandler,
  error,
  disabled,
  status,
  resetErrorAndStatus,
  selectedRowsData,
  columns
}) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(selectedRows.length);

  useEffect(() => {
    setSelectedRowsCount(selectedRows.length);
    if (resetErrorAndStatus) {
      resetErrorAndStatus();
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedRowsCount(selectedRows.length);
  }, [selectedRows]);

  const deleteRows = (rows: Array<string | number>): void => {
    rows.forEach(id => {
      deleteRowsHandler({ id });
    });
    setOpen(false);
  };

  return (
    <div>
      <Button
        theme={buttonAddAndRemoveTheme}
        size="small"
        disabled={selectedRows.length === 0}
        onClick={(): void => setOpen(true)}
      >
        Удалить
      </Button>

      <PopupConfirm
        isOpen={isOpen}
        setOpen={setOpen}
        action={(): void => deleteRows(selectedRows)}
        title={`Вы точно хотите удалить ${selectedRowsCount} ${declOfNum(selectedRows.length, [
          "запись",
          "записи",
          "записей"
        ])}?`}
        desc=""
        error={error}
        status={status}
        disabled={disabled}
        displaySuccess={selectedRowsData.length === 0}
        successTitle="Данные успешно удалены"
      >
        <Table columns={columns} data={selectedRowsData} />
      </PopupConfirm>
    </div>
  );
};
export default DeleteRows;
