export const isMail = (value: string): boolean => {
  const re = new RegExp(`^[ a-zA-Z0-9_.+-]{1,31}@[a-zA-Z0-9-]{1,50}\\.[a-zA-Z0-9-. ]+$`);
  return re.test(value);
};

export const isPhone = (value: string): boolean => {
  return Boolean(value.match(/^\d{11}$/g));
};

export const isValidPasswordSpace = new RegExp("^((?!\\s{2}).)*$");
export const isValidPassword = new RegExp("^[A-Za-z0-9!\"#$ %&'()*+,-./:;<=>?@[\\\\\\]^_`{|}~]+$");
export const isValidMMName = new RegExp("^[a-zA-Zа-яёА-ЯЁ0-9\"#$ %'()-./@[\\\\\\]^_`{}~]+$");
export const isValidName = new RegExp("^[a-zA-Zа-яёА-ЯЁ\\- ]*$");
export const isValidEmail = new RegExp(`^[ a-zA-Z0-9_.+-]{1,31}@[a-zA-Z0-9-]{1,50}\\.[a-zA-Z0-9-. ]+$`);
export const isValidNameDealers = new RegExp(`^[a-zA-Zа-яёА-ЯЁ0-9\\- «»"]*$`);
export const isValidINN = new RegExp(`^[\\d+]{10,12}$`);
export const isValidCode = new RegExp(`^[a-zA-Z0-9\\-]*$`);
export const isValidProcessingPurpose = new RegExp("^[a-zA-Zа-яёА-ЯЁ0-9\"#$ %'()-:;«»№•./@[\\\\\\]^_`{}~\\s]+$");
export const isValidNameNumber = new RegExp("^[a-zA-Zа-яёА-ЯЁ0-9\"#$ %'()-:;«»№./@[\\\\\\]^_`{}~]+$");
export const isValidNameString = new RegExp("^[a-zA-Zа-яёА-ЯЁ0-9\"#$ %'()-:;«»./@[\\\\\\]^_`{}~]+$");
export const isValidReason = new RegExp("^[a-zA-Zа-яёА-ЯЁ0-9\"#$ %'№()-./@[\\\\\\]^_`{}~]+$");

export const isValidFirstAndLastChar = (value: string | null | undefined): boolean => {
  if (value) {
    if (value[0] === "-" || value[value.length - 1] === "-") {
      return false;
    }
    return true;
  }

  return true;
};

export const isPassword = (value: string): boolean => {
  return Boolean(value.match(isValidPassword));
};
export const isName = (value: string): boolean => {
  return Boolean(value.match(isValidName));
};

export const isEmail = (value: string): boolean => {
  return Boolean(value.match(isValidEmail));
};
