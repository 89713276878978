import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { Link, Logo } from "components/ui";
import styles from "./footer.module.scss";
import { Facebook, Instagram, Twitter, Youtube, Vkontakte } from "./icons";
import Container from "../container";

const Footer: FC = () => {
  const lastLinkClass = cn(styles.link, styles.lastLink);
  return (
    <div className={styles.footer}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <NavLink to="/" className={styles.logo}>
              <Logo type="logo2" />
            </NavLink>
            <div className={styles.row}>
              <div className={styles.link}>
                <Link size="small" target="_blank" href="/doc">
                  Инструкция пользователя
                </Link>
              </div>
              <div className={styles.link}>
                <Link size="small" target="_blank" href="mailto:hid@hyundai.ru">
                  Служба поддержки
                </Link>
              </div>
              <div className={styles.link}>
                <Link size="small" target="_blank" href="https://www.hyundai.ru/Legal">
                  Правовые документы
                </Link>
              </div>
              <div className={lastLinkClass}>
                <Link target="_blank" size="small" href="https://www.hyundai.ru/Privacy">
                  Политика конфиденциальности
                </Link>
              </div>
              <div className={styles.socialContainer}>
                {/* <a
                  target="_blank"
                  className={styles.social}
                  href="https://www.facebook.com/HyundaiRussia"
                  rel="noreferrer"
                >
                  <Facebook />
                </a> */}
                <a target="_blank" className={styles.social} href="https://vk.com/hyundairussia" rel="noreferrer">
                  <Vkontakte />
                </a>
                {/* <a target="_blank" className={styles.social} href="https://twitter.com/HyundaiRussia" rel="noreferrer">
                  <Twitter />
                </a> */}
                {/* <a
                  target="_blank"
                  className={styles.social}
                  href="https://www.instagram.com/HyundaiRussia/"
                  rel="noreferrer"
                >
                  <Instagram />
                </a> */}
                {/* <a
                  target="_blank"
                  className={styles.social}
                  href="https://www.youtube.com/channel/UC0UnOmgllZR1fRGrXa2ONFQ"
                  rel="noreferrer"
                >
                  <Youtube />
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.copyright}>2022 © ООО “Хендэ Мотор СНГ”</div>
        <div className={styles.wrapper}>
          <div className={styles.title}>Юридическая информация</div>
        </div>
        <div className={styles.desc}>
          Вся представленная на сайте информация, касающаяся автомобилей и сервисного обслуживания, носит информационный
          характер и не является публичной офертой, определяемой положениями ст. 437 (2) ГК РФ. Все цены, сертификаты и
          специальные предложения, указанные на данном сайте носят информационный характер и являются максимально
          рекомендуемыми розничными ценами по расчетам дистрибьютора (ООО «Хендэ Мотор СНГ»). Для получения подробной
          информации просьба обращаться к ближайшему официальному дилеру ООО «Хендэ Мотор СНГ». Опубликованная на данном
          сайте информация может быть изменена в любое время без предварительного уведомления.
        </div>
      </Container>
    </div>
  );
};

export default Footer;
