import React, { FC } from "react";
import cn from "classnames";
import styles from "./accept.module.scss";
import { Link } from "../../ui/link";

type AcceptType = {
  color: "white" | "black";
};

const Accept: FC<AcceptType> = ({ color }) => {
  const cnAccept = cn(styles.accept, { [styles[color]]: color });

  return (
    <div className={cnAccept}>
      <span>
        {"Я даю своё "}
        <Link
          target="_blank"
          href="/rules"
          weight="regular"
          theme="lightBlue"
          size="small"
          style={{ display: "inline" }}
        >
          согласие на обработку персональных данных
        </Link>
        {" согласно "}
        <Link
          target="_blank"
          href="https://www.hyundai.ru/Legal"
          weight="regular"
          theme="lightBlue"
          size="small"
          style={{ display: "inline" }}
        >
          Политике обработки персональных данных
        </Link>
        {" и "}
        <Link
          target="_blank"
          href="https://www.hyundai.ru/Privacy"
          weight="regular"
          theme="lightBlue"
          size="small"
          style={{ display: "inline" }}
        >
          Политике конфиденциальности
        </Link>
      </span>
    </div>
  );
};

export default Accept;
