import { TYPES_DELETED_PERSONAL_DATA } from "./constants";
import { GetDeletedPersonalDataParams } from "./types";

export const deletedPersonalDataRequestWithDebounce = (params: GetDeletedPersonalDataParams) => ({
  type: TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_REQUEST_WITH_DEBOUNCE,
  payload: params
});

export const deletedPersonalDataRequest = (params: GetDeletedPersonalDataParams) => ({
  type: TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_REQUEST,
  payload: params
});

export const deletedPersonalDataSuccess = (data: any) => ({
  type: TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_SUCCESS,
  payload: data
});

export const deletedPersonalDataError = (error: { [key: string]: string }) => ({
  type: TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_ERROR,
  payload: error
});

export const getDeletedPersonalDataServicesRequest = () => ({
  type: TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_SERVICES_REQUEST
});

export const getDeletedPersonalDataServicesSuccess = (services: any) => ({
  type: TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_SERVICES_SUCCESS,
  payload: services
});

export const getDeletedPersonalDataServicesError = (error: { [key: string]: string }) => ({
  type: TYPES_DELETED_PERSONAL_DATA.GET_DELETED_PERSONAL_DATA_SERVICES_ERROR,
  payload: error
});
