import React, { ButtonHTMLAttributes, FC } from "react";
import cn from "classnames";
import { buttonMainTheme, isHyundaiTheme } from "components/ui/switch-themes";
import genesis from "./styles/genesis-button.module.scss";
import hyundai from "./styles/hyundai-button.module.scss";

export interface PropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: "submit";
  theme?: "light" | "blue" | "black" | "white" | "transparent" | "link" | "lightLink";
  /**
   * How large should the button be?
   */
  size?: "small" | "normal" | "xs";
  /**
   * Optional click handler
   */
  onClick?: () => void;
  disabled?: boolean;
  fullWidth?: boolean;
  noBorder?: boolean;
  notHover?: boolean;
}

export const Button: FC<PropsType> = ({
  type,
  notHover = true,
  children,
  theme = buttonMainTheme,
  size = "normal",
  fullWidth,
  noBorder = false,
  ...props
}: PropsType) => {
  const mainTheme = isHyundaiTheme ? hyundai : genesis;

  const cl = cn(mainTheme.button, {
    [mainTheme[theme]]: theme,
    [mainTheme[size]]: size,
    [mainTheme.fullWidth]: fullWidth,
    [mainTheme.noBorder]: noBorder,
    [mainTheme.notHover]: notHover
  });
  return (
    <div>
      <button type={type ? "submit" : "button"} {...props} className={cl}>
        {children}
      </button>
      <span />
    </div>
  );
};

Button.defaultProps = {
  fullWidth: false
};
