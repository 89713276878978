export const TYPES_DC = {
  DEALER_CENTER_LOAD: "DEALER_CENTER_LOAD",
  DEALER_CENTER_LOAD_STOP: "DEALER_CENTER_LOAD_STOP",
  DEALER_CENTER_REQUEST: "DEALER_CENTER_REQUEST",
  DEALER_CENTER_SUCCESS: "DEALER_CENTER_SUCCESS",
  DEALER_CENTER_ERROR: "DEALER_CENTER_ERROR",

  DELETE_DEALER_CENTER_REQUEST: "DELETE_DEALER_CENTER_REQUEST",
  DELETE_DEALER_CENTER_SUCCESS: "DELETE_DEALER_CENTER_SUCCESS",
  DELETE_DEALER_CENTER_ERROR: "DELETE_DEALER_CENTER_ERROR",

  DEALER_CENTER_BY_ID_REQUEST: "DEALER_CENTER_BY_ID_REQUEST",
  DEALER_CENTER_BY_ID_SUCCESS: "DEALER_CENTER_BY_ID_SUCCESS",

  SET_DC_ROWS: "SET_DC_ROWS",

  FILTER_DC_ROWS: "FILTER_DC_ROWS",
  DEALER_CENTER_STATUS: "DEALER_CENTER_STATUS",
  DEALERS_CLEAR_ROWS: "DEALERS_CLEAR_ROWS"
} as const;
