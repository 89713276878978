import React, { FC, useMemo, useState, useEffect } from "react";
import cn from "classnames";
import { useFormikContext } from "formik";
import { Role } from "store/roles/types";
import { isHyundaiTheme } from "components/ui/switch-themes";
import styles from "./service.module.scss";
import { ReactComponent as Checkmark } from "./images/checkmark.svg";
import { ReactComponent as CheckmarkGns } from "./images/checkmarkGns.svg";
import { Services as ServiceType } from "../../../store/users/types";

type Props = {
  services: Array<ServiceType>;
  setFieldValue: (field: string, value: any) => void;
  initValues: Array<string>;
  reset?: boolean;
};

const Service: FC<Props> = ({ services, setFieldValue, initValues, reset }) => {
  const [active, setActive] = useState(new Set(initValues));
  const [userServices, setUserServices] = useState<ServiceType[]>([]);

  const { values } = useFormikContext();

  const { roles } = values as any;

  useEffect(() => {
    setUserServices([...services]);
  }, [services]);

  useEffect(() => {
    setActive(new Set(initValues));
  }, [reset]);

  const addActive = (i: string): void => {
    const activeServices = new Set(active);
    activeServices.add(i);
    setActive(activeServices);
    const convertToArray = [...activeServices].sort();
    setFieldValue("services", convertToArray);
  };
  const removeActive = (i: string): void => {
    const activeServices = new Set(active);
    activeServices.delete(i);
    setActive(activeServices);
    const convertToArray = [...activeServices];
    setFieldValue("services", convertToArray);
  };

  const clickHandler = (id: string): void => {
    if (active.has(id)) {
      removeActive(id);
    } else {
      addActive(id);
    }
  };

  const widgets = useMemo(
    () =>
      userServices?.length &&
      userServices.map(({ picture, id, code }) => {
        const checkmarkClass = cn(styles.checkmark, {
          [styles.active]: active.has(id),
          [styles.genesis]: !isHyundaiTheme
        });
        return (
          <button type="button" className={styles.widget} key={id} onClick={(): void => clickHandler(id)}>
            <img className={styles.logo} src={`/${picture}`} alt={code} />
            {isHyundaiTheme ? <Checkmark className={checkmarkClass} /> : <CheckmarkGns className={checkmarkClass} />}
          </button>
        );
      }),
    [userServices, active]
  );

  return (
    <>
      {services.length ? (
        <div className={styles.service}>
          <div className={styles.row}>{widgets}</div>
        </div>
      ) : null}
    </>
  );
};

export default Service;
