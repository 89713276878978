import React, { FC, useEffect, useState, useRef, ReactElement } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { isValidReason } from "helpers/validate";
import { Button, Table, Wrapper, Input, Text, Checkbox } from "components/ui";
import { Loading } from "components/ui/loading";
import DeleteRows from "components/admin/delete-rows";
import { StoreTypes } from "store/types";
import Row from "components/ui/row";
import { Popup } from "components/ui/popup";
import { buttonAddAndRemoveTheme } from "components/ui/switch-themes";
import { resetErrorAndStatus } from "store/actions";
import { BanDeletionType } from "store/users/types";
import { isChangedValues } from "helpers/formik-handlers";
import styles from "./ban-deletion-panel.module.scss";
import { columnsBanDeletion, columnsBanDeleted } from "./columns";
import { getUsersServicesRequest } from "../../../../store/users/actions";
import {
  createBanDeletionRequest,
  deleteBanDeletionRequest,
  updateBanDeletionRequest,
  setBanDeletionRows
} from "../../../../store/employees/actions";
import setRowsHandler from "../../../../helpers/setRowsData";

const validationSchema = yup.object().shape({
  reason: yup
    .string()
    .matches(isValidReason, "Используйте кириллицу или латиницу, цифры и символы: ~@#$%^_-+*(){}'`№/\\.,")
    .required("Необходимо заполнить данные")
    .min(1, "Используйте от 1 до 1000 символов")
    .max(1000, "Используйте от 1 до 1000 символов")
    .required("Необходимо заполнить данные"),
  services: yup.array().min(1, "Выберите хотя бы один сервис")
});

type ValuesTypes = {
  reason: string;
  services: Array<string | number> | [];
};

const toggleById = (array: Array<string | number>, id: string | number) => {
  const newArray = [];
  let isContain = false;
  array.forEach(item => {
    if (item !== id) {
      newArray.push(item);
    } else {
      isContain = true;
    }
  });
  if (!isContain) {
    newArray.push(id);
  }
  return newArray;
};

type Props = {
  userId: string | null;
  banDeletions: Array<BanDeletionType>;
};

const BanDeletionPanel: FC<ReduxProps & Props> = ({
  userId,
  banDeletions,
  onCreate,
  onUpdate,
  isLoading,
  services,
  getUsersServices,
  resetErrorsAndStatus,
  deleteBanDeletion,
  employeeError,
  setRows,
  selectedRows
}) => {
  const [banDeletionRowsData, setBanDeletionRowsData] = useState<Array<BanDeletionType>>([]);
  const [isEditBanDeletion, setIsEditBanDeletion] = useState<boolean>(false);
  const [editingBanDeletion, setEditingBanDeletion] = useState<BanDeletionType | null>(null);
  const [isOpen, setOpen] = useState(false);
  const formikRef = useRef<any>();

  const popupTitle = isEditBanDeletion
    ? "Редактирование запрета на удаление пользователя"
    : "Вы хотите создать запрет на удаление пользователя?";

  const getInitialValues = (): ValuesTypes => {
    if (isEditBanDeletion) {
      if (editingBanDeletion) {
        const { reason, service_id } = editingBanDeletion;
        return {
          reason: reason || "",
          services: [service_id]
        };
      }
    }
    return {
      reason: "",
      services: []
    };
  };

  const initValue = getInitialValues();

  useEffect(() => {
    getUsersServices();
    return (): void => {
      resetErrorsAndStatus();
      setRows([]);
      setIsEditBanDeletion(false);
      setEditingBanDeletion(null);
    };
  }, []);

  useEffect(() => {
    setBanDeletionRowsData(setRowsHandler(banDeletionRowsData, selectedRows, banDeletions as BanDeletionType[]));
  }, [selectedRows]);

  const inputsBlur = (item: string, value: string): void => {
    if (value) {
      formikRef.current.setFieldValue(item, value.trim());
      formikRef.current.setTouched({ [item]: true });
    }
  };

  const closePopup = (): void => {
    setOpen(false);
    setIsEditBanDeletion(false);
    setEditingBanDeletion(null);
  };

  const openPopup = (id: string | number | null): void => {
    if (id) {
      setIsEditBanDeletion(true);
      setEditingBanDeletion(banDeletions?.find(item => item.id === id) || null);
    }
    setOpen(true);
  };

  const submitHandler = (values: ValuesTypes): void => {
    const { reason, services } = values;

    if (isEditBanDeletion && editingBanDeletion) {
      onUpdate(editingBanDeletion.id, { reason });
    } else {
      onCreate({
        reason,
        services,
        user: userId
      });
    }
    closePopup();
  };

  return (
    <Wrapper>
      {banDeletions && banDeletions.length === 0 && (
        <Wrapper>
          <p className={styles.desc}>Нет ни одного запрета на удаление.</p>
        </Wrapper>
      )}
      <Popup isOpen={isOpen} onClick={closePopup}>
        <div className={styles.popupContainer}>
          <h3 className={styles.popupTitle}>{popupTitle}</h3>
          <Formik
            validationSchema={validationSchema}
            innerRef={formikRef}
            onSubmit={submitHandler}
            initialValues={initValue}
          >
            {({ values, touched, setFieldValue, errors, handleChange }): ReactElement => (
              <Form className={styles.formContainer}>
                <Wrapper>
                  <div className={styles.form_input}>
                    <div>
                      <div className={styles.reasonContainer}>
                        <Input
                          name="reason"
                          placeholder="Обязательное поле"
                          label="Примечание"
                          onChange={handleChange}
                          onBlur={(): void => inputsBlur("reason", values.reason)}
                          value={values.reason}
                          invalid={Boolean(errors.reason && touched.reason)}
                          errorMessages={errors.reason && touched.reason ? errors.reason : null}
                        />
                      </div>
                      {!isEditBanDeletion && (
                        <div>
                          <Wrapper pb={4}>
                            <Text size="h5" theme="black">
                              Сервисы
                            </Text>
                            <span className={styles.errorContainer}>
                              {touched.services && errors.services && (
                                <span className={styles.errorText}>{errors.services}</span>
                              )}
                            </span>
                          </Wrapper>
                        </div>
                      )}
                      <div className={styles.serviceContainer}>
                        {!isEditBanDeletion &&
                          services.map(service => (
                            <Wrapper key={service.id}>
                              <Checkbox
                                checked={!!values.services.find(el => el === service.id)}
                                name="p2"
                                color="prime"
                                onClick={() => setFieldValue("services", toggleById(values.services, service.id))}
                              >
                                {service.name}
                              </Checkbox>
                            </Wrapper>
                          ))}
                      </div>
                    </div>
                  </div>
                </Wrapper>
                <div className={styles.btnContainer}>
                  <div className={styles.yesBtn}>
                    <Button theme={buttonAddAndRemoveTheme} type="submit" disabled={isChangedValues(initValue, values)}>
                      Да
                    </Button>
                  </div>
                  <Button theme={buttonAddAndRemoveTheme} onClick={closePopup}>
                    Нет
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Popup>
      <Row justifyContent="normal">
        <div className={styles.delBtn}>
          <Button size="small" onClick={() => openPopup(null)}>
            Создать запрет на удаление
          </Button>
        </div>
        {banDeletions && banDeletions.length > 0 && (
          <DeleteRows
            selectedRows={selectedRows}
            deleteRowsHandler={deleteBanDeletion}
            error={employeeError?.deleteBanDeletionError || ""}
            resetErrorAndStatus={resetErrorsAndStatus}
            columns={columnsBanDeleted}
            selectedRowsData={banDeletionRowsData}
            disabled={isLoading}
          />
        )}
      </Row>
      <Wrapper pt={26}>
        <Loading isLoading={isLoading}>
          {banDeletions && banDeletions.length > 0 && (
            <Table
              columns={columnsBanDeletion}
              data={banDeletions}
              rowSelection={(rows): any => setRows(rows)}
              selectedRows={selectedRows}
              rowEdit={id => openPopup(id)}
            />
          )}
        </Loading>
      </Wrapper>
    </Wrapper>
  );
};

const mapStateToProps = ({ users, employees }: StoreTypes) => ({
  isLoading: users.isLoading || employees.isLoading,
  userError: users.error,
  services: users.services,
  deleteError: users.error,
  employeeError: employees.error,
  selectedRows: employees.selectedBanDeletionRows
});

const mapDispatchToProps = {
  deleteBanDeletion: deleteBanDeletionRequest,
  onCreate: createBanDeletionRequest,
  onUpdate: updateBanDeletionRequest,
  getUsersServices: getUsersServicesRequest,
  resetErrorsAndStatus: resetErrorAndStatus,
  setRows: setBanDeletionRows
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(BanDeletionPanel);
