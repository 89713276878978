import React, { FC } from "react";
import { company, isHyundaiTheme } from "components/ui/switch-themes";
import cn from "classnames";
import styles from "./baner.module.scss";
import baner from "./images/baner.jpg";
import baner_genesis from "./images/baner_genesis.jpg";
import Container from "../container";

const Baner: FC = () => {
  const cnBanner = cn(styles.baner, {
    [styles.hyundai]: isHyundaiTheme
  });
  return (
    <div className={cnBanner}>
      <Container position="absolute">
        <div>Добро пожаловать</div>
        <div>{`в ${company}`}</div>
      </Container>
      <div className={styles.image}>
        <img src={isHyundaiTheme ? baner : baner_genesis} alt=" baner" />
      </div>
    </div>
  );
};

export default Baner;
