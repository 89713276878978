import React, { FC, useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import history from "services/history";
import { Text, Wrapper } from "components/ui";
import SearchForm from "components/admin/search-form/search-form";
import { Table } from "components/ui/table";
import { Loading } from "components/ui/loading";
import Pagination from "components/ui/pagination/pagination";
import Select from "components/ui/select";
import { StoreTypes } from "store/types";
import { dealerCenterRequest, deleteDealerCenterRequest, setDcRows } from "store/dealer-center/actions";
import selectOptions from "constant/quantity-rows-per-admin-page";
import ButtonAdd from "components/admin/button-add";
import DeleteRows from "components/admin/delete-rows";
import Row from "components/ui/row";
import { DealerCenter } from "store/dealer-center/types";
import debounce from "helpers/debounce";
import { dealershipInfo } from "store/dealers/actions";
import { resetErrorAndStatus } from "store/actions";
import setRowsDataHandler from "helpers/setRowsData";
import NotificationPopup from "components/ui/notification-popup";
import usePermissions from "helpers/usePermissions";
import { superadminPermissions } from "constant/permissions";
import { columns, deleteRowsColumn } from "pages/admin/dealers/columns";
import { getDealersSuggestion } from "helpers/function-suggest";
import { paginationTheme } from "components/ui/switch-themes";
import styles from "./dealers-page.module.scss";
import Warning from "../../../components/ui/warning";

const DealersPage: FC<ReduxProps> = ({
  status,
  isLoading,
  dealerCenterRequest: getDealers,
  dealerCenters: { data: dealerCenters, last_page: lastPage },
  deleteDealerCenterRequest: deleteDealerCenter,
  deleteError,
  resetErrorsAndStatus,
  setRows,
  selectedRows
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRowsData, setRowsData] = useState<Array<DealerCenter>>([]);
  const [notification, setNotification] = useState<boolean>(false);

  const isPermissionAdminMain = usePermissions(superadminPermissions);

  useEffect(() => {
    setRowsData(setRowsDataHandler(selectedRowsData, selectedRows, dealerCenters));
  }, [selectedRows, dealerCenters, setRowsData]);

  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
      setRows([]);
    };
  }, []);

  const getSuggestionByDebounce = debounce(getDealersSuggestion, 500);

  const getDealershipID = (id: string | number): void => {
    history.push(`/admin/dealers/added/${id}`);
  };
  useEffect(() => {
    if (status) {
      setNotification(true);
    }
  }, [status]);

  return (
    <>
      <div className={styles.header}>
        <NotificationPopup
          title="Данные успешно удалены"
          isOpen={notification}
          action={(): void => setNotification(false)}
        />
        <Wrapper pb={20}>
          <Text size="h3" theme="black">
            Дилерские центры
          </Text>
        </Wrapper>
        {isPermissionAdminMain ? (
          <Row justifyContent="spaceBetween">
            <Row wrap>
              <Wrapper pr={10}>
                <ButtonAdd path="/admin/dealers/added" />
              </Wrapper>
              <DeleteRows
                selectedRows={selectedRows}
                deleteRowsHandler={deleteDealerCenter}
                error={deleteError?.deleteDealerCenter || ""}
                resetErrorAndStatus={resetErrorsAndStatus}
                columns={deleteRowsColumn}
                selectedRowsData={selectedRowsData}
                disabled={isLoading}
              />
            </Row>
            <SearchForm
              rowsPerPage={rowsPerPage}
              action={getDealers}
              suggestionOnClick={getDealershipID}
              getSuggestion={getSuggestionByDebounce}
              placeholder="Поиск по ID, email, телефону"
              currentPage={currentPage}
              typeData="Dealership"
            />
          </Row>
        ) : null}
      </div>
      <div className={styles.wrapper}>
        <Loading isLoading={isLoading} />
        {!isLoading && (
          <Table
            columns={columns}
            data={dealerCenters}
            rowSelection={(rows): any => setRows(rows)}
            rowEdit={getDealershipID}
            selectedRows={selectedRows}
          />
        )}

        {!isLoading && dealerCenters.length === 0 && (
          <Wrapper pt={16}>
            <Warning color="black">Ничего не найдено</Warning>
          </Wrapper>
        )}
        <Wrapper pt={32}>
          <div className={styles.bottomBlock}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
            <Select options={selectOptions} onClick={setRowsPerPage} />
          </div>
        </Wrapper>
      </div>
    </>
  );
};

const mapStateToProps = ({ dealerCenter }: StoreTypes) => ({
  isLoading: dealerCenter.isLoading,
  dealerCenters: dealerCenter.dealerCenters,
  deleteError: dealerCenter.deleteError,
  selectedRows: dealerCenter.selectedRows,
  status: dealerCenter.status
});

const mapDispatchToProps = {
  dealershipInfo,
  dealerCenterRequest,
  deleteDealerCenterRequest,
  resetErrorsAndStatus: resetErrorAndStatus,
  setRows: setDcRows
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(DealersPage);
