/**
 * Работа с session storage
 * @type {{set(*=, *=): void, get(*=): *, clear(): void, remove(*=): void}}
 */
const sessionData = {
  set(key: string, value: any) {
    sessionStorage.setItem(key, value);
  },
  get(key: string) {
    return sessionStorage.getItem(key);
  },
  remove(key: string) {
    sessionStorage.removeItem(key);
  },
  clear() {
    sessionStorage.clear();
  }
};
export default sessionData;
