import axios from "axios";
import { CreateDealersData } from "store/dealers/types";
import { DealerCenterType } from "store/dealer-center/types";
import { RequestParams } from "store/roles/constants";
import { Role } from "store/roles/types";
import { GetEmployeesParams } from "store/employees/types";

type InfoDealers = {
  address: string;
  city: string;
  created_at: string;
  display_name: string;
  id: string | number;
  is_dealer_center: boolean;
  name: string;
  phone: string;
  updated_at: string;
};

type DealersEmployees = {
  role: string;
  user_id: Array<string | number>;
};

type DeleteDealersEmployee = {
  user_id: Array<string | number>;
};

export const Dealers = {
  baseEmployee: (body: GetEmployeesParams): Promise<DealerCenterType> => axios.post("/front/users", body),

  employees: (body: GetEmployeesParams): Promise<DealerCenterType> => axios.post(`/front/employee`, body),

  createDealers: (body: CreateDealersData): Promise<InfoDealers> => axios.post("/front/team", body),

  role: (type?: RequestParams): Promise<Role[]> =>
    axios.get("/front/role", {
      params: {
        type
      }
    }),
  addedEmployee: (id: any, body: DealersEmployees): Promise<any> => axios.post(`/front/team/${id}/attach_user`, body),

  addedEmployeeAllRole: (id: string, body: DealersEmployees[]): Promise<any> =>
    axios.post(`/front/team/${id}/attach_users`, body),

  deleteEmployee: (id: any, body: DeleteDealersEmployee): Promise<any> =>
    axios.post(`/front/team/${id}/detach_user`, body),

  info: (id: number | string): Promise<InfoDealers> => axios.get(`/front/team/${id}`),

  update: (id: string | number, body: Partial<CreateDealersData>): Promise<any> => axios.put(`/front/team/${id}`, body),

  usersList: (body: string[]): Promise<any> => axios.post("/front/employee", body)
};
