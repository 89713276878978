import React, { FC } from "react";
import { DataDealershipTypes } from "components/admin/personal-info/personal-info";
import { TeamsType } from "store/employees/types";
import { isHyundaiTheme } from "components/ui/switch-themes";
import cn from "classnames";
import { uniqify } from "helpers/uniqify";
import styles from "./chip.module.scss";

type ChipProps = {
  isHidden: boolean;
  isEdit: boolean;
  dataServer: TeamsType[];
  setData: (field: string, value: DataDealershipTypes[]) => void;
};

export const Chip: FC<ChipProps> = ({ isHidden, setData, dataServer, isEdit }) => {
  const removeDealership = (id: number): void => {
    if (isEdit) {
      if (dataServer.find(item => item.id === id)) {
        const newState = dataServer.filter(item => item.id !== id);
        setData("teams", [...newState]);
      } else {
        const newState = dataServer.filter(item => item.id !== id);
        setData("teams", [...newState]);
      }
    } else {
      const newState = dataServer.filter(item => item.id !== id);
      setData("teams", [...newState]);
    }
  };
  const cnTag = cn(styles.tag, { [styles.genesis]: !isHyundaiTheme });

  return (
    <div className={styles.tags}>
      <ul className={styles.tagsList}>
        {dataServer?.length > 0 &&
          uniqify<TeamsType>(dataServer).map(tag => (
            <li key={tag.id} className={cnTag}>
              <span className={styles.tagTitle}>{tag.data || tag.display_name}</span>
              {isHidden && (
                <button className={styles.tagClose} type="button" onClick={(): void => removeDealership(tag.id)}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,jsx-a11y/control-has-associated-label */}
                  <span className={styles.close} />
                </button>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};
