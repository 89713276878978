export enum AUTH_API_STATUS {
  CODE_SENT = "code_sent",
  CODE_ALREADY_SENT = "code_already_sent",
  CODE_VERIFIED = "code_verified",
  INVALID_CODE = "invalid_code",
  PASSWORD_REQUIRED = "password_required",
  SMS_VERIFICATION_REQUIRED = "sms_verification_required",
  EMAIL_EXISTS = "email_exists",
  EMAIL_SENT = "email_sent",
  PHONE_EXISTS = "phone_exists",
  USER_EXISTS = "user_exists",
  EMAIL_VERIFICATION_REQUIRED = "email_verification_required",
  PHONE_VERIFICATION_REQUIRED = "phone_verification_required",
  AUTHENTICATION_FAILED = "authentication_failed",
  TOO_MANY_ATTEMPTS = "too_many_attempts",
  CODE_EXPIRED = "code_expired",
  TOKEN_VERIFIED = "token_verified",
  TOKEN_INVALID = "token_invalid",
  PASSWORD_POLICE_VIOLATION = "password_policy_violation",
  EMAIL_VERIFIED = "email_verified",
  INVALID_CLIENT = "invalid_client",
  USER_IS_BLOCKED = "user_is_blocked",
  USER_NOT_FOUND = "user_not_found",
  INCORRECT_EMAIL = "incorrect_email",
  EMAIL_CHANGE = "email_change",
  USER_DELETED = "user_deleted"
}

export enum STEP_SIGN_IN {
  LOGIN = "LOGIN",
  PASSWORD = "PASSWORD",
  TELEPHONE = "TELEPHONE",
  SUCCESS = "SUCCESS",
  ADDED_PHONE = "  ADDED_PHONE",
  ADDED_PHONE_CODE = "ADDED_PHONE_CODE",
  USER_NO_ACCESS = "USER_NO_ACCESS"
}

export enum STEP_REGISTER {
  TELEPHONE = "TELEPHONE",
  CODE = "CODE",
  REGISTER = "REGISTER",
  SUCCESS = "SUCCESS",
  EXISTS = "EXISTS",
  INVALID = "INVALID"
}

export enum STEP_FORGOTTEN {
  TELEPHONE = "TELEPHONE",
  EMAIL = "EMAIL",
  CODE = "CODE",
  NEW_PASSWORD = "NEW_PASSWORD",
  SUCCESS = "SUCCESS"
}

export enum ERROR_MESSAGE {
  INVALID_LINK = "Вы прошли по недействительной или поврежденной ссылке",
  INVALID_EMAIL = "Введенный email уже зарегистрирован. Укажите новый email или восстановите доступ к текущему аккаунту "
}
