import { combineReducers } from "redux";
import { dealersReducer } from "store/dealers/reducer";
import { removeTokens } from "services/storageCookie";
import { authReducer } from "./auth/reducer";
import { userReducer } from "./users/reducer";
import { employeesReducer } from "./employees/reducer";
import { dealerCenterReducer } from "./dealer-center/reducer";
import { servicesReducer } from "./services/reducer";
import { rolesReducer } from "./roles/reducer";
import { loginHistoryReducer } from "./loginHistory/reducer";
import { marketingMailingsReducer } from "./marketing-mailings/reducer";
import { legalEntitiesListReducer } from "./legalEntitiesList/reducer";
import { legalEntityReducer } from "./legalEntity/reducer";
import { deletePersonalDataReducer } from "./deleted-personal-data/reducer";
import { deletePDOrdersReducer } from "./delete-personal-data-orders/reducer";
import { thirdPartiesListReducer } from "./third-parties-list/reducer";
import { thirdPartyReducer } from "./third-party/reducer";
import { personalDataListReducer } from "./personal-data-list/reducer";
import { personalDataReducer } from "./personal-data/reducer";
import { phoneBlacklistReducer } from "./phone-blacklist/reducer";

const reducerCombine = combineReducers({
  auth: authReducer,
  users: userReducer,
  employees: employeesReducer,
  dealers: dealersReducer,
  dealerCenter: dealerCenterReducer,
  legalEntity: legalEntityReducer,
  legalEntitiesList: legalEntitiesListReducer,
  services: servicesReducer,
  roles: rolesReducer,
  loginHistory: loginHistoryReducer,
  marketingMailings: marketingMailingsReducer,
  deletedPersonalData: deletePersonalDataReducer,
  personalDataDeleteOrders: deletePDOrdersReducer,
  thirdPartiesList: thirdPartiesListReducer,
  thirdParty: thirdPartyReducer,
  personalDataList: personalDataListReducer,
  personalData: personalDataReducer,
  phoneBlacklist: phoneBlacklistReducer
});

const reducer = (state: any, action: any): ReturnType<typeof reducerCombine> => {
  let newState = state;
  // when a logout action is dispatched it will reset redux state
  if (action.type === "AUTH_RESET_ALL") {
    newState = undefined;
    removeTokens();
  }

  return reducerCombine(state, action);
};

export default reducer;
