export const TYPES_USER = {
  USERS_LOAD: "USERS_LOAD",
  USERS_LOAD_STOP: "USERS_LOAD_STOP",
  USERS_REQUEST: "USERS_REQUEST",
  USERS_SUCCESS: "USERS_SUCCESS",
  USERS_ERROR: "USERS_ERROR",
  USERS_STATUS: "USERS_STATUS",
  USERS_RESTORE: "USERS_RESTORE",

  UPLOAD_AVATAR_REQUEST: "UPLOAD_AVATAR_REQUEST",
  UPLOAD_AVATAR_SUCCESS_USER_SET: "UPLOAD_AVATAR_SUCCESS_USER_SET",
  UPLOAD_AVATAR_SUCCESS_EMPLOYEE_SET: "UPLOAD_AVATAR_SUCCESS_EMPLOYEE_SET",
  UPLOAD_AVATAR_SUCCESS_NOT_SET: "UPLOAD_AVATAR_SUCCESS_NOT_SET",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",

  UPDATE_USER_PHONE_REQUEST: "UPDATE_USER_PHONE_REQUEST",
  USER_SEND_CODE: "USER_SEND_CODE",
  SEND_CODE_SUCCESS: "SEND_CODE_SUCCESS",

  UPDATE_USER_EMAIL_REQUEST: "UPDATE_USER_EMAIL_REQUEST",
  USER_SEND_EMAIL_CODE: "USER_SEND_EMAIL_CODE",

  USER_SEND_EMAIL_VERIFY_REQUEST: "USER_SEND_EMAIL_VERIFY_REQUEST",
  USER_SEND_EMAIL_VERIFY_SUCCESS: "USER_SEND_EMAIL_VERIFY_SUCCESS",
  USER_SEND_EMAIL_VERIFY_ERROR: "USER_SEND_EMAIL_VERIFY_ERROR",

  DELETE_ACCOUNT_REQUEST: "DELETE_ACCOUNT_REQUEST",
  DELETE_ACCOUNT_SUCCESS: "DELETE_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT_CHECK_REQUEST: "DELETE_ACCOUNT_CHECK_REQUEST",
  DELETE_ACCOUNT_CHECK_SUCCESS: "DELETE_ACCOUNT_CHECK_SUCCESS",
  DELETE_ACCOUNT_CHECK_ERROR: "DELETE_ACCOUNT_CHECK_ERROR",
  RESTORE_ACCOUNT_REQUEST: "RESTORE_ACCOUNT_REQUEST",
  RESTORE_ACCOUNT_SUCCESS: "RESTORE_ACCOUNT_SUCCESS",
  AUTH_RESET_ALL: "AUTH_RESET_ALL",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",

  OLD_PASSWORD: "old-password",
  NEW_PASSWORD: "new-password",
  REPEAT_PASSWORD: "repeat-password",

  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",

  ATTACH_TEAM_REQUEST: "ATTACH_TEAM_REQUEST",
  DETACH_TEAM_REQUEST: "DETACH_TEAM_REQUEST",

  USER_DELETE_MARKET_CONS: "USER_DELETE_MARKET_CONS",
  USER_ACCEPT_MARKET_CONS: "USER_ACCEPT_MARKET_CONS",
  USER_ACCEPT_MARKET_CONS_FOR_SERVICE: "USER_ACCEPT_MARKET_CONS_FOR_SERVICE",

  SWITCH_USER_CONCENT: "SWITCH_USER_CONCENT",

  BAN_DELETION_CLIENTS_REQUEST: "BAN_DELETION_CLIENTS_REQUEST",
  BAN_DELETION_CLIENTS_SUCCESS: "BAN_DELETION_CLIENTS_SUCCESS",

  GET_USERS_SERVICES_REQUEST: "GET_USERS_SERVICES_REQUEST",
  GET_USERS_SERVICES_SUCCESS: "GET_USERS_SERVICES_SUCCESS"
} as const;

export enum UpdateType {
  UpdateMainUser = "UpdateMainUser",
  UpdateCurrentEmployee = "UpdateCurrentEmployee"
}
