import React, { FC } from "react";
import Layout from "components/lk/layout";
import Container from "../../components/lk/container";

const RulesPage: FC = () => {
  return (
    <Layout withoutHeader>
      <div style={{ margin: "auto", width: "fit-content" }}>
        <Container>
          {" "}
          <h2>Правила обработки персональных данных</h2>
          <p>
            Настоящим Я, в соответствии с требованиями Федерального закона от 27.07.09 №152-ФЗ «О персональных данных»
            даю свое согласие лично, своей волей и в своих интересах на обработку (сбор, систематизацию, накопление,
            хранение, уточнение (обновление, изменение), использование, передачу (включая трансграничную передачу),
            обезличивание, блокирование и уничтожение) моих персональных данных, в том числе с использованием средств
            автоматизации. Такое согласие мной даётся в отношении следующих персональных данных: ФИО, контактный адрес
            электронной почты (email), контактный телефон, дата рождения. Персональные данные предоставляются мной для
            определения потребностей в производственной мощности; мониторинга исполнения сервисными центрами гарантийной
            политики; ведения истории обращения в сервисные центры; проведения дилерами, дистрибьюторами, контрагентами
            маркетинговых исследований в области продаж, сервиса и послепродажного обслуживания; для рекламных,
            исследовательских, информационных, а также иных целей, в том числе, путем осуществления со мной прямых
            контактов по различным средствам связи. Согласие дается Hyundai Motor Company (Хёндэ Мотор Компани, 231
            Янгджи-Донг, Сеочо-Гу, Сеул, 137-938, Республика Корея), ООО «Хендэ Мотор СНГ» (г. Москва, пр-д 1-Й
            Красногвардейский, д. 21 стр. 1), ООО «Хендэ Мотор МануфактурингPус» (197706, Санкт-Петербург, г.
            Сестрорецк, Левашовское ш., д. 20, литер А). Я даю свое согласие передавать мои персональные данные для
            обработки исследовательским агентствам: ООО «Международный институт маркетинговых и социальных исследований
            «ГФК-Русь» (г. Москва, 9-я Парковая улица, д. 48, корп. 4), ЗАО «Бизнес Аналитика МК» (г. Москва, ул.
            Новослободская, д. 31, стр. 2), АО «АВТОАССИСТАНС» (г. Москва, ул. Обручева д. 52 стр.3), ООО «Ипсос» (г.
            Москва, Гамсоновский переулок, д. 5), а также любым другим третьим лицам, для целей, указанных в настоящем
            согласии. Срок действия данного согласия – 10 лет. Согласие может быть отозвано мной в любой момент путем
            направления письменного заявления об отзыве в ООО «Хендэ Мотор СНГ» (г. Москва, пр-д 1-Й Красногвардейский,
            д. 21 стр. 1).
          </p>
        </Container>
      </div>
    </Layout>
  );
};

export default RulesPage;
