export const TYPES_SERVICES = {
  GET_SERVICES_REQUEST: "GET_SERVICES_REQUEST",
  GET_SERVICES_SUCCESS: "GET_SERVICES_SUCCESS",
  GET_SERVICES_ERROR: "GET_SERVICES_ERROR",

  GET_SERVICE_BY_ID_REQUEST: "GET_SERVICE_BY_ID_REQUEST",
  GET_SERVICE_BY_ID_SUCCESS: "GET_SERVICE_BY_ID_SUCCESS",
  GET_SERVICE_BY_ID_ERROR: "GET_SERVICE_BY_ID_ERROR",

  GET_PERSONAL_DATA_TYPE_REQUEST: "GET_PERSONAL_DATA_TYPE_REQUEST",
  GET_PERSONAL_DATA_TYPE_SUCCESS: "GET_PERSONAL_DATA_TYPE_SUCCESS",
  GET_PERSONAL_DATA_TYPE_ERROR: "GET_PERSONAL_DATA_TYPE_ERROR",

  GET_PERMISSION_LIST_REQUEST: "GET_PERMISSION_LIST_REQUEST",
  GET_PERMISSION_LIST_SUCCESS: "GET_PERMISSION_LIST_SUCCESS",
  GET_PERMISSION_LIST_ERROR: "GET_PERMISSION_LIST_ERROR",

  UPDATE_SERVICE_REQUEST: "UPDATE_SERVICE_REQUEST",
  UPDATE_SERVICE_SUCCESS: "UPDATE_SERVICE_SUCCESS",
  UPDATE_SERVICE_ERROR: "UPDATE_SERVICE_ERROR",
  OPEN_SERVICE_SUCCESS_MODAL: "OPEN_SERVICE_SUCCESS_MODAL",
  CLOSE_SERVICE_SUCCESS_MODAL: "CLOSE_SERVICE_SUCCESS_MODAL",

  GET_THIRD_PARTIES_LIST_REQUEST: "GET_THIRD_PARTIES_LIST_REQUEST",
  GET_THIRD_PARTIES_LIST_SUCCESS: "GET_THIRD_PARTIES_LIST_SUCCESS",
  GET_THIRD_PARTIES_LIST_ERROR: "GET_THIRD_PARTIES_LIST_ERROR",

  SERVICES_LOAD: "SERVICES_LOAD",
  SERVICES_LOAD_STOP: "SERVICES_LOAD_STOP",

  DELETE_SERVICE_REQUEST: "DELETE_SERVICE_REQUEST",
  DELETE_SERVICE_SUCCESS: "DELETE_SERVICE_SUCCESS",
  DELETE_SERVICE_ERROR: "DELETE_SERVICE_ERROR",

  SERVICE_CREATE_REQUEST: "SERVICE_CREATE_REQUEST",
  SERVICE_CREATE_SUCCESS: "SERVICE_CREATE_SUCCESS",

  MODAL_OPEN_SERVICE: "MODAL_OPEN_SERVICE",
  POPUP_OPEN_CREDENTIALS: "POPUP_OPEN_CREDENTIALS",

  GET_SERVICE_CREDENTIALS_REQUEST: "GET_SERVICE_CREDENTIALS_REQUEST",
  GET_SERVICE_CREDENTIALS_SUCCESS: "GET_SERVICE_CREDENTIALS_SUCCESS",
  CLEAR_SERVICE_CREDENTIALS: "CLEAR_SERVICE_CREDENTIALS",

  SET_SERVICES_ROWS: "SET_SERVICES_ROWS",
  FILTER_SERVICES_ROWS: "FILTER_SERVICES_ROWS",

  SERVICES_STATUS: "SERVICES_STATUS"
} as const;
