import React, { FC, useState } from "react";
import cn from "classnames";
import FooterGns from "components/lk/footer-gns";
import { isHyundaiTheme } from "components/ui/switch-themes";
import styles from "./layout.module.scss";
import Header from "../header";
import Footer from "../footer";

type LayoutProps = {
  children: unknown;
  withoutHeader?: boolean;
};

const Layout: FC<LayoutProps> = ({ children, withoutHeader }) => {
  const [isOpen, setOpen] = useState(false);

  const className = cn(styles.layout, {
    [styles.isOpen]: isOpen
  });

  return (
    <div className={className}>
      <div className={styles.cover} />
      {withoutHeader ? null : <Header onClick={setOpen} isOpen={isOpen} />}
      <div className={styles.lineTop} />
      <div className={styles.content}>{children}</div>
      <div className={styles.lineBottom} />
      {isHyundaiTheme ? <Footer /> : <FooterGns />}
    </div>
  );
};

export default Layout;
