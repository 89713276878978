import axios from "axios";
import { LoginTypes } from "./authentication ";

export interface RegisterPhone {
  phone?: string;
  type?: LoginTypes;
  username: string;
  token: string | null;
}

type RegisterCode = Record<"phone" | "code" | "username", string>;

export type RegisterUserTypes = RegisterCode & {
  name: string;
  type: LoginTypes;
  lastname: string;
  email: string;
  password: string;
  provider_code: string;
  provider_user_id: string;
};

interface RegisterPhoneResponse {
  code?: number;
  message: string;
  status: string;
}

type RegisterCodeResponse = Record<"message" | "status", string>;

type registerTokenResponse = {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
};

export const Registration = {
  phone: (body: RegisterPhone & { reason?: string }): Promise<RegisterPhoneResponse> => {
    return axios.post("/front/send-code", body);
  },
  code: (body: RegisterCode): Promise<RegisterCodeResponse> => {
    return axios.post("/front/check-code", body);
  },
  form: (body: RegisterUserTypes): Promise<registerTokenResponse> => {
    return axios.post("/front/register-user", body);
  }
};
