/* eslint-disable react/jsx-one-expression-per-line */

import React from "react";
import { company, companyName, isHyundaiTheme } from "../../components/ui/switch-themes";

export enum MANUAL_BLOCK_TYPES {
  LIST = "list",
  TEXT = "text",
  WARNING = "warning",
  SECTION = "section"
}

export type ManualSection = {
  type: MANUAL_BLOCK_TYPES.SECTION;
  level: number;
  title: string | React.ReactNode;
  children: Array<ManualSection | ManualTextBlock | ManualListBlock | ManualWarningBlock>;
};

export type ManualTextBlock = {
  type: MANUAL_BLOCK_TYPES.TEXT;
  content: string | React.ReactNode | HTMLElement;
};

export type ManualListBlock = {
  type: MANUAL_BLOCK_TYPES.LIST;
  content: Array<string | React.ReactNode | HTMLElement>;
};

export type ManualWarningBlock = {
  type: MANUAL_BLOCK_TYPES.WARNING;
  color: "red" | "green";
  title: string;
  text: string;
};

const servicesHyundai: ManualTextBlock = {
  type: MANUAL_BLOCK_TYPES.TEXT,
  content: (
    <>
      {`После авторизации в ${company} на главной странице отобразятся доступные Вам сервисы.`}
      <br />
      <br />
      <a href="https://mir.hyundai.ru/">Мир Хендэ </a>
      - Личный кабинет сервиса Мир Хендэ.
      <br />
      <br />
      <a href="https://showroom.hyundai.ru/">Онлайн покупка </a>
      - Онлайн продажи автомобилей.
      <br />
      <br />
      <a href="https://mobility.hyundai.ru/">Hyundai Mobility </a>
      - Онлайн-сервис подписки на автомобили Хендэ.
      <br />
      <br />
      <a href="https://parts.hyundai.ru/profile/orders">Accessories & spare parts shop </a>
      <>- Магазин запасных частей и аксессуаров.</>
    </>
  )
};

const servicesGenesis: ManualTextBlock = {
  type: MANUAL_BLOCK_TYPES.TEXT,
  content: (
    <>
      {`После авторизации в ${company} на главной странице отобразятся доступные Вам сервисы.`}
      <br />
      <br />
      <a href="https://platform.genesis.ru/inner">Онлайн покупка </a>
      <>- Онлайн продажи автомобилей.</>
    </>
  )
};

const services = isHyundaiTheme ? servicesHyundai : servicesGenesis;

export const manualInstruction: Array<ManualSection> = [
  {
    level: 1,
    type: MANUAL_BLOCK_TYPES.SECTION,
    title: `Что такое ${company}?`,
    children: [
      {
        type: MANUAL_BLOCK_TYPES.TEXT,
        content: `${company} является Вашим личным кабинетом в мире ${company} для всех сервисов. Единый аккаунт ${company} предоставляет Вам возможность редактировать личные и контактные данные, управлять сервисами, способами входа.`
      }
    ]
  },
  {
    level: 1,
    type: MANUAL_BLOCK_TYPES.SECTION,
    title: `Как создать ${company}`,
    children: [
      {
        type: MANUAL_BLOCK_TYPES.TEXT,
        content: (
          <>
            Для создания
            {` ${company} `}
            перейдите по
            <a href="/auth/signIn"> ссылке</a>. Если Вы уже являетесь клиентом
            {` ${companyName}, `}
            то Ваш
            {` ${company} `}
            уже готов — просто авторизуйтесь по номеру телефона.
          </>
        )
      }
    ]
  },
  {
    level: 1,
    type: MANUAL_BLOCK_TYPES.SECTION,
    title: `Регистрация в ${company}`,
    children: [
      {
        type: MANUAL_BLOCK_TYPES.LIST,
        content: [
          <>
            Откройте
            <a href="/auth/signIn">
              {" "}
              {` `}
              {company}
            </a>
            .
          </>,
          <>
            Нажмите на ссылку
            <b> Регистрация</b>.
          </>,
          "Введите Ваш номер телефона в поле ввода.",
          <>
            Удостоверьтесь, что Вы подтвердили
            <a href="/rules"> согласие на обработку персональных данных</a>
            {`, ознакомились с `}
            <a href="https://hyundai.ru/Legal">политикой обработки персональных данных</a>
            {` и `}
            <a href="https://hyundai.ru/Privacy">политикой конфиденциальности</a>.
          </>,
          <>
            Активируйте чек-бокс согласия и нажмите
            <b> Зарегистрироваться</b>.
          </>,
          "На указанный номер поступит телефонный звонок. Прослушайте сообщение оператора и введите 4 цифры в поле.",
          <>
            Заполните форму регистрации и нажмите на кнопку
            <b> Завершить регистрацию</b>.
          </>
        ]
      },
      {
        type: MANUAL_BLOCK_TYPES.WARNING,
        title: "Ограничение",
        text: "Для одного номера телефона можно создать только один аккаунт.",
        color: "red"
      }
    ]
  },
  {
    level: 1,
    type: MANUAL_BLOCK_TYPES.SECTION,
    title: `Вход в ${company}`,
    children: [
      {
        level: 2,
        type: MANUAL_BLOCK_TYPES.SECTION,
        title: "Вход по номеру телефона:",
        children: [
          {
            type: MANUAL_BLOCK_TYPES.LIST,
            content: [
              <>
                Откройте
                <a href="/auth/signIn">{` ${company} `}</a>
                или любой сервис
                {` ${companyName}`}.
              </>,
              <>
                Выберите способ авторизации
                <b> по телефону</b>.
              </>,
              <>
                В качестве логина укажите номер телефона, который использовали при регистрации, и нажмите
                <b> Продолжить</b>.
              </>,
              "На указанный номер поступит телефонный звонок. Прослушайте сообщение оператора и введите 4 цифры в поле."
            ]
          }
        ]
      },
      {
        level: 2,
        type: MANUAL_BLOCK_TYPES.SECTION,
        title: "Вход по логину и паролю:",
        children: [
          {
            type: MANUAL_BLOCK_TYPES.LIST,
            content: [
              <>
                Откройте
                <a href="/auth/signIn">{` ${company} `}</a>
                или любой сервис
                {` ${companyName}`}.
              </>,
              <>
                Выберите способ авторизации
                <b> по email</b>.
              </>,
              <>
                В качестве логина укажите email, который указывали при регистрации, и нажмите
                <b> Продолжить</b>.
              </>,
              <>
                В поле ввода введите пароль, указанный при регистрации, и нажмите
                <b> Продолжить</b>.
              </>
            ]
          },
          {
            type: MANUAL_BLOCK_TYPES.TEXT,
            content: (
              <>
                <>Если Вы забыли пароль, нажмите на ссылку</>
                <a href="/auth/forgotten"> Забыли пароль? </a>
                <>и следуйте инструкции.</>
              </>
            )
          }
        ]
      }
    ]
  },
  {
    level: 1,
    type: MANUAL_BLOCK_TYPES.SECTION,
    title: "Восстановление пароля",
    children: [
      {
        type: MANUAL_BLOCK_TYPES.TEXT,
        content: (
          <>
            Если Вы забыли пароль для входа на
            {` ${company}, `}
            попробуйте сбросить его самостоятельно. Для этого следуйте инструкциям на странице
            <a href="/auth/forgotten"> Восстановление пароля</a>.
            <br />
            <br />
            <>
              Вам понадобится ввести номер телефона или адрес электронной почты, указанный при регистрации, чтобы
              получить код подтверждения. Если Вы видите сообщение о том, что адрес почты или номер неправильный,
              проверьте, не опечатались ли Вы при вводе. Если корректно введенный номер все-таки не подходит,
              постарайтесь вспомнить, какие еще адреса или номера Вы могли указать при регистрации. Если останутся
              вопросы, Вы всегда можете написать в
            </>
            <a href="mailto:hid@hyundai.ru">{` службу поддержки ${company}.`}</a>
          </>
        )
      }
    ]
  },
  {
    level: 1,
    type: MANUAL_BLOCK_TYPES.SECTION,
    title: "Главная. Сервисы",
    children: [
      services,
      {
        level: 3,
        type: MANUAL_BLOCK_TYPES.SECTION,
        title: <i>Для перехода в сервис :</i>,
        children: [
          {
            type: MANUAL_BLOCK_TYPES.LIST,
            content: ["Выберите нужный сервис.", "Кликните на карточку с названием сервиса."]
          }
        ]
      }
    ]
  },
  {
    level: 1,
    type: MANUAL_BLOCK_TYPES.SECTION,
    title: "Данные",
    children: [
      {
        level: 2,
        type: MANUAL_BLOCK_TYPES.SECTION,
        title: "Личные данные",
        children: [
          {
            type: MANUAL_BLOCK_TYPES.TEXT,
            content: (
              <>
                Чтобы изменить личные данные, вверху страницы нажмите на вкладку
                <a href="/personal"> Личные данные</a>.
              </>
            )
          }
        ]
      },
      {
        level: 3,
        type: MANUAL_BLOCK_TYPES.SECTION,
        title: <i>Добавить фото:</i>,
        children: [
          {
            type: MANUAL_BLOCK_TYPES.LIST,
            content: [
              `Выберите фотографию, которая будет отображаться в сервисах ${companyName}.`,
              <>
                Нажмите
                <b> кнопку добавления фото </b>
                на аватаре и выберите файл на компьютере.
              </>,
              <>
                Нажмите на кнопку
                <b> Сохранить </b>
                внизу страницы.
              </>
            ]
          }
        ]
      },
      {
        level: 3,
        type: MANUAL_BLOCK_TYPES.SECTION,
        title: <i>Изменить информацию о себе:</i>,
        children: [
          {
            type: MANUAL_BLOCK_TYPES.TEXT,
            content: `По умолчанию в ${company} отображается информация, которую Вы указали при регистрации. Вы можете ее изменить:`
          },
          {
            type: MANUAL_BLOCK_TYPES.LIST,
            content: [
              "Введите имя и фамилию.",
              "Укажите дату рождения.",
              <>
                Нажмите на кнопку
                <b> Сохранить </b>
                внизу страницы.
              </>
            ]
          }
        ]
      },
      {
        type: MANUAL_BLOCK_TYPES.WARNING,
        title: "Важно",
        text: `Когда Вы изменяете свои личные данные, они автоматически обновляются во всех сервисах мира ${companyName}.`,
        color: "green"
      }
    ]
  },
  {
    level: 1,
    type: MANUAL_BLOCK_TYPES.SECTION,
    title: "Безопасность и вход",
    children: [
      {
        type: MANUAL_BLOCK_TYPES.TEXT,
        content: (
          <>
            Чтобы изменить пароль и отследить историю подключений, вверху страницы нажмите на вкладку
            <a href="/personal"> Безопасность и вход.</a>
          </>
        )
      },
      {
        level: 3,
        type: MANUAL_BLOCK_TYPES.SECTION,
        title: <i>История подключений:</i>,
        children: [
          {
            type: MANUAL_BLOCK_TYPES.LIST,
            content: [
              <>
                В правой части страницы найдите раздел
                <b> История подключений</b>.
              </>,
              <>
                Нажмите на кнопку
                <b> Смотреть все</b>.
              </>
            ]
          }
        ]
      },
      {
        level: 3,
        type: MANUAL_BLOCK_TYPES.SECTION,
        title: <i>Изменить пароль:</i>,
        children: [
          {
            type: MANUAL_BLOCK_TYPES.LIST,
            content: [
              <>
                В левой части страницы найдите раздел
                <b> Изменить пароль</b>.
              </>,
              <>
                В поле
                <b> Введите старый пароль </b>
                укажите текущий пароль.
              </>,
              <>
                В поле
                <b> Введите новый пароль </b>
                укажите новый пароль.
              </>,
              <>
                В поле
                <b> Повторите пароль </b>
                укажите новый пароль.
              </>,
              <>
                Нажмите на кнопку
                <b> Сохранить</b>.
              </>
            ]
          }
        ]
      }
    ]
  },
  {
    level: 1,
    type: MANUAL_BLOCK_TYPES.SECTION,
    title: "Смена email",
    children: [
      {
        type: MANUAL_BLOCK_TYPES.LIST,
        content: [
          "Авторизуйтесь в системе.",
          <>
            Перейдите на вкладку
            <b> Безопасность и вход</b>.
          </>,
          <>
            Нажмите на кнопку
            <b> Сменить email</b>.
          </>,
          <>
            В поле модального окна введите новый email и нажмите
            <b> Продолжить</b>.
          </>,
          "На новую почту придет письмо с кодом. Его нужно ввести в следующее модальное окно.",
          <>
            Нажмите на кнопку
            <b> Сохранить</b>.
          </>,
          "При успешном вводе кода email изменится на новый, и на старую почту придёт уведомление о том, что почта была изменена."
        ]
      }
    ]
  },
  {
    level: 1,
    type: MANUAL_BLOCK_TYPES.SECTION,
    title: "Смена номера телефона",
    children: [
      {
        type: MANUAL_BLOCK_TYPES.LIST,
        content: [
          "Авторизуйтесь в системе.",
          <>
            Перейдите на вкладку
            <b> Безопасность и вход</b>.
          </>,
          <>
            Нажмите на кнопку
            <b> Сменить номер телефона</b>.
          </>,
          <>
            В поле модального окна введите новый номер телефона и нажмите
            <b> Продолжить</b>.
          </>,
          "На новый номер поступит телефонный звонок. Прослушайте сообщение оператора и введите 4 цифры в поле.",
          <>
            Нажмите на кнопку
            <b> Сохранить</b>.
          </>,
          "При успешном вводе кода телефон изменится на новый."
        ]
      }
    ]
  },
  {
    level: 1,
    type: MANUAL_BLOCK_TYPES.SECTION,
    title: "Выход",
    children: [
      {
        type: MANUAL_BLOCK_TYPES.TEXT,
        content: `Для выхода из ${company} нажмите на иконку в правом верхнем углу страницы.`
      }
    ]
  }
];
