import React, { FC, useMemo, useState, useEffect } from "react";
import { buttonEditTheme, checkmarkTheme, isHyundaiTheme } from "components/ui/switch-themes";
import cn from "classnames";
import styles from "./table.module.scss";
import { Checkbox } from "../checkbox";
import { Button } from "../button";

type StrOrNum = string | number;

export type ColumnsTableType = {
  title: string | JSX.Element;
  dataIndex: StrOrNum;
  render?: (item: any, index: string) => string | JSX.Element;
  width?: number;
};

export type DataTableType = {
  id: StrOrNum;
  [key: string]: any;
};

export type PropsType = {
  columns: ColumnsTableType[];
  data: DataTableType[];
  onClick?: (event: React.ChangeEvent<any>) => void;
  /**
   * функция отдает выделениые чекбоксы если не передовать то будет простая таблица
   * @param selectKeys
   */
  rowSelection?: (selectKeys: StrOrNum[]) => void;
  rowEdit?: (id: StrOrNum) => void;
  rowAction?: (id: StrOrNum) => void;
  rowBtnAction?: (id: StrOrNum) => void;
  selectedRows?: Array<string | number>;
  onClear?: boolean;
  buttonName?: string;
  lockedIndex?: number;
  buttonActionName?: string;
};

export const Table: FC<PropsType> = ({
  buttonName,
  onClear,
  onClick,
  columns,
  data,
  rowSelection,
  rowEdit,
  rowAction,
  rowBtnAction,
  buttonActionName,
  selectedRows,
  lockedIndex
}) => {
  const [selectRows, setSelectRows] = useState<StrOrNum[]>(Object.keys(data).map((x: string) => +x));

  const onSelectRowAll = (): void => {
    const uniq = new Set([...selectRows]);
    if (!data.every(item => selectRows.includes(item.id))) {
      data.forEach(item => {
        uniq.add(item.id);
      });
    } else {
      data.forEach(item => {
        uniq.delete(item.id);
      });
    }
    setSelectRows(Array.from(uniq));
    if (rowSelection) {
      rowSelection(Array.from(uniq));
    }
  };

  useEffect(() => {
    if (onClear) {
      setSelectRows([]);
    }
  }, [onClear]);

  const onSelectRow = (value: StrOrNum): void => {
    const uniq = new Set([...selectRows]);
    if (uniq.has(value)) {
      if (!((lockedIndex || lockedIndex === 0) && uniq.has(lockedIndex))) {
        uniq.delete(value);
      }
      if (lockedIndex === value) {
        uniq.delete(value);
      }
    } else {
      if (value === lockedIndex) {
        data.forEach(item => {
          uniq.add(item.id);
        });
      }
      uniq.add(value);
    }
    const selectRowsChecked: StrOrNum[] = [...uniq];
    setSelectRows(selectRowsChecked);
    if (rowSelection) {
      rowSelection(selectRowsChecked);
    }
  };

  useEffect(() => {
    if (rowSelection && selectedRows) {
      setSelectRows(selectedRows);
    }
  }, [data]);

  const tHead = useMemo(
    () =>
      columns.map(column => (
        <th key={column.dataIndex} style={{ width: `${column.width}%` }}>
          {column.title}
        </th>
      )),
    [columns]
  );
  const resultHead = (
    <>
      {rowSelection && data && (
        <th className={styles.selectTd}>
          <Checkbox
            color={checkmarkTheme}
            checked={data.every(item => selectRows.includes(item.id))}
            onClick={(): void => onSelectRowAll()}
          />
        </th>
      )}
      {tHead}
      {rowEdit && <th className={styles.selectTd}>Редактировать</th>}
      {rowAction && <th className={styles.selectTd}>Скачать</th>}
      {rowBtnAction && <th className={styles.selectTd}>{buttonActionName}</th>}
    </>
  );

  const tData = useMemo(
    () =>
      data.map((item, index) => {
        const tdData = columns.map(({ dataIndex, render }) => {
          const renderItem = render ? render(item, String(index + 1)) : item;
          return <td key={`${dataIndex}-${item.id}`}>{renderItem}</td>;
        });
        const tdSelected = rowSelection ? (
          <td>
            <Checkbox
              color={checkmarkTheme}
              checked={selectRows.includes(item.id)}
              onClick={(): void => onSelectRow(item.id)}
            />
          </td>
        ) : null;
        const tdEdit = rowEdit ? (
          <td>
            <Button size="xs" theme={buttonEditTheme} onClick={(): void => rowEdit(item.id)}>
              {buttonName}
            </Button>
          </td>
        ) : null;
        const tdAction = rowAction ? (
          <td>
            <Button size="xs" theme={buttonEditTheme} onClick={(): void => rowAction(item.id)}>
              {buttonName}
            </Button>
          </td>
        ) : null;
        const tdBtnAction = rowBtnAction ? (
          <td>
            <Button size="xs" theme={buttonEditTheme} onClick={(): void => rowBtnAction(item.id)}>
              {buttonActionName}
            </Button>
          </td>
        ) : null;
        return (
          <tr onClick={onClick} id={String(item.id)} key={item.id}>
            {tdSelected}
            {tdData}
            {tdEdit}
            {tdAction}
            {tdBtnAction}
          </tr>
        );
      }),
    [data, selectRows]
  );
  const cnTable = cn({ [styles.gray]: !isHyundaiTheme });

  return (
    <table className={styles.table}>
      <thead>
        <tr className={cnTable}>{resultHead}</tr>
      </thead>
      <tbody>{tData}</tbody>
    </table>
  );
};

Table.defaultProps = {
  buttonName: "Редактировать"
};
