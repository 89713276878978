import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { PersonalDataApi } from "services/api/personal-data";
import { TYPES_PERSONAL_DATA } from "./constants";
import {
  personalDataListError,
  personalDataListSuccess,
  personalDataListLoad,
  personalDataListLoadStop,
  personalDataByIdSuccess,
  deletePersonalDataSuccess,
  deletePersonalDataError,
  personalDataListStatus,
  personalDataListRequest,
  deletePersonalDataRequest,
  personalDataByIdRequest,
  clearPersonalDataListRows,
  filterPersonalDataListRows
} from "./actions";

function* getPersonalDataList(actions: ReturnType<typeof personalDataListRequest>): SagaIterator {
  const { payload } = actions;
  yield put(personalDataListLoad());
  try {
    const response = yield call(PersonalDataApi.getPersonalDataList, payload);

    yield put(personalDataListSuccess(response));
  } catch (e) {
    const result = { total: 0, per_page: 10, current_page: 1, last_page: 1, data: [] };
    yield put(personalDataListSuccess(result));
    yield put(personalDataListError({ personalDataList: "Не удалось загрузить данные" }));
  } finally {
    yield put(personalDataListLoadStop());
  }
}

function* deletePersonalData(actions: ReturnType<typeof deletePersonalDataRequest>): SagaIterator {
  const {
    payload: { id }
  } = actions;
  yield put(personalDataListLoad());
  try {
    yield call(PersonalDataApi.deletePersonalData, id);
    yield put(filterPersonalDataListRows(id));
    yield put(deletePersonalDataSuccess(id));
    yield put(personalDataListStatus({ personalDataList: "Успешно" }));
  } catch (e) {
    yield put(deletePersonalDataError({ deletePersonalData: "Не удалось удалить перечень ПДн" }));
  } finally {
    yield put(personalDataListLoadStop());
  }
}

function* getPersonalDataById(actions: ReturnType<typeof personalDataByIdRequest>): SagaIterator {
  const { payload } = actions;
  yield put(personalDataListLoad());
  try {
    const response = yield call(PersonalDataApi.getPersonalDataById, payload);
    yield put(personalDataByIdSuccess(response));
  } catch (e) {
    yield put(personalDataListError({ personalDataById: "Не удалось загрузить данные" }));
  } finally {
    yield put(personalDataListLoadStop());
  }
}

function* personalDataListSaga(): SagaIterator {
  yield takeLatest(TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_REQUEST, getPersonalDataList);
  yield takeEvery(TYPES_PERSONAL_DATA.DELETE_PERSONAL_DATA_REQUEST, deletePersonalData);
  yield takeEvery(TYPES_PERSONAL_DATA.PERSONAL_DATA_BY_ID_REQUEST, getPersonalDataById);
}

export { personalDataListSaga };
