import React, { FC, ReactNode } from "react";

export type PropsType = {
  /**
   * Отступ снизу
   */
  pt?: number;
  /**
   * Отступ сверху
   */
  pb?: number;
  /**
   * Отступ Слева
   */
  pl?: number | string;
  /**
   * Отступ Справа
   */
  pr?: number | string;
  children?: ReactNode;
  className?: string;
};

export const Wrapper: FC<PropsType> = ({ pt, pb, pl, pr, children, className }: PropsType) => {
  return (
    <div className={className} style={{ paddingTop: pt, paddingBottom: pb, paddingLeft: pl, paddingRight: pr }}>
      {children}
    </div>
  );
};
Wrapper.defaultProps = {
  pt: 0,
  pb: 0,
  pl: 0,
  pr: 0,
  children: undefined
};
