import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Switch, Route } from "react-router-dom";
import VerifyEmail from "components/auth/notification/verify-email";
import ErrorPage from "pages/error-page/error-page";
import { usersRequest } from "store/users/actions";
import { AUTH_ROUTES_URL } from "constant/routers";
import { superadminPermissions, defaultPermissions, adminDealershipPermission } from "constant/permissions";
import SocialNetwork from "components/auth/notification/sso";
import storageCookie from "services/storageCookie";
import { REFRESH_TOKEN, TOKEN } from "constant/api";
import { StoreTypes } from "store/types";
import ForgottenByEmail from "components/auth/steps/forgotten/forgotten-from-by-email";
import AuthPage from "./auth/auth-page";
import AdminPage from "./admin/admin-page";
import RulesPage from "./rules/rules-page";
import LkPage from "./lk/lk-page";
import ProtectRoute from "../components/routes/protect-route";
import OpenAuthPage from "./auth/open-auth.page";
import PreAuthPage from "./auth/pre-auth-page";
import UserTokenWrapper from "../components/admin/user-token-wrapper";
import UserManualPage from "./user-manual-page/user-manual-page";

const MainRouter: FC<ReduxProps> = ({ usersRequest: userInfo, isAuth, isGuestAuth }) => {
  const token = storageCookie.get(TOKEN);
  const refreshToken = storageCookie.get(REFRESH_TOKEN);

  useEffect(() => {
    if (isAuth || (token && refreshToken)) {
      userInfo();
    }
    /// todo тут идет  запрос
  }, [isAuth]);

  return (
    <UserTokenWrapper isGuestAuth={isGuestAuth}>
      <Switch>
        <Route path={AUTH_ROUTES_URL.ROOT} component={AuthPage} />
        <Route path={AUTH_ROUTES_URL.OPEN_AUTH} component={OpenAuthPage} />
        <Route path={AUTH_ROUTES_URL.RESET_PASSWORD} component={ForgottenByEmail} />

        <Route path={AUTH_ROUTES_URL.VERIFY_EMAIL} component={VerifyEmail} />
        <Route path={AUTH_ROUTES_URL.ERROR_PAGE} component={ErrorPage} />
        <Route path={AUTH_ROUTES_URL.USER_MANUAL} component={UserManualPage} />
        <Route path={AUTH_ROUTES_URL.SSO} component={SocialNetwork} />
        <Route path={AUTH_ROUTES_URL.PREAUTH} component={PreAuthPage} />
        <Route path={AUTH_ROUTES_URL.RULES} component={RulesPage} />
        <ProtectRoute path="/admin" component={AdminPage} permissions={adminDealershipPermission} />
        <ProtectRoute path="/admin" component={AdminPage} permissions={superadminPermissions} />

        <ProtectRoute
          path="/"
          component={LkPage}
          permissions={[...superadminPermissions, ...defaultPermissions, ...adminDealershipPermission]}
        />
      </Switch>
    </UserTokenWrapper>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  isAuth: store.auth.isTokenWritten,
  isGuestAuth: store.auth.guestAuth.isAuth
});

const mapDispatchToProps = {
  usersRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(MainRouter);
