import React, { FC, useContext, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { dealersEmployeeUpdate, deleteEmployeesDC, getEmployeesFilter } from "store/dealers/actions";
import { Dealers } from "services/api/dealers";
import debounce from "helpers/debounce";
import EmployeeDealers from "components/admin/added-dealership/employee-dealers";
import { DealershipContext } from "context/Dealership";
import { StoreTypes } from "store/types";
import { FiltersType } from "store/employees/types";
import getPrettyPhone from "helpers/pretty-phone-format";
import selectOptions from "constant/quantity-rows-per-admin-page";
import PopupConfirm from "components/ui/popup-confirm";
import declOfNum from "helpers/declensionOfNum";
import setRowsHandler from "helpers/setRowsData";
import { EmployeeType } from "constant/radiobutton-values";
import EmployeeRoles from "constant/eployee-roles";
import { paginationTheme } from "components/ui/switch-themes";
import Row from "../../../ui/row";
import { Button, Table, Wrapper } from "../../../ui";
import SearchForm from "../../search-form";
import { Loading } from "../../../ui/loading";
import Pagination from "../../../ui/pagination/pagination";
import Select from "../../../ui/select";
import styles from "./employee-dealership.module.scss";
import { columnsPopup, columnsDealership } from "../columns";

type Props = {
  employeeType: string;
};

const EmployeeDealership: FC<ReduxProps & Props> = ({
  dealersEmployeeUpdate: onUpdateList,
  editDealership,
  getEmployeesFilter: onRequest,
  employees: { data: dealers, last_page: lastPage },
  deleteEmployeesDC: onDetach,
  isLoading,
  employeeType
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRows, setRows] = useState<Array<string | number>>([]);
  const [filters, setFilters] = useState<FiltersType>({});
  const [isOpen, setOpen] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(selectedRows.length);
  const [employeeDetach, setEmployeeDetach] = useState<Array<any>>([]);
  const [teamName, setTeamName] = useState<string | null>(null);
  const [users, setUsers] = useState<string | number>(0);
  const [clear, setClear] = useState(false);

  const { setSelectedRows, isEdit, setEmployeesChange } = useContext(DealershipContext);

  useEffect(() => {
    if (teamName !== null && isEdit) {
      if (employeeType === EmployeeType.admins) {
        setFilters({ team: [teamName], role: [EmployeeRoles.adminDC] });
      } else if (employeeType === EmployeeType.employees) {
        setFilters({ team: [teamName], role: [EmployeeRoles.employeeDC] });
      } else {
        setFilters({ team: [teamName] });
      }
    } else if (employeeType === EmployeeType.admins) {
      setFilters({ dealer_center_employee: true, role: [EmployeeRoles.adminDC] });
    } else if (employeeType === EmployeeType.employees) {
      setFilters({ dealer_center_employee: true, role: [EmployeeRoles.employeeDC] });
    } else {
      setFilters({ dealer_center_employee: true });
    }
  }, [employeeType, teamName]);

  useEffect(() => {
    if (editDealership) {
      const { name, users_count } = editDealership;
      setTeamName(String(name));
      setUsers(Number(users_count));
    }
  }, [editDealership]);

  useEffect(() => {
    if (clear) {
      setClear(false);
    }
    setEmployeeDetach(setRowsHandler(employeeDetach, selectedRows, dealers));
  }, [selectedRows, clear]);

  useEffect(() => {
    setSelectedRowsCount(selectedRows.length);
  }, [isOpen]);

  useEffect(() => {
    if (!isEdit) {
      setSelectedRows(selectedRows);
    }
  }, [selectedRows]);

  const getSuggestion = async (
    value: string,
    setSuggestions: (data: Array<{ id: string; data: string }>) => void
  ): Promise<void> => {
    try {
      const { data } = await Dealers.employees({
        search: value,
        filters
      });
      const firstSuggestions = data.slice(0, rowsPerPage);
      const extractData = firstSuggestions.map(({ id, email, name, lastname, patronymic, phone }: any) => {
        const fullName = `${lastname} ${name && name[0]}. ${patronymic && patronymic[0]}.`;
        const prettyPhone = getPrettyPhone(phone);
        return {
          id,
          data: `${fullName} ${email} ${prettyPhone}`
        };
      });
      setSuggestions(extractData);
    } catch (error) {
      console.log(error);
    }
  };

  const getSuggestionByDebounce = debounce(getSuggestion, 500);

  const detachEmployees = (): void => {
    if (selectedRows && teamName) {
      const newListDealers = dealers.filter(employee => !selectedRows.includes(employee.id));
      onDetach(selectedRows);
      onUpdateList(newListDealers);
      setOpen(false);
      setClear(true);
      setEmployeesChange(true);
    }
  };

  return (
    <div>
      {isEdit && users ? (
        <Wrapper pt={40}>
          <PopupConfirm
            isOpen={isOpen}
            action={(): void => detachEmployees()}
            setOpen={setOpen}
            title={`Вы  хотите открепить  ${selectedRowsCount} ${declOfNum(selectedRows.length, [
              "сотрудника",
              "сотрудников",
              "сотрудников"
            ])}?`}
            desc=""
          >
            <Table columns={columnsPopup} data={employeeDetach} />
          </PopupConfirm>
          <Row justifyContent="spaceBetween">
            <div className={styles.wrap_btn}>
              {/* <Button>Добавить</Button> */}
              <Button disabled={!employeeDetach.length} onClick={() => setOpen(true)}>
                Открепить
              </Button>
            </div>
            <SearchForm
              rowsPerPage={rowsPerPage}
              action={onRequest}
              getSuggestion={getSuggestionByDebounce}
              placeholder="Поиск по ID, email, телефону"
              filters={filters}
              currentPage={currentPage}
              disableAutosuggest
            />
          </Row>
          <Wrapper pt={26}>
            <Loading isLoading={isLoading} />
            <Table
              onClear={clear}
              columns={columnsDealership}
              data={dealers}
              rowSelection={(rows): void => setRows(rows)}
              selectedRows={selectedRows}
            />
          </Wrapper>
          <Wrapper pb={32} pt={32}>
            <div className={styles.select_wrap}>
              <div />
              <Pagination
                theme={paginationTheme}
                onChange={setCurrentPage}
                currentPage={currentPage}
                totalPages={lastPage}
              />
              <Select options={selectOptions} onClick={setRowsPerPage} />
            </div>
          </Wrapper>
        </Wrapper>
      ) : (
        <EmployeeDealers />
      )}
    </div>
  );
};

const mapStateToProps = ({ dealers }: StoreTypes) => ({
  employees: dealers.dealers,
  isLoading: dealers.isLoading,
  editing: dealers.edit,
  editDealership: dealers.editDealership
});
const mapDispatchToProps = {
  getEmployeesFilter,
  deleteEmployeesDC,
  dealersEmployeeUpdate
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(EmployeeDealership);
