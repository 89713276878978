import React, { FC } from "react";
import cn from "classnames";
import styles from "./container.module.scss";

interface Position {
  absolute: string;
  relative: string;
  static: string;
}

type PropsType = {
  position?: keyof Position;
  row?: boolean;
};

const Container: FC<PropsType> = ({ children, position = "static", row }) => {
  const className = cn(styles.container, { [styles[position]]: position, [styles.row]: row });
  return <div className={className}>{children}</div>;
};

export default Container;
