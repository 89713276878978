import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { resetErrorAndStatus } from "store/actions";
import { company, companyName, isHyundaiTheme } from "components/ui/switch-themes";
import cn from "classnames";
import styles from "./home.module.scss";
import Baner from "../../../components/lk/baner";
import Service from "../../../components/lk/service";
import Container from "../../../components/lk/container";
import { StoreTypes } from "../../../store/types";
import { Button, Checkbox, Popup, Table } from "../../../components/ui";
import { acceptMarketingConsentForServiceRequest } from "../../../store/users/actions";

const desk = "я хочу получать рекламу и другие предложения от ООО “Хендэ Мотор СНГ“";

const HomePage: FC<ReduxProps> = ({
  connectedServices = [],
  connectedServicesFront = [],
  unconnectedServices = [],
  resetErrorsAndStatus,
  verificationEmail,
  isPreAuth,
  acceptMarketingConsent
}) => {
  const [isPreAuthOpen, setIsPreAuth] = useState(isPreAuth);
  const [isMarketingMailingsAccept, setMarketingMailingsAccept] = useState(false);

  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
    };
  }, [resetErrorsAndStatus]);
  const cnText = cn(styles.subtitle, { [styles.genesis]: !isHyundaiTheme });
  const cnDesc = cn(styles.desc, { [styles.genesis]: !isHyundaiTheme });

  const submitMarketingMailingModal = () => {
    setIsPreAuth(false);
    if (isMarketingMailingsAccept) {
      acceptMarketingConsent();
    }
  };

  return (
    <div className={styles.home}>
      <div className={styles.wrapper}>
        <Baner />
      </div>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <div className={cnText}>{`Что такое ${company}`}</div>
            <div className={cnDesc}>
              {`${company} является вашим личным кабинетом в мире ${companyName} для всех сервисов. Единый аккаунт ${company}
              предоставляет вам возможность редактировать личные и контактные данные, управлять сервисами, способами
              входа. Войти в ${company} можно с любой платформы мира ${companyName}.`}
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <Service
            verificationEmail={verificationEmail}
            connectedServices={connectedServicesFront}
            unconnectedServices={unconnectedServices}
          />
        </div>
      </Container>
      <Popup isOpen={isPreAuthOpen} onClick={() => setIsPreAuth(false)}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.titlePopup}>{`Добро пожаловать в ${company}`}</div>
          </div>
          <div className={styles.popupContent}>
            <Checkbox
              checked={isMarketingMailingsAccept}
              name="checked"
              style={{ marginTop: "32px", marginLeft: "32px" }}
              onClick={() => setMarketingMailingsAccept(!isMarketingMailingsAccept)}
            />
            <span className={styles.descPopup}>{desk}</span>
          </div>
          <div className={styles.submitBtnContainer}>
            <Button onClick={submitMarketingMailingModal}>Ок</Button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

const mapStateToProps = ({ users: { user }, auth: { isPreAuth } }: StoreTypes) => ({
  connectedServices: user.connected_services,
  connectedServicesFront: user.connected_services_front,
  unconnectedServices: user.unconnected_services,
  verificationEmail: user.email_verified_at,
  isPreAuth
});

const mapDispatchToProps = {
  resetErrorsAndStatus: resetErrorAndStatus,
  acceptMarketingConsent: acceptMarketingConsentForServiceRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(HomePage);
