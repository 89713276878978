import React, { FC, useEffect, useState, useContext } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, Table, Wrapper } from "components/ui";
import SearchForm from "components/admin/search-form";
import { Loading } from "components/ui/loading";
import Pagination from "components/ui/pagination/pagination";
import Select from "components/ui/select";
import { StoreTypes } from "store/types";
import selectOptions from "constant/quantity-rows-per-admin-page";
import Row from "components/ui/row";
import PopupConfirm from "components/ui/popup-confirm";
import { paginationTheme } from "components/ui/switch-themes";
import styles from "./legalEntityCurrentCarBase.module.scss";
import { columnsCar, columnsPopup } from "../columns";
import { detachCarsRequest, getLegalCarsRequest } from "../../../../store/legalEntity/actions";
import setRowsHandler from "../../../../helpers/setRowsData";
import { FiltersType } from "../../../../store/employees/types";

const LegalEntityCurrentCarBase: FC<ReduxProps> = ({
  getCars,
  currentCarsData: { data: currentCars, last_page: lastPage },
  dataLegalEntity,
  detachCars,
  isLoading
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedRows, setRows] = useState<Array<string | number>>([]);
  const [selectedRowsCount, setSelectedRowsCount] = useState(selectedRows.length);
  const [filters, setFilters] = useState<FiltersType>(dataLegalEntity ? { legalEntity: dataLegalEntity.id } : {});

  const [detachedCars, setDetachedCars] = useState<Array<any>>([]);
  const [isOpen, setOpen] = useState(false);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    setDetachedCars(setRowsHandler(detachedCars, selectedRows, currentCars));
    setClear(false);
  }, [selectedRows]);

  useEffect(() => {
    setSelectedRowsCount(selectedRows.length);
  }, [isOpen]);

  const attachCarsHandler = (): void => {
    detachCars(detachedCars.map(car => car.id));
    setDetachedCars([]);
    setSelectedRowsCount(0);
    setRows([]);
    setClear(true);
    setOpen(false);
  };

  if (dataLegalEntity && dataLegalEntity.cars.length === 0) {
    return (
      <Wrapper>
        <p className={styles.desc}>
          Ни одно ТС не прикреплено к юридическому лицу.
          <br />
          Вы можете добавить их из «База ТС».
        </p>
      </Wrapper>
    );
  }

  return (
    <div>
      <Wrapper pt={40}>
        <PopupConfirm
          isOpen={isOpen}
          action={attachCarsHandler}
          setOpen={setOpen}
          title={`Вы  хотите открепить  ${selectedRowsCount} ТС?`}
          desc=""
        >
          <Table columns={columnsCar} data={detachedCars} />
        </PopupConfirm>
        <Row justifyContent="spaceBetween">
          <div>
            <Button size="small" disabled={!detachedCars.length} onClick={(): void => setOpen(true)}>
              Открепить
            </Button>
          </div>
          <SearchForm
            rowsPerPage={rowsPerPage}
            action={getCars}
            filters={filters}
            placeholder="Поиск по VIN"
            currentPage={currentPage}
            typeData="Car"
            disableAutosuggest
          />
        </Row>
        <Wrapper pt={26}>
          <Loading isLoading={isLoading}>
            <Table
              onClear={clear}
              columns={columnsCar}
              data={currentCars}
              rowSelection={(rows): void => setRows(rows)}
              selectedRows={selectedRows}
            />
          </Loading>
        </Wrapper>
        <Wrapper pb={32} pt={32}>
          <div className={styles.select_wrap}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
            <Select options={selectOptions} onClick={setRowsPerPage} />
          </div>
        </Wrapper>
      </Wrapper>
    </div>
  );
};

const mapStateToProps = ({ legalEntity }: StoreTypes) => ({
  dataLegalEntity: legalEntity.editLegalEntity,
  currentCarsData: legalEntity.cars,
  isLoading: legalEntity.isCarsLoading,
  editing: legalEntity.edit
});

const mapDispatchToProps = {
  getCars: getLegalCarsRequest,
  detachCars: detachCarsRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(LegalEntityCurrentCarBase);
