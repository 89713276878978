import { STEP_FORGOTTEN, STEP_REGISTER, STEP_SIGN_IN } from "constant/step-auth";
import { ActionsType, Store } from "./types";
import storage from "../../services/storage";
import { TYPES } from "./constants";

const initialState: Store = {
  isLoading: false,
  isTokenWritten: false,
  isPreAuth: false,
  redirectLink: null,
  authData: {
    username: null,
    phone: null,
    email: null,
    name: null,
    code: null,
    lastname: null,
    avatar: null,
    type: null,
    state: null,
    captchaToken: null
  },
  stepSignIn: STEP_SIGN_IN.LOGIN,
  stepReg: STEP_REGISTER.TELEPHONE,
  stepForgot: STEP_FORGOTTEN.TELEPHONE,
  openAuthStatus: null,
  openAuthUrl: "",
  status: null,
  error: null,
  serverError: null,
  ttl: null,
  updateDataUser: false,
  sso: {
    registerViaSSO: false,
    isPhoneRequired: false,
    links: null,
    dataUserSSO: null
  },
  isVerification: true,
  guestAuth: {
    isAuth: false
  }
};

export const authReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES.AUTH_LOAD: {
      return { ...state, error: null, isLoading: true };
    }
    case TYPES.AUTH_LOAD_STOP: {
      return { ...state, isLoading: false };
    }
    case TYPES.AUTH_UPDATE_DATA: {
      return { ...state, authData: { ...state.authData, ...action.payload } };
    }
    case TYPES.AUTH_UPDATE_STEP_SIGN_IN: {
      return { ...state, error: null, stepSignIn: action.payload };
    }
    case TYPES.AUTH_ADDED_TTL: {
      return { ...state, ttl: action.payload };
    }
    case TYPES.AUTH_RESET_ALL: {
      storage.clear();
      return { ...initialState };
    }
    case TYPES.AUTH_RESET: {
      return { ...state };
    }
    case TYPES.AUTH_ERROR: {
      return { ...state, error: action.payload };
    }
    case TYPES.AUTH_TOKEN_WRITTEN: {
      return { ...state, isTokenWritten: action.payload };
    }
    case TYPES.PRE_AUTH_REQUEST: {
      return { ...state, isPreAuth: false };
    }
    case TYPES.PRE_AUTH_SUCCESS: {
      return { ...state, isPreAuth: true };
    }
    case TYPES.PRE_AUTH_SET_LINK: {
      return { ...state, redirectLink: action.payload };
    }
    case TYPES.SERVER_ERROR: {
      return { ...state, serverError: action.payload };
    }
    case TYPES.UPDATE_STEP_REGISTER: {
      return { ...state, stepReg: action.payload };
    }
    case TYPES.FORGOTTEN_STEPS: {
      return { ...state, stepForgot: action.payload };
    }
    case TYPES.OPEN_AUTH_UPDATE_STATUS: {
      return { ...state, openAuthStatus: action.payload };
    }
    case TYPES.OPEN_AUTH_UPDATE_URL: {
      return { ...state, openAuthUrl: action.payload };
    }
    case TYPES.SET_LINKS_SSO: {
      return { ...state, sso: { ...state.sso, links: action.payload } };
    }
    case TYPES.SET_VERIFY: {
      return { ...state, isVerification: action.payload };
    }
    case TYPES.UPDATE_DATA_USER: {
      return { ...state, updateDataUser: action.payload };
    }
    case TYPES.AUTH_REGISTER_SSO: {
      return { ...state, sso: { ...state.sso, registerViaSSO: action.payload } };
    }
    case TYPES.PHONE_REQUIRED_SSO: {
      return { ...state, sso: { ...state.sso, isPhoneRequired: action.payload } };
    }
    case TYPES.DATA_USER_SSO: {
      return { ...state, sso: { ...state.sso, dataUserSSO: action.payload } };
    }
    case TYPES.AUTH_STATUS: {
      return { ...state, status: action.payload };
    }
    case TYPES.AUTH_ON_USER_SUCCESS: {
      return { ...state, guestAuth: { id: action.payload, isAuth: true } };
    }
    case TYPES.SIGN_OUT_ON_USER_REQUEST: {
      return { ...state, guestAuth: { ...state.guestAuth, isAuth: false } };
    }
    default: {
      return state;
    }
  }
};
