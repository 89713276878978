import React, { FC, useEffect, useState } from "react";
import Layout from "components/auth/layout";
import { Button, Text, Wrapper } from "components/ui";
import { StoreTypes } from "store/types";
import { connect, ConnectedProps } from "react-redux";
import { AUTH_ROUTES_URL } from "constant/routers";
import { Loading } from "components/ui/loading";
import history from "services/history";
import { verificationEmail } from "store/auth/actions";
import { getAuthLoad } from "store/auth/selectors/getAuthLoadingSelector";
import { getAuthServerError } from "store/auth/selectors/getAuthErrorSelector";
import { authLogo } from "components/ui/helpersComponents";
import { isHyundaiTheme, titleCompanyTheme } from "components/ui/switch-themes";

const VerifyEmail: FC<ReduxProps> = ({ sendUrl, isLoading, error }) => {
  const [title, setTitle] = useState("Идет загрузка.....");

  useEffect(() => {
    const locationStr = String(document.location.href);
    const getUrl = locationStr.split("verify/")[1];
    sendUrl(getUrl);
  }, []);

  useEffect(() => {
    if (error) {
      setTitle(error);
    }
  }, [error]);

  return (
    <Layout>
      <Loading isLoading={isLoading}>
        <div className="text-center">
          {authLogo}
          <Wrapper pb={48}>
            <Text uppercase={!isHyundaiTheme} size="h3" align="center" theme={titleCompanyTheme}>
              Подтверждение Email
            </Text>
          </Wrapper>
          <Wrapper pb={32}>
            <Text size="h4" align="center" theme={titleCompanyTheme}>
              {title}
            </Text>
          </Wrapper>
          <Wrapper>
            <Button fullWidth onClick={() => history.push(AUTH_ROUTES_URL.SIGN_IN)}>
              На главную
            </Button>
          </Wrapper>
        </div>
      </Loading>
    </Layout>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  isLoading: getAuthLoad(store),
  error: getAuthServerError(store)
});

const mapDispatchToProps = {
  sendUrl: verificationEmail
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(VerifyEmail);
