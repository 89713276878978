export const isHyundaiTheme = process.env.REACT_APP_COMPANY === "hyundai";

/// Label на шагах авторизации, регистрации, вост пароля
// ввод телефона

export const labelPhone = !isHyundaiTheme ? "Номер телефона" : null;
export const labelEmail = !isHyundaiTheme ? "Email" : null;
export const labelCode = !isHyundaiTheme ? "Подтверждение телефона" : null;
export const labelPassword = !isHyundaiTheme ? "Пароль" : null;
export const labelName = !isHyundaiTheme ? "Имя" : null;
export const labelSurname = !isHyundaiTheme ? "Фамилия" : null;

const mainTheme = isHyundaiTheme ? "hyundai" : "genesis";
export const company = isHyundaiTheme ? "Hyundai ID" : "Genesis ID";
export const companyName = isHyundaiTheme ? "Hyundai" : "Genesis";
export const mainCompany = isHyundaiTheme ? "ХМСНГ" : "Genesis";
/// name division
export const nameCompanyDivision = isHyundaiTheme ? "ХМСНГ" : "GENESIS";

/// Для текста и заголовков
export const titleCompanyTheme = isHyundaiTheme ? "black" : "white";
export const sizeTitleAuth = isHyundaiTheme ? "h2" : "h2M";
export const sizeTitleCreateUsers = isHyundaiTheme ? "h4" : "h3M";
export const sizeTitleBanner = isHyundaiTheme ? "h1L" : "h1M";
export const alignText = isHyundaiTheme ? "left" : "center";
/// styles button
export const buttonMainTheme = isHyundaiTheme ? "blue" : "black";
export const buttonTheme = isHyundaiTheme ? "blue" : "gold";
export const buttonAddAndRemoveTheme = isHyundaiTheme ? "blue" : "white";
export const buttonRadioSize = isHyundaiTheme ? "xs" : "normal";
export const buttonEditTheme = isHyundaiTheme ? "light" : "white";

/// styles link
export const linkTimerLink = isHyundaiTheme ? "blue" : "gold";
export const linkThemePassword = isHyundaiTheme ? "lightBlue" : "gold";
/// style Spinner
export const spinnerCompanyTheme = isHyundaiTheme ? "white" : "black";

/// logo in admin panel
export const logoCompany = isHyundaiTheme ? "logo4" : "logo6";

/// Переменная для текста нужна
/// сделать обьект для конфига
export const linkMainTheme = isHyundaiTheme ? "blue" : "white";
/// logout
export const logOutTheme = isHyundaiTheme ? "dark" : "white";

/// table checkbox
export const checkmarkTheme = isHyundaiTheme ? "blue" : "black";
/// styles pagination
export const paginationTheme = isHyundaiTheme ? "blue" : "gold";

/// /styles input
export const inputAdminTheme = isHyundaiTheme ? "gray" : "white";

/// /styles guest border
export const guestBorderTheme = isHyundaiTheme ? "blue" : "gold";

/// size wrapper
export const paddingCreateUser = isHyundaiTheme ? 32 : 60;

export default mainTheme;
