import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { FormikTouched, useFormikContext } from "formik";
import { connect, ConnectedProps } from "react-redux";

import { handlerPaste } from "helpers/handlePastePhone";
import { PHONE_MASK } from "constant/phone-mask";
import trimValueOnBlur from "helpers/trimValueOnBlur";
import { detachTeamRequest, usersError } from "store/users/actions";
import { maxBirthdate, minBirthdate } from "constant/min-max-birthdate";
import { dealerCenterByIdRequest, dealerCenterByIdSuccess } from "store/dealer-center/actions";
import { StoreTypes } from "store/types";
import UploadPhoto from "components/lk/upload-photo/upload-photo";
import { getDepartmentSuggestion, getPositionSuggest, getDealersSuggestion } from "helpers/function-suggest";
import { Role } from "store/roles/types";
import { Chip } from "components/ui/chip";
import EmployeeRoles from "constant/eployee-roles";
import usePermissions from "helpers/usePermissions";
import { superadminPermissions } from "constant/permissions";
import { inputAdminTheme, isHyundaiTheme, paddingCreateUser, sizeTitleCreateUsers } from "components/ui/switch-themes";
import AutoSuggest from "components/admin/autosaggest";
import { autoSuggestOnSelected, onSelectedPosition } from "components/admin/personal-info/helpers-autosugest";
import { errorMessages } from "components/admin/personal-info/erorrMessages";
import styles from "./personal-info.module.scss";
import { Input, Text, Wrapper } from "../../ui";
import CustomDatepicker from "../../ui/custom-datepicker";
import debounce from "../../../helpers/debounce";
import TeamName from "../../../constant/team-name";

type ValuesType = {
  [key: string]: any;
};

export type DataDealershipTypes = {
  id: number;
  data: string;
};

type Props = {
  defaultRole?: any;
  isClient?: boolean;
  setFieldError: (field: string, errorMsg: string) => void;
  setFieldValue: (field: string, value: any) => void;
  setFieldTouched: (name: string, value: boolean) => void;
  errors: ValuesType;
  values: ValuesType;
  teamName?: TeamName;
  isEdit: boolean;
  reset?: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  touched: FormikTouched<any>;
};

const PersonalInfo: FC<Props & ReduxProps> = ({
  currentUserRole,
  defaultRole,
  isClient,
  setFieldValue,
  setFieldTouched,
  errors,
  values,
  dealerCenterByIdRequest: getCurrentDealer,
  isEdit,
  dealerCenterByIdSuccess: setCurrentDealerCenter,
  reset,
  handleChange,
  handleBlur,
  touched,
  userError,
  setUsersError,
  setFieldError
}) => {
  const [clearInput, setClearInput] = useState(false);
  const { values: formValues } = useFormikContext();

  const { roles, position_id: positionId } = formValues as any;
  const roleName = roles && roles[0]?.name;
  const isSuperadmin = usePermissions(superadminPermissions);

  useEffect(() => {
    if (isEdit && values.team) {
      getCurrentDealer(values.team);
    }
    return (): void => {
      setCurrentDealerCenter(null);
    };
  }, [isEdit]);

  const isRoleDealers = useMemo(() => {
    return (
      roles?.length &&
      roles?.some((role: Role) => role.name === EmployeeRoles.adminDC || role.name === EmployeeRoles.employeeDC)
    );
  }, [roles]);

  useEffect(() => {
    setFieldValue("isEmployee", isRoleDealers);
  }, [isRoleDealers]);

  const typePosition = isRoleDealers ? "dc" : "main";

  const getPositionByDebounce = debounce(getPositionSuggest(typePosition), 500);
  const getSuggestionByDebounce = debounce(getDealersSuggestion, 500);
  const getDepartmentByDebounce = debounce(getDepartmentSuggestion, 500);

  const notRoleClient = useMemo(() => {
    return roles?.length && !roles?.every((item: Role) => item.name.includes("client"));
  }, [roles]);

  useEffect(() => {
    /// чистка при смене роли с дц на хмснг
    if (!isEdit || currentUserRole !== roleName) {
      if (positionId) {
        setFieldValue("position_id", "");
        setFieldTouched("position_id", true);
        setClearInput(true);
      }
    }
  }, [isRoleDealers, notRoleClient]);

  const defaultRoleClient = (): void => {
    if (roles.length === 0) {
      setFieldValue("roles", [...defaultRole]);
    }
  };
  const checkedUserError = userError || {};

  return (
    <div className={styles.wrapper}>
      <div className={styles.avatar}>
        <Wrapper pt={40} pb={32}>
          <Text uppercase={!isHyundaiTheme} size={sizeTitleCreateUsers} theme="black">
            Личная информация
          </Text>
        </Wrapper>
      </div>
      <div className={styles.wrap}>
        <div className={styles.blockColumn}>
          <Wrapper pb={paddingCreateUser}>
            <Input
              onFocus={isClient ? defaultRoleClient : undefined}
              name="name"
              label="Имя"
              type="text"
              theme={inputAdminTheme}
              placeholder="Имя"
              value={values.name}
              onChange={handleChange}
              onBlur={(): void => trimValueOnBlur("name", values.name, setFieldValue, setFieldTouched)}
              errorMessages={(touched.name && errors.name) || (values.name && errors.name)}
              invalid={(!!errors.name && !!touched.name) || (values.name && errors.name)}
            />
          </Wrapper>
          <Wrapper pb={paddingCreateUser}>
            <CustomDatepicker
              label="Дата рождения"
              placeholder="дд.мм.гггг"
              selected={values.birthdate}
              defaultDate="2000-01-01"
              onChange={setFieldValue}
              fieldName="birthdate"
              minDate={new Date(minBirthdate)}
              maxDate={new Date(maxBirthdate)}
            />
          </Wrapper>
          <Wrapper pb={paddingCreateUser}>
            <Input
              name="email"
              label="Email"
              placeholder="Укажите email"
              type="text"
              theme={inputAdminTheme}
              value={values.email}
              onChange={(event): void => {
                handleChange(event);
              }}
              onFocus={(): void => {
                if (checkedUserError.email) {
                  setUsersError({ email: "" });
                }
              }}
              onBlur={(): void => trimValueOnBlur("email", values.email, setFieldValue, setFieldTouched)}
              errorMessages={
                (touched.email && errors.email) || (values.email && errors.email) || checkedUserError.email
              }
              invalid={(!!errors.email && !!touched.email) || (values.email && errors.email) || checkedUserError.email}
            />
          </Wrapper>
          {notRoleClient && roles.length ? (
            <Wrapper pb={paddingCreateUser}>
              <AutoSuggest
                autoSuggestOnSelected={onSelectedPosition}
                getSuggestion={getPositionByDebounce}
                clear={clearInput}
                initialValues={values.position}
                autoComplete="off"
                label="Должность"
                placeholder="Выберите должность"
                fieldName="position_id"
                errorMessages={errors.position_id && touched.position_id ? errors.position_id : null}
                invalid={!!errors.position_id && !!touched.position_id}
              />
            </Wrapper>
          ) : null}
        </div>
        <div className={styles.blockColumn}>
          <Wrapper pb={paddingCreateUser}>
            <Input
              name="lastname"
              label="Фамилия"
              type="text"
              theme={inputAdminTheme}
              placeholder="Фамилия"
              value={values.lastname}
              onChange={handleChange}
              onBlur={(): void => trimValueOnBlur("lastname", values.lastname, setFieldValue, setFieldTouched)}
              errorMessages={(touched.lastname && errors.lastname) || (values.lastname && errors.lastname)}
              invalid={(!!errors.lastname && !!touched.lastname) || (values.lastname && errors.lastname)}
            />
          </Wrapper>
          <Wrapper pb={paddingCreateUser}>
            <Input
              type="tel"
              name="phone"
              label="Телефон"
              mask={PHONE_MASK}
              placeholder="Укажите телефон"
              theme={inputAdminTheme}
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={(): void => {
                if (checkedUserError.phone) {
                  setUsersError({ phone: "" });
                }
              }}
              errorMessages={
                (touched.phone && errors.phone) || (values.phone && errors.phone) || checkedUserError.phone
              }
              invalid={(!!errors.phone && !!touched.phone) || (values.phone && errors.phone) || checkedUserError.phone}
              onPaste={(elem: React.ClipboardEvent<HTMLInputElement>): void => handlerPaste(elem, setFieldValue)}
            />
          </Wrapper>
          {typePosition === "dc" ? (
            <div className={styles.wrapper_mb}>
              <AutoSuggest
                autoSuggestOnSelected={autoSuggestOnSelected}
                values={values.teams}
                clear={clearInput}
                label="Дилерский центр"
                placeholder="Выберите центр"
                fieldName="teams"
                autoComplete="off"
                getSuggestion={getSuggestionByDebounce}
                errorMessages={errors.teams && touched.teams ? errors.teams : null}
                invalid={!!errors.teams && !!touched.teams}
              />
              <Chip isHidden={isSuperadmin} setData={setFieldValue} isEdit={isEdit} dataServer={values.teams || []} />
            </div>
          ) : null}
          {typePosition === "main" && notRoleClient ? (
            <>
              <Wrapper pb={paddingCreateUser}>
                <AutoSuggest
                  autoSuggestOnSelected={onSelectedPosition}
                  values={values.department}
                  initialValues={values.department}
                  clear={clearInput}
                  label="Департамент"
                  placeholder="Выберите департамент"
                  fieldName="department_id"
                  getSuggestion={getDepartmentByDebounce}
                  invalid={!values.department_id && !!touched.department_id}
                  errorMessages={
                    !values.department_id && touched.department_id ? errorMessages.departmentNotSelect : null
                  }
                />
              </Wrapper>
            </>
          ) : null}
        </div>
        <div className={styles.avatarImage}>
          <Wrapper>
            {isEdit ? (
              <UploadPhoto setFieldValue={setFieldValue} picture={values.picture} userId={values.id} />
            ) : (
              <UploadPhoto setFieldValue={setFieldValue} resetAfterSubmit />
            )}
          </Wrapper>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ employees, dealerCenter, users: { error } }: StoreTypes) => ({
  currentDealerCenter: dealerCenter.currentDealerCenter,
  positionEmployee: employees.currentEmployee?.position,
  teamsCurrentEmployees: employees.currentEmployee?.teams,
  userError: error,
  currentUserRole: employees?.currentEmployee?.roles && employees?.currentEmployee?.roles[0]?.name
});

const mapDispatchToProps = {
  dealerCenterByIdRequest,
  dealerCenterByIdSuccess,
  setUsersError: usersError,
  detachTeam: detachTeamRequest
};

PersonalInfo.defaultProps = {
  isClient: false
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PersonalInfo);
