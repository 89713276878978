import React, { FC, useEffect, useState } from "react";

import { connect, ConnectedProps } from "react-redux";
import { STEP_FORGOTTEN } from "constant/step-auth";
import { forgottenCodeAction, forgottenLoginAction, forgottenStep } from "store/auth/actions";
import { StoreTypes } from "store/types";
import { addError, createControl, deleteError, validateForm } from "helpers/form-bulder";
import { getAuthError } from "store/auth/selectors/getAuthErrorSelector";
import { getAuthData } from "store/auth/selectors/getAuthDataSelector";
import Timer from "components/ui/timer/timer";
import { Input, Text, Wrapper, Link } from "components/ui";
import { authLogo, subTitleCode } from "components/ui/helpersComponents";
import { isHyundaiTheme, labelCode, sizeTitleAuth, titleCompanyTheme } from "components/ui/switch-themes";

const formInitialState = {
  forgottenCode: createControl(
    {
      placeholder: "Введите код",
      value: ""
    },
    {
      required: true
    }
  )
};

const ForgottenCode: FC<ReduxProps> = ({ login: onResend, onStep: onSubmit, code, user, errors }) => {
  const [showTimer, setShowTimer] = useState(false);
  const [form, setForm] = useState({
    isFormValid: false,
    formControls: formInitialState
  });
  /**
   * добавление ошибок с сервера
   */
  useEffect(() => {
    if (errors) {
      const formControls = addError<typeof form.formControls>(form.formControls, errors);
      setForm({
        formControls,
        isFormValid: validateForm(formControls)
      });
    }
  }, [errors]);

  useEffect(() => {
    setShowTimer(true);
    const formControls = deleteError<typeof form.formControls>(form.formControls);
    setForm({
      formControls,
      isFormValid: validateForm(formControls)
    });
    return () => setShowTimer(false);
  }, []);

  const changeHandler = (value: string): void => {
    if (value.length === 4) {
      code(value);
    }
  };

  const resendCode = () => {
    let data = user;
    data = String(user);
    onResend({ username: data, phone: data, type: "phone" });
  };

  // TEMPLATE
  return (
    <div className="text-center">
      {authLogo}
      <Wrapper pb={48}>
        <Text uppercase={!isHyundaiTheme} size={sizeTitleAuth} align="center" theme={titleCompanyTheme}>
          Восстановление пароля
        </Text>
      </Wrapper>
      <Wrapper pb={32}>{subTitleCode}</Wrapper>
      <Wrapper pb={16}>
        <Input
          label={labelCode}
          type="text"
          placeholder={form.formControls.forgottenCode.placeholder}
          errorMessages={form.formControls.forgottenCode.errorMessage}
          invalid={!form.formControls.forgottenCode.valid && form.formControls.forgottenCode.touched}
          theme="gray"
          onChange={e => changeHandler(e.target.value)}
        />
      </Wrapper>
      <Wrapper pb={32}>
        <Timer setShowTimer={() => setShowTimer(!showTimer)} start={showTimer} onClick={resendCode} />
      </Wrapper>
      <Wrapper pb={40}>
        <Link
          href="/"
          isButton
          onClick={() => onSubmit(STEP_FORGOTTEN.TELEPHONE)}
          type="text"
          weight="regular"
          theme="gray"
          underline
        >
          Назад
        </Link>
      </Wrapper>
    </div>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  user: getAuthData(store).username,
  errors: getAuthError(store)
});
const mapDispatchToProps = {
  onStep: forgottenStep,
  code: forgottenCodeAction,
  login: forgottenLoginAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ForgottenCode);
