import React, { FC, useEffect } from "react";
import Modal from "react-modal";
import cn from "classnames";
import styles from "./popup.module.scss";
import { alertClose } from "../../../assets/icons";

Modal.setAppElement("#root");

export type PropsType = {
  onClick?: () => void;
  isOpen: boolean;
  height?: string;
};

export const Popup: FC<PropsType> = ({ children, onClick, isOpen, height = "588px" }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClick}
      style={{ content: { maxHeight: height } }}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <button type="button" className={styles.closeBtn} onClick={onClick}>
        <img src={alertClose} alt="close" />
      </button>
      <div className={styles.popupContent}>{children}</div>
    </Modal>
  );
};
