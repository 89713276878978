import { isHyundaiTheme } from "components/ui/switch-themes";
import { Wrapper } from "components/ui/wrapper";
import { Logo } from "components/ui/logo";
import React from "react";
import { Text } from "components/ui/text";
import { Hint } from "components/ui/hint";
import styles from "components/auth/layout/layout.module.scss";

export const authLogoGenesis = isHyundaiTheme ? (
  <>
    <div> </div>
  </>
) : (
  <div className={styles.logoMobile}>
    <div>
      <Logo type="logo7" />
    </div>
  </div>
);

export const authLogo = isHyundaiTheme ? (
  <Wrapper pb={32}>
    <Logo type="logo2" />
  </Wrapper>
) : null;

export const headerLogo = isHyundaiTheme ? <Logo type="logo2" /> : <Logo type="logo6" />;

export const registerTitle = (
  <Wrapper pb={60}>
    <Text size="h2M" uppercase align="center" theme="white">
      Регистрация
    </Text>
  </Wrapper>
);

export const subTitleCode = isHyundaiTheme ? (
  <Wrapper pb={32}>
    <Text size="main" align="left" theme="black">
      Прослушайте сообщение оператора и введите 4 цифры в поле
      <Hint
        isHyundaiTheme={isHyundaiTheme}
        text={
          'Если нет входящего звонка, дождитесь, пожалуйста, активности кнопки "Отправить повторно" по истечении 120 секунд и повторите попытку.'
        }
      />
    </Text>
  </Wrapper>
) : (
  <Wrapper pb={32}>
    <Text size="main" align="left" theme="white">
      На указанный Вами телефон поступит звонок. Прослушайте сообщение оператора и введите 4 цифры в поле.
      <Hint
        isHyundaiTheme={isHyundaiTheme}
        text={
          'Если нет входящего звонка, дождитесь, пожалуйста, активности кнопки "Отправить повторно" по истечении 120 секунд и повторите попытку.'
        }
      />
    </Text>
  </Wrapper>
);
