import { FormikErrors } from "formik";
import { ErrorType } from "components/admin/added-users/added-user-form/added-user-form";

export const scrollUp = (errors: FormikErrors<any> | ErrorType, setUseScrollUp: (arg: boolean) => void): void => {
  if (errors) {
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      const selectorKey = keys[0] === "roles" ? "id" : "name";
      const selector = `[${selectorKey}="${keys[0]}"]`;
      const errorElement: HTMLElement | null = document.querySelector(selector);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
        setUseScrollUp(false);
      }
    }
  }
};
