import React, { FC } from "react";
import cn from "classnames";

import { isHyundaiTheme, linkMainTheme } from "components/ui/switch-themes";
import hyundai from "components/ui/link/styles/link-hyundai.module.scss";
import genesis from "components/ui/link/styles/link-genesis.module.scss";

export type PropsType = {
  children: React.ReactNode;
  isButton?: boolean;
  size?: "normal" | "small" | "big";
  theme?: "blue" | "lightBlue" | "gray" | "gold" | "white" | "uppercase";
  underline?: boolean;
  href?: string;
  onClick?: () => void;
  type?: "head" | "text";
  weight?: "media" | "regular";
  textAlign?: "left" | "center" | "right";
  blocked?: boolean;
  target?: "_blank" | "_parent" | "_top" | "_self";
  style?: any;
};

export const Link: FC<PropsType> = ({
  target = "_self",
  blocked,
  children,
  type = "head",
  isButton,
  onClick,
  size = "normal",
  theme = linkMainTheme,
  weight = "media",
  href,
  underline,
  textAlign = "center",
  style = {}
}) => {
  const stylesCompany = isHyundaiTheme ? hyundai : genesis;
  const cl = cn(stylesCompany.link, {
    [stylesCompany[type]]: type,
    [stylesCompany[size]]: size,
    [stylesCompany[theme]]: theme,
    [stylesCompany[weight]]: weight,
    [stylesCompany.underline]: underline,
    [stylesCompany[textAlign]]: textAlign
  });
  if (isButton && onClick) {
    return (
      <button disabled={blocked} type="button" onClick={onClick} className={cl}>
        {children}
      </button>
    );
  }
  return (
    <a href={href} target={target} onClick={onClick} className={cl} style={style}>
      {children}
    </a>
  );
};
Link.defaultProps = {
  underline: false,
  href: ""
};
