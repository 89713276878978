import React, { FC } from "react";
import history from "services/history";
import { AUTH_ROUTES_URL } from "constant/routers";
import { Text, Wrapper, Button } from "components/ui";
import { authLogo, registerTitle } from "components/ui/helpersComponents";
import { company, companyName, titleCompanyTheme } from "components/ui/switch-themes";

const RegisterSuccess: FC = () => {
  return (
    <div className="text-center">
      {authLogo}
      {registerTitle}
      <Wrapper pb={40}>
        <Text size="main" align="left" theme={titleCompanyTheme}>
          {` Регистрация ${company} была успешно пройдена.  ${company} является вашим личным кабинетом в мире ${companyName}
          для всех сервисов. Единый аккаунт ${company} предоставляет вам возможность редактировать личные и контактные
          данные, управлять сервисами и способами входа. Войти в ${company} можно с любой платформы мира ${companyName}`}
        </Text>
      </Wrapper>
      <Wrapper pb={24}>
        <Button fullWidth onClick={(): void => history.push(AUTH_ROUTES_URL.SIGN_IN)}>
          На главную
        </Button>
      </Wrapper>
    </div>
  );
};

export default RegisterSuccess;
