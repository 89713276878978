import React, { FC, ReactElement, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, Form } from "formik";
import cn from "classnames";
import * as yup from "yup";
import { isValidName, isValidFirstAndLastChar } from "helpers/validate";
import trimValueOnBlur from "helpers/trimValueOnBlur";
import { buttonAddAndRemoveTheme, inputAdminTheme, isHyundaiTheme } from "components/ui/switch-themes";
import { Button } from "components/ui";
import { Alert } from "../../ui/alert";
import styles from "./personal-form.module.scss";
import "react-datepicker/dist/react-datepicker.module.css";
import { Input } from "../../ui/input";
import CustomDatepicker from "../../ui/custom-datepicker";
import { updateUserRequest, usersStatus } from "../../../store/users/actions";
import { UpdateType } from "../../../store/users/constants";
import { UpdateUserPayload, UserCars } from "../../../store/users/types";
import { StoreTypes } from "../../../store/types";
import { isChangedValues } from "../../../helpers/formik-handlers";
import Warning from "../../ui/warning";
import { minBirthdate, maxBirthdate } from "../../../constant/min-max-birthdate";
import hyundai from "../../ui/input/styles/input-hyandai.module.scss";
import genesis from "../../ui/input/styles/input-genesis.module.scss";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Необходимо заполнить данные")
    .min(1, "Используйте от 1 до 20 символов")
    .max(20, "Используйте от 1 до 20 символов")
    .test("name", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
    .matches(isValidName, "Используйте символы А-Я а-я A-Z a-z (пробел) -"),
  lastname: yup
    .string()
    .required("Необходимо заполнить данные")
    .min(1, "Используйте от 1 до 20 символов")
    .max(50, "Используйте от 1 до 50 символов")
    .test("lastname", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
    .matches(isValidName, "Используйте символы А-Я а-я A-Z a-z (пробел) -")
});

const PersonalForm: FC<ReduxProps> = ({
  id,
  name,
  lastname,
  birthdate,
  updateUserRequest: updateUser,
  isLoading,
  error,
  status,
  setStatus,
  cars
}) => {
  const [isShow, setShow] = useState(true);
  const leftColClass = cn(styles.col, styles.colWrapper);

  const submitHandler = (data: Partial<UpdateUserPayload>): void => {
    setStatus({ [name]: "" });
    const userData = { id, ...data };
    updateUser({ body: userData, updateType: UpdateType.UpdateMainUser, errorName: "personalData" });
  };

  const stylesCompany = isHyundaiTheme ? hyundai : genesis;

  const cnLabel = cn(stylesCompany.label, {
    [stylesCompany.black]: "black"
  });

  const initValuesCompareReference = {
    name,
    lastname,
    birthdate
  };

  return (
    <div className={styles.form}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name,
          lastname,
          birthdate
        }}
        onSubmit={submitHandler}
      >
        {({ values, setFieldValue, errors, isValid, handleChange, setFieldTouched }): ReactElement => (
          <Form>
            {isShow && <Alert onClick={setShow} />}
            <div className={styles.row}>
              <div className={leftColClass}>
                <div className={styles.wrapper}>
                  <Input
                    name="name"
                    label="Имя"
                    type="text"
                    theme={inputAdminTheme}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={(): void => trimValueOnBlur("name", values.name, setFieldValue, setFieldTouched)}
                    errorMessages={errors.name ? errors.name : null}
                    invalid={!!errors.name}
                  />
                </div>
                <div className={styles.wrapper}>
                  <Input
                    name="lastname"
                    label="Фамилия"
                    type="text"
                    theme={inputAdminTheme}
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={(): void => trimValueOnBlur("lastname", values.lastname, setFieldValue, setFieldTouched)}
                    errorMessages={errors.lastname ? errors.lastname : null}
                    invalid={!!errors.lastname}
                  />
                </div>
              </div>
              <div className={styles.col}>
                {cars.length > 0 && (
                  <div className={styles.carsContainer}>
                    <div className={cnLabel}>Автомобили</div>
                    <div className={styles.carsInContainer}>
                      {cars.map((car: UserCars, index: number) => (
                        <div key={car.id} className={index !== 0 && styles.carItem}>
                          {`Модель: ${car.model_name_en} VIN: ${car.vin}`}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className={styles.wrapper}>
                  <CustomDatepicker
                    label="Дата рождения"
                    selected={values.birthdate}
                    defaultDate="2000-01-01"
                    onChange={setFieldValue}
                    fieldName="birthdate"
                    minDate={new Date(minBirthdate)}
                    maxDate={new Date(maxBirthdate)}
                  />
                </div>
              </div>
            </div>

            <div className={styles.column}>
              <Button
                type="submit"
                theme={buttonAddAndRemoveTheme}
                disabled={isLoading || isChangedValues(initValuesCompareReference, values) || !isValid}
              >
                Сохранить
              </Button>
            </div>

            <div className={styles.wrapper}>
              {status && <Warning>{status.updateUser}</Warning>}
              {error?.personalData && <Warning color="red">{error.personalData}</Warning>}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ users: { user }, users }: StoreTypes) => ({
  id: user.id,
  name: user.name,
  phone: user.phone,
  email: user.email,
  lastname: user.lastname,
  cars: user.cars,
  birthdate: user.birthdate,
  isLoading: users.isLoading,
  error: users.error,
  status: users.status
});

const mapDispatchToProps = {
  updateUserRequest,
  setStatus: usersStatus
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PersonalForm);
