import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FiltersType } from "store/employees/types";
import { RadioButton } from "components/ui";

import { buttonRadioSize, isHyundaiTheme } from "components/ui/switch-themes";
import { BlockType } from "constant/radiobutton-values";
import { omit } from "lodash";
import { NameButtonList } from "pages/admin/employees/employees";
import styles from "./users-tab-panel.module.scss";

type Props = {
  setFilters: (arg: FiltersType) => void;
  filters: FiltersType;
  setButtonName: Dispatch<SetStateAction<NameButtonList>>;
  setIsBanDeletionTab: Dispatch<SetStateAction<boolean>>;
};

const UsersTabPanel: FC<Props> = ({ setButtonName, setFilters, filters, setIsBanDeletionTab }) => {
  const [employeeType, setEmployeeType] = useState<string>(BlockType.all);

  const options = [
    { value: BlockType.all, text: "Все" },
    { value: BlockType.deleted, text: "Удаленные" }
  ];
  if (isHyundaiTheme) {
    options.push({ value: BlockType.banDeletion, text: "Запреты на удаление" });
  }

  useEffect(() => {
    if (employeeType === BlockType.banDeletion) {
      setButtonName(NameButtonList.edit);
      setIsBanDeletionTab(true);
    } else if (employeeType === BlockType.all) {
      setButtonName(NameButtonList.edit);
      setFilters({ ...omit(filters, ["is_deleted"]) });
      setIsBanDeletionTab(false);
    } else if (employeeType === BlockType.deleted) {
      setButtonName(NameButtonList.edit);
      setFilters({ ...omit(filters), is_deleted: true });
      setIsBanDeletionTab(false);
    } else {
      setButtonName(NameButtonList.edit);
      setFilters({ ...omit(filters, "is_deleted") });
      setIsBanDeletionTab(false);
    }
  }, [employeeType, setFilters]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.panel}>
        <RadioButton
          size={buttonRadioSize}
          noBorder
          initialSelect={employeeType}
          onChange={(type: string): void => setEmployeeType(type)}
          options={options}
        />
      </div>
    </div>
  );
};

export default connect(null)(UsersTabPanel);
