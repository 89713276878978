import { TYPES_SERVICES } from "store/services/constants";
import { ActionsType, Store } from "./types";
import { RESET_ERROR_AND_STATUS } from "../constants";

const initialState: Store = {
  isLoading: false,
  servicesIsLoaded: false,
  error: null,
  services: {
    current_page: 0,
    data: [],
    last_page: 0,
    per_page: 0,
    total: 0
  },
  personalDataList: [],
  permissionList: [],
  isSuccessModalOpen: false,
  thirdPartiesList: [],
  selectedRows: [],
  deleteError: null,
  status: null,
  currentServiceCredentials: null,
  modalWindow: false,
  credentialsPopup: false
};

export const servicesReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_SERVICES.GET_SERVICES_REQUEST: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_SERVICES.GET_SERVICES_SUCCESS: {
      return { ...state, services: action.payload, servicesIsLoaded: true, isLoading: false };
    }
    case TYPES_SERVICES.GET_PERSONAL_DATA_TYPE_SUCCESS: {
      return { ...state, personalDataList: action.payload };
    }
    case TYPES_SERVICES.GET_PERMISSION_LIST_SUCCESS: {
      return { ...state, permissionList: action.payload };
    }
    case TYPES_SERVICES.GET_SERVICES_ERROR: {
      return { ...state, error: { ...state.error, ...action.payload }, isLoading: false };
    }
    case TYPES_SERVICES.GET_SERVICE_BY_ID_REQUEST: {
      return { ...state, isLoading: true, error: null, isSuccessModalOpen: false };
    }
    case TYPES_SERVICES.GET_SERVICE_BY_ID_SUCCESS: {
      return { ...state, currentService: action.payload, servicesIsLoaded: true, isLoading: false };
    }
    case TYPES_SERVICES.GET_SERVICE_BY_ID_ERROR: {
      return { ...state, error: { ...state.error, ...action.payload }, isLoading: false };
    }
    case TYPES_SERVICES.OPEN_SERVICE_SUCCESS_MODAL: {
      return { ...state, isSuccessModalOpen: true };
    }
    case TYPES_SERVICES.CLOSE_SERVICE_SUCCESS_MODAL: {
      return { ...state, isSuccessModalOpen: false };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null, deleteError: null, status: null };
    }
    case TYPES_SERVICES.GET_THIRD_PARTIES_LIST_SUCCESS: {
      return { ...state, thirdPartiesList: action.payload };
    }
    case TYPES_SERVICES.SERVICES_LOAD: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_SERVICES.SERVICES_LOAD_STOP: {
      return { ...state, isLoading: false };
    }
    case TYPES_SERVICES.DELETE_SERVICE_SUCCESS: {
      return {
        ...state,
        services: {
          ...state.services,
          data: state.services.data.filter(service => service.id !== action.payload)
        }
      };
    }
    case TYPES_SERVICES.SET_SERVICES_ROWS: {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
    case TYPES_SERVICES.FILTER_SERVICES_ROWS: {
      return {
        ...state,
        selectedRows: state.selectedRows.filter(item => item !== action.payload)
      };
    }
    case TYPES_SERVICES.DELETE_SERVICE_ERROR: {
      return { ...state, deleteError: action.payload };
    }
    case TYPES_SERVICES.MODAL_OPEN_SERVICE: {
      return { ...state, modalWindow: action.payload };
    }
    case TYPES_SERVICES.GET_SERVICE_CREDENTIALS_SUCCESS: {
      return { ...state, currentServiceCredentials: action.payload };
    }
    case TYPES_SERVICES.POPUP_OPEN_CREDENTIALS: {
      return { ...state, credentialsPopup: action.payload };
    }
    case TYPES_SERVICES.CLEAR_SERVICE_CREDENTIALS: {
      return { ...state, currentServiceCredentials: null };
    }
    case TYPES_SERVICES.SERVICES_STATUS: {
      return { ...state, status: action.payload };
    }
    default: {
      return state;
    }
  }
};
