import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import { STEP_SIGN_IN } from "constant/step-auth";
import { authRequestPassword, authUpdateStepSignIn, sendVerifyEmail, serverError, setVerify } from "store/auth/actions";
import { AUTH_ROUTES_URL } from "constant/routers";
import { StoreTypes } from "store/types";
import { isValidPassword, isValidPasswordSpace } from "helpers/validate";
import { Button, Input, Wrapper } from "components/ui";

import { getAuthServerError } from "store/auth/selectors/getAuthErrorSelector";
import { Text } from "components/ui/text";
import { Link } from "components/ui/link";
import history from "services/history";
import { authLogo } from "components/ui/helpersComponents";
import {
  isHyundaiTheme,
  labelPassword,
  linkThemePassword,
  sizeTitleAuth,
  titleCompanyTheme
} from "components/ui/switch-themes";
import { getAuthIsVerify } from "store/auth/selectors/getAuthIsVerifySelector";
import { InvisibleSmartCaptcha } from "@yandex/smart-captcha";

const validationSchema = yup.object().shape({
  passwordSingIn: yup
    .string()
    .matches(isValidPassword, "Некорректные данные")
    .matches(isValidPasswordSpace, "Не используйте несколько пробелов подряд")
    .required("Необходимо заполнить данные")
    .min(6, "Пароль должен быть от 6 до 20 символов")
    .max(20, "Пароль должен быть от 6 до 20 символов")
});

const SignInPassword: FC<ReduxProps> = ({
  sendEmail,
  toggleVerify,
  isVerification,
  authUpdateStep: setStep,
  authRequestPassword: onSubmit,
  errorServer,
  warning
}: ReduxProps) => {
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [validCaptchaSuccess, setValidCaptchaSuccess] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const handleReset = () => {
    setValidCaptcha(false);
    setResetCaptcha(prev => prev + 1);
  };

  const formik = useFormik({
    initialValues: {
      passwordSingIn: ""
    },
    validationSchema,
    onSubmit: () => {
      setValidCaptcha(true);
    }
  });

  useEffect(() => {
    if (validCaptchaSuccess) {
      warning(null);
      onSubmit({ password: formik.values.passwordSingIn, token: captchaToken });
    }
    handleReset();
    setValidCaptchaSuccess(false);
  }, [validCaptchaSuccess]);

  useEffect(() => {
    if (errorServer) {
      formik.setFieldError("passwordSingIn", errorServer);
    }
  }, [errorServer]);

  const handleVerificationEmail = (): void => {
    sendEmail();
    toggleVerify(true);
  };

  return (
    <div className="text-center">
      <form onSubmit={formik.handleSubmit}>
        {authLogo}
        <Wrapper pb={48}>
          <Text uppercase={!isHyundaiTheme} size={sizeTitleAuth} align="center" theme={titleCompanyTheme}>
            Авторизация
          </Text>
        </Wrapper>
        <Wrapper pb={30}>
          <Input
            label={labelPassword}
            onChange={formik.handleChange}
            placeholder="Введите пароль"
            type="password"
            name="passwordSingIn"
            onBlur={formik.handleBlur}
            value={formik.values.passwordSingIn}
            invalid={Boolean(formik.errors.passwordSingIn && formik.touched.passwordSingIn)}
            send={!isVerification && formik.touched.passwordSingIn ? handleVerificationEmail : null}
            title="Отправить повторно"
            errorMessages={
              formik.errors.passwordSingIn && formik.touched.passwordSingIn ? formik.errors.passwordSingIn : null
            }
          />
        </Wrapper>
        <Wrapper pb={32}>
          <Link
            theme={linkThemePassword}
            type="text"
            weight="regular"
            underline
            href="/"
            isButton
            onClick={(): void => history.push(AUTH_ROUTES_URL.FORGOTTEN)}
          >
            Забыли пароль?
          </Link>
        </Wrapper>
        <InvisibleSmartCaptcha
          key={resetCaptcha}
          sitekey={process.env.REACT_APP_YANDEX_CAPTCHA_ID ?? ""}
          onSuccess={token => {
            setCaptchaToken(token);
            setValidCaptchaSuccess(true);
          }}
          visible={validCaptcha}
          onChallengeHidden={() => handleReset()}
        />
        <Wrapper pb={24}>
          <Button fullWidth type="submit">
            Продолжить
          </Button>
        </Wrapper>
        <Wrapper pb={32}>
          <Link
            href="/"
            isButton
            onClick={() => setStep(STEP_SIGN_IN.LOGIN)}
            type="text"
            weight="regular"
            theme="gray"
            underline
          >
            Назад
          </Link>
        </Wrapper>
        <Wrapper>
          <Link href="/" size="big" isButton onClick={() => history.push(AUTH_ROUTES_URL.REGISTER)}>
            Нет аккаунта? Зарегистрироваться
          </Link>
        </Wrapper>
      </form>
    </div>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  errorServer: getAuthServerError(store),
  isVerification: getAuthIsVerify(store)
});

const mapDispatchToProps = {
  warning: serverError,
  authUpdateStep: authUpdateStepSignIn,
  authRequestPassword,
  toggleVerify: setVerify,
  sendEmail: sendVerifyEmail
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SignInPassword);
