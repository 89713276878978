import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import { Button, Input, Wrapper } from "components/ui";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { isValidFirstAndLastChar, isValidINN, isValidMMName, isValidNameDealers } from "helpers/validate";
import { connect, ConnectedProps } from "react-redux";
import { StoreTypes } from "store/types";
import { useParams } from "react-router-dom";
import { Loading } from "components/ui/loading";
import Row from "components/ui/row";
import NotificationPopup from "components/ui/notification-popup";
import { isChangedValues } from "helpers/formik-handlers";
import { resetErrorAndStatus } from "store/actions";
import styles from "./added-legal-form.module.scss";
import history from "../../../../services/history";
import {
  createLegalEntityRequest,
  legalEntityInfoClear,
  legalEntityInfoRequest,
  setOpenLEModalWindow,
  updateLegalEntityRequest
} from "../../../../store/legalEntity/actions";
import LegalEntityUsersPanel from "../legalEntityUsersPanel";
import LegalEntityCarsPanel from "../legalEntityCarsPanel";

const validationSchema = yup.object().shape({
  legalName: yup
    .string()
    .test("legalName", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
    .matches(isValidMMName, "Используйте кириллицу или латиницу, цифры и символы: ~@#$%^-_(){}'`")
    .required("Необходимо заполнить данные")
    .min(1, "Используйте от 1 до 255 символов")
    .max(255, "Используйте от 1 до 255 символов")
    .required("Необходимо заполнить данные"),
  inn: yup
    .string()
    .matches(isValidINN, "ИНН должен содержать 10 или 12 цифр")
    .required("Необходимо заполнить данные")
});

type ValuesTypes = {
  legalName: string;
  inn: string;
};

type Props = {
  isEdit?: boolean;
};

const AddedLegalForm: FC<ReduxProps & Props> = ({
  getLegalEntityInfo,
  isEdit = false,
  errorServer,
  onUpdate,
  dataLegalEntity,
  clear,
  onCreate,
  isOpen,
  setOpen
}) => {
  const [employeesChange, setEmployeesChange] = useState(false);
  const { id: legalEntityId } = useParams<any>();
  const formikRef = useRef<any>();

  useEffect(() => {
    if (isEdit) {
      getLegalEntityInfo(legalEntityId);
    }
    return (): void => {
      setEmployeesChange(false);
      setOpen(false);
      clear();
    };
  }, []);

  const getInitialValues = (): ValuesTypes => {
    if (isEdit) {
      if (dataLegalEntity) {
        const { name, inn } = dataLegalEntity;
        return {
          legalName: name || "",
          inn: inn || ""
        };
      }
    }
    return {
      legalName: "",
      inn: ""
    };
  };

  const initValue = getInitialValues();
  const isDisplayContent = !isEdit || (isEdit && dataLegalEntity);

  useEffect(() => {
    if (errorServer) {
      formikRef.current.setFieldError("inn", errorServer);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [errorServer]);

  const inputsBlur = (item: string, value: string): void => {
    if (value) {
      formikRef.current.setFieldValue(item, value.trim());
      formikRef.current.setTouched({ [item]: true });
    }
  };

  const guard = (): void => {
    if (Object.keys(formikRef.current.errors).length !== 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const submitHandler = (values: any): void => {
    if (isEdit) {
      onUpdate({ name: values.legalName, inn: values.inn });
    } else {
      onCreate({ name: values.legalName, inn: values.inn });
    }
  };

  const notificationTitle = isEdit ? "Данные успешно изменены" : "Данные успешно сохранены";

  if (!isDisplayContent) {
    return (
      <Loading isLoading>
        <Wrapper pt={300}>
          <Row justifyContent="justifyCenter" alignItems="alignCenter">
            <h1>Идет загрузка</h1>
          </Row>
        </Wrapper>
      </Loading>
    );
  }
  return (
    <div className={styles.container}>
      <NotificationPopup
        title={notificationTitle}
        isOpen={isOpen}
        action={() => {
          if (dataLegalEntity && dataLegalEntity.id) {
            history.push(`/admin/legal/added/${dataLegalEntity.id}`);
          } else {
            history.push(`/admin/legal`);
          }
          setOpen(false);
        }}
      />
      <Formik
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={submitHandler}
        initialValues={initValue}
      >
        {({ values, touched, setFieldValue, errors, handleBlur, setFieldTouched, handleChange }): ReactElement => (
          <Form>
            <Wrapper pt={30} pl={40}>
              {isEdit ? (
                <h3 className={styles.title}>Редактирование юридического лица</h3>
              ) : (
                <h3 className={styles.title}>Добавление юридического лица</h3>
              )}
              <div className={styles.form_input}>
                <div>
                  <div>
                    <Input
                      name="legalName"
                      placeholder="Обязательное поле"
                      label="Название"
                      onChange={handleChange}
                      onBlur={(): void => inputsBlur("legalName", values.legalName)}
                      value={values.legalName}
                      invalid={Boolean(errors.legalName && touched.legalName)}
                      errorMessages={errors.legalName && touched.legalName ? errors.legalName : null}
                    />
                  </div>
                  <div>
                    <Input
                      name="inn"
                      placeholder="Обязательное поле"
                      label="ИНН"
                      onChange={handleChange}
                      onBlur={(): void => inputsBlur("inn", values.inn)}
                      value={values.inn}
                      invalid={Boolean(errors.inn && touched.inn)}
                      errorMessages={errors.inn && touched.inn ? errors.inn : null}
                    />
                  </div>
                </div>
              </div>
            </Wrapper>
            <div>
              <Wrapper className={styles.form_btn} pl={40} pb={20}>
                <div>
                  <Button
                    onClick={guard}
                    type="submit"
                    disabled={isChangedValues(initValue, values) && !employeesChange}
                  >
                    Сохранить
                  </Button>
                </div>
                <div>
                  <Button noBorder theme="transparent" onClick={(): void => history.push("/admin/legal")}>
                    Отменить
                  </Button>
                </div>
              </Wrapper>
              <Wrapper pb={80}>
                {isEdit ? <LegalEntityUsersPanel /> : null}
                {isEdit ? <LegalEntityCarsPanel /> : null}
              </Wrapper>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ legalEntity }: StoreTypes) => ({
  dataLegalEntity: legalEntity.editLegalEntity,
  errorServer: legalEntity.serverError,
  isOpen: legalEntity.modalWindow
});

const mapDispatchToProps = {
  onCreate: createLegalEntityRequest,
  onUpdate: updateLegalEntityRequest,
  getLegalEntityInfo: legalEntityInfoRequest,
  clear: legalEntityInfoClear,
  setOpen: setOpenLEModalWindow
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddedLegalForm);
