/* eslint-disable no-shadow */
import React, { FC, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { restoreAccountRequest, restoreUser } from "store/users/actions";
import { StoreTypes } from "store/types";
import { Button } from "components/ui";
import { company } from "components/ui/switch-themes";
import styles from "./restore-account.module.scss";
import PopupConfirm from "../../ui/popup-confirm";
import { authUpdateTtl, serverError } from "../../../store/auth/actions";
import { resetErrorAndStatus } from "../../../store/actions";

type Props = {
  id: string;
  phone: string;
  username: string | null;
  isLoading: boolean;
  error: {
    [key: string]: string;
  } | null;
  status: {
    [key: string]: string;
  } | null;
};

const RestoreAccount: FC<Props & ReduxProps> = ({ id, isLoading, restoreAccount }) => {
  const [isOpen, setOpen] = useState(false);

  const restoreAccepted = (): void => {
    setOpen(false);
    restoreAccount(id);
  };
  return (
    <div className={styles.deleteAcc}>
      <Button theme="link" onClick={(): void => setOpen(true)}>
        {`Отменить удаление аккаунта ${company.toUpperCase()}`}
      </Button>
      <PopupConfirm
        isOpen={isOpen}
        setOpen={setOpen}
        action={() => restoreAccepted()}
        title="Отменить удаление аккаунта?"
        desc={`Ваша заявка на удаление из сервиса ${company} будет отозвана`}
        disabled={isLoading}
      />
    </div>
  );
};

const mapStateToProps = ({ users: { user, isLoading }, users }: StoreTypes): Props => ({
  id: user.id,
  phone: user.phone,
  username: user.username,
  isLoading,
  status: users.status,
  error: users.error
});

const mapDispatchToProps = {
  restoreAccount: restoreAccountRequest,
  updateTime: authUpdateTtl,
  warning: serverError,
  resetStatus: resetErrorAndStatus
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(RestoreAccount);
