import React, { FC } from "react";
import cn from "classnames";
import styles from "./user-token-wrapper.module.scss";
import storageCookie from "../../../services/storageCookie";
import { ADMIN_TOKEN } from "../../../constant/api";
import { guestBorderTheme } from "../../ui/switch-themes";

type Props = {
  children: unknown;
  isGuestAuth?: boolean;
};

const UserTokenWrapper: FC<Props> = ({ children, isGuestAuth }) => {
  const adminToken = storageCookie.get(ADMIN_TOKEN);

  const cnLabel = cn(styles[`${guestBorderTheme}Label`], styles.guestModeLabel);
  const cnBorder = cn(styles[`${guestBorderTheme}Border`], styles.border);

  return (
    <div>
      <div>{children}</div>
      {isGuestAuth || adminToken ? <div className={cnBorder} /> : null}
      {isGuestAuth || adminToken ? <div className={cnLabel}>Гостевой режим</div> : null}
    </div>
  );
};

export default UserTokenWrapper;
