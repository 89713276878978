import { TYPES_PERSONAL_DATA } from "./constants";
import { PersonalDataType, Store, ActionsType } from "./types";
import { RESET_ERROR_AND_STATUS } from "../constants";

const initialState: Store = {
  isLoading: false,
  error: null,
  currentPersonalData: null,
  personalDataList: {
    current_page: 0,
    data: [],
    last_page: 0,
    per_page: 0,
    total: 0
  },
  selectedRows: [],
  deleteError: null,
  status: null
};

export const personalDataListReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_LOAD: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_LOAD_STOP: {
      return { ...state, isLoading: false };
    }
    case TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_SUCCESS: {
      return { ...state, personalDataList: action.payload };
    }
    case TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_ERROR: {
      return { ...state, error: { ...state.error, ...action.payload } };
    }
    case TYPES_PERSONAL_DATA.PERSONAL_DATA_BY_ID_SUCCESS: {
      return { ...state, currentPersonalData: action.payload };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null, deleteError: null, status: null };
    }
    case TYPES_PERSONAL_DATA.DELETE_PERSONAL_DATA_SUCCESS: {
      return {
        ...state,
        personalDataList: {
          ...state.personalDataList,
          data: state.personalDataList.data.filter((pd: PersonalDataType) => pd.id !== action.payload)
        }
      };
    }
    case TYPES_PERSONAL_DATA.SET_PERSONAL_DATA_LIST_ROWS: {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
    case TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_CLEAR_ROWS: {
      return {
        ...state,
        selectedRows: []
      };
    }
    case TYPES_PERSONAL_DATA.FILTER_PERSONAL_DATA_LIST_ROWS: {
      return {
        ...state,
        selectedRows: state.selectedRows.filter(item => item !== action.payload)
      };
    }
    case TYPES_PERSONAL_DATA.DELETE_PERSONAL_DATA_ERROR: {
      return { ...state, deleteError: action.payload };
    }
    case TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_STATUS: {
      return { ...state, status: action.payload };
    }
    default: {
      return state;
    }
  }
};
