import React, { FC, useRef, useState, ChangeEvent, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { uploadAvatarRequest } from "store/users/actions";
import { StoreTypes } from "store/types";
import Warning from "components/ui/warning";
import { Avatar } from "components/ui";
import styles from "./upload-photo.module.scss";
import { uploadIcon } from "../../../assets/icons";

type Error = {
  [key: string]: string;
};

type Props = {
  picture?: string;
  userId?: string;
  setFieldValue?: (field: string, value: FormData) => void;
  resetAfterSubmit?: boolean;
};

type StateProps = {
  isLoading: boolean;
  error: Error | null;
  avatarIsLoaded: boolean;
};

const getImageLink = (id: string) => `/front/user/${id}/avatar?timestamp=${Date.now()}`;

const UploadPhoto: FC<ReduxProps & Props> = ({
  userId,
  uploadAvatarRequest: uploadAvatar,
  isLoading,
  error: fetchError,
  setFieldValue,
  avatarIsLoaded,
  resetAfterSubmit
}) => {
  const [sizeError, setError] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<any>(userId ? getImageLink(userId) : "");
  const inputRef = useRef<HTMLInputElement>(null);

  const clickHandler = (): void => {
    inputRef.current?.click();
  };

  useEffect(() => {
    if (avatarIsLoaded && resetAfterSubmit) {
      setImagePreviewUrl("");
    } else if (avatarIsLoaded && userId) {
      setImagePreviewUrl(getImageLink(userId));
    }
  }, [avatarIsLoaded]);

  const fileSelectionHandler = ({ target: { files } }: ChangeEvent<HTMLInputElement>): void => {
    setError(false);
    const file = files && files[0];
    const formData = new FormData();
    if (file) {
      if (file.size > 10000000) {
        setError(true);
      } else if (setFieldValue) {
        formData.append("avatar", file);
        setFieldValue("picture", formData);
        const reader = new FileReader();
        reader.onloadend = (): void => {
          setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      } else if (userId) {
        formData.append("avatar", file);
        uploadAvatar({ id: userId, data: formData, setAvatar: "user" });
      }
    }
  };
  return (
    <div className={styles.wrap}>
      <button onClick={clickHandler} type="button" disabled={isLoading} className={styles.upload}>
        <Avatar src={imagePreviewUrl} size="normal" />
        <img className={styles.uploadIcon} src={uploadIcon} alt="upload" />
      </button>
      <input
        type="file"
        accept="image/jpeg,image/jpg,image/png,image/gif"
        className={styles.input}
        ref={inputRef}
        onChange={fileSelectionHandler}
      />
      {sizeError && <Warning color="red">Размер файла не должен превышать 10mb</Warning>}
      {fetchError && <Warning color="red">{fetchError.avatar}</Warning>}
    </div>
  );
};

const mapStateToProps = ({ users }: StoreTypes): StateProps => ({
  isLoading: users.isLoading,
  error: users.error,
  avatarIsLoaded: users.avatarIsLoaded
});

const mapDispatchToProps = {
  uploadAvatarRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(UploadPhoto);
