import React, { FC, useMemo } from "react";
import { Services } from "store/users/types";
import { isHyundaiTheme } from "components/ui/switch-themes";
import { notificationErrors } from "components/ui/notification/notification-errors";
import styles from "./service.module.scss";
import arrowGns from "./images/arrowGns.svg";

import { User as UserApi } from "../../../services/api/user";
import { arrow } from "./images";

type PropsType = {
  connectedServices: Array<Services>;
  unconnectedServices: Array<Services>;
  /// Требуется подтверждение email что бы был доступен LMS
  verificationEmail: string | null;
};

const Service: FC<PropsType> = ({ verificationEmail, connectedServices, unconnectedServices }) => {
  const signInServiceHandler = async (code: string, baseLink: string, newWindow: Window | null): Promise<void> => {
    try {
      const userInfo = await UserApi.getUserInfo().then(res => res);
      const seamlessURL = userInfo?.services?.find((item: any) => item.code === code)?.seamless_url;
      const cookies = document.cookie.split("; ");
      const token = cookies.find((item: string) => item.startsWith("token"))?.replace("token=", "");
      const refreshToken = cookies
        .find((item: string) => item.startsWith("refresh_token"))
        ?.replace("refresh_token=", "");

      const getLink = async () => {
        const { link } = await UserApi.serviceSignIn(code);
        return link;
      };
      const serviceLink =
        seamlessURL === null
          ? await getLink()
          : seamlessURL?.replace("[[access_token]]", `${token}`).replace("[[refresh_token]]", `${refreshToken}`);
      if (newWindow) {
        const newWindowReference = newWindow;
        newWindowReference.location.href = serviceLink;
      }
    } catch {
      if (newWindow) {
        const newWindowReference = newWindow;
        newWindowReference.location.href = baseLink;
      }
    }
  };

  const createWidgets = (services: Array<Services>): JSX.Element[] =>
    services.map(({ id, picture, code, link }) => {
      return (
        <button
          type="button"
          className={styles.widget}
          key={id}
          onClick={(): void => {
            if (code === "hyundai-lms" && !verificationEmail) {
              notificationErrors({
                title: "Доступ закрыт",
                message: "Для доступа в сервис подтвердите email"
              });
            } else {
              const newWindow = window.open();
              signInServiceHandler(code, link, newWindow);
            }
          }}
        >
          <img className={styles.logo} src={picture} alt={code} />
          <img className={styles.arrow} src={isHyundaiTheme ? arrow : arrowGns} alt="arrow" />
        </button>
      );
    });

  const unconnectedServicesWidgets = useMemo(() => createWidgets(unconnectedServices), [unconnectedServices]);
  const connectedServicesWidgets = useMemo(() => createWidgets(connectedServices), [connectedServices]);

  return (
    <>
      {connectedServices.length ? (
        <div className={styles.service}>
          <div className={styles.title}>Активные сервисы</div>
          <div className={styles.row}>{[...connectedServicesWidgets]}</div>
        </div>
      ) : null}
      {unconnectedServices.length ? (
        <div className={styles.service}>
          <div className={styles.title}>Сервисы</div>
          <div className={styles.row}>{[...unconnectedServicesWidgets]}</div>
        </div>
      ) : null}
    </>
  );
};

export default Service;
