import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AUTH_ROUTES_URL } from "constant/routers";
import { StoreTypes } from "store/types";
import { forgottenCheckingLinkAction } from "store/auth/actions";
import { getAuthLoad } from "store/auth/selectors/getAuthLoadingSelector";
import queryString from "query-string";
import storageCookie from "services/storageCookie";
import { TOKEN } from "constant/api";
import { Button, Text, Wrapper } from "components/ui";
import Layout from "components/auth/layout";
import { Loading } from "components/ui/loading";
import history from "services/history";
import { authLogo } from "components/ui/helpersComponents";
import { isHyundaiTheme, sizeTitleAuth, titleCompanyTheme } from "components/ui/switch-themes";
import { getAuthServerError } from "store/auth/selectors/getAuthErrorSelector";

const ForgottenByEmail: FC<ReduxProps> = ({ errorServer, dataLink: onSubmit, isLoading }) => {
  useEffect(() => {
    const locationStr = document.location.href;
    if (locationStr.includes("token") && locationStr.includes("email")) {
      const { email, token } = queryString.parse(document.location.search);
      storageCookie.set(TOKEN, token);
      onSubmit({ email: String(email), type: "email", token: String(token) });
    }
  }, []);

  return (
    <Layout>
      <Loading isLoading={isLoading}>
        <div className="text-center">
          {authLogo}
          <Wrapper pb={48}>
            <Text uppercase={!isHyundaiTheme} size={sizeTitleAuth} align="center" theme={titleCompanyTheme}>
              Восстановление пароля
            </Text>
          </Wrapper>
          <Wrapper pb={32}>
            <Text size="h4" align="center" theme={titleCompanyTheme}>
              {errorServer || "Загрузка..."}
            </Text>
          </Wrapper>
          <Wrapper>
            <Button fullWidth onClick={() => history.push(AUTH_ROUTES_URL.FORGOTTEN)}>
              На главную
            </Button>
          </Wrapper>
        </div>
      </Loading>
    </Layout>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  isLoading: getAuthLoad(store),
  errorServer: getAuthServerError(store)
});

const mapDispatchToProps = {
  dataLink: forgottenCheckingLinkAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ForgottenByEmail);
