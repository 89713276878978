import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { PhoneBlacklistApi } from "services/api/phone-blacklist";
import { notificationErrors } from "components/ui/notification/notification-errors";
import { TYPES_PHONE_BLACKLIST } from "./constants";
import {
  phoneBlacklistError,
  phoneBlacklistSuccess,
  phoneBlacklistLoad,
  phoneBlacklistLoadStop,
  deletePhoneBlacklistSuccess,
  deletePhoneBlacklistError,
  phoneBlacklistStatus,
  phoneBlacklistRequest,
  deletePhoneBlacklistRequest
} from "./actions";

function* getPhoneBlacklist(actions: ReturnType<typeof phoneBlacklistRequest>): SagaIterator {
  const { payload } = actions;
  yield put(phoneBlacklistLoad());
  try {
    const response = yield call(PhoneBlacklistApi.getPhoneBlacklist, payload);

    yield put(phoneBlacklistSuccess(response));
  } catch (e) {
    const result = { total: 0, per_page: 10, current_page: 1, last_page: 1, data: [] };
    yield put(phoneBlacklistSuccess(result));
    yield put(phoneBlacklistError({ phoneBlacklist: "Не удалось загрузить данные" }));
  } finally {
    yield put(phoneBlacklistLoadStop());
  }
}

function* deletePhoneBlacklist(actions: ReturnType<typeof deletePhoneBlacklistRequest>): SagaIterator {
  const { payload } = actions;
  yield put(phoneBlacklistLoad());
  try {
    yield call(PhoneBlacklistApi.deletePhoneBlacklist, payload);
    yield put(deletePhoneBlacklistSuccess(payload));
    yield put(phoneBlacklistStatus({ phoneBlacklist: "Успешно" }));
  } catch (e) {
    yield put(deletePhoneBlacklistError({ deletePhoneBlacklist: "Не удалось удалить телефон" }));
    notificationErrors({});
  } finally {
    yield put(phoneBlacklistLoadStop());
  }
}

function* phoneBlacklistSaga(): SagaIterator {
  yield takeLatest(TYPES_PHONE_BLACKLIST.PHONE_BLACKLIST_REQUEST, getPhoneBlacklist);
  yield takeEvery(TYPES_PHONE_BLACKLIST.DELETE_PHONE_BLACKLIST_REQUEST, deletePhoneBlacklist);
}

export { phoneBlacklistSaga };
