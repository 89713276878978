import { TYPES_PD } from "./constants";
import { RESET_ERROR_AND_STATUS } from "../constants";
import { ActionsType, Store } from "./types";

const initialState: Store = {
  isPersonalDataLoading: false,
  editPersonalData: null,
  edit: false,
  serverError: null,
  modalWindow: false
};

export const personalDataReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_PD.SET_PERSONAL_DATA_EDIT_MODE: {
      return { ...state, edit: true };
    }
    case TYPES_PD.PERSONAL_DATA_INFO_REQUEST: {
      return { ...state, isPersonalDataLoading: true };
    }
    case TYPES_PD.PERSONAL_DATA_INFO_SUCCESS: {
      return { ...state, editPersonalData: action.payload };
    }
    case TYPES_PD.PERSONAL_DATA_CREATE_SUCCESS: {
      return { ...state, editPersonalData: action.payload };
    }
    case TYPES_PD.PERSONAL_DATA_INFO_CLEAR: {
      return { ...state, editPersonalData: null };
    }
    case TYPES_PD.PERSONAL_DATA_INFO_LOAD_STOP: {
      return { ...state, isPersonalDataLoading: false };
    }
    case TYPES_PD.MODAL_OPEN_PERSONAL_DATA: {
      return { ...state, modalWindow: action.payload };
    }
    case TYPES_PD.SERVER_ERROR_PERSONAL_DATA: {
      return { ...state, serverError: action.payload };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, serverError: null };
    }
    default: {
      return state;
    }
  }
};
