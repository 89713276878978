import React, { FC } from "react";
import moment from "moment";
import { inputAdminTheme } from "components/ui/switch-themes";
import DatePicker from "react-datepicker";
import { Input } from "../input";
import "./custom-datepicker.scss";

type PropTypes = {
  defaultDate?: string;
  selected?: string | null;
  onChange: (fieldName: string, date: string | null) => void;
  fieldName: string;
  minDate: Date;
  maxDate: Date;
  placeholder?: string;
  label?: string;
};

const CustomDatePicker: FC<PropTypes> = ({
  selected,
  onChange,
  fieldName,
  minDate,
  maxDate,
  defaultDate,
  placeholder,
  label
}) => {
  const checkSelected = (): null | Date => {
    if (selected) {
      if (Date.parse(selected)) {
        return new Date(selected);
      }
      if (defaultDate) {
        return new Date(defaultDate);
      }
    } else if (defaultDate && !selected) {
      return new Date(defaultDate);
    }
    return null;
  };
  return (
    <DatePicker
      placeholderText={placeholder}
      selected={checkSelected()}
      dateFormat="dd.MM.yyyy"
      name="birthdate"
      onChange={(date: Date): void => {
        if (date) {
          const dateToString = moment(date).format("YYYY-MM-DD");
          onChange(fieldName, dateToString);
        } else {
          const dateToString = defaultDate ? moment(defaultDate).format("YYYY-MM-DD") : null;
          onChange(fieldName, dateToString);
        }
      }}
      customInput={<Input theme={inputAdminTheme} label={label} />}
      minDate={minDate}
      maxDate={maxDate}
      yearDropdownItemNumber={150}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
    />
  );
};

export default CustomDatePicker;
