import { TeamType } from "store/employees/types";
import { DataDealershipTypes } from "components/admin/personal-info/personal-info";

export const newStateTeams = (arrayTeams: TeamType[]): DataDealershipTypes[] => {
  return arrayTeams.map(team => {
    return {
      id: team.id,
      data: team.display_name,
      pivot: team.pivot
    };
  });
};
