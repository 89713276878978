import { TYPES_PERSONAL_DATA } from "./constants";
import { GetPersonalDataListParams, PersonalDataType, PersonalDataListType, DeletePersonalDataPayload } from "./types";

export const personalDataListLoad = () => ({
  type: TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_LOAD
});

export const personalDataListLoadStop = () => ({
  type: TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_LOAD_STOP
});

export const personalDataListRequest = (params: GetPersonalDataListParams) => ({
  type: TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_REQUEST,
  payload: params
});

export const personalDataListSuccess = (payload: PersonalDataListType) => ({
  type: TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_SUCCESS,
  payload
});

export const personalDataListError = (error: { [key: string]: string }) => ({
  type: TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_ERROR,
  payload: error
});

export const deletePersonalDataRequest = (payload: DeletePersonalDataPayload) => ({
  type: TYPES_PERSONAL_DATA.DELETE_PERSONAL_DATA_REQUEST,
  payload
});

export const deletePersonalDataSuccess = (payload: string | number) => ({
  type: TYPES_PERSONAL_DATA.DELETE_PERSONAL_DATA_SUCCESS,
  payload
});

export const deletePersonalDataError = (error: { [key: string]: string }) => ({
  type: TYPES_PERSONAL_DATA.DELETE_PERSONAL_DATA_ERROR,
  payload: error
});

export const clearPersonalDataListRows = () => ({
  type: TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_CLEAR_ROWS
});

export const personalDataByIdRequest = (id: number | string) => ({
  type: TYPES_PERSONAL_DATA.PERSONAL_DATA_BY_ID_REQUEST,
  payload: id
});

export const personalDataByIdSuccess = (payload: PersonalDataType | null) => ({
  type: TYPES_PERSONAL_DATA.PERSONAL_DATA_BY_ID_SUCCESS,
  payload
});

export const filterPersonalDataListRows = (payload: number | string) => ({
  type: TYPES_PERSONAL_DATA.FILTER_PERSONAL_DATA_LIST_ROWS,
  payload
});

export const setPersonalDataListRows = (payload: Array<string | number>) => ({
  type: TYPES_PERSONAL_DATA.SET_PERSONAL_DATA_LIST_ROWS,
  payload
});

export const personalDataListStatus = (status: { [key: string]: string } | null) => ({
  type: TYPES_PERSONAL_DATA.PERSONAL_DATA_LIST_STATUS,
  payload: status
});
