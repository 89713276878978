import React, { FC, ReactElement, useEffect, useRef } from "react";
import { Button, Input, Wrapper } from "components/ui";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { isValidFirstAndLastChar, isValidNameString } from "helpers/validate";
import { connect, ConnectedProps } from "react-redux";
import { StoreTypes } from "store/types";
import { useParams } from "react-router-dom";
import { Loading } from "components/ui/loading";
import Row from "components/ui/row";
import NotificationPopup from "components/ui/notification-popup";
import { isChangedValues } from "helpers/formik-handlers";
import styles from "./added-personal-data-form.module.scss";
import history from "../../../../services/history";
import {
  createPersonalDataRequest,
  personalDataInfoClear,
  personalDataInfoRequest,
  setOpenPersonalDataModalWindow,
  updatePersonalDataRequest
} from "../../../../store/personal-data/actions";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .test("name", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
    .matches(isValidNameString, "Используйте кириллицу или латиницу, цифры и символы: ~@#$%^_-+*(){}'`\"«»:;/\\.")
    .required("Необходимо заполнить данные")
    .min(1, "Используйте от 1 до 255 символов")
    .max(255, "Используйте от 1 до 255 символов")
    .required("Необходимо заполнить данные")
});

type ValuesTypes = {
  name: string;
};

type Props = {
  isEdit?: boolean;
};

const AddedPersonalDataForm: FC<ReduxProps & Props> = ({
  getPersonalDataInfo,
  isEdit = false,
  errorServer,
  onUpdate,
  dataPersonalData,
  clear,
  onCreate,
  isOpen,
  setOpen
}) => {
  const { id: personalDataId } = useParams<any>();
  const formikRef = useRef<any>();

  useEffect(() => {
    if (isEdit) {
      getPersonalDataInfo(personalDataId);
    }
    return (): void => {
      setOpen(false);
      clear();
    };
  }, []);

  const getInitialValues = (): ValuesTypes => {
    if (isEdit) {
      if (dataPersonalData) {
        const { name } = dataPersonalData;
        return {
          name: name || ""
        };
      }
    }
    return {
      name: ""
    };
  };

  const initValue = getInitialValues();
  const isDisplayContent = !isEdit || (isEdit && dataPersonalData);

  const inputsBlur = (item: string, value: string): void => {
    if (value) {
      formikRef.current.setFieldValue(item, value.trim());
      formikRef.current.setTouched({ [item]: true });
    }
  };

  const guard = (): void => {
    if (Object.keys(formikRef.current.errors).length !== 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const submitHandler = (values: ValuesTypes): void => {
    const { name } = values;

    if (isEdit) {
      onUpdate({ name });
    } else {
      onCreate({ name });
    }
  };

  const notificationTitle = isEdit ? "Данные успешно изменены" : "Данные успешно сохранены";

  if (!isDisplayContent) {
    return (
      <Loading isLoading>
        <Wrapper pt={300}>
          <Row justifyContent="justifyCenter" alignItems="alignCenter">
            <h1>Идет загрузка</h1>
          </Row>
        </Wrapper>
      </Loading>
    );
  }
  return (
    <div className={styles.container}>
      <NotificationPopup
        title={notificationTitle}
        isOpen={isOpen}
        action={() => {
          if (dataPersonalData && dataPersonalData.id) {
            history.push(`/admin/personal-data-list/added/${dataPersonalData.id}`);
          } else {
            history.push(`/admin/personal-data-list`);
          }
          setOpen(false);
        }}
      />
      <Formik
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={submitHandler}
        initialValues={initValue}
      >
        {({ values, touched, setFieldValue, errors, handleBlur, setFieldTouched, handleChange }): ReactElement => (
          <Form>
            <Wrapper pt={30} pl={40}>
              {isEdit ? (
                <h3 className={styles.title}>Редактирование перечня ПДн</h3>
              ) : (
                <h3 className={styles.title}>Добавление перечня ПДн</h3>
              )}
              <div className={styles.form_input}>
                <div>
                  <Input
                    name="name"
                    placeholder="Обязательное поле"
                    label="Название"
                    onChange={handleChange}
                    onBlur={(): void => inputsBlur("name", values.name)}
                    value={values.name}
                    invalid={Boolean(errors.name && touched.name)}
                    errorMessages={errors.name && touched.name ? errors.name : null}
                  />
                </div>
              </div>
            </Wrapper>
            <div>
              <Wrapper className={styles.form_btn} pl={40} pb={20}>
                <div>
                  <Button onClick={guard} type="submit" disabled={isChangedValues(initValue, values)}>
                    Сохранить
                  </Button>
                </div>
                <div>
                  <Button noBorder theme="transparent" onClick={(): void => history.push("/admin/personal-data-list")}>
                    Отменить
                  </Button>
                </div>
              </Wrapper>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ personalData }: StoreTypes) => ({
  dataPersonalData: personalData.editPersonalData,
  errorServer: personalData.serverError,
  isOpen: personalData.modalWindow
});

const mapDispatchToProps = {
  onCreate: createPersonalDataRequest,
  onUpdate: updatePersonalDataRequest,
  getPersonalDataInfo: personalDataInfoRequest,
  clear: personalDataInfoClear,
  setOpen: setOpenPersonalDataModalWindow
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddedPersonalDataForm);
