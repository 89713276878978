import React, { FC, ReactElement, useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import cn from "classnames";
import { FormikErrors } from "formik";
import { inputAdminTheme } from "components/ui/switch-themes";
import styles from "./autosuggest.module.scss";
import { Input } from "../input";
import FieldName from "./constants";

type PropTypes = {
  getData: any;
  placeholder: string;
  field: FieldName;
  label?: string;
  errors: FormikErrors<typeof FieldName>;
  values: { [key: string]: any };
  setFieldValue: (field: string, value: any) => void;
  invalid?: boolean;
  errorMessages?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | null;
  interconnected?: boolean;
  reset?: boolean;
  setFieldTouched?: (field: string, isTouched: boolean) => void;
};

const CustomAutosuggest: FC<PropTypes> = ({
  getData,
  placeholder,
  field,
  label,
  errors,
  values,
  setFieldValue,
  invalid,
  errorMessages,
  interconnected = false,
  reset,
  setFieldTouched
}) => {
  const currentCity = values.city;
  const defaultValue = field === FieldName.city ? values.city : values.address;
  const [suggestions, setSuggestions] = useState<Array<string>>([]);
  const [val, setVal] = useState(defaultValue);
  const [closeSuggestionList, setCloseSuggestionList] = useState(false);
  const [prevCity, setPrevCity] = useState<string>();

  useEffect(() => {
    setPrevCity(values.city);
  }, []);

  useEffect(() => {
    if (prevCity) {
      if (prevCity !== values.city) {
        setFieldValue("address", "");
      }
    }
  }, [values.city]);

  useEffect(() => {
    /// 'Это сброс
    setVal(defaultValue);
  }, [reset]);

  useEffect(() => {
    if (!values[field]) {
      setFieldValue(field, "");
      setVal("");
      setSuggestions([]);
    }
  }, [values[field]]);

  useEffect(() => {
    if (field === FieldName.city && errors.city && interconnected) {
      setFieldValue(FieldName.address, "");
    }
  }, [errors.city]);

  const getSuggestionValue = (suggestion: string): string => {
    return suggestion;
  };

  const renderSuggestion = (suggestion: string): ReactElement => {
    return <span>{suggestion}</span>;
  };

  type OnChangeType = {
    newValue: string;
  };

  const onChange = (event: any, { newValue }: OnChangeType): void => {
    setVal(newValue);
  };

  useEffect(() => {
    if (!suggestions.find(item => item === val) && !values[field]) {
      setVal("");
    }
    if (val !== values[field]) {
      setVal("");
      setFieldValue(field, "");
    }
    setSuggestions([]);
  }, [closeSuggestionList]);

  const onSuggestionsClearRequested = (): void => {
    setCloseSuggestionList(!closeSuggestionList);
  };

  const onBlur = (): void => {
    if (setFieldTouched) {
      setFieldTouched(field, true);
    }
  };

  const inputProps = {
    theme: inputAdminTheme,
    name: field,
    placeholder,
    value: val,
    onChange,
    onBlur,
    id: field,
    label,
    errorMessages,
    invalid,
    autoComplete: "new-password"
  };

  type SuggestionSelectedType = {
    suggestionValue: string;
  };
  const onSuggestionSelected = (event: any, { suggestionValue }: SuggestionSelectedType): void => {
    event.preventDefault();
    setVal(suggestionValue);
    setFieldValue(field, suggestionValue);
  };

  const renderInputComponent = (props: any): ReactElement => <Input {...props} />;

  const suggestionsContainerClass = cn({
    [styles.suggestionsContainer]: true,
    [styles.suggestionsContainerOpen]: suggestions.length > 0
  });

  return (
    <Autosuggest
      id={field}
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value }): void => {
        getData(value, setSuggestions, currentCity);
      }}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      highlightFirstSuggestion
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={onSuggestionSelected}
      renderInputComponent={renderInputComponent}
      theme={{
        container: styles.container,
        suggestionsContainer: suggestionsContainerClass,
        suggestionsList: styles.suggestionsList,
        suggestion: styles.suggestion,
        suggestionHighlighted: styles.suggestionHighlighted
      }}
    />
  );
};

export default CustomAutosuggest;
