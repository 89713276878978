import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { STEP_REGISTER } from "constant/step-auth";
import { StoreTypes } from "store/types";
import { updateStepRegister } from "store/auth/actions";
import RegisterInvalid from "components/auth/steps/register/register-invalid";
import RegisterTel from "components/auth/steps/register/register-tel";
import RegisterCode from "components/auth/steps/register/register-code";
import Register from "components/auth/steps/register/register";
import RegisterExists from "components/auth/steps/register/register-exists";
import RegisterSuccess from "components/auth/steps/register/register-success";

const RegisterForm: FC<ReduxProps> = ({ step, updateStepRegister: resetStep }) => {
  useEffect(() => {
    return (): void => {
      resetStep(STEP_REGISTER.TELEPHONE);
    };
  }, [resetStep]);

  switch (step) {
    case STEP_REGISTER.CODE: {
      return <RegisterCode />;
    }
    case STEP_REGISTER.REGISTER: {
      return <Register />;
    }
    case STEP_REGISTER.SUCCESS: {
      return <RegisterSuccess />;
    }
    case STEP_REGISTER.EXISTS: {
      return <RegisterExists />;
    }
    case STEP_REGISTER.INVALID: {
      return <RegisterInvalid />;
    }
    default: {
      return <RegisterTel />;
    }
  }
};

const mapStateToProps = (store: StoreTypes) => ({
  step: store.auth.stepReg
});

const mapDispatchToProps = {
  updateStepRegister
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(RegisterForm);
