import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import { connect, ConnectedProps } from "react-redux";
import { sendEmailCodeRequest, updateUserEmailRequest, usersStatus } from "store/users/actions";
import { StoreTypes } from "store/types";
import { Button, Input, Popup } from "components/ui";
import { labelCode, labelEmail } from "components/ui/switch-themes";
import { serverError } from "store/auth/actions";
import { useFormik } from "formik";
import { handlerPaste } from "helpers/handlePastePhone";
import { resetErrorAndStatus } from "store/actions";
import { isValidEmail } from "helpers/validate";
import { handlerEmailPaste } from "helpers/handlePasteEmail";
import styles from "./change-email.module.scss";

const validationSchema = yup.object().shape({
  isEmail: yup.string(),
  email: yup
    .string()
    .required("Необходимо заполнить данные")
    .matches(isValidEmail, "Недопустимый формат email"),
  code: yup
    .string()
    .transform(value => value.replace(/[^\d]/g, ""))
    .min(4, "Код должен состоять из 4 символов")
    .max(4, "Код должен состоять из 4 символов")
    .when("isEmail", {
      is: "code",
      then: yup.string().required("Необходимо ввести код"),
      otherwise: yup.string().notRequired()
    })
});

type DispatchProps = {
  updateUserEmailRequest: (phone: string) => void;
};

type Props = {
  id: string;
  isLoading: boolean;
  status: {
    [key: string]: string;
  } | null;
};

const ChangeEmail: FC<Props & ReduxProps> = ({
  updateUserEmailRequest: onSubmitEmail,
  sendEmailCodeRequest: onSubmitCode,
  isLoading,
  warning,
  status,
  resetStatus
}) => {
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);
  const [inputTypes, setInputTypes] = useState<string>("email");
  const [isCodePopupOpen, setIsCodePopupOpen] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const formik = useFormik({
    initialValues: {
      isEmail: inputTypes,
      email: "",
      code: ""
    },
    validationSchema,
    onSubmit: values => {
      if (inputTypes === "email") {
        resetStatus();
        const data = String(values.email);
        warning(null);
        setNewEmail(data);
        onSubmitEmail({ email: data });
      } else if (inputTypes === "code") {
        const data = String(values.code);
        warning(null);
        onSubmitCode({ code: data, email: newEmail });
      }
    }
  });
  useEffect(() => {
    if (isEmailPopupOpen) {
      setInputTypes("email");
      formik.setFieldValue("isEmail", "email");
      formik.setFieldTouched("code", false);
    } else {
      setInputTypes("code");
      formik.setFieldValue("isEmail", "code");
      formik.setFieldTouched("email", false);
    }
  }, [isEmailPopupOpen]);
  const closeEmailPopup = (): void => {
    setIsEmailPopupOpen(false);
  };
  const closeCodePopup = (): void => {
    setIsCodePopupOpen(false);
  };

  useEffect(() => {
    if (status?.updateUserEmail === "Код отправлен") {
      closeEmailPopup();
      resetStatus();
      setIsCodePopupOpen(true);
    }
    if (status?.sendCode === "Email обновлен") {
      closeCodePopup();
      resetStatus();
    }
  }, [status]);
  return (
    <div className={styles.changePhoneContainer}>
      <Button theme="link" onClick={(): void => setIsEmailPopupOpen(true)}>
        Сменить email
      </Button>
      <Popup isOpen={isEmailPopupOpen} onClick={closeEmailPopup}>
        <div className={styles.row}>
          <div className={styles.title}>Новый email</div>
        </div>
        <div className={styles.addRoles}>
          <form onSubmit={formik.handleSubmit} className={styles.formStyle}>
            <Input
              name="email"
              type="text"
              placeholder="Введите Ваш новый email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              errorMessages={formik.errors.email && formik.touched.email ? formik.errors.email : null}
              invalid={Boolean(formik.errors.email && formik.touched.email)}
              onPaste={(elem: React.ClipboardEvent<HTMLInputElement>): void =>
                handlerEmailPaste(elem, formik.setFieldValue)
              }
            />
            <div className={styles.submitBtnContainer}>
              <Button disabled={isLoading} type="submit">
                Продолжить
              </Button>
            </div>
          </form>
        </div>
      </Popup>
      <Popup isOpen={isCodePopupOpen} onClick={closeCodePopup}>
        <div className={styles.row}>
          <div className={styles.title}>Укажите код, отправленный вам на новый email</div>
        </div>
        <div className={styles.addRoles}>
          <form onSubmit={formik.handleSubmit} className={styles.formStyle}>
            <Input
              name="code"
              placeholder="Введите код"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.code}
              errorMessages={formik.errors.code && formik.touched.code ? formik.errors.code : null}
              invalid={Boolean(formik.errors.code && formik.touched.code)}
              onPaste={(elem: React.ClipboardEvent<HTMLInputElement>): void => handlerPaste(elem, formik.setFieldValue)}
            />
            <div className={styles.submitBtnContainer}>
              <Button disabled={!(formik.isValid && formik.dirty) || isLoading} type="submit">
                Сохранить
              </Button>
            </div>
          </form>
        </div>
      </Popup>
    </div>
  );
};

const mapStateToProps = ({ users: { user, isLoading }, users }: StoreTypes): Props => ({
  id: user.id,
  isLoading,
  status: users.status
});

const mapDispatchToProps = {
  sendEmailCodeRequest,
  updateUserEmailRequest,
  warning: serverError,
  setStatus: usersStatus,
  resetStatus: resetErrorAndStatus
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ChangeEmail);
