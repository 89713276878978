import React, { FC } from "react";
import history from "services/history";
import { Loading } from "components/ui/loading";
import { Button, Text, Wrapper } from "components/ui";
import { StoreTypes } from "store/types";
import { connect, ConnectedProps } from "react-redux";
import { authLogo } from "components/ui/helpersComponents";
import { isHyundaiTheme, titleCompanyTheme } from "components/ui/switch-themes";

const UserNoAccess: FC<ReduxProps> = ({ isLoading, errorTitle }) => {
  return (
    <Loading isLoading={isLoading}>
      <div className="text-center">
        {authLogo}
        <Wrapper pb={48}>
          <Text uppercase={!isHyundaiTheme} size="h3" align="center" theme={titleCompanyTheme}>
            Проверка доступа
          </Text>
        </Wrapper>
        <Wrapper pb={62}>
          <Text size="h4" align="center" theme={titleCompanyTheme}>
            {errorTitle || "Загрузка...."}
          </Text>
        </Wrapper>
        <Wrapper>
          <Button fullWidth onClick={(): void => history.push("/auth")}>
            На главную
          </Button>
        </Wrapper>
      </div>
    </Loading>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  isLoading: store.auth.isLoading,
  errorTitle: store.auth.serverError
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(UserNoAccess);

UserNoAccess.defaultProps = {
  errorTitle: "К сожалению у вас нет доступа к данному сервису"
};
