import { Employees as EmployeesApi, Employees, ResponsePosition } from "services/api/employees";
import { DealerCenterApi } from "services/api/dealer-center";
import { DealerCenter } from "store/dealer-center/types";
import getPrettyPhone from "helpers/pretty-phone-format";
import { DepartmentApi } from "services/api/department";
import { LegalEntitiesListApi } from "../services/api/legalEntitiesList";
import { LegalEntity } from "../store/legalEntitiesList/types";
import { Employee } from "../store/employees/types";
import { LegalEntityApi } from "../services/api/legalEntity";
import { ThirdPartiesApi } from "../services/api/third-parties";
import { ThirdPartiesEntity } from "../store/third-parties-list/types";
import { PersonalDataApi } from "../services/api/personal-data";
import { PersonalDataType } from "../store/personal-data-list/types";
import { Clients } from "../services/api/clients";
import { BanDeletionType } from "../store/users/types";

/// список Должностей
export const getPositionSuggest = (type: string) => async (
  value: string,
  setSuggestions: (data: Array<{ id: string; data: string }>) => void
): Promise<void> => {
  try {
    const data = await Employees.getPosition({
      search: value,
      type
    });
    const firstSuggestions = data.slice(0, 10);
    const extractData = firstSuggestions.map(({ id, title }: ResponsePosition) => {
      return {
        id: String(id),
        data: `${title} `
      };
    });
    setSuggestions(extractData);
  } catch (error) {
    console.log(error);
  }
};

/// список ДЦ
export const getDealersSuggestion = async (
  value: string,
  setSuggestions: (data: Array<{ id: string; data: string }>) => void
): Promise<void> => {
  try {
    const { data } = await DealerCenterApi.getDealerCenter({
      search: value
    });
    const firstSuggestions = data.slice(0, 10);
    const extractData = firstSuggestions.map(({ id, email, phone, display_name: displayName }: DealerCenter) => {
      const prettyPhone = getPrettyPhone(phone || "");
      return {
        id: String(id),
        data: `${displayName} ${email || ""} ${prettyPhone}`.trim()
      };
    });
    setSuggestions(extractData);
  } catch (error) {
    console.log(error);
  }
};

/// список ЮЛ
export const getLegalEntitiesSuggestion = async (
  value: string,
  setSuggestions: (data: Array<{ id: string; data: string }>) => void
): Promise<void> => {
  try {
    const { data } = await LegalEntitiesListApi.getLegalEntities({
      search: value
    });
    const firstSuggestions = data.slice(0, 10);
    const extractData = firstSuggestions.map(({ id, inn, name }: LegalEntity) => {
      return {
        id: String(id),
        data: `${name}, ИНН: ${inn}`.trim()
      };
    });
    setSuggestions(extractData);
  } catch (error) {
    console.log(error);
  }
};

// Список Департаментов

export const getDepartmentSuggestion = async (
  value: string,
  setSuggestions: (data: Array<{ id: string; data: string }>) => void
): Promise<void> => {
  try {
    const { data } = await DepartmentApi.getDepartment({
      search: value
    });
    const firstSuggestions = data.slice(0, 10);
    const extractData = firstSuggestions.map(({ id, title }: any) => {
      return {
        id: String(id),
        data: title
      };
    });
    setSuggestions(extractData);
  } catch (error) {
    console.log(error);
  }
};

// список сотрудников

// список Третьих лиц
export const getThirdPartiesSuggestion = async (
  value: string,
  setSuggestions: (data: Array<{ id: string; data: string }>) => void
): Promise<void> => {
  try {
    const { data } = await ThirdPartiesApi.getThirdPartiesList({
      search: value
    });
    const firstSuggestions = data.slice(0, 10);
    const extractData = firstSuggestions.map(({ id, name }: ThirdPartiesEntity) => {
      return {
        id: String(id),
        data: name
      };
    });
    setSuggestions(extractData);
  } catch (error) {
    console.log(error);
  }
};

// список категорий ПДн
export const getPersonalDataSuggestion = async (
  value: string,
  setSuggestions: (data: Array<{ id: string; data: string }>) => void
): Promise<void> => {
  try {
    const { data } = await PersonalDataApi.getPersonalDataList({
      search: value
    });
    const firstSuggestions = data.slice(0, 10);
    const extractData = firstSuggestions.map(({ id, name }: PersonalDataType) => {
      return {
        id: String(id),
        data: name
      };
    });
    setSuggestions(extractData);
  } catch (error) {
    console.log(error);
  }
};

// список запретов на удаления
export const getBanDeletionClientsSuggestion = async (
  value: string,
  setSuggestions: (data: Array<{ id: string; data: string }>) => void
): Promise<void> => {
  try {
    const { data } = await Clients.getBanDeletionClients({
      search: value
    });
    const firstSuggestions = data.slice(0, 10);
    const extractData = firstSuggestions.map(({ user_id, user }: BanDeletionType) => {
      const fullName = `${user.lastname} ${(user.name && user.name[0]) || ""}. ${(user.patronymic &&
        user.patronymic[0]) ||
        ""}${(user.patronymic && ".") || ""}`;
      const prettyPhone = getPrettyPhone(user.phone);
      return {
        id: user_id,
        data: `${fullName} ${user.email} ${prettyPhone}`
      };
    });
    setSuggestions(extractData);
  } catch (error) {
    console.log(error);
  }
};
