import { TYPES_PHONE_BLACKLIST } from "./constants";
import { PhoneBlacklistResponse, GetPhoneBlacklistParams } from "./types";

export const phoneBlacklistLoad = () => ({
  type: TYPES_PHONE_BLACKLIST.PHONE_BLACKLIST_LOAD
});

export const phoneBlacklistLoadStop = () => ({
  type: TYPES_PHONE_BLACKLIST.PHONE_BLACKLIST_LOAD_STOP
});

export const phoneBlacklistRequest = (body: GetPhoneBlacklistParams) => ({
  type: TYPES_PHONE_BLACKLIST.PHONE_BLACKLIST_REQUEST,
  payload: body
});

export const phoneBlacklistSuccess = (payload: PhoneBlacklistResponse) => ({
  type: TYPES_PHONE_BLACKLIST.PHONE_BLACKLIST_SUCCESS,
  payload
});

export const phoneBlacklistError = (error: { [key: string]: string }) => ({
  type: TYPES_PHONE_BLACKLIST.PHONE_BLACKLIST_ERROR,
  payload: error
});

export const deletePhoneBlacklistRequest = (payload: any) => ({
  type: TYPES_PHONE_BLACKLIST.DELETE_PHONE_BLACKLIST_REQUEST,
  payload
});

export const deletePhoneBlacklistSuccess = (payload: string) => ({
  type: TYPES_PHONE_BLACKLIST.DELETE_PHONE_BLACKLIST_SUCCESS,
  payload
});

export const deletePhoneBlacklistError = (error: { [key: string]: string }) => ({
  type: TYPES_PHONE_BLACKLIST.DELETE_PHONE_BLACKLIST_ERROR,
  payload: error
});

export const phoneBlacklistStatus = (status: { [key: string]: string } | null) => ({
  type: TYPES_PHONE_BLACKLIST.PHONE_BLACKLIST_STATUS,
  payload: status
});
