import axios from "axios";
import { EmployeesType, GetEmployeesParams } from "store/employees/types";
import { GetBanDeletionParams, BanDeletionListType, Services } from "store/users/types";

export const Clients = {
  getClients: (body: Partial<GetEmployeesParams>): Promise<EmployeesType> => axios.post("/front/client", body),
  getBanDeletionClients: (params?: Partial<GetBanDeletionParams>): Promise<BanDeletionListType> =>
    axios.get("/front/ban-deletion", { params }),
  getServices: (): Promise<Array<Services>> => axios.get(`/front/service-list`)
};
