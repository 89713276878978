import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { resetErrorAndStatus } from "store/actions";
import AddedServiceForm from "components/admin/added-service/added-service-form";

type Props = {
  children?: React.ReactElement;
};

const AddedService: FC<ReduxProps & Props> = ({ resetErrorsAndStatus, children }) => {
  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
    };
  }, [resetErrorsAndStatus]);

  return <>{children || <AddedServiceForm />}</>;
};

const mapDispatchToProps = {
  resetErrorsAndStatus: resetErrorAndStatus
};

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddedService);
