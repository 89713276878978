import axios from "axios";
import {
  Permission,
  Service,
  UpdateServiceRequest,
  ThirdPartiesDataResponse,
  PersonalDataResponse,
  ServiceCredentialsType,
  CreateServiceType,
  GetServiceListParams,
  ServiceListType
} from "../../store/services/types";

// eslint-disable-next-line import/prefer-default-export
export const ServicesApi = {
  getServices: (params?: Partial<GetServiceListParams>): Promise<ServiceListType> =>
    axios.get(`/front/service`, { params }),
  getPersonalDataTypes: (): Promise<PersonalDataResponse> =>
    axios.get(`/front/personal-data-list?pagination[page]=1&pagination[count]=1000`),
  getPermissionList: (): Promise<Array<Permission>> => axios.get(`/front/permission-list-for-service`),
  getServiceById: (id: string): Promise<Service> => axios.get(`/front/service/${id}`),
  updateService: ({ id, body }: { id: string; body: UpdateServiceRequest }): Promise<Service> =>
    axios.put(`/front/service/${id}`, body),
  getThirdPartiesList: (): Promise<ThirdPartiesDataResponse> =>
    axios.get("/front/third-parties?pagination[page]=1&pagination[count]=1000"),
  deleteService: (id: string): Promise<void> => axios.delete(`/front/service/${id}`),
  getServiceCredentials: (id: string | number): Promise<ServiceCredentialsType> =>
    axios.get(`/front/service/${id}/credentials`),
  createService: (body: CreateServiceType): Promise<any> => axios.post("/front/service", body)
};
