import { TYPES_LE } from "store/legalEntity/constants";
import { RESET_ERROR_AND_STATUS } from "../constants";

import { ActionsType, Store } from "./types";
import { TYPES_DEALERS } from "../dealers/constants";

const initialState: Store = {
  isUsersLoading: false,
  isCarsLoading: false,
  isLegalEntityLoading: false,
  editLegalEntity: null,
  edit: false,
  users: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
    data: []
  },
  cars: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
    data: []
  },
  serverError: null,
  modalWindow: false
};

export const legalEntityReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_LE.SET_LEGAL_ENTITY_EDIT_MODE: {
      return { ...state, edit: true };
    }
    case TYPES_LE.LEGAL_ENTITY_INFO_REQUEST: {
      return { ...state, isLegalEntityLoading: true };
    }
    case TYPES_LE.LEGAL_ENTITY_INFO_SUCCESS: {
      return { ...state, editLegalEntity: action.payload };
    }
    case TYPES_LE.LEGAL_ENTITY_INFO_CLEAR: {
      return {...state, editLegalEntity: null}
    }
    case TYPES_LE.LEGAL_ENTITY_INFO_LOAD_STOP: {
      return { ...state, isLegalEntityLoading: false };
    }
    case TYPES_LE.GET_LEGAL_USERS_REQUEST: {
      return {
        ...state,
        isUsersLoading: true
      };
    }
    case TYPES_LE.GET_LEGAL_USERS_LOAD_STOP: {
      return {
        ...state,
        isUsersLoading: false
      };
    }
    case TYPES_LE.GET_LEGAL_USERS_SUCCESS: {
      return { ...state, users: action.payload };
    }
    case TYPES_LE.GET_LEGAL_CARS_SUCCESS: {
      return { ...state, cars: action.payload };
    }
    case TYPES_LE.GET_LEGAL_CARS_REQUEST: {
      return {
        ...state,
        isCarsLoading: true
      };
    }
    case TYPES_LE.GET_LEGAL_CARS_LOAD_STOP: {
      return {
        ...state,
        isCarsLoading: false
      };
    }
    case TYPES_LE.MODAL_OPEN_LE: {
      return { ...state, modalWindow: action.payload };
    }
    case TYPES_LE.SERVER_ERROR_LE: {
      return { ...state, serverError: action.payload };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, serverError: null };
    }
    default: {
      return state;
    }
  }
};
