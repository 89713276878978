import { Location } from "./location";
import debounce from "../../helpers/debounce";

const getCity = async (value: string, setSuggestions: (data: Array<any>) => void): Promise<any> => {
  if (value.length < 3) {
    return;
  }
  try {
    const { suggestions: cities } = await Location.getCity({ city: value });
    const extractСities: Array<any> = cities.map((item: any) => item.data.city);
    setSuggestions(extractСities);
  } catch (error) {
    console.log(error);
  }
};

const getAddress = async (
  value: string,
  setSuggestions: (data: Array<any>) => void,
  currentCity: string
): Promise<any> => {
  if (value.length < 3) {
    return;
  }
  try {
    const { suggestions: addresses } = await Location.getAddress({
      city: currentCity,
      address: value
    });

    const extractСities: Array<any> = addresses.map((item: any) => item.value);
    setSuggestions(extractСities);
  } catch (error) {
    console.log(error);
  }
};

export const getCityHandler = debounce(getCity, 400);
export const getAddressHandler = debounce(getAddress, 400);
