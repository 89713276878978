import { put, takeEvery, call, select, delay, race, take, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { MarketingMailingsApi } from "services/api/marketing-mailings";
import { TYPES_MARKETING_MAILINGS } from "./constants";
import {
  marketingMailingsLoad,
  marketingMailingsLoadStop,
  marketingMailingsSuccess,
  marketingMailingsError,
  marketingMailingsRequest,
  createMarketingMailingsRequest,
  deleteMarketingMailingsRequest,
  downloadMarketingMailingsRequest,
  marketingMailingsServicesLoad,
  marketingMailingsServicesSuccess,
  marketingMailingsServicesError,
  filterMMRows,
  MarketingMailingsStatus,
  deleteMarketingMailingsSuccess,
  modalWindowToggle,
  stopRepeatMarketingMailings
} from "./actions";
import { employeeUpdateRoleSuccess } from "../employees/actions";
import { MarketingMailings } from "./types";

function* getMarketingMailings(actions: ReturnType<typeof marketingMailingsRequest>): SagaIterator {
  const { payload } = actions;
  try {
    const response = yield call(MarketingMailingsApi.getMarketingMailings, payload);
    yield put(marketingMailingsSuccess(response));

    const isRepeated = yield select(state => state.marketingMailings.isRepeated);
    const canRepeatRequest =
      Boolean(response.data.find((mailing: MarketingMailings) => mailing.status !== 6 && mailing.status !== 4)) &&
      isRepeated;
    if (canRepeatRequest) {
      yield delay(3000);
      yield put(marketingMailingsRequest(payload));
    } else {
      yield put(stopRepeatMarketingMailings(payload));
    }
  } catch (e) {
    yield put(marketingMailingsError({ marketingMailings: "Не удалось загрузить данные" }));
  } finally {
    yield put(marketingMailingsLoadStop());
  }
}

export function* createNewMarketingMailings(actions: ReturnType<typeof createMarketingMailingsRequest>): SagaIterator {
  const { payload } = actions;
  yield put(marketingMailingsLoad());
  try {
    yield call(MarketingMailingsApi.createMarketingMailings, payload);
    yield put(modalWindowToggle(true));
  } catch (e) {
    yield put(marketingMailingsError({ attachRoles: "Не удалось создать маркетинговую рассылку" }));
  } finally {
    yield put(marketingMailingsLoadStop());
  }
}

export function* deleteChoosenMarketingMailings(
  actions: ReturnType<typeof deleteMarketingMailingsRequest>
): SagaIterator {
  const {
    payload: { id }
  } = actions;

  yield put(marketingMailingsLoad());
  try {
    yield call(MarketingMailingsApi.deleteMarketingMailings, id);
    yield put(filterMMRows(id));
    yield put(deleteMarketingMailingsSuccess(id));
    yield put(MarketingMailingsStatus({ deleteMarketingMailingsCenter: "Успешно" }));
  } catch (e) {
    yield put(marketingMailingsError({ deleteMarketingMailingsCenter: "Не удалось удалить маркетинговую рассылку" }));
  } finally {
    yield put(marketingMailingsLoadStop());
  }
}

export function* downloadChoosenMarketingMailings(
  actions: ReturnType<typeof downloadMarketingMailingsRequest>
): SagaIterator {
  const { payload } = actions;

  yield put(marketingMailingsLoad());
  try {
    const response = yield call(MarketingMailingsApi.downloadMarketingMailings, payload);
    const downloadFile = (responseData: any) => {
      const url = window.URL.createObjectURL(new Blob([responseData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `marketing-campaign-${payload}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    };
    downloadFile(response);
    yield put(employeeUpdateRoleSuccess(response));
  } catch (e) {
    yield put(marketingMailingsError({ detachRoles: "Не удалось скачать маркетинговую рассылку" }));
  } finally {
    yield put(marketingMailingsLoadStop());
  }
}

function* getMarketingMailingsServices(): SagaIterator {
  yield put(marketingMailingsServicesLoad());
  try {
    const response = yield call(MarketingMailingsApi.getServices);
    yield put(marketingMailingsServicesSuccess(response));
  } catch (e) {
    yield put(marketingMailingsServicesError({ marketingMailingsServices: "Не удалось загрузить сервисы" }));
  }
}

function* marketingMailingsSaga(): SagaIterator {
  yield takeLatest(TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_REQUEST, getMarketingMailings);
  yield takeEvery(TYPES_MARKETING_MAILINGS.CREATE_MARKETING_MAILINGS_REQUEST, createNewMarketingMailings);
  yield takeEvery(TYPES_MARKETING_MAILINGS.DELETE_MARKETING_MAILINGS_REQUEST, deleteChoosenMarketingMailings);
  yield takeEvery(TYPES_MARKETING_MAILINGS.DOWNLOAD_MARKETING_MAILINGS_REQUEST, downloadChoosenMarketingMailings);
  yield takeEvery(TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_SERVICES_REQUEST, getMarketingMailingsServices);
}

export { marketingMailingsSaga };
