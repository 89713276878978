import { TYPES_THIRD_PARTIES } from "./constants";
import { ThirdPartiesEntity, Store, ActionsType } from "./types";
import { RESET_ERROR_AND_STATUS } from "../constants";

const initialState: Store = {
  isLoading: false,
  error: null,
  currentThirdParty: null,
  thirdPartiesEntities: {
    current_page: 0,
    data: [],
    last_page: 0,
    per_page: 0,
    total: 0
  },
  selectedRows: [],
  deleteError: null,
  status: null
};

export const thirdPartiesListReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_THIRD_PARTIES.THIRD_PARTIES_LOAD: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_THIRD_PARTIES.THIRD_PARTIES_LOAD_STOP: {
      return { ...state, isLoading: false };
    }
    case TYPES_THIRD_PARTIES.THIRD_PARTIES_SUCCESS: {
      return { ...state, thirdPartiesEntities: action.payload };
    }
    case TYPES_THIRD_PARTIES.THIRD_PARTIES_ERROR: {
      return { ...state, error: { ...state.error, ...action.payload } };
    }
    case TYPES_THIRD_PARTIES.THIRD_PARTY_BY_ID_SUCCESS: {
      return { ...state, currentThirdParty: action.payload };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null, deleteError: null, status: null };
    }
    case TYPES_THIRD_PARTIES.DELETE_THIRD_PARTY_SUCCESS: {
      return {
        ...state,
        thirdPartiesEntities: {
          ...state.thirdPartiesEntities,
          data: state.thirdPartiesEntities.data.filter((tpe: ThirdPartiesEntity) => tpe.id !== action.payload)
        }
      };
    }
    case TYPES_THIRD_PARTIES.SET_THIRD_PARTIES_ROWS: {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
    case TYPES_THIRD_PARTIES.THIRD_PARTIES_CLEAR_ROWS: {
      return {
        ...state,
        selectedRows: []
      };
    }
    case TYPES_THIRD_PARTIES.FILTER_THIRD_PARTIES_ROWS: {
      return {
        ...state,
        selectedRows: state.selectedRows.filter(item => item !== action.payload)
      };
    }
    case TYPES_THIRD_PARTIES.DELETE_THIRD_PARTY_ERROR: {
      return { ...state, deleteError: action.payload };
    }
    case TYPES_THIRD_PARTIES.THIRD_PARTIES_STATUS: {
      return { ...state, status: action.payload };
    }
    default: {
      return state;
    }
  }
};
