export default [
  {
    id: 1,
    name: "10 строк на стр.",
    value: 10
  },
  {
    id: 2,
    name: "15 строк на стр.",
    value: 15
  },
  {
    id: 3,
    name: "20 строк на стр.",
    value: 20
  }
];
