import React, { FC, ChangeEvent } from "react";
import { FormikValues, FormikTouched } from "formik";
import { inputAdminTheme, isHyundaiTheme, sizeTitleCreateUsers } from "components/ui/switch-themes";
import styles from "./safety-block.module.scss";
import { Input, Text, Wrapper } from "../../ui";

type Props = {
  errors: any;
  values: FormikValues;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  touched: FormikTouched<any>;
};

const SafetyBlock: FC<Props> = ({ errors, values, handleBlur, handleChange, touched }) => {
  return (
    <div className={styles.wrapper}>
      <Wrapper pt={40} pb={32}>
        <Text uppercase={!isHyundaiTheme} size={sizeTitleCreateUsers} theme="black">
          Безопасность и вход
        </Text>
      </Wrapper>
      <div className={styles.wrap}>
        <div className={styles.blockColumn}>
          <Wrapper pb={32}>
            <Input
              type="password"
              name="password"
              label="Новый пароль"
              theme={inputAdminTheme}
              value={values.password}
              placeholder="Введите пароль"
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessages={(touched.password && errors.password) || (values.password && errors.password)}
              invalid={(!!errors.password && !!touched.password) || (values.password && errors.password)}
            />
          </Wrapper>
        </div>
        <div className={styles.blockColumn}>
          <Wrapper pb={32}>
            <Input
              type="password"
              name="confirmedPassword"
              label="Повтор пароля"
              theme={inputAdminTheme}
              placeholder="Повторите пароль"
              value={values.confirmedPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessages={touched.password ? errors.confirmedPassword : ""}
              invalid={Boolean(!!errors.confirmedPassword && touched.password)}
            />
          </Wrapper>
        </div>
      </div>
    </div>
  );
};

export default SafetyBlock;
