import { TYPES_DEALERS } from "store/dealers/constants";
import { RESET_ERROR_AND_STATUS } from "../constants";

import { ActionsType, Store } from "./types";

const initialState: Store = {
  isLoading: false,
  token: null,
  error: null,
  editDealership: null,
  edit: false,
  dealersEmployee: [],
  list: [],
  dealers: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
    data: []
  },
  serverError: null,
  modalWindow: false
};

export const dealersReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_DEALERS.DEALERS_LOAD: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_DEALERS.DEALERS_LOAD_STOP: {
      return { ...state, isLoading: false };
    }
    case TYPES_DEALERS.ADDED_EMPLOYEES_DC || TYPES_DEALERS.DELETE_EMPLOYEES_DC: {
      return { ...state, dealersEmployee: state.dealersEmployee.concat(action.payload) };
    }
    case TYPES_DEALERS.DEALERS_SUCCESS: {
      return { ...state, dealers: action.payload };
    }
    case TYPES_DEALERS.DEALERSHIP_ENTRY_EDITING: {
      return { ...state, editDealership: action.payload, edit: true };
    }
    case TYPES_DEALERS.DEALERSHIP_EDITING_VALUE: {
      return { ...state, edit: action.payload };
    }
    case TYPES_DEALERS.SERVER_ERROR_DC: {
      return { ...state, serverError: action.payload };
    }
    case TYPES_DEALERS.MODAL_OPEN_DC: {
      return { ...state, modalWindow: action.payload };
    }

    case TYPES_DEALERS.USERS_LIST_DC: {
      return { ...state, list: [...action.payload] };
    }
    case TYPES_DEALERS.DEALERS_EMPLOYEE_UPDATE: {
      return { ...state, dealers: { ...state.dealers, data: action.payload } };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, serverError: null };
    }
    default: {
      return state;
    }
  }
};
