import { put, takeLatest, call, select } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { Dealers } from "services/api/dealers";

import { TYPES_DEALERS } from "store/dealers/constants";
import { notificationErrors } from "components/ui/notification/notification-errors";
import EmployeeRoles from "constant/eployee-roles";
import {
  createDealers,
  dealershipEntryEditing,
  dealershipInfo,
  dealersLoad,
  dealersLoadStop,
  dealersSuccess,
  deleteEmployeesDC,
  getBaseEmployee,
  getEmployeesFilter,
  modalWindowOpen,
  putEmployeesDC,
  serverErrorDealer,
  updateDealership
} from "./actions";

function* getDealersEmployees(action: ReturnType<typeof getEmployeesFilter>): SagaIterator {
  yield put(dealersLoad());
  try {
    const { payload } = action;
    const response = yield call(Dealers.employees, payload);
    yield put(dealersSuccess(response));
  } catch (e) {
    notificationErrors({});
  } finally {
    yield put(dealersLoadStop());
  }
}

function* getBaseEmployees(action: ReturnType<typeof getBaseEmployee>): SagaIterator {
  yield put(dealersLoad());
  try {
    const { payload } = action;
    const response = yield call(Dealers.employees, payload);
    yield put(dealersSuccess(response));
  } catch (e) {
    notificationErrors({});
  } finally {
    yield put(dealersLoadStop());
  }
}

function* attachEmployeesDC(action: ReturnType<typeof putEmployeesDC>): SagaIterator {
  yield put(dealersLoad());
  try {
    const { payload } = action;
    const { id } = yield select(store => store.dealers.editDealership);
    yield call(Dealers.addedEmployeeAllRole, id, payload);
    const response = yield call(Dealers.info, id);
    yield put(dealershipEntryEditing(response));
  } catch (e) {
    notificationErrors({});
  } finally {
    yield put(dealersLoadStop());
  }
}

function* detachEmployeeDC(action: ReturnType<typeof deleteEmployeesDC>): SagaIterator {
  yield put(dealersLoad());
  try {
    const { payload } = action;
    const { id } = yield select(store => store.dealers.editDealership);
    yield call(Dealers.deleteEmployee, id, { user_id: payload });
    // const response = yield call(Dealers.info, id);
    // yield put(dealershipEntryEditing(response));
  } finally {
    yield put(dealersLoadStop());
  }
}

function* createDealership(action: ReturnType<typeof createDealers>): SagaIterator {
  yield put(dealersLoad());
  try {
    const { payload } = action;
    const response = yield call(Dealers.createDealers, { is_dealer_center: true, ...payload });
    const { dealersEmployee } = yield select(store => store.dealers);
    const { id } = response;
    if (dealersEmployee.length > 0) {
      yield call(Dealers.addedEmployeeAllRole, id, dealersEmployee);
    }
    yield put(modalWindowOpen(true));
  } catch (e) {
    if ([400].includes(e.status)) {
      yield put(serverErrorDealer(e.data.errors.phone));
    }
  } finally {
    yield put(dealersLoadStop());
  }
}

function* GetDealershipInfo(action: ReturnType<typeof dealershipInfo>): SagaIterator {
  yield put(dealersLoad());
  try {
    const { payload } = action;
    const response = yield call(Dealers.info, payload);
    yield put(dealershipEntryEditing(response));
  } finally {
    yield put(dealersLoadStop());
  }
}

function* dealershipUpdate(action: ReturnType<typeof updateDealership>): SagaIterator {
  yield put(dealersLoad());
  try {
    const { payload } = action;
    const { id } = yield select(store => store.dealers.editDealership);
    yield call(Dealers.update, id, payload);
    yield put(modalWindowOpen(true));
  } catch (e) {
    if ([400].includes(e.status)) {
      yield put(serverErrorDealer(e.data.errors.phone[0]));
    }
  } finally {
    yield put(dealersLoadStop());
  }
}

function* dealersSaga(): SagaIterator {
  yield takeLatest(TYPES_DEALERS.GET_EMPLOYEES_FILTER, getDealersEmployees);
  yield takeLatest(TYPES_DEALERS.CREATE_DEALERS, createDealership);
  yield takeLatest(TYPES_DEALERS.DEALERSHIP_INFO, GetDealershipInfo);
  yield takeLatest(TYPES_DEALERS.DELETE_EMPLOYEES_DC, detachEmployeeDC);
  yield takeLatest(TYPES_DEALERS.PUT_EMPLOYEES_DC, attachEmployeesDC);
  yield takeLatest(TYPES_DEALERS.UPDATE_DEALERS, dealershipUpdate);
  yield takeLatest(TYPES_DEALERS.GET_BASE_EMPLOYEES, getBaseEmployees);
}

export { dealersSaga };
