import React, { FC, useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import { getUserSessionToken, preAuthRequest } from "store/auth/actions";
import sessionData from "services/sessionStorage";
import { useLocation } from "react-router-dom";
import Layout from "../../components/auth/layout";
import { StoreTypes } from "../../store/types";
import { Loading } from "../../components/ui/loading";

const PreAuthPage: FC<ReduxProps> = ({ loading, getSessionToken, preAuth }) => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  useEffect(() => {
    const token = queryParameters.get("token");
    if (token !== null) {
      preAuth(token);
    }
    if (!sessionData.get("session_token")) {
      getSessionToken();
    }
  }, []);
  return (
    <Layout>
      <Loading isLoading />
    </Layout>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  loading: store.auth.isLoading
});
const mapDispatchToProps = {
  getSessionToken: getUserSessionToken,
  preAuth: preAuthRequest
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PreAuthPage);
