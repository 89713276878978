import React, { FC } from "react";
import ReactNotification from "react-notifications-component";
import ErrorBoundary from "helpers/errorBoundary";
import { isHyundaiTheme } from "components/ui/switch-themes";
import cn from "classnames";
import { HamletTags } from "helpers/hamlet";
import { HelmetProvider } from "react-helmet-async";
import MainRouter from "./pages/main-router";
import styles from "./root.module.scss";

const App: FC = () => {
  const cnAppFont = cn({ [styles.genesis]: !isHyundaiTheme });
  return (
    <HelmetProvider>
      <div className={cnAppFont}>
        <HamletTags />
        <ReactNotification />
        <ErrorBoundary>
          <MainRouter />
        </ErrorBoundary>
      </div>
    </HelmetProvider>
  );
};

export default App;
