import { TYPES_THIRD_PARTY } from "./constants";
import { RESET_ERROR_AND_STATUS } from "../constants";
import { ActionsType, Store } from "./types";

const initialState: Store = {
  isThirdPartyLoading: false,
  editThirdParty: null,
  edit: false,
  serverError: null,
  modalWindow: false
};

export const thirdPartyReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_THIRD_PARTY.SET_THIRD_PARTY_EDIT_MODE: {
      return { ...state, edit: true };
    }
    case TYPES_THIRD_PARTY.THIRD_PARTY_INFO_REQUEST: {
      return { ...state, isThirdPartyLoading: true };
    }
    case TYPES_THIRD_PARTY.THIRD_PARTY_INFO_SUCCESS: {
      return { ...state, editThirdParty: action.payload };
    }
    case TYPES_THIRD_PARTY.THIRD_PARTY_CREATE_SUCCESS: {
      return { ...state, editThirdParty: action.payload };
    }
    case TYPES_THIRD_PARTY.THIRD_PARTY_INFO_CLEAR: {
      return { ...state, editThirdParty: null };
    }
    case TYPES_THIRD_PARTY.THIRD_PARTY_INFO_LOAD_STOP: {
      return { ...state, isThirdPartyLoading: false };
    }
    case TYPES_THIRD_PARTY.MODAL_OPEN_THIRD_PARTY: {
      return { ...state, modalWindow: action.payload };
    }
    case TYPES_THIRD_PARTY.SERVER_ERROR_THIRD_PARTY: {
      return { ...state, serverError: action.payload };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, serverError: null };
    }
    default: {
      return state;
    }
  }
};
