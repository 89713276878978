import React, { useState } from "react";
import UsersBase from "components/admin/added-legal/legalEntityUserBase";
import LegalCurrentUsers from "components/admin/added-legal/legalEntityCurrentUserBase"

import { RadioButton, Wrapper } from "../../../ui";
import styles from "./legalEntityUsersPanel.module.scss";

export enum AddedLegalEntityUsers {
  current = "current",
  base = "base"
}

const LegalEntityUsersPanel = () => {
  const [addedUsersType, setAddedUsersType] = useState(AddedLegalEntityUsers.base);

  return (
    <Wrapper className={styles.container} pl={40} pt={40} pr={32}>
      <h3 className={styles.title}>Добавление пользователей</h3>
      <div className={styles.wrapper}>
        <div className={styles.panel}>
          <RadioButton
            size="xs"
            onChange={(value: any): void => setAddedUsersType(value)}
            noBorder
            initialSelect={AddedLegalEntityUsers.base}
            options={[
              { value: AddedLegalEntityUsers.current, text: "Список пользователей ЮЛ" },
              { value: AddedLegalEntityUsers.base, text: "База пользователей" }
            ]}
          />
        </div>
      </div>
      {addedUsersType === AddedLegalEntityUsers.base ? <UsersBase /> : <LegalCurrentUsers />}
    </Wrapper>
  );
};

export default LegalEntityUsersPanel;
