import { put, takeLatest, call, select } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { notificationErrors } from "components/ui/notification/notification-errors";
import {
  createPersonalDataRequest,
  createPersonalDataSuccess,
  updatePersonalDataRequest,
  personalDataInfoRequest,
  personalDataInfoSuccess,
  personalDataInfoLoadStop,
  setPersonalDataEditMode,
  setOpenPersonalDataModalWindow
} from "./actions";
import { TYPES_PD } from "./constants";
import { PersonalDataApi } from "../../services/api/personal-data";

// Создание перечня ПДн
function* createPersonalData(action: ReturnType<typeof createPersonalDataRequest>): SagaIterator {
  try {
    const { payload } = action;
    const response = yield call(PersonalDataApi.createPersonalData, payload);
    yield put(createPersonalDataSuccess(response));
    yield put(setOpenPersonalDataModalWindow(true));
  } catch (e) {
    notificationErrors({});
  }
}

// Обновление перечня ПДн
function* updatePersonalData(action: ReturnType<typeof updatePersonalDataRequest>): SagaIterator {
  try {
    const { id } = yield select(store => store.personalData.editPersonalData);
    const { payload } = action;
    yield call(PersonalDataApi.updatePersonalData, id, payload);
    yield put(setOpenPersonalDataModalWindow(true));
  } catch (e) {
    notificationErrors({});
  }
}

// Получение подробной информации о перечне ПДн по id
function* getPersonalDataInfo(action: ReturnType<typeof personalDataInfoRequest>): SagaIterator {
  try {
    const { payload } = action;
    const response = yield call(PersonalDataApi.getPersonalDataById, payload);
    yield put(setPersonalDataEditMode());
    yield put(personalDataInfoSuccess(response));
  } catch (e) {
    notificationErrors({});
  } finally {
    yield put(personalDataInfoLoadStop());
  }
}

function* personalDataSaga(): SagaIterator {
  yield takeLatest(TYPES_PD.PERSONAL_DATA_CREATE_REQUEST, createPersonalData);
  yield takeLatest(TYPES_PD.PERSONAL_DATA_UPDATE_REQUEST, updatePersonalData);
  yield takeLatest(TYPES_PD.PERSONAL_DATA_INFO_REQUEST, getPersonalDataInfo);
}

export { personalDataSaga };
