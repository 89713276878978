export const TYPES_DELETED_PERSONAL_DATA = {
  GET_DELETED_PERSONAL_DATA_REQUEST_WITH_DEBOUNCE: "GET_DELETED_PERSONAL_DATA_REQUEST_WITH_DEBOUNCE",

  GET_DELETED_PERSONAL_DATA_REQUEST: "GET_DELETED_PERSONAL_DATA_REQUEST",
  GET_DELETED_PERSONAL_DATA_SUCCESS: "GET_DELETED_PERSONAL_DATA_SUCCESS",
  GET_DELETED_PERSONAL_DATA_ERROR: "GET_DELETED_PERSONAL_DATA_ERROR",

  GET_DELETED_PERSONAL_DATA_SERVICES_REQUEST: "GET_DELETED_PERSONAL_DATA_SERVICES_REQUEST",
  GET_DELETED_PERSONAL_DATA_SERVICES_SUCCESS: "GET_DELETED_PERSONAL_DATA_SERVICES_SUCCESS",
  GET_DELETED_PERSONAL_DATA_SERVICES_ERROR: "GET_DELETED_PERSONAL_DATA_SERVICES_ERROR"
} as const;
