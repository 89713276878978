import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { ServicesApi } from "services/api/services";
import { TYPES_SERVICES } from "store/services/constants";
import {
  getServicesSuccess,
  getServiceByIdSuccess,
  getServiceByIdRequest,
  getServiceByIdError,
  getServicesError,
  updateServiceRequest,
  updateServiceSuccess,
  updateServiceError,
  getPersonalDataTypeSuccess,
  getPersonalDataTypeError,
  openServiceSuccessModal,
  getPermissionListSuccess,
  getPermissionListError,
  getThirdPartiesListSuccess,
  getThirdPartiesListError,
  servicesLoad,
  servicesLoadStop,
  deleteServiceError,
  deleteServiceSuccess,
  deleteServiceRequest,
  filterServicesRows,
  createServiceSuccess,
  createServiceRequest,
  getServiceCredentialsRequest,
  getServiceCredentialsSuccess,
  setOpenServiceModalWindow,
  setOpenCredentialsPopup,
  servicesStatus,
  getServicesRequest
} from "./actions";
import { notificationErrors } from "../../components/ui/notification/notification-errors";

function* getServices(actions: ReturnType<typeof getServicesRequest>): SagaIterator {
  const { payload } = actions;
  try {
    const response = yield call(ServicesApi.getServices, payload);
    yield put(getServicesSuccess(response));
  } catch (e) {
    const result = { total: 0, per_page: 10, current_page: 1, last_page: 1, data: [] };
    yield put(getServicesSuccess(result));
    yield put(getServicesError({ services: "Не удалось загрузить данные" }));
    notificationErrors({ message: "Не удалось загрузить данные" });
  }
}

function* getPersonalDataType(): SagaIterator {
  try {
    const response = yield call(ServicesApi.getPersonalDataTypes);
    yield put(getPersonalDataTypeSuccess(response.data));
  } catch (e) {
    yield put(getPersonalDataTypeError({ services: "Не удалось загрузить данные" }));
    notificationErrors({ message: "Не удалось загрузить данные" });
  }
}

function* getPermissionList(): SagaIterator {
  try {
    const response = yield call(ServicesApi.getPermissionList);
    yield put(getPermissionListSuccess(response));
  } catch (e) {
    yield put(getPermissionListError({ services: "Не удалось загрузить данные" }));
    notificationErrors({ message: "Не удалось загрузить данные" });
  }
}

function* getServiceById(action: ReturnType<typeof getServiceByIdRequest>): SagaIterator {
  try {
    const response = yield call(ServicesApi.getServiceById, action.payload);
    yield put(getServiceByIdSuccess(response));
  } catch (e) {
    yield put(getServiceByIdError({ serviceById: "Не удалось загрузить данные" }));
    notificationErrors({ message: "Не удалось загрузить данные" });
  }
}

function* updateService(action: ReturnType<typeof updateServiceRequest>): SagaIterator {
  yield put(servicesLoad());
  try {
    const response = yield call(ServicesApi.updateService, action.payload);
    yield put(updateServiceSuccess(response));
    notificationErrors({ title: "Сервис успешно обновлен", type: "success" });
    yield put(openServiceSuccessModal());
  } catch (e) {
    yield put(updateServiceError({ updateService: "Не удалось обновить данные сервиса" }));
    notificationErrors({ message: "Не удалось обновить данные сервиса" });
  } finally {
    yield put(servicesLoadStop());
  }
}

function* getThirdPartiesList(): SagaIterator {
  try {
    const response = yield call(ServicesApi.getThirdPartiesList);
    yield put(getThirdPartiesListSuccess(response.data));
  } catch (e) {
    yield put(getThirdPartiesListError({ services: "Не удалось загрузить данные" }));
    notificationErrors({ message: "Не удалось загрузить данные" });
  }
}

// Удаление сервиса
function* deleteService(actions: ReturnType<typeof deleteServiceRequest>): SagaIterator {
  const {
    payload: { id }
  } = actions;
  yield put(servicesLoad());
  try {
    yield call(ServicesApi.deleteService, id);
    yield put(filterServicesRows(id));
    yield put(deleteServiceSuccess(id));
    yield put(servicesStatus({ deleteService: "Успешно" }));
  } catch (e) {
    yield put(deleteServiceError({ deleteService: "Не удалось удалить сервис" }));
  } finally {
    yield put(servicesLoadStop());
  }
}

// Создание сервиса и креда
function* createService(action: ReturnType<typeof createServiceRequest>): SagaIterator {
  yield put(servicesLoad());
  try {
    const { payload } = action;
    const response = yield call(ServicesApi.createService, payload);
    yield put(createServiceSuccess(response));
    yield put(setOpenServiceModalWindow(true));
  } catch (e) {
    notificationErrors({});
  } finally {
    yield put(servicesLoadStop());
  }
}

// Получение кредов сервиса
function* getServiceCredentials(action: ReturnType<typeof getServiceCredentialsRequest>): SagaIterator {
  yield put(servicesLoad());
  try {
    const response = yield call(ServicesApi.getServiceCredentials, action.payload);
    yield put(getServiceCredentialsSuccess(response));
    yield put(setOpenCredentialsPopup(true));
  } catch (e) {
    notificationErrors({ message: "Не удалось загрузить данные" });
  } finally {
    yield put(servicesLoadStop());
  }
}

function* servicesSaga(): SagaIterator {
  yield takeLatest(TYPES_SERVICES.GET_SERVICES_REQUEST, getServices);
  yield takeLatest(TYPES_SERVICES.GET_PERSONAL_DATA_TYPE_REQUEST, getPersonalDataType);
  yield takeLatest(TYPES_SERVICES.GET_SERVICE_BY_ID_REQUEST, getServiceById);
  yield takeLatest(TYPES_SERVICES.UPDATE_SERVICE_REQUEST, updateService);
  yield takeLatest(TYPES_SERVICES.GET_PERMISSION_LIST_REQUEST, getPermissionList);
  yield takeLatest(TYPES_SERVICES.GET_THIRD_PARTIES_LIST_REQUEST, getThirdPartiesList);
  yield takeEvery(TYPES_SERVICES.DELETE_SERVICE_REQUEST, deleteService);
  yield takeLatest(TYPES_SERVICES.SERVICE_CREATE_REQUEST, createService);
  yield takeLatest(TYPES_SERVICES.GET_SERVICE_CREDENTIALS_REQUEST, getServiceCredentials);
}

export { servicesSaga };
