export const TYPES_THIRD_PARTIES = {
  THIRD_PARTIES_LOAD: "THIRD_PARTIES_LOAD",
  THIRD_PARTIES_LOAD_STOP: "THIRD_PARTIES_LOAD_STOP",
  THIRD_PARTIES_REQUEST: "THIRD_PARTIES_REQUEST",
  THIRD_PARTIES_SUCCESS: "THIRD_PARTIES_SUCCESS",
  THIRD_PARTIES_ERROR: "THIRD_PARTIES_ERROR",

  DELETE_THIRD_PARTY_REQUEST: "DELETE_THIRD_PARTY_REQUEST",
  DELETE_THIRD_PARTY_SUCCESS: "DELETE_THIRD_PARTY_SUCCESS",
  DELETE_THIRD_PARTY_ERROR: "DELETE_THIRD_PARTY_ERROR",

  THIRD_PARTY_BY_ID_REQUEST: "THIRD_PARTY_BY_ID_REQUEST",
  THIRD_PARTY_BY_ID_SUCCESS: "THIRD_PARTY_BY_ID_SUCCESS",

  SET_THIRD_PARTIES_ROWS: "SET_THIRD_PARTIES_ROWS",

  FILTER_THIRD_PARTIES_ROWS: "FILTER_THIRD_PARTIES_ROWS",
  THIRD_PARTIES_STATUS: "THIRD_PARTIES_STATUS",
  THIRD_PARTIES_CLEAR_ROWS: "THIRD_PARTIES_CLEAR_ROWS"
} as const;
