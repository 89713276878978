import { TYPES_MARKETING_MAILINGS } from "store/marketing-mailings/constants";
import { ActionsType, Store } from "./types";
import { RESET_ERROR_AND_STATUS } from "../constants";
import { TYPES_USER } from "../users/constants";

const initialState: Store = {
  isLoading: false,
  error: null,
  mailingsData: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
    data: []
  },
  selectedRows: [],
  isRepeated: true,
  services: [],
  status: "",
  modalWindow: false
};

export const marketingMailingsReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_LOAD: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_LOAD_STOP: {
      return { ...state, isLoading: false };
    }
    case TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_SUCCESS: {
      return { ...state, mailingsData: action.payload };
    }
    case TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_START_REPEAT: {
      return { ...state, isRepeated: true };
    }
    case TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_STOP_REPEAT: {
      return { ...state, isRepeated: false };
    }
    case TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_ERROR: {
      return { ...state, error: { ...state.error, ...action.payload } };
    }
    case TYPES_MARKETING_MAILINGS.MARKETING_MAILINGS_SERVICES_SUCCESS: {
      return { ...state, services: action.payload };
    }
    case TYPES_MARKETING_MAILINGS.SET_MARKETING_MAILINGS_ROWS: {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
    case TYPES_MARKETING_MAILINGS.FILTER_MM_ROWS: {
      return {
        ...state,
        selectedRows: state.selectedRows.filter(item => item !== action.payload)
      };
    }
    case TYPES_MARKETING_MAILINGS.DELETE_MARKETING_MAILINGS_SUCCESS: {
      return {
        ...state,
        mailingsData: { ...state.mailingsData, data: state.mailingsData.data.filter(mm => mm.id !== action.payload) }
      };
    }
    case TYPES_MARKETING_MAILINGS.MODAL_TOGGLE_MM: {
      return {
        ...state,
        modalWindow: action.payload
      };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null };
    }
    default: {
      return state;
    }
  }
};
