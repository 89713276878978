export function matchServices<T extends { id: any }, K>(arrayObj: T[], arrayUuid: K[]) {
  if (arrayObj?.length === arrayUuid?.length) {
    const isChange = arrayObj.every((item: T) => arrayUuid.includes(item.id));
    return isChange;
  }
  if (!arrayObj.length && !arrayUuid.length) {
    return true;
  }
  return false;
}
