import React, { ReactNode } from "react";
import ErrorPage from "pages/error-page/error-page";

type State = {
  hasError: boolean;
};
type Props = {
  children: ReactNode;
};

export default class ErrorBoundary extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  render(): any {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <ErrorPage />;
    }
    return children;
  }
}
