import React, { FC, useEffect } from "react";
import query from "query-string";
import { connect, ConnectedProps } from "react-redux";
import { openAuthRequest, openAuthRequestIframe } from "store/auth/actions";
import { Loading } from "components/ui/loading";
import { Wrapper } from "components/ui/wrapper";
import { Text } from "components/ui/text";
import { StoreTypes } from "store/types";
import { OAuthParams } from "store/auth/types";
import { company, companyName, titleCompanyTheme } from "components/ui/switch-themes";
import Layout from "../../components/auth/layout";
import history from "../../services/history";

type StateProps = {
  isLoading: boolean;
  error: { [key: string]: string } | null;
};

const OpenAuthPage: FC<ReduxProps> = ({ request, isLoading, error, requestIframe }) => {
  useEffect(() => {
    const urlParams = query.parse(history.location.search);
    const params: OAuthParams = {
      client_id: String(urlParams.client_id),
      redirect_uri: String(urlParams.redirect_uri),
      response_type: String(urlParams.response_type),
      state: String(urlParams.state),
      scope: String(urlParams.scope)
    };
    if (urlParams?.param === "iframe") {
      requestIframe(params);
    } else {
      request(params);
    }
  }, []);

  return (
    <Layout>
      <Loading isLoading={isLoading}>
        <Wrapper pb={48}>
          <Text size="h2" align="center" theme="black">
            Авторизация
          </Text>
        </Wrapper>
        <Wrapper>
          <Text size="main" align="left" theme={titleCompanyTheme}>
            {`${company} является вашим личным кабинетом в мире ${companyName} для всех сервисов. Единый аккаунт ${company}
            предоставляет вам возможность редактировать личные и контактные данные, управлять сервисами и способами
            входа. Войти в ${company} можно с любой платформы мира ${companyName}.`}
          </Text>
          {error && error.aouth && (
            <Text size="main" align="center" theme="red">
              {error.aouth}
            </Text>
          )}
        </Wrapper>
      </Loading>
    </Layout>
  );
};

const mapStateToProps = (store: StoreTypes): StateProps => ({
  isLoading: store.auth.isLoading,
  error: store.auth.error
});

const mapDispatchToProps = {
  request: openAuthRequest,
  requestIframe: openAuthRequestIframe
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(OpenAuthPage);
