import * as yup from "yup";
import { isValidName, isValidEmail, isValidPassword, isValidFirstAndLastChar } from "helpers/validate";

const validationSchema = (isEdit: boolean, client: boolean): any => {
  const isRequired = isEdit ? "notRequired" : "required";
  const isClient = client ? "notRequired" : "required";

  return yup.object().shape({
    isEmployee: yup.boolean(),
    name: yup
      .string()
      .required("Необходимо заполнить данные")
      .min(1, "Используйте от 1 до 20 символов")
      .max(20, "Используйте от 1 до 20 символов")
      .test("name", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
      .matches(isValidName, "Используйте кириллицу или латиницу"),
    lastname: yup
      .string()
      .required("Необходимо заполнить данные")
      .min(1, "Используйте от 1 до 20 символов")
      .max(50, "Используйте от 1 до 50 символов")
      .test("name", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
      .matches(isValidName, "Используйте кириллицу или латиницу"),
    email: yup
      .string()
      .required("Необходимо заполнить данные")
      .matches(isValidEmail, "Недопустимый формат email"),
    password: yup
      .string()
      .matches(
        isValidPassword,
        "Используйте символы a-z A-Z 0-9 (пробел)! \"# $% & '() * +, -. /:; <=>? @ [\\] ^ _` {|} ~"
      )
      .min(6, "Пароль должен быть от 6 до 20 символов")
      .max(20, "Пароль должен быть от 6 до 20 символов")
      [isRequired]("Необходимо заполнить данные"),

    confirmedPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Пароли не совпадают")
      [isRequired]("Необходимо заполнить данные"),
    teams: yup.array().when("isEmployee", {
      is: true,
      then: yup
        .array()
        .min(1, "Выберите значение из выпадающего списка")
        .required("Выберите значение из выпадающего списка")
    }),
    roles: yup.array().min(1, "Выберите минимум одну роль"),
    phone: yup
      .string()
      .transform(value => value.replace(/[^\d]/g, ""))
      .min(11, "Номер телефона должен состоять из 11 символов")
      .required("Необходимо заполнить данные"),
    city: yup.string(),
    position_id: yup
      .string()
      .nullable()
      [isClient]("Выберите значение из выпадающего списка")
  });
};

export default validationSchema;
