import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Text, Wrapper } from "components/ui";
import { Table } from "components/ui/table";
import { Loading } from "components/ui/loading";
import NotificationPopup from "components/ui/notification-popup";
import Pagination from "components/ui/pagination/pagination";
import Select from "components/ui/select";
import { paginationTheme } from "components/ui/switch-themes";
import usePermissions from "helpers/usePermissions";
import { superadminPermissions } from "constant/permissions";
import selectOptions from "constant/quantity-rows-per-admin-page";
import ButtonAdd from "components/admin/button-add";
import DeleteRows from "components/admin/delete-rows";
import CredentialsPopup from "components/admin/credentials-popup";
import Row from "components/ui/row";
import { StoreTypes } from "store/types";
import { resetErrorAndStatus } from "store/actions";
import setRowsDataHandler from "helpers/setRowsData";
import styles from "./ServicesPage.module.scss";
import Warning from "../../../components/ui/warning";
import {
  getServicesRequest,
  deleteServiceRequest,
  setServicesRows,
  getServiceCredentialsRequest,
  clearServiceCredentials,
  setOpenCredentialsPopup
} from "../../../store/services/actions";
import history from "../../../services/history";
import { columns, deleteRowsColumn } from "./columns";
import { Service } from "../../../store/services/types";

const ServicesPage: FC<ReduxProps> = ({
  servicesList: { data: services, last_page: lastPage },
  isLoading,
  selectedRows,
  deleteError,
  getServicesRequest: getServices,
  deleteServiceRequest: deleteService,
  resetErrorsAndStatus,
  setRows,
  getServiceCredentials,
  currentServiceCredentials,
  isOpen,
  setOpen,
  status
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRowsData, setRowsData] = useState<Array<Service>>([]);
  const [currentServiceName, setCurrentServiceName] = useState<string>("");
  const [notification, setNotification] = useState<boolean>(false);
  const isPermissionAdminMain = usePermissions(superadminPermissions);

  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
      setRows([]);
    };
  }, []);

  useEffect(() => {
    getServices({
      "pagination[page]": currentPage,
      "pagination[count]": rowsPerPage
    });
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    setRowsData(setRowsDataHandler(selectedRowsData, selectedRows, services));
  }, [selectedRows, services, setRowsData]);

  useEffect(() => {
    if (status) {
      setNotification(true);
    }
  }, [status]);

  const redirectToServicePage = (id: string | number): void => {
    history.push(`/admin/service/${id}`);
  };

  const openCredentialsPopup = (id: string | number) => {
    const serviceName = services.find(item => item.id === id)?.name || "";
    setCurrentServiceName(serviceName);
    getServiceCredentials(id);
  };

  const closeCredentialsPopup = (): void => {
    setOpen(false);
    setCurrentServiceName("");
  };

  return (
    <>
      <div className={styles.header}>
        <CredentialsPopup
          isOpen={isOpen}
          closePopup={closeCredentialsPopup}
          serviceCredentials={currentServiceCredentials}
          serviceName={currentServiceName}
        />
        <NotificationPopup
          title="Данные успешно удалены"
          isOpen={notification}
          action={(): void => setNotification(false)}
        />
        <Wrapper pb={20}>
          <Text size="h3" theme="black">
            Сервисы
          </Text>
        </Wrapper>
        {isPermissionAdminMain ? (
          <Row wrap>
            <Wrapper pr={10}>
              <ButtonAdd path="/admin/services/added" />
            </Wrapper>
            <DeleteRows
              selectedRows={selectedRows}
              deleteRowsHandler={deleteService}
              error={deleteError?.deleteService || ""}
              resetErrorAndStatus={resetErrorsAndStatus}
              columns={deleteRowsColumn}
              selectedRowsData={selectedRowsData}
              disabled={isLoading}
            />
          </Row>
        ) : null}
      </div>
      <div className={styles.wrapper}>
        <Loading isLoading={isLoading}>
          <Table
            columns={columns}
            data={services}
            rowEdit={redirectToServicePage}
            rowBtnAction={openCredentialsPopup}
            buttonName="Редактировать"
            buttonActionName="Показать креды"
            rowSelection={(rows): any => setRows(rows)}
            selectedRows={selectedRows}
          />
        </Loading>
        {!isLoading && services.length === 0 && (
          <Wrapper pt={16}>
            <Warning color="black">Ничего не найдено</Warning>
          </Wrapper>
        )}
        <Wrapper pt={32}>
          <div className={styles.bottomBlock}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
            <Select options={selectOptions} onClick={setRowsPerPage} />
          </div>
        </Wrapper>
      </div>
    </>
  );
};

const mapStateToProps = ({ services }: StoreTypes) => ({
  servicesList: services.services,
  isLoading: services.isLoading,
  selectedRows: services.selectedRows,
  deleteError: services.deleteError,
  currentServiceCredentials: services.currentServiceCredentials,
  isOpen: services.credentialsPopup,
  status: services.status
});

const mapDispatchToProps = {
  getServicesRequest,
  deleteServiceRequest,
  clearServiceCredentials,
  resetErrorsAndStatus: resetErrorAndStatus,
  setRows: setServicesRows,
  getServiceCredentials: getServiceCredentialsRequest,
  setOpen: setOpenCredentialsPopup
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ServicesPage);
