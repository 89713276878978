import React, { FC } from "react";
import { Popup } from "components/ui/popup";
import { Text, Wrapper } from "components/ui";
import { isHyundaiTheme } from "components/ui/switch-themes";
import styles from "./credentials-popup.module.scss";
import { ServiceCredentialsType } from "../../../store/services/types";

type Props = {
  isOpen: boolean;
  closePopup: () => void;
  serviceCredentials: ServiceCredentialsType | null;
  serviceName: string;
};

const CredentialsPopup: FC<Props> = ({ isOpen, closePopup, serviceCredentials, serviceName }) => {
  return (
    <Popup isOpen={isOpen} onClick={closePopup} height="400px">
      <h3 className={styles.popupTitle}>{`Креды сервиса - ${serviceName}`}</h3>
      <div>
        <Wrapper pb={24}>
          <Text uppercase={!isHyundaiTheme} size="main" theme="black">
            ClientId
          </Text>
          <Text uppercase={!isHyundaiTheme} size="h5" theme="black">
            {serviceCredentials ? serviceCredentials.clientId : ""}
          </Text>
        </Wrapper>
        <Wrapper>
          <Text uppercase={!isHyundaiTheme} size="main" theme="black">
            Secret
          </Text>
          <Text uppercase={!isHyundaiTheme} size="h5" theme="black">
            {serviceCredentials ? serviceCredentials.secret : ""}
          </Text>
        </Wrapper>
      </div>
    </Popup>
  );
};

export default CredentialsPopup;
