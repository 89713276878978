import React, { FC, useState, useEffect } from "react";

import cn from "classnames";
import styles from "./pagination.module.scss";

export type PropsType = {
  currentPage: number;
  totalPages: number;
  onChange: (number: number) => void;
  theme: "blue" | "gold";
};

const Pagination: FC<PropsType> = ({ theme, onChange: setCurrentPage, currentPage, totalPages }) => {
  const [startPage, setStartPage] = useState(1);
  const defaultLastPage = totalPages - 1 < 4 ? totalPages : 5;
  const defaultPaginationLength = totalPages < 5 ? totalPages : 5;
  const [lastPage, setLastPage] = useState(defaultLastPage);
  const [paginationLength, setPaginationLength] = useState(defaultPaginationLength);
  const makeArray = (factor: number, length: number): Array<number> => Array.from({ length }, (v, k) => k + factor);

  const getStartPage = (): number => {
    if (currentPage === lastPage) {
      return startPage + 1;
    }
    if (currentPage === startPage && startPage !== 1) {
      return startPage - 1;
    }
    return startPage;
  };

  const getLastPage = (): number => {
    if (currentPage === lastPage) {
      return lastPage + 1;
    }
    if (currentPage === startPage && startPage !== 1) {
      return lastPage - 1;
    }
    return lastPage;
  };

  const getPaginationLength = (): number => {
    if (totalPages < 5) {
      return totalPages;
    }
    return 5;
  };

  const prevHandler = (): void => {
    if (currentPage < 2) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const lastHandler = (): void => setCurrentPage(totalPages);
  const nextHandler = (): void => {
    if (currentPage > totalPages - 1) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const jumpFirstPageHandler = (): void => {
    if (totalPages === 1) return;
    setStartPage(1);
    setLastPage(defaultLastPage);
    setPaginationLength(defaultPaginationLength);
    setCurrentPage(1);
  };

  const jumpLastPageHandler = (): void => {
    if (totalPages === 1) return;
    setStartPage(totalPages - paginationLength + 1);
    setLastPage(totalPages);
    lastHandler();
  };
  useEffect(() => {
    if (currentPage === totalPages) {
      return;
    }
    setStartPage(getStartPage());
    setLastPage(getLastPage());
  }, [currentPage]);

  useEffect(() => {
    if (totalPages < paginationLength) {
      setCurrentPage(1);
      setStartPage(1);
      setLastPage(totalPages);
      setPaginationLength(totalPages);
      return;
    }
    if (totalPages >= paginationLength) {
      setStartPage(1);
      setCurrentPage(1);
      setPaginationLength(getPaginationLength());
      setLastPage(getPaginationLength());
    }
  }, [totalPages]);

  const rowNumbers = makeArray(startPage, paginationLength);

  return (
    <div className={styles.pagination}>
      <div className={styles.navigation}>
        <button aria-label="first page" type="button" className={styles.prevAll} onClick={jumpFirstPageHandler} />
        <button aria-label="previous" type="button" className={styles.prev} onClick={prevHandler} />
        <div className={styles.pages}>
          {rowNumbers.map(number => {
            const cnPageNumber = cn(styles.page, {
              [styles.active]: currentPage === number,
              [styles[theme]]: theme
            });
            return (
              <button type="button" className={cnPageNumber} key={number} onClick={(): void => setCurrentPage(number)}>
                {number}
              </button>
            );
          })}
        </div>
        <button aria-label="next" type="button" className={styles.next} onClick={nextHandler} />
        <button aria-label="last page" type="button" className={styles.nextAll} onClick={jumpLastPageHandler} />
      </div>
    </div>
  );
};

export default Pagination;
