import { TYPES_THIRD_PARTY } from "./constants";
import { CreateThirdParty, ThirdPartyType } from "./types";

export const createThirdPartyRequest = (data: CreateThirdParty) => ({
  type: TYPES_THIRD_PARTY.THIRD_PARTY_CREATE_REQUEST,
  payload: data
});

export const createThirdPartySuccess = (data: ThirdPartyType) => ({
  type: TYPES_THIRD_PARTY.THIRD_PARTY_CREATE_SUCCESS,
  payload: data
});

export const createThirdPartyError = () => ({
  type: TYPES_THIRD_PARTY.THIRD_PARTY_CREATE_ERROR
});

export const updateThirdPartyRequest = (data: CreateThirdParty) => ({
  type: TYPES_THIRD_PARTY.THIRD_PARTY_UPDATE_REQUEST,
  payload: data
});

export const updateThirdPartySuccess = (data: ThirdPartyType) => ({
  type: TYPES_THIRD_PARTY.THIRD_PARTY_UPDATE_SUCCESS,
  payload: data
});

export const updateThirdPartyError = () => ({
  type: TYPES_THIRD_PARTY.THIRD_PARTY_UPDATE_ERROR
});

export const thirdPartyInfoRequest = (id: string) => ({
  type: TYPES_THIRD_PARTY.THIRD_PARTY_INFO_REQUEST,
  payload: id
});

export const thirdPartyInfoSuccess = (data: ThirdPartyType) => ({
  type: TYPES_THIRD_PARTY.THIRD_PARTY_INFO_SUCCESS,
  payload: data
});

export const thirdPartyInfoLoadStop = () => ({
  type: TYPES_THIRD_PARTY.THIRD_PARTY_INFO_LOAD_STOP
});

export const thirdPartyInfoClear = () => ({
  type: TYPES_THIRD_PARTY.THIRD_PARTY_INFO_CLEAR
});

export const setThirdPartyEditMode = () => ({
  type: TYPES_THIRD_PARTY.SET_THIRD_PARTY_EDIT_MODE
});

export const serverErrorThirdParty = (error: string) => ({
  type: TYPES_THIRD_PARTY.SERVER_ERROR_THIRD_PARTY,
  payload: error
});

export const setOpenThirdPartyModalWindow = (toggle: boolean) => ({
  type: TYPES_THIRD_PARTY.MODAL_OPEN_THIRD_PARTY,
  payload: toggle
});
