export const TYPES_LEL = {
  LEGAL_ENTITIES_LOAD: "LEGAL_ENTITIES_LOAD",
  LEGAL_ENTITIES_LOAD_STOP: "LEGAL_ENTITIES_LOAD_STOP",
  LEGAL_ENTITIES_REQUEST: "LEGAL_ENTITIES_REQUEST",
  LEGAL_ENTITIES_SUCCESS: "LEGAL_ENTITIES_SUCCESS",
  LEGAL_ENTITIES_ERROR: "LEGAL_ENTITIES_ERROR",

  DELETE_LEGAL_ENTITY_REQUEST: "DELETE_LEGAL_ENTITY_REQUEST",
  DELETE_LEGAL_ENTITY_SUCCESS: "DELETE_LEGAL_ENTITY_SUCCESS",
  DELETE_LEGAL_ENTITY_ERROR: "DELETE_LEGAL_ENTITY_ERROR",

  LEGAL_ENTITY_BY_ID_REQUEST: "LEGAL_ENTITY_BY_ID_REQUEST",
  LEGAL_ENTITY_BY_ID_SUCCESS: "LEGAL_ENTITY_BY_ID_SUCCESS",

  SET_LE_ROWS: "SET_LE_ROWS",

  FILTER_LE_ROWS: "FILTER_LE_ROWS",
  LEGAL_ENTITY_STATUS: "LEGAL_ENTITY_STATUS",
  LE_CLEAR_ROWS: "LE_CLEAR_ROWS"
} as const;
