import { put, takeLatest, takeEvery, call, select } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { Clients } from "services/api/clients";

import { Employees } from "services/api/employees";
import { User } from "services/api/user";
import { TYPES_EMPLOYEES } from "store/employees/constants";
import EmployeeRoles from "constant/eployee-roles";
import addTypesRole from "helpers/addedTypesRoles";
import { notificationErrors } from "components/ui/notification/notification-errors";
import history from "services/history";
import { newStateTeams } from "helpers/new-state-teams";
import { TYPES_USER, UpdateType } from "../users/constants";
import { TYPES_ROLES as RolesTYPES } from "../roles/constants";
import {
  employeesError,
  employeesSuccess,
  employeesLoad,
  employeesLoadStop,
  deleteEmployeeSuccess,
  employeesByIdSuccess,
  employeesStatus,
  filterEmployeesRows,
  deleteEmployeeError,
  employeesByIdRequest,
  employeesRequest,
  clientRequest,
  deleteEmployeeRequest,
  employeeUpdateRequest,
  createBanDeletionRequest,
  createBanDeletionSuccess,
  updateBanDeletionRequest,
  updateBanDeletionSuccess,
  deleteBanDeletionRequest,
  deleteBanDeletionSuccess,
  deleteBanDeletionError,
  filterBanDeletionRows
} from "./actions";

import { uploadAvatar, attachTeam, detachTeam, updateUser } from "../users/saga";
import { attachRoles as attachRolesSaga } from "../roles/saga";
import { StoreTypes } from "../types";

function* getEmployees(action: ReturnType<typeof employeesRequest>): SagaIterator {
  const { payload } = action;
  yield put(employeesLoad());
  try {
    const response = yield call(Employees.getEmployees, payload);
    yield put(employeesSuccess(response));
  } catch (e) {
    yield put(employeesError({ message: "Не удалось загрузить данные" }));
  } finally {
    yield put(employeesLoadStop());
  }
}
function* getClient(action: ReturnType<typeof clientRequest>): SagaIterator {
  const { payload } = action;
  yield put(employeesLoad());
  try {
    const response = yield call(Clients.getClients, payload);
    yield put(employeesSuccess(response));
  } catch (e) {
    yield put(employeesError({ message: "Не удалось загрузить данные" }));
  } finally {
    yield put(employeesLoadStop());
  }
}

function* deleteEmployee(action: ReturnType<typeof deleteEmployeeRequest>): SagaIterator {
  const {
    payload: { id }
  } = action;

  yield put(employeesLoad());
  try {
    yield call(User.deleteAccount, action.payload);
    yield put(filterEmployeesRows(id));
    yield put(deleteEmployeeSuccess(id));
    yield put(employeesStatus({ deleteEmployee: "Успешно" }));
  } catch (e) {
    yield put(deleteEmployeeError({ deleteEmployee: `Не удалось удалить аккаунт ${id}` }));
  } finally {
    yield put(employeesLoadStop());
  }
}

function* getEmployeesById(action: ReturnType<typeof employeesByIdRequest>): SagaIterator {
  const { payload } = action;
  yield put(employeesLoad());
  try {
    const result = yield call(User.getUserById, payload);
    let response = Array.isArray(result) ? result[0] : result;
    response.teams = newStateTeams(response.teams);
    if (response.roles.length) {
      const { roles } = response;
      response = { ...response, roles: addTypesRole(roles) };
    } else {
      response = { ...response, roles: [] };
    }
    yield put(employeesByIdSuccess(response));
  } catch (e) {
    history.push("/");
    notificationErrors({});
  } finally {
    yield put(employeesLoadStop());
  }
}

const getUserId = (store: StoreTypes): string => store.users.user.id;

function* updateEmployee(action: ReturnType<typeof employeeUpdateRequest>): SagaIterator {
  const {
    payload: {
      body: { requestBody, picture, attachRoles, teams, detachTeams, attachTeams, teamsRole, id },
      setOpen,
      customErrorSetter
    }
  } = action;

  yield put(employeesLoad());

  const userId = yield select(getUserId);

  try {
    if (picture) {
      yield call(uploadAvatar, {
        type: TYPES_USER.UPLOAD_AVATAR_REQUEST,
        payload: { id, data: picture, setAvatar: "currentEmployee" }
      });
    }

    if (attachRoles) {
      yield call(attachRolesSaga, {
        type: RolesTYPES.ATTACH_ROLES_REQUEST,
        payload: { userId: id, roles: attachRoles }
      });
    }

    if (detachTeams) {
      yield call(detachTeam, {
        type: TYPES_USER.DETACH_TEAM_REQUEST,
        payload: { userId: id, ...detachTeams }
      });
    }
    if (requestBody) {
      if (userId === id) {
        yield call(updateUser, {
          type: TYPES_USER.UPDATE_USER_REQUEST,
          payload: {
            body: requestBody,
            updateType: UpdateType.UpdateMainUser,
            errorName: "updateUser",
            customErrorSetter
          }
        });
      } else {
        yield call(updateUser, {
          type: TYPES_USER.UPDATE_USER_REQUEST,
          payload: {
            body: requestBody,
            updateType: UpdateType.UpdateCurrentEmployee,
            errorName: "updateUser",
            customErrorSetter
          }
        });
      }
    }
    yield call(setOpen, true);
  } finally {
    yield put(employeesLoadStop());
  }
}

// Создание запрета на удаление
function* createBanDeletion(action: ReturnType<typeof createBanDeletionRequest>): SagaIterator {
  yield put(employeesLoad());
  try {
    const { payload } = action;
    const response = yield call(User.createBanDeletion, payload);
    yield put(createBanDeletionSuccess(response));
    notificationErrors({ title: "Запрет на удаление создан", type: "success" });
  } catch (e) {
    notificationErrors({ message: "Не удалось создать запрет на удаление" });
  } finally {
    yield put(employeesLoadStop());
  }
}

// Изменение запрета на удаление
function* updateBanDeletion(action: ReturnType<typeof updateBanDeletionRequest>): SagaIterator {
  yield put(employeesLoad());
  try {
    const { id, payload } = action;
    const response = yield call(User.updateBanDeletion, id, payload);
    yield put(updateBanDeletionSuccess(response));
    notificationErrors({ title: "Запрет на удаление изменен", type: "success" });
  } catch (e) {
    notificationErrors({ message: "Не удалось изменить запрет на удаление" });
  } finally {
    yield put(employeesLoadStop());
  }
}

// Удаление запрета на удаление
function* deleteBanDeletion(actions: ReturnType<typeof deleteBanDeletionRequest>): SagaIterator {
  const {
    payload: { id }
  } = actions;
  yield put(employeesLoad());
  try {
    yield call(User.deleteBanDeletion, id);
    yield put(filterBanDeletionRows(id));
    yield put(deleteBanDeletionSuccess(id));
    notificationErrors({ title: "Запрет на удаление удален", type: "success" });
  } catch (e) {
    notificationErrors({ message: "Не удалось удалить запрет на удаление" });
    yield put(deleteBanDeletionError({ deleteBanDeletionError: "Не удалось удалить запрет на удаление" }));
  } finally {
    yield put(employeesLoadStop());
  }
}

function* employeesSaga(): SagaIterator {
  yield takeLatest(TYPES_EMPLOYEES.EMPLOYEES_REQUEST, getEmployees);
  yield takeLatest(TYPES_EMPLOYEES.CLIENT_REQUEST, getClient);
  yield takeEvery(TYPES_EMPLOYEES.DELETE_EMPLOYEE_REQUEST, deleteEmployee);
  yield takeEvery(TYPES_EMPLOYEES.EMPLOYEES_BY_ID_REQUEST, getEmployeesById);
  yield takeEvery(TYPES_EMPLOYEES.EMPLOYEE_UPDATE_REQUEST, updateEmployee);
  yield takeEvery(TYPES_EMPLOYEES.CREATE_BAN_DELETION_REQUEST, createBanDeletion);
  yield takeEvery(TYPES_EMPLOYEES.UPDATE_BAN_DELETION_REQUEST, updateBanDeletion);
  yield takeEvery(TYPES_EMPLOYEES.DELETE_BAN_DELETION_REQUEST, deleteBanDeletion);
}

export { employeesSaga };
