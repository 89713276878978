import React, { FC, ReactElement } from "react";
import { Redirect, Route, Switch, RouteComponentProps, RouteProps } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { StoreTypes } from "store/types";
import storageCookie from "services/storageCookie";
import { REFRESH_TOKEN, TOKEN } from "constant/api";
import usePermissions from "../../helpers/usePermissions";
import HomePage from "../../pages/lk/home";
import Layout from "../lk/layout";

type Props = {
  component: FC;
  path: string;
  permissions: Array<string>;
};

const ProtectRoute: FC<Props & ReduxProps> = ({
  userPermissions,
  component: ProtectedComponent,
  userInfoIsLoaded,
  permissions,
  ...props
}) => {
  const isAllowedPath = usePermissions(permissions);
  const token = storageCookie.get(TOKEN);
  const refreshToken = storageCookie.get(REFRESH_TOKEN);

  const isAuth = Boolean(token && refreshToken);

  const renderProtected = (routerProps: RouteComponentProps & RouteProps): ReactElement => {
    if (isAuth && !isAllowedPath) {
      return (
        <Layout>
          <Switch>
            <Route exact path="/home" component={HomePage} />
            <Redirect to="/home" />
          </Switch>
        </Layout>
      );
    }
    return <ProtectedComponent {...routerProps} />;
  };
  return (
    <Switch>
      {!isAuth && <Redirect to="/auth" />}
      {userInfoIsLoaded && <Route {...props} render={renderProtected} />}
    </Switch>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  userInfoIsLoaded: store.users.userInfoIsLoaded,
  userPermissions: store.users.user.permissions
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ProtectRoute);
