export const deleteRowsColumn = [
  {
    title: "Название",
    dataIndex: "name",
    render: ({ name }: any): string => `${name}`
  }
];

export const columns = [
  {
    title: "Название",
    dataIndex: "name",
    render: ({ name }: any): string => `${name}`
  }
];
