import React, { FC, ReactElement, useEffect, useRef } from "react";
import { Button, Input, Wrapper, Textarea } from "components/ui";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { isValidFirstAndLastChar, isValidEmail, isValidProcessingPurpose, isValidNameNumber } from "helpers/validate";
import { connect, ConnectedProps } from "react-redux";
import { StoreTypes } from "store/types";
import { useParams } from "react-router-dom";
import { Loading } from "components/ui/loading";
import Row from "components/ui/row";
import NotificationPopup from "components/ui/notification-popup";
import { isChangedValues } from "helpers/formik-handlers";
import styles from "./added-third-party-form.module.scss";
import history from "../../../../services/history";
import {
  createThirdPartyRequest,
  thirdPartyInfoClear,
  thirdPartyInfoRequest,
  setOpenThirdPartyModalWindow,
  updateThirdPartyRequest
} from "../../../../store/third-party/actions";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .test("name", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
    .matches(isValidNameNumber, "Используйте кириллицу или латиницу, цифры и символы: ~@#$%^_-+*(){}'`\"«»:;/\\№.")
    .required("Необходимо заполнить данные")
    .min(1, "Используйте от 1 до 255 символов")
    .max(255, "Используйте от 1 до 255 символов")
    .required("Необходимо заполнить данные"),
  email: yup
    .string()
    .required("Необходимо заполнить данные")
    .matches(isValidEmail, "Недопустимый формат email"),
  processing_purpose: yup
    .string()
    .test("assigment", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
    .matches(
      isValidProcessingPurpose,
      "Используйте кириллицу или латиницу, цифры и символы: ~@#$%^_-+*(){}'`\"«»:;/\\№•."
    )
    .max(2000, "Используйте от 1 до 2000 символов"),
  assigment: yup
    .string()
    .test("assigment", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
    .matches(isValidNameNumber, "Используйте кириллицу или латиницу, цифры и символы: ~@#$%^_-+*(){}'`\"«»:;/\\№.")
    .required("Необходимо заполнить данные")
    .min(1, "Используйте от 1 до 255 символов")
    .max(255, "Используйте от 1 до 255 символов")
    .required("Необходимо заполнить данные"),
  address: yup
    .string()
    .test("address", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
    .matches(isValidNameNumber, "Используйте кириллицу или латиницу, цифры и символы: ~@#$%^_-+*(){}'`\"«»:;/\\№.")
    .required("Необходимо заполнить данные")
    .min(1, "Используйте от 1 до 255 символов")
    .max(255, "Используйте от 1 до 255 символов")
    .required("Необходимо заполнить данные")
  // to_all_services: yup.boolean().required("Необходимо заполнить данные")
});

type ValuesTypes = {
  name: string;
  assigment: string;
  address: string;
  email: string;
  processing_purpose?: string;
  to_all_services?: boolean;
};

type Props = {
  isEdit?: boolean;
};

const AddedThirdPartyForm: FC<ReduxProps & Props> = ({
  getThirdPartyInfo,
  isEdit = false,
  errorServer,
  onUpdate,
  dataThirdParty,
  clear,
  onCreate,
  isOpen,
  setOpen
}) => {
  const { id: thirdPartyId } = useParams<any>();
  const formikRef = useRef<any>();

  useEffect(() => {
    if (isEdit) {
      getThirdPartyInfo(thirdPartyId);
    }
    return (): void => {
      setOpen(false);
      clear();
    };
  }, []);

  const getInitialValues = (): ValuesTypes => {
    if (isEdit) {
      if (dataThirdParty) {
        const { name, assigment, address, email, processing_purpose } = dataThirdParty;
        return {
          name: name || "",
          assigment: assigment || "",
          address: address || "",
          email: email || "",
          processing_purpose: processing_purpose || ""
          // to_all_services: to_all_services || false
        };
      }
    }
    return {
      name: "",
      assigment: "",
      address: "",
      email: "",
      processing_purpose: ""
      // to_all_services: false
    };
  };

  const initValue = getInitialValues();
  const isDisplayContent = !isEdit || (isEdit && dataThirdParty);

  const inputsBlur = (item: string, value: string): void => {
    if (value) {
      formikRef.current.setFieldValue(item, value.trim());
      formikRef.current.setTouched({ [item]: true });
    }
  };

  const guard = (): void => {
    if (Object.keys(formikRef.current.errors).length !== 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const submitHandler = (values: ValuesTypes): void => {
    const { name, assigment, address, email, processing_purpose, to_all_services } = values;

    if (isEdit) {
      onUpdate({
        name,
        assigment,
        address,
        email,
        processing_purpose
        // to_all_services
      });
    } else {
      onCreate({
        name,
        assigment,
        address,
        email,
        processing_purpose
        // to_all_services
      });
    }
  };

  const notificationTitle = isEdit ? "Данные успешно изменены" : "Данные успешно сохранены";

  if (!isDisplayContent) {
    return (
      <Loading isLoading>
        <Wrapper pt={300}>
          <Row justifyContent="justifyCenter" alignItems="alignCenter">
            <h1>Идет загрузка</h1>
          </Row>
        </Wrapper>
      </Loading>
    );
  }
  return (
    <div className={styles.container}>
      <NotificationPopup
        title={notificationTitle}
        isOpen={isOpen}
        action={() => {
          if (dataThirdParty && dataThirdParty.id) {
            history.push(`/admin/third-parties/added/${dataThirdParty.id}`);
          } else {
            history.push(`/admin/third-parties`);
          }
          setOpen(false);
        }}
      />
      <Formik
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={submitHandler}
        initialValues={initValue}
      >
        {({ values, touched, setFieldValue, errors, handleBlur, setFieldTouched, handleChange }): ReactElement => (
          <Form>
            <Wrapper pt={30} pl={40}>
              {isEdit ? (
                <h3 className={styles.title}>Редактирование третьего лица</h3>
              ) : (
                <h3 className={styles.title}>Добавление третьего лица</h3>
              )}
              <div className={styles.form_input}>
                <div className={styles.containerWrapper}>
                  <div className={styles.inputWrapper}>
                    <Input
                      name="name"
                      placeholder="Обязательное поле"
                      label="Название"
                      onChange={handleChange}
                      onBlur={(): void => inputsBlur("name", values.name)}
                      value={values.name}
                      invalid={Boolean(errors.name && touched.name)}
                      errorMessages={errors.name && touched.name ? errors.name : null}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <Input
                      name="assigment"
                      placeholder="Обязательное поле"
                      label="Поручение / Передача"
                      onChange={handleChange}
                      onBlur={(): void => inputsBlur("assigment", values.assigment)}
                      value={values.assigment}
                      invalid={Boolean(errors.assigment && touched.assigment)}
                      errorMessages={errors.assigment && touched.assigment ? errors.assigment : null}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <Input
                      name="address"
                      placeholder="Обязательное поле"
                      label="Адрес"
                      onChange={handleChange}
                      onBlur={(): void => inputsBlur("address", values.address)}
                      value={values.address}
                      invalid={Boolean(errors.address && touched.address)}
                      errorMessages={errors.address && touched.address ? errors.address : null}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <Input
                      name="email"
                      placeholder="Обязательное поле"
                      label="Email"
                      onChange={handleChange}
                      onBlur={(): void => inputsBlur("email", values.email)}
                      value={values.email}
                      invalid={Boolean(errors.email && touched.email)}
                      errorMessages={errors.email && touched.email ? errors.email : null}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <Textarea
                      name="processing_purpose"
                      placeholder=""
                      label="Цель обработки"
                      rows={3}
                      onChange={handleChange}
                      onBlur={(): void => inputsBlur("processing_purpose", values.processing_purpose || "")}
                      value={values.processing_purpose}
                      invalid={Boolean(errors.processing_purpose && touched.processing_purpose)}
                      errorMessages={
                        errors.processing_purpose && touched.processing_purpose ? errors.processing_purpose : null
                      }
                    />
                  </div>
                  {/* <div className={styles.wrapper}>
                    <Checkbox
                      invalid={!values.to_all_services && !!errors.to_all_services && touched.to_all_services}
                      errorMessages={
                        !values.to_all_services && !!errors.to_all_services && touched.to_all_services
                          ? errors.to_all_services
                          : null
                      }
                      checked={!!values.to_all_services}
                      onChange={() => setFieldValue("to_all_services", !values.to_all_services)}
                      name="to_all_services"
                      color="prime"
                    >
                      <Text size="h5">Для всех сервисов</Text>
                    </Checkbox>
                  </div> */}
                </div>
              </div>
            </Wrapper>
            <div>
              <Wrapper className={styles.form_btn} pl={40} pb={20}>
                <div>
                  <Button onClick={guard} type="submit" disabled={isChangedValues(initValue, values)}>
                    Сохранить
                  </Button>
                </div>
                <div>
                  <Button noBorder theme="transparent" onClick={(): void => history.push("/admin/third-parties")}>
                    Отменить
                  </Button>
                </div>
              </Wrapper>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ thirdParty }: StoreTypes) => ({
  dataThirdParty: thirdParty.editThirdParty,
  errorServer: thirdParty.serverError,
  isOpen: thirdParty.modalWindow
});

const mapDispatchToProps = {
  onCreate: createThirdPartyRequest,
  onUpdate: updateThirdPartyRequest,
  getThirdPartyInfo: thirdPartyInfoRequest,
  clear: thirdPartyInfoClear,
  setOpen: setOpenThirdPartyModalWindow
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddedThirdPartyForm);
