import { Role } from "store/roles/types";
import EmployeeRoles from "constant/eployee-roles";
import TeamName from "constant/team-name";

const addTypesRole = (arr: Role[]) => {
  let newArray: Role[] = [];
  const { adminDC, adminMain, employeeDC, employeeMain, client } = EmployeeRoles;

  const rolesDC = arr
    .filter(role => role.name === adminDC || role.name === employeeDC)
    .map(obj => ({ ...obj, type: TeamName.DC }));

  const rolesMain = arr
    .filter(role => role.name === adminMain || role.name === employeeMain)
    .map(obj => ({ ...obj, type: TeamName.MAIN }));

  const roleClient = arr.filter(role => role.name === client).map(obj => ({ ...obj, type: null }));

  newArray = [...rolesDC, ...rolesMain, ...roleClient];
  return newArray;
};

export default addTypesRole;
