import { REPLACE_VALUES } from "constant/phone-mask";

export const onlyNumbers = (str: string): string => str.replace(/[^0-9]/g, "");

export const handlerPaste = (elem: any, setFieldValue: (field: string, value: any) => void): void => {
  elem.preventDefault();
  const phone = elem.clipboardData.getData("Text");
  const clearedPhone = onlyNumbers(phone);
  if (clearedPhone.length > 10 && REPLACE_VALUES.includes(clearedPhone[0])) {
    const phoneMask =
      clearedPhone
        .substring(1)
        .replace(/\D/g, "")
        .match(/(\d{3})(\d{3})(\d{2})(\d{2})/) || "";

    const result = `7${phoneMask[1]} ${phoneMask[2]} ${phoneMask[3]} ${phoneMask[4]}`;
    setFieldValue("phone", result);
  }
};

export const handlerPasteForSearch = (elem: any): string => {
  elem.preventDefault();
  const phone = elem.clipboardData.getData("Text");
  const clearedPhone = onlyNumbers(phone);
  if (clearedPhone.length > 10 && REPLACE_VALUES.includes(clearedPhone[0])) {
    const phoneMask =
      clearedPhone
        .substring(1)
        .replace(/\D/g, "")
        .match(/(\d{3})(\d{3})(\d{2})(\d{2})/) || "";

    const result = `7${phoneMask[1]} ${phoneMask[2]} ${phoneMask[3]} ${phoneMask[4]}`;
    return result;
  }
  return "";
};
