import { all } from "redux-saga/effects";
import { dealersSaga } from "store/dealers/saga";
import { authSaga } from "./auth/saga";
import { usersSaga } from "./users/saga";
import { employeesSaga } from "./employees/saga";
import { dealerCenterSaga } from "./dealer-center/saga";
import { servicesSaga } from "./services/saga";
import { rolesSaga } from "./roles/saga";
import { loginHistorySaga } from "./loginHistory/saga";
import { marketingMailingsSaga } from "./marketing-mailings/saga";
import { legalEntitiesListSaga } from "./legalEntitiesList/saga";
import { legalEntitySaga } from "./legalEntity/saga";
import { deletePersonalDataSaga } from "./deleted-personal-data/saga";
import { deletePDOrdersSaga } from "./delete-personal-data-orders/saga";
import { thirdPartiesListSaga } from "./third-parties-list/saga";
import { thirdPartySaga } from "./third-party/saga";
import { personalDataListSaga } from "./personal-data-list/saga";
import { personalDataSaga } from "./personal-data/saga";
import { phoneBlacklistSaga } from "./phone-blacklist/saga";

export default function* saga(): Generator {
  yield all([
    authSaga(),
    usersSaga(),
    employeesSaga(),
    dealersSaga(),
    legalEntitiesListSaga(),
    legalEntitySaga(),
    dealerCenterSaga(),
    servicesSaga(),
    rolesSaga(),
    loginHistorySaga(),
    marketingMailingsSaga(),
    deletePersonalDataSaga(),
    deletePDOrdersSaga(),
    thirdPartiesListSaga(),
    thirdPartySaga(),
    personalDataListSaga(),
    personalDataSaga(),
    phoneBlacklistSaga()
  ]);
}
