import { put, takeEvery, call, all, debounce, takeLatest, delay, select } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { PersonalDataDeleteOrdersApi } from "services/api/delete-personal-data-orders";
import { TYPES_PERSONAL_DATA_DELETE_ORDERS } from "./constants";
import {
  deletePDOrdersError,
  deletePDOrdersRequest,
  deletePDOrdersSuccess,
  getPersonalDataDeleteOrdersServicesError,
  getPersonalDataDeleteOrdersServicesSuccess,
  restorePersonalDataError,
  restorePersonalDataRequest,
  restorePersonalDataSuccess
} from "./actions";
import { PersonalDataDeleteOrders } from "./types";
import { notificationErrors } from "../../components/ui/notification/notification-errors";
import { legalEntityStatus } from "../legalEntitiesList/actions";

function* getDeletePersonalData(action: ReturnType<typeof deletePDOrdersRequest>): SagaIterator {
  if (action.payload.isFilterChanged) {
    yield delay(500);
  }

  try {
    const [services, response] = yield all([
      call(PersonalDataDeleteOrdersApi.getServices),
      call(PersonalDataDeleteOrdersApi.getPersonalDataDeleteOrders, action.payload)
    ]);
    yield put(getPersonalDataDeleteOrdersServicesSuccess(services));
    const personalDataDeleteOrders = response.data.map((item: PersonalDataDeleteOrders) => {
      const service = services.find((service: any) => item.service_id === service.id);
      return { ...item, service_name: service.name };
    });
    yield put(deletePDOrdersSuccess({ ...response, data: personalDataDeleteOrders }));
  } catch (e) {
    yield put(deletePDOrdersError({ personalDataDeleteOrders: "Не удалось загрузить данные по удаленным ПД" }));
  }
}

function* getDeletePersonalDataServices(): SagaIterator {
  try {
    const response = yield call(PersonalDataDeleteOrdersApi.getServices);
    yield put(getPersonalDataDeleteOrdersServicesSuccess(response));
  } catch (e) {
    yield put(
      getPersonalDataDeleteOrdersServicesError({
        deletePDOrdersServices: "Не удалось загрузить список сервисов"
      })
    );
  }
}

function* restorePersonalData(action: ReturnType<typeof restorePersonalDataRequest>): SagaIterator {
  try {
    const { userId, serviceId, id } = action.payload;
    const { filters, data } = yield select(store => store.personalDataDeleteOrders);
    yield call(PersonalDataDeleteOrdersApi.restoreUserInService, userId, serviceId);
    yield put(restorePersonalDataSuccess(id));
    notificationErrors({
      title: "ПД восстановлены",
      type: "success"
    });
    yield put(
      deletePDOrdersRequest({
        filters,
        pagination: { page: data.data.current_page, count: data.data.per_page },
        isFilterChanged: false
      })
    );
  } catch (e) {
    yield put(
      restorePersonalDataError({
        restorePersonalData: "Не удалось восстановить ПД"
      })
    );
  }
}

function* deletePDOrdersSaga(): SagaIterator {
  yield takeLatest(TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_REQUEST, getDeletePersonalData);
  yield takeLatest(TYPES_PERSONAL_DATA_DELETE_ORDERS.RESTORE_PERSONAL_DATA_REQUEST, restorePersonalData);
  yield takeEvery(
    TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_SERVICES_REQUEST,
    getDeletePersonalDataServices
  );
}

export { deletePDOrdersSaga };
