import React, { FC, useMemo, useState } from "react";
import { Button, Link, Logo, Popup } from "components/ui";
import cn from "classnames";

import mainTheme, { isHyundaiTheme } from "components/ui/switch-themes";
import { authLogoGenesis } from "components/ui/helpersComponents";
import styles from "./layout.module.scss";

import Banner from "../baner/banner";

const Layout: FC = ({ children }) => {
  const renderLeftCol = useMemo(
    () => (
      <div className={styles.leftCol}>
        <Banner />
      </div>
    ),
    []
  );

  const cnLayout = cn(styles.rightCol, {
    [styles[mainTheme]]: mainTheme
  });

  return (
    <div className={styles.layout}>
      {renderLeftCol}
      <div className={cnLayout}>
        {authLogoGenesis}
        <div className={styles.content}>{children}</div>
        <div className={styles.bottom}>
          <Link theme="gray" size="normal" target="_blank" href="mailto:hid@hyundai.ru">
            Служба поддержки
          </Link>
          <div className={styles.bottomRow}>
            <Link theme="gray" size="normal" target="_blank" href="https://www.hyundai.ru/Legal">
              Правовые документы
            </Link>
            <Link theme="gray" size="normal" target="_blank" href="/doc">
              Инструкция пользователя
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
