import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AUTH_ROUTES_URL } from "constant/routers";
import { forgottenLoginAction, forgottenStep, serverError } from "store/auth/actions";
import { isValidEmail } from "helpers/validate";
import { formatPhoneNumber } from "helpers/format-phone-number";
import { StoreTypes } from "store/types";
import Tabs from "components/ui/tabs";
import { handlerPaste } from "helpers/handlePastePhone";
import { useFormik } from "formik";
import * as yup from "yup";
import { getAuthServerError } from "store/auth/selectors/getAuthErrorSelector";
import { Button, Input, Text, Wrapper, Link } from "components/ui";
import history from "services/history";
import {
  alignText,
  isHyundaiTheme,
  labelEmail,
  labelPhone,
  sizeTitleAuth,
  titleCompanyTheme
} from "components/ui/switch-themes";
import { authLogo } from "components/ui/helpersComponents";
import { Hint } from "../../../ui/hint";

const ForgottenTel: FC<ReduxProps> = ({ errorServer, login: onSubmit, warning }) => {
  const [inputTypes, setInputTypes] = useState<string>("phone");

  const validationSchema = yup.object().shape({
    isPhone: yup.string(),
    phone: yup
      .string()
      .transform(value => value.replace(/[^\d]/g, ""))
      .min(11, "Номер телефона должен состоять из 11 символов")
      .when("isPhone", {
        is: "phone",
        then: yup.string().required("Необходимо заполнить данные"),
        otherwise: yup.string()
      }),
    email: yup
      .string()
      .min(1)
      .matches(isValidEmail, "Недопустимый формат email")
      .when("isPhone", {
        is: "email",
        then: yup.string().required("Необходимо заполнить данные"),
        otherwise: yup.string()
      })
  });

  const formik = useFormik({
    initialValues: {
      isPhone: inputTypes,
      phone: "",
      email: ""
    },
    validationSchema,
    onSubmit: values => {
      if (inputTypes === "phone") {
        const data = formatPhoneNumber(String(values.phone));
        warning(null);
        onSubmit({ username: data, phone: data, type: inputTypes });
      } else if (inputTypes === "email") {
        const data = String(values.email);
        warning(null);
        onSubmit({ username: data, email: data, type: inputTypes });
      }
    }
  });

  useEffect(() => {
    if (inputTypes === "phone") {
      formik.setFieldValue("isPhone", "phone");
      formik.setFieldTouched("email", false);
    } else if (inputTypes === "email") {
      formik.setFieldValue("isPhone", "email");
      formik.setFieldTouched("phone", false);
    }
  }, [inputTypes]);

  useEffect(() => {
    if (errorServer) {
      formik.setFieldError(inputTypes, errorServer);
    }
  }, [errorServer]);

  const inputsBlur = (item: string, value: string) => {
    formik.setFieldValue(item, value.trim());
    formik.setTouched({ [item]: true });
  };

  const hintText = (
    <>
      <h6 style={{ margin: "0" }}>При неудачной попытке восстановления пароля пройти следующие шаги:</h6>
      <ol style={{ padding: "0 20px", marginBottom: "0" }}>
        <li>Ввести свой зарегистрированный email</li>
        <li>Нажать на кнопку &quot;Продолжить&quot;</li>
        <li>Нажать на ссылку &quot;Забыли пароль?&quot;</li>
        <li>Ввести email или телефон, который вы используете для своей учетной записи</li>
        <li>Нажать на кнопку &quot;Продолжить&quot;</li>
      </ol>
    </>
  );

  return (
    <div className="text-center">
      <form onSubmit={formik.handleSubmit}>
        {authLogo}
        <Wrapper pb={48}>
          <Text align={alignText} uppercase={!isHyundaiTheme} size={sizeTitleAuth} theme={titleCompanyTheme}>
            Восстановление пароля
          </Text>
        </Wrapper>
        <Wrapper pb={24}>
          <Text size="main" align="left" theme={titleCompanyTheme}>
            Введите адрес электронной почты или телефон, который вы используете для своей учетной записи
            <Hint isHyundaiTheme={isHyundaiTheme} text={hintText} />
          </Text>
        </Wrapper>
        <Wrapper pb={32}>
          <Tabs inputTypes={setInputTypes} />
        </Wrapper>
        <Wrapper pb={42}>
          {inputTypes === "email" ? (
            <Input
              name="email"
              placeholder="Введите Ваш email"
              theme="gray"
              onChange={formik.handleChange}
              value={formik.values.email}
              invalid={Boolean(formik.errors.email && formik.touched.email)}
              errorMessages={formik.errors.email && formik.touched.email ? formik.errors.email : null}
              onBlur={() => inputsBlur("email", formik.values.email)}
            />
          ) : (
            <Input
              type="tel"
              name="phone"
              placeholder="Введите Ваш номер телефона"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              errorMessages={formik.errors.phone && formik.touched.phone ? formik.errors.phone : null}
              invalid={Boolean(formik.errors.phone && formik.touched.phone)}
              onPaste={(elem: React.ClipboardEvent<HTMLInputElement>) => handlerPaste(elem, formik.setFieldValue)}
            />
          )}
        </Wrapper>
        <Wrapper pb={48}>
          <Button fullWidth type="submit">
            Продолжить
          </Button>
        </Wrapper>
        <Wrapper pb={24}>
          <Link href="/" size="big" isButton onClick={() => history.push(AUTH_ROUTES_URL.REGISTER)}>
            Регистрация
          </Link>
        </Wrapper>
        <Wrapper>
          <Link href="/" size="big" isButton onClick={() => history.push(AUTH_ROUTES_URL.SIGN_IN)}>
            Вспомнили пароль? Войти
          </Link>
        </Wrapper>
      </form>
    </div>
  );
};

const mapStateToProps = (store: StoreTypes) => ({
  errorServer: getAuthServerError(store)
});
const mapDispatchToProps = {
  onStep: forgottenStep,
  login: forgottenLoginAction,
  warning: serverError
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ForgottenTel);
