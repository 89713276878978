import { TYPES_USER } from "store/users/constants";
import {
  UserType,
  UploadAvatarTypes,
  UploadAvatarPayload,
  CreateUserPayload,
  AttachTeamBodyType,
  ChangePasswordPayloadType,
  UpdateUserPayloadType,
  UserTeamAttachType,
  ChangeUserPhoneType,
  UserNewEmailType,
  SendUserEmailType,
  SendCodeType,
  SendCodeResponseType,
  DeleteUserPayload,
  GetBanDeletionParams,
  Services,
  BanDeletionListType
} from "./types";

export const usersLoad = () => ({
  type: TYPES_USER.USERS_LOAD
});
export const usersLoadStop = () => ({
  type: TYPES_USER.USERS_LOAD_STOP
});
export const usersRequest = () => ({
  type: TYPES_USER.USERS_REQUEST
});
export const usersSuccess = (payload: Partial<UserType>) => ({
  type: TYPES_USER.USERS_SUCCESS,
  payload
});

export const usersError = (error: { [key: string]: string }) => ({
  type: TYPES_USER.USERS_ERROR,
  payload: error
});

export const usersStatus = (status: { [key: string]: string } | null) => ({
  type: TYPES_USER.USERS_STATUS,
  payload: status
});

export const uploadAvatarRequest = (body: UploadAvatarTypes) => ({
  type: TYPES_USER.UPLOAD_AVATAR_REQUEST,
  payload: body
});

export const uploadAvatarSuccessUserSet = (payload: UploadAvatarPayload) => ({
  type: TYPES_USER.UPLOAD_AVATAR_SUCCESS_USER_SET,
  payload
});

export const uploadAvatarSuccessEmployeeSet = (payload: UploadAvatarPayload) => ({
  type: TYPES_USER.UPLOAD_AVATAR_SUCCESS_EMPLOYEE_SET,
  payload
});

export const uploadAvatarSuccessNotSet = () => ({
  type: TYPES_USER.UPLOAD_AVATAR_SUCCESS_NOT_SET
});

export const updateUserRequest = (payload: UpdateUserPayloadType) => ({
  type: TYPES_USER.UPDATE_USER_REQUEST,
  payload
});

export const updateUserSuccess = (payload: Partial<UserType>) => ({
  type: TYPES_USER.UPDATE_USER_SUCCESS,
  payload
});

export const deleteAccountRequest = (payload: DeleteUserPayload) => ({
  type: TYPES_USER.DELETE_ACCOUNT_REQUEST,
  payload
});

export const deleteAccountSuccess = () => ({
  type: TYPES_USER.DELETE_ACCOUNT_SUCCESS
});

export const deleteAccountCheckRequest = () => ({
  type: TYPES_USER.DELETE_ACCOUNT_CHECK_REQUEST
});

export const deleteAccountCheckSuccess = () => ({
  type: TYPES_USER.DELETE_ACCOUNT_CHECK_SUCCESS
});

export const deleteAccountCheckError = () => ({
  type: TYPES_USER.DELETE_ACCOUNT_CHECK_ERROR
});

export const restoreAccountRequest = (id: string | number) => ({
  type: TYPES_USER.RESTORE_ACCOUNT_REQUEST,
  payload: id
});

export const restoreAccountSuccess = () => ({
  type: TYPES_USER.RESTORE_ACCOUNT_SUCCESS
});

export const authResetAll = () => ({
  type: TYPES_USER.AUTH_RESET_ALL
});

export const changePasswordRequest = (payload: ChangePasswordPayloadType) => ({
  type: TYPES_USER.CHANGE_PASSWORD_REQUEST,
  payload
});

export const changePasswordSuccess = () => ({
  type: TYPES_USER.CHANGE_PASSWORD_SUCCESS
});

export const sendCodeSuccess = (payload: SendCodeResponseType) => ({
  type: TYPES_USER.SEND_CODE_SUCCESS,
  payload
});

export const sendCodeRequest = (payload: SendCodeType) => ({
  type: TYPES_USER.USER_SEND_CODE,
  payload
});

export const updateUserEmailRequest = (payload: UserNewEmailType) => ({
  type: TYPES_USER.UPDATE_USER_EMAIL_REQUEST,
  payload
});

export const createUserRequest = (payload: CreateUserPayload) => ({
  type: TYPES_USER.CREATE_USER_REQUEST,
  payload
});

export const attachTeamRequest = (body: AttachTeamBodyType) => ({
  type: TYPES_USER.ATTACH_TEAM_REQUEST,
  payload: body
});

export const detachTeamRequest = (body: UserTeamAttachType) => ({
  type: TYPES_USER.DETACH_TEAM_REQUEST,
  payload: body
});

export const restoreUser = (id: string | number) => ({
  type: TYPES_USER.USERS_RESTORE,
  payload: id
});

export const updateUserPhoneRequest = (body: ChangeUserPhoneType) => ({
  type: TYPES_USER.UPDATE_USER_PHONE_REQUEST,
  payload: body
});

export const sendEmailCodeRequest = (body: SendUserEmailType) => ({
  type: TYPES_USER.USER_SEND_EMAIL_CODE,
  payload: body
});

export const deleteMarketingConsentRequest = () => ({
  type: TYPES_USER.USER_DELETE_MARKET_CONS
});

export const acceptMarketingConsentRequest = () => ({
  type: TYPES_USER.USER_ACCEPT_MARKET_CONS
});

export const acceptMarketingConsentForServiceRequest = () => ({
  type: TYPES_USER.USER_ACCEPT_MARKET_CONS_FOR_SERVICE
});

export const switchUserConcent = () => ({
  type: TYPES_USER.SWITCH_USER_CONCENT
});

export const sendEmailVerifyRequest = () => ({
  type: TYPES_USER.USER_SEND_EMAIL_VERIFY_REQUEST
});

export const sendEmailVerifySuccess = () => ({
  type: TYPES_USER.USER_SEND_EMAIL_VERIFY_SUCCESS
});

export const sendEmailVerifyError = (error: { [key: string]: string }) => ({
  type: TYPES_USER.USER_SEND_EMAIL_VERIFY_SUCCESS,
  payload: error
});

export const banDeletionClientsRequest = (params: GetBanDeletionParams) => ({
  type: TYPES_USER.BAN_DELETION_CLIENTS_REQUEST,
  payload: params
});

export const banDeletionClientsSuccess = (payload: BanDeletionListType) => ({
  type: TYPES_USER.BAN_DELETION_CLIENTS_SUCCESS,
  payload
});

export const getUsersServicesRequest = () => ({
  type: TYPES_USER.GET_USERS_SERVICES_REQUEST
});

export const getUsersServicesSuccess = (services: Array<Services>) => ({
  type: TYPES_USER.GET_USERS_SERVICES_SUCCESS,
  payload: services
});
