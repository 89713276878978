import { call, put, select, takeLatest, delay } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { Authentication, AuthPasswordTypes } from "services/api/authentication ";

import { User } from "services/api/user";
import { AUTH_API_STATUS, ERROR_MESSAGE, STEP_FORGOTTEN, STEP_REGISTER, STEP_SIGN_IN } from "constant/step-auth";
import { ADMIN_TOKEN, OPEN_AUTH_STATUS, REFRESH_TOKEN, SESSION_TOKEN, TOKEN } from "constant/api";
import { Registration } from "services/api/register";
import { Forgotten } from "services/api/forgotten";
import { AUTH_ROUTES_URL } from "constant/routers";
import queryString from "query-string";
import { notificationErrors } from "components/ui/notification/notification-errors";
import { getAuthData } from "store/auth/selectors/getAuthDataSelector";
import { getAuthSelector } from "store/auth/selectors/getAuthSelector";
import { getSSO } from "store/auth/selectors/getSSOSelector";
import storageCookie from "services/storageCookie";
import { usersSuccess } from "store/users/actions";
import sessionData from "services/sessionStorage";
import history from "../../services/history";
import storage from "../../services/storage";
import {
  authDataUpdate,
  authError,
  authLoad,
  authLoadStop,
  authUpdateStepSignIn,
  forgottenStep,
  updateStepRegister,
  openAuthUpdateStatus,
  openAuthUpdateUrl,
  serverError,
  authUpdateTtl,
  forgottenLoginAction,
  forgottenCodeAction,
  forgottenCheckingLinkAction,
  forgottenNewPasswordAction,
  registerSendSmsPhone,
  registerRequestCode,
  registerFormUser,
  authAddedPhone,
  authAddedPhoneCode,
  authRequestLogin,
  authRequestPassword,
  authRequestCode,
  openAuthRequest,
  verificationEmail,
  authResetAll,
  authReset,
  getLinksSSO,
  authSSO,
  sendVerifyEmail,
  setVerify,
  authStatus,
  setLinkSSO,
  logOutUser,
  openAuthRequestIframe,
  isTokenWritten,
  authRegisterSSO,
  updateDataUser,
  isPhoneRequiredSSO,
  checkDataUserRequest,
  signInOnUserRequest,
  signOutOnUserRequest,
  signInOnUserSuccess,
  getUserSessionToken,
  preAuthRequest,
  preAuthSuccess,
  preAuthSetLink,
  regSSO,
  authEmailContainsCheck
} from "./actions";
import { TYPES } from "./constants";

function* forgottenLogin(action: ReturnType<typeof forgottenLoginAction>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    const { type } = payload;
    const response = yield call(Forgotten.restore, payload);
    yield put(authDataUpdate({ ...payload }));
    if (type === "email") {
      yield put(forgottenStep(STEP_FORGOTTEN.EMAIL));
    } else {
      yield put(authUpdateTtl(response.sms_code_ttl));
      yield put(forgottenStep(STEP_FORGOTTEN.CODE));
    }
  } catch (e) {
    if (e.status === 404) {
      yield put(serverError("Пользователь с таким логином не зарегистрирован"));
    } else if (e.status === 400) {
      yield put(serverError("Ссылка на восстановление уже выслана вам на почту"));
    } else if (e.status === 422) {
      yield put(serverError("Пользователь с таким логином не зарегистрирован"));
    }
  } finally {
    yield put(authLoadStop());
  }
}

function* forgottenCode(action: ReturnType<typeof forgottenCodeAction>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    const { phone, username } = yield select(getAuthData);
    const response = yield call(Forgotten.code, { phone, username, code: payload });
    if (response.status === AUTH_API_STATUS.CODE_VERIFIED) {
      yield put(authDataUpdate({ code: payload }));
      yield put(forgottenStep(STEP_FORGOTTEN.NEW_PASSWORD));
    } else if (response.status === AUTH_API_STATUS.INVALID_CODE) {
      yield put(authError({ forgottenCode: response.error }));
    } else if (response.status === AUTH_API_STATUS.CODE_EXPIRED) {
      yield put(authError({ forgottenCode: response.error }));
    }
  } finally {
    yield put(authLoadStop());
  }
}

function* forgottenCheckedLink(action: ReturnType<typeof forgottenCheckingLinkAction>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    const response = yield call(Forgotten.checked, { ...payload });
    if (response.status === AUTH_API_STATUS.TOKEN_VERIFIED) {
      const { email } = payload;
      yield put(authDataUpdate({ email, type: "email" }));
      history.push(AUTH_ROUTES_URL.FORGOTTEN);
      yield put(forgottenStep(STEP_FORGOTTEN.NEW_PASSWORD));
    }
  } catch (e) {
    if (e.status === 400) {
      yield put(serverError(e.data.error));
    }
  } finally {
    yield put(authLoadStop());
  }
}

function* forgottenNewPassword(action: ReturnType<typeof forgottenNewPasswordAction>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    const { code, type, email, phone } = yield select(getAuthData);
    const token = storageCookie.get(TOKEN);
    const { password } = payload;
    if (type === "phone") {
      yield call(Forgotten.reset, { code, phone, password, type });
    } else {
      yield call(Forgotten.reset, { email, token, password, type });
    }
    yield put(forgottenStep(STEP_FORGOTTEN.SUCCESS));
  } catch (e) {
    if (e.data.error === AUTH_API_STATUS.TOKEN_INVALID) {
      yield put(serverError(e.data.message));
    } else if (e.status === 400) {
      yield put(serverError("Новый пароль должен отличаться от старого"));
    } else {
      yield put(serverError(e.data.error));
    }
  } finally {
    yield put(authLoadStop());
  }
}

const addTokenAndRedirect = (token: string, refresh: string, redirectUrl?: string | null): void => {
  storageCookie.set(TOKEN, token);
  storageCookie.set(REFRESH_TOKEN, refresh);
  if (redirectUrl) {
    history.push(redirectUrl);
  } else if (redirectUrl !== null) {
    history.push("/");
  }
};

/// todo пересмотреть нужен редирект для стороних сервисов
export const redirectUriService = (redirectUri?: string): void => {
  if (redirectUri) {
    history.push(redirectUri);
  } else {
    history.push("/");
  }
};

function* register(action: ReturnType<typeof registerSendSmsPhone>): SagaIterator {
  yield put(authLoad());
  const { payload } = action;
  const { registerViaSSO, isPhoneRequired } = yield select(getSSO);
  try {
    const response = yield call(Registration.phone, payload);
    if (response.status === AUTH_API_STATUS.PHONE_EXISTS && isPhoneRequired) {
      yield put(serverError("Данный номер уже зарегистрирован"));
    } else if (response.status === AUTH_API_STATUS.CODE_SENT) {
      yield put(authUpdateTtl(response.sms_code_ttl));
      yield put(updateStepRegister(STEP_REGISTER.CODE));
    } else if (response.status === AUTH_API_STATUS.CODE_ALREADY_SENT) {
      yield put(updateStepRegister(STEP_REGISTER.CODE));
    }
    yield put(authDataUpdate({ ...payload }));
  } catch (e) {
    if ([422].includes(e.status) && e.data.status === AUTH_API_STATUS.USER_EXISTS && registerViaSSO) {
      /// Действия при регистрации SSO
      const responseSSO = yield call(Registration.phone, { ...payload, reason: "update_profile" });
      yield put(authDataUpdate({ ...payload }));
      yield put(authUpdateTtl(responseSSO.sms_code_ttl));
      yield put(updateStepRegister(STEP_REGISTER.CODE));
    } else if ([422].includes(e.status) && e.data.status === AUTH_API_STATUS.USER_EXISTS && !registerViaSSO) {
      yield put(updateStepRegister(STEP_REGISTER.EXISTS));
    }
    if (e.status === 422) {
      yield put(authError({ registerNumber: "Некорректные данные" }));
    } else if ([401].includes(e.status) && e.data.status === AUTH_API_STATUS.USER_IS_BLOCKED) {
      history.push(AUTH_ROUTES_URL.SIGN_IN);
      notificationErrors({ message: e.data.error });
    }
  } finally {
    yield put(authLoadStop());
  }
}

function* registerCode(action: ReturnType<typeof registerRequestCode>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    const { phone, username } = yield select(store => store.auth.authData);
    const response = yield call(Registration.code, { phone, username, code: payload });
    const { isPhoneRequired } = yield select(getSSO);
    if (response.status === AUTH_API_STATUS.CODE_VERIFIED && isPhoneRequired) {
      // todo возможно когда нет телефона?
      yield put(authAddedPhoneCode(payload));
    } else if (response.status === AUTH_API_STATUS.CODE_VERIFIED) {
      yield put(authDataUpdate({ code: payload }));
      yield put(authStatus(response.message));
      yield put(updateStepRegister(STEP_REGISTER.REGISTER));
    } else if ([AUTH_API_STATUS.INVALID_CODE, AUTH_API_STATUS.CODE_EXPIRED].includes(response.status)) {
      yield put(authError({ code: response.error }));
    }
    // else if ([AUTH_API_STATUS.CODE_EXPIRED].includes(response.status)) {
    //   yield put(authError({ сode: response.error }));
    // }
  } catch (e) {
    console.log(e);
  } finally {
    yield put(authLoadStop());
  }
}

function* registerForm(action: ReturnType<typeof registerFormUser>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    const { code, phone, type } = yield select(getAuthData);
    const { registerViaSSO } = yield select(getSSO);
    const { openAuthUrl: redirectUrl } = yield select(getAuthSelector);
    const data = { code, phone, type };
    const response = yield call(Registration.form, { ...data, ...payload });
    if (response.status === AUTH_API_STATUS.EMAIL_EXISTS && registerViaSSO) {
      /// todo rename || delete ?
      yield put(authAddedPhoneCode(code));
    } else if (response.status === AUTH_API_STATUS.EMAIL_EXISTS) {
      yield put(serverError(ERROR_MESSAGE.INVALID_EMAIL));
    } else if (response?.message) {
      yield put(updateStepRegister(STEP_REGISTER.SUCCESS));
      notificationErrors({ message: response.message, type: "success", title: "Подтверждение email" });
    } else {
      addTokenAndRedirect(response.access_token, response.refresh_token, redirectUrl);
      yield put(isTokenWritten(true));
    }
  } catch (e) {
    if (e.status === 422) {
      yield put(serverError(ERROR_MESSAGE.INVALID_EMAIL));
    } else if (e.data.status === AUTH_API_STATUS.INVALID_CODE) {
      yield put(updateStepRegister(STEP_REGISTER.INVALID));
    } else {
      notificationErrors({});
    }
  } finally {
    yield put(authLoadStop());
  }
}

function* registerVerifyEmail(action: ReturnType<typeof verificationEmail>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    const response = yield call(Authentication.verify, payload);
    if (response.status === AUTH_API_STATUS.EMAIL_VERIFIED) {
      history.push("/auth/signIn");
      notificationErrors({ title: "Подтверждение email", message: "Ваш email подтвержден", type: "success" });
    }
  } catch (e) {
    if (e.status === 403) {
      yield put(serverError("Вы прошли по недействительной ссылке"));
      history.push("/verify");
    } else {
      notificationErrors({});
      history.push(AUTH_ROUTES_URL.SIGN_IN);
    }
  } finally {
    yield put(authLoadStop());
  }
}

function* addedPhone(action: ReturnType<typeof authAddedPhone>): SagaIterator {
  yield put(authLoad());
  const { payload } = action;
  try {
    const response = yield call(Authentication.phone, payload);
    yield put(authUpdateTtl(response.sms_code_ttl));
    if (response.status === AUTH_API_STATUS.PHONE_EXISTS) {
      yield put(serverError("Пользователь с таким номером телефона уже зарегистрирован"));
    } else if (response.status === AUTH_API_STATUS.CODE_SENT || response.status === AUTH_API_STATUS.CODE_ALREADY_SENT) {
      yield put(authDataUpdate({ ...payload }));
      yield put(authUpdateStepSignIn(STEP_SIGN_IN.ADDED_PHONE_CODE));
    }
  } catch (e) {
    if ([422].includes(e.status) && e.data.status === AUTH_API_STATUS.USER_EXISTS) {
      yield put(serverError(e.data.message));
    }
  } finally {
    yield put(authLoadStop());
  }
}

function* updatePhone(action: ReturnType<typeof authAddedPhoneCode>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    const authData = yield select(getAuthData);
    const { type, email, phone, code, name, lastname } = authData;
    const { isPhoneRequired, registerViaSSO } = yield select(getSSO);
    const { openAuthUrl: redirectUrl } = yield select(getAuthSelector);
    let response;
    if (isPhoneRequired) {
      response = yield call(Authentication.addedPhone, {
        email,
        type,
        phone,
        code: payload
      });
    } else if (registerViaSSO) {
      response = yield call(Authentication.addedPhone, { email, type, phone, code, name, lastname });
      yield put(authRegisterSSO(false));
    } else {
      response = yield call(Authentication.addedPhone, { email, type, phone, code: payload });
    }
    if (response.status === AUTH_API_STATUS.INVALID_CODE) {
      yield put(authError({ AddedPhoneCode: "Вы ввели неверный код" }));
    } else {
      yield put(usersSuccess(response));
      yield put(isTokenWritten(true));
      redirectUriService(redirectUrl);
    }
  } catch (e) {
    if (e.data.status === AUTH_API_STATUS.INVALID_CODE) {
      yield put(authError({ AddedPhoneCode: "Вы ввели неверный код" }));
    } else if ([401].includes(e.status) && [AUTH_API_STATUS.EMAIL_VERIFICATION_REQUIRED].includes(e.data.status)) {
      notificationErrors({ title: "Подтверждение email", message: e.data.error, type: "success" });
      history.push(AUTH_ROUTES_URL.SIGN_IN);
    } else if ([401].includes(e.status) && [AUTH_API_STATUS.EMAIL_EXISTS].includes(e.data.status)) {
      yield put(serverError("Данный email уже зарегистрирован. Введите новый или восстановите текущий аккаунт"));
    } else if ([400].includes(e.status) && [AUTH_API_STATUS.PHONE_EXISTS].includes(e.data.status)) {
      notificationErrors({ message: e.data.message });
    } else {
      notificationErrors({});
      history.push(AUTH_ROUTES_URL.SIGN_IN);
    }
  } finally {
    yield put(authLoadStop());
  }
}

function* signInLogin(action: ReturnType<typeof authRequestLogin>): SagaIterator {
  /// запуск только при вводе телефон + код
  yield put(authLoad());
  const { payload } = action;
  try {
    const response = yield call(Authentication.authn, payload);
    yield put(authUpdateTtl(response.sms_code_ttl));
    if (response.status === AUTH_API_STATUS.SMS_VERIFICATION_REQUIRED) {
      yield put(authUpdateStepSignIn(STEP_SIGN_IN.TELEPHONE));
    } else if (response.status === AUTH_API_STATUS.CODE_ALREADY_SENT) {
      yield put(authUpdateStepSignIn(STEP_SIGN_IN.TELEPHONE));
    }
    yield put(authDataUpdate({ ...payload, phone: payload.username, captchaToken: payload.token }));
  } catch (e) {
    if (e.status === 422) {
      yield put(serverError("Некорректные данные"));
    } else if (e.status === 404 && e.data.status === AUTH_API_STATUS.USER_NOT_FOUND) {
      history.push(`/auth/register?phone=${payload.username}`);
      yield put(authStatus("Данный номер не найден"));
    } else {
      yield put(serverError(e.data.error));
    }
  } finally {
    yield put(authLoadStop());
  }
}

function* emailContainsCheck(action: ReturnType<typeof authEmailContainsCheck>): SagaIterator {
  const { payload } = action;
  try {
    const response = yield call(Authentication.emailCheck, payload);

    if (response.isRegistered) {
      yield put(authUpdateStepSignIn(STEP_SIGN_IN.PASSWORD));
    } else {
      history.push("/auth/register");
    }
  } catch (e) {
    yield put(serverError(e.data.error));
  }
}

function* checkDataUser(): SagaIterator {
  try {
    const { openAuthUrl: redirectUrl, guestAuth, redirectLink } = yield select(getAuthSelector);
    const userResponse = yield call(User.getUserInfo);

    if (userResponse?.update_required) {
      yield put(usersSuccess(userResponse));
      const { name, lastname, email, phone } = userResponse;
      yield put(
        authDataUpdate({
          name,
          lastname,
          email,
          phone
        })
      );
      /*
       * поле update_required === true в случае если поле
       *  name || lastname || phone === null
       */
      if ((!name || !lastname) && !phone) {
        yield put(authRegisterSSO(true));
      } else if (!name || !lastname) {
        yield put(updateDataUser(true));
      } else {
        yield put(isPhoneRequiredSSO(true));
      }
      history.push(AUTH_ROUTES_URL.REGISTER);
    } else {
      yield put(isTokenWritten(true));
      yield put(usersSuccess(userResponse));
      if (redirectLink) {
        history.push(redirectLink);
      } else if (guestAuth.id && !guestAuth.isAuth) {
        history.push(`/admin/users/added/${guestAuth.id}`);
      } else {
        redirectUriService(redirectUrl);
      }
    }
  } catch (e) {
    notificationErrors({});
  }
}

/// todo пересмотреть редирект
function* signInPassword(action: ReturnType<typeof authRequestPassword>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    const { username, type } = yield select(getAuthData);
    const response = yield call(Authentication.password, {
      username,
      type,
      password: payload.password,
      token: payload.token
    });
    storageCookie.set(TOKEN, response.access_token);
    storageCookie.set(REFRESH_TOKEN, response.refresh_token);
    yield put(checkDataUserRequest());
  } catch (e) {
    if (e.data.status === AUTH_API_STATUS.AUTHENTICATION_FAILED) {
      yield put(serverError("Учетные данные неверны"));
    } else if (
      [401].includes(e.status) &&
      [AUTH_API_STATUS.EMAIL_VERIFICATION_REQUIRED, AUTH_API_STATUS.USER_DELETED].includes(e.data.status)
    ) {
      yield put(serverError(e.data.error));
      yield put(setVerify(false));
    } else if ([404].includes(e.status) && e.data.status === AUTH_API_STATUS.USER_NOT_FOUND) {
      yield put(serverError("Введенный email не найден. Проверьте корректность или пройдите регистрацию"));
    } else if ([AUTH_API_STATUS.USER_IS_BLOCKED, AUTH_API_STATUS.TOO_MANY_ATTEMPTS].includes(e.data.status)) {
      yield put(serverError(e.data.error));
    }
  } finally {
    yield put(authLoadStop());
  }
}

function* signInCode(action: ReturnType<typeof authRequestCode>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    const { phone } = yield select(getAuthData);
    const response = yield call(Authentication.code, { username: phone, code: payload, type: "phone" });
    storageCookie.set(TOKEN, response.access_token);
    storageCookie.set(REFRESH_TOKEN, response.refresh_token);
    yield put(checkDataUserRequest());
  } catch (e) {
    if (e.status === 400 || e.status === 422) {
      yield put(authError({ code: "Вы ввели неверный код" }));
    }
  } finally {
    yield put(authLoadStop());
  }
}

function* signInOpenAuth(action: ReturnType<typeof openAuthRequest>): SagaIterator {
  const { payload } = action;
  yield put(authLoad());
  yield put(openAuthUpdateUrl(payload.redirect_uri));
  yield delay(2000);
  try {
    const response = yield call(Authentication.openAuth, payload);
    const url = new URL(payload.redirect_uri);
    url.searchParams.set("state", response.state);
    url.searchParams.set("code", response.code);
    window.location.href = url.href;
    yield put(openAuthUpdateStatus(OPEN_AUTH_STATUS.REDIRECT));
  } catch (e) {
    if (e.data.error === AUTH_API_STATUS.INVALID_CLIENT) {
      /// Битая ссылка или токен протух
      notificationErrors({ message: ERROR_MESSAGE.INVALID_LINK });
      yield put(authResetAll());
      history.push("/auth");
    } else if (e.status === 403) {
      /// Отстуствие прав доступа к сервису, чтобы ему
      // повторно не логинится редиректим его на главную
      history.push("./home");
    } else if ([401].includes(e.status) && e.config.url.includes("/oauth/authorize/token")) {
      /// Не авторизованный пользователь пытается
      // перейти в целевой сервис
      /// Тут в отличии от интерсепторах мы не чистим
      // редакс, для редиректа при авторизации
      yield put(openAuthUpdateStatus(OPEN_AUTH_STATUS.AUTHORIZE));
      const url = queryString.stringifyUrl({ url: AUTH_ROUTES_URL.OPEN_AUTH, query: payload });
      yield put(openAuthUpdateUrl(url));
      yield put(authReset());
      history.push("/auth");
    } else {
      yield put(authResetAll());
      history.push("/auth");
    }
  } finally {
    yield put(authLoadStop());
  }
}

function* signInOpenAuthIframe(action: ReturnType<typeof openAuthRequestIframe>): SagaIterator {
  const { payload } = action;
  yield put(authLoad());
  yield put(openAuthUpdateUrl(payload.redirect_uri));
  const { redirect_uri } = payload;
  const parent = window.opener || window.top;
  yield delay(2000);
  try {
    const response = yield call(Authentication.openAuth, payload);
    const data = {
      code: response.code,
      state: response.state
    };
    parent.postMessage(data, redirect_uri);
    window.close();
  } catch (e) {
    if (e.data.error === AUTH_API_STATUS.INVALID_CLIENT) {
      /// Битая ссылка или токен протух
      parent.postMessage(e.data, redirect_uri);
      notificationErrors({ message: ERROR_MESSAGE.INVALID_LINK });
      yield put(authResetAll());
      yield delay(2000);
      window.close();
    } else if (e.status === 403) {
      /// Отстуствие прав доступа к сервису, чтобы ему
      // повторно не логинится редиректим его на главную
      parent.postMessage(e.data, redirect_uri);
      yield put(authResetAll());
      yield delay(2000);
      window.close();
    } else if ([401].includes(e.status) && e.config.url.includes("/oauth/authorize/token")) {
      /// Не авторизованный пользователь пытается
      // перейти в целевой сервис
      /// Тут в отличии от интерсепторах мы не чистим
      // редакс, для редиректа при авторизации
      const data = { param: "iframe", ...payload };
      yield put(openAuthUpdateStatus(OPEN_AUTH_STATUS.AUTHORIZE));
      const url = queryString.stringifyUrl({ url: AUTH_ROUTES_URL.OPEN_AUTH, query: data });
      yield put(openAuthUpdateUrl(url));
      yield put(authReset());
      history.push("/auth");
    } else {
      const data = { error: "authentication_failed" };
      parent.postMessage(data, redirect_uri);
      yield delay(2000);
      window.close();
    }
  } finally {
    yield put(authLoadStop());
  }
}

function* signInPreAuth(action: ReturnType<typeof preAuthRequest>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    const response = yield call(Authentication.token, { token: payload.token });
    if (response.link && response.access_token) {
      storageCookie.set(TOKEN, response.access_token);
      storageCookie.set(REFRESH_TOKEN, response.refresh_token);
      yield put(preAuthSuccess());
      yield put(preAuthSetLink(response.link));
      yield put(checkDataUserRequest());
    } else if (response.link && !response.access_token) {
      window.open(response.link, "_self");
    } else if (response.access_token && !response.link) {
      storageCookie.set(TOKEN, response.access_token);
      storageCookie.set(REFRESH_TOKEN, response.refresh_token);
      yield put(preAuthSuccess());
      yield put(checkDataUserRequest());
    }
    // storageCookie.set(TOKEN, response.access_token);
    // storageCookie.set(REFRESH_TOKEN, response.refresh_token);
    // yield put(checkDataUserRequest());
  } catch (e) {
    if (e.status === 400 || e.status === 422) {
      yield put(authError({ code: "Неверный токен" }));
    }
    notificationErrors({ title: "Не удалось авторизоваться по токену" });
    history.push("/auth");
  } finally {
    yield put(authLoadStop());
  }
}

function* getLinks(action: ReturnType<typeof getLinksSSO>): SagaIterator {
  yield put(authLoad());
  try {
    /// todo убрать кеширование
    // const response = yield call(Authentication.authSSO);
    const dataLinks = { date: new Date().toLocaleDateString(), links: ["vk", "yandex"] };
    storage.set("SSO", JSON.stringify(dataLinks));
    yield put(setLinkSSO(["vk", "yandex"]));
  } catch (e) {
    notificationErrors({});
  } finally {
    yield put(authLoadStop());
  }
}

function* authSocialNetwork(action: ReturnType<typeof authSSO>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    storageCookie.set(TOKEN, payload.access_token);
    storageCookie.set(REFRESH_TOKEN, payload.refresh_token);
    yield put(authRegisterSSO(true));
    yield put(checkDataUserRequest());
  } catch (e) {
    notificationErrors({});
  } finally {
    yield put(authLoadStop());
  }
}

function* regSocialNetwork(action: ReturnType<typeof regSSO>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    const response = yield call(Authentication.regSocialNetwork, { userId: payload });
    storageCookie.set(TOKEN, response.access_token);
    storageCookie.set(REFRESH_TOKEN, response.refresh_token);
    yield put(authRegisterSSO(true));
    yield put(checkDataUserRequest());
  } catch (e) {
    notificationErrors({});
  } finally {
    yield put(authLoadStop());
  }
}

function* sendEmail(action: ReturnType<typeof sendVerifyEmail>): SagaIterator {
  yield put(authLoad());
  const { email: userEmail } = yield select(getAuthData);
  try {
    if (userEmail) {
      const response = yield call(Authentication.verification, { email: userEmail });
      notificationErrors({ message: response.message, title: "Письмо отправлено", type: "success" });
    }
  } catch (e) {
    notificationErrors({ title: "Подтверждение email", message: e.data.error });
  } finally {
    yield put(authLoadStop());
  }
}

function* logOut(action: ReturnType<typeof logOutUser>): SagaIterator {
  yield put(authLoad());
  try {
    yield call(Authentication.logOut);
    yield put(authResetAll());
  } catch (e) {
    // notificationErrors({});
  } finally {
    yield put(authLoadStop());
  }
}

function* getSessionToken(action: ReturnType<typeof getUserSessionToken>): SagaIterator {
  yield put(authLoad());
  try {
    const response = yield call(Authentication.sessionToken);
    sessionData.set(SESSION_TOKEN, response.token);
  } catch (e) {
    notificationErrors({ title: "Ошибка при  получении токена сессии" });
  } finally {
    yield put(authLoadStop());
  }
}

function* signInOnUser(action: ReturnType<typeof signInOnUserRequest>): SagaIterator {
  yield put(authLoad());
  try {
    const { payload } = action;
    const response = yield call(User.getUserToken, payload);
    const adminToken = storageCookie.get(TOKEN);
    storageCookie.set(ADMIN_TOKEN, adminToken);
    storageCookie.set(TOKEN, response.access_token);
    yield put(signInOnUserSuccess(payload));
    yield put(checkDataUserRequest());
  } catch (e) {
    storageCookie.set(ADMIN_TOKEN, "");
    notificationErrors({ title: "Ошибка при получении гостевого токена" });
  } finally {
    yield put(authLoadStop());
  }
}

function* signOutOnUser(): SagaIterator {
  yield put(authLoad());
  try {
    const adminToken = storageCookie.get(ADMIN_TOKEN);
    storageCookie.set(TOKEN, adminToken);
    storageCookie.set(ADMIN_TOKEN, "");
    yield put(checkDataUserRequest());
  } catch (e) {
    yield put(serverError(e.data.error));
  } finally {
    yield put(authLoadStop());
  }
}

function* authSaga(): SagaIterator {
  yield takeLatest(TYPES.AUTH_REQUEST_LOGIN, signInLogin);
  yield takeLatest(TYPES.AUTH_REQUEST_PASSWORD, signInPassword);
  yield takeLatest(TYPES.AUTH_EMAIL_CONTAINS_CHECK, emailContainsCheck);
  yield takeLatest(TYPES.AUTH_REQUEST_CODE, signInCode);
  yield takeLatest(TYPES.PRE_AUTH_REQUEST, signInPreAuth);
  yield takeLatest(TYPES.REGISTER_SEND_SMS_PHONE, register);
  yield takeLatest(TYPES.REGISTER_REQUEST_CODE, registerCode);
  yield takeLatest(TYPES.REGISTER_FORM_USER, registerForm);
  yield takeLatest(TYPES.FORGOTTEN_LOGIN, forgottenLogin);
  yield takeLatest(TYPES.FORGOTTEN_CODE, forgottenCode);
  yield takeLatest(TYPES.FORGOTTEN_NEW_PASSWORD, forgottenNewPassword);
  yield takeLatest(TYPES.OPEN_AUTH_REQUEST, signInOpenAuth);
  yield takeLatest(TYPES.OPEN_AUTH_REQUEST_IFRAME, signInOpenAuthIframe);
  yield takeLatest(TYPES.AUTH_ADDED_PHONE, addedPhone);
  yield takeLatest(TYPES.AUTH_ADDED_PHONE_CODE, updatePhone);
  yield takeLatest(TYPES.FORGOTTEN_NEW_CHECKING_LINK, forgottenCheckedLink);
  yield takeLatest(TYPES.VERIFY_EMAIL, registerVerifyEmail);
  yield takeLatest(TYPES.GET_LINKS_SSO, getLinks);
  yield takeLatest(TYPES.AUTH_SSO, authSocialNetwork);
  yield takeLatest(TYPES.REG_SSO, regSocialNetwork);
  yield takeLatest(TYPES.SEND_VERIFY_EMAIL, sendEmail);
  yield takeLatest(TYPES.AUTH_LOGOUT, logOut);
  yield takeLatest(TYPES.CHECK_DATA_USER, checkDataUser);
  yield takeLatest(TYPES.USER_SESSION_TOKEN, getSessionToken);
  yield takeLatest(TYPES.AUTH_ON_USER_REQUEST, signInOnUser);
  yield takeLatest(TYPES.SIGN_OUT_ON_USER_REQUEST, signOutOnUser);
  // yield takeLatest(TYPES.REGISTER_SUCCESS, registerSuccess);
}

export { authSaga };
