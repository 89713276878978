import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, CustomSelect, Input, Text, Wrapper } from "components/ui";
import { Table } from "components/ui/table";
import { StoreTypes } from "store/types";
import { resetErrorAndStatus } from "store/actions";
import { buttonEditTheme, inputAdminTheme, paginationTheme } from "components/ui/switch-themes";
import Pagination from "../../../components/ui/pagination/pagination";
import styles from "./delete-personal-data-page.module.scss";
import Warning from "../../../components/ui/warning";
import { DeletedPersonalData } from "../../../store/deleted-personal-data/types";
import {
  deletePDOrdersRequest,
  getPersonalDataDeleteOrdersServicesRequest,
  restorePersonalDataRequest
} from "../../../store/delete-personal-data-orders/actions";
import { PersonalDataDeleteOrders } from "../../../store/delete-personal-data-orders/types";

const PersonalDataDeleteOrdersPage: FC<ReduxProps> = ({
  deletedPersonalData: { data: deletedPersonalData, last_page: lastPage },
  services,
  getPersonalDataDeleteOrders,
  restorePersonalData,
  isLoading,
  resetErrorsAndStatus
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userFilter, setUserFilter] = useState<string>("");
  const [actNumberFilter, setActNumberFilter] = useState<string>("");
  const [serviceFilter, setServiceFilter] = useState<string>("");
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  function changeUserFilterHandle(e: React.ChangeEvent<HTMLInputElement>) {
    setIsFilterChanged(true);
    setUserFilter(e.target.value);
    setActNumberFilter("");
  }

  function changeActNumberFilterHandle(e: any) {
    setIsFilterChanged(true);
    setActNumberFilter(e.target.value);
    setUserFilter("");
  }

  function changeServiceFilterHandle(e: React.ChangeEvent<HTMLSelectElement>) {
    setServiceFilter(e.target.value);
  }

  useEffect(() => {
    getPersonalDataDeleteOrders({
      pagination: { page: currentPage, count: rowsPerPage },
      filters: { service: serviceFilter, user: userFilter, actNumber: actNumberFilter },
      isFilterChanged
    });
    setIsFilterChanged(false);
  }, [rowsPerPage, currentPage, serviceFilter, userFilter, actNumberFilter]);

  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
    };
  }, []);

  const columns = [
    {
      title: "Дата удаления ПД",
      dataIndex: "personal_date_delete",
      render({ personal_date_delete }: DeletedPersonalData): string {
        return `${personal_date_delete || ""}`;
      }
    },
    {
      title: (
        <div>
          <CustomSelect
            options={services}
            name="service_id"
            theme="white"
            placeholder="Сервис"
            value={serviceFilter}
            onChange={changeServiceFilterHandle}
          />
        </div>
      ),
      dataIndex: "service_name",
      render({ service_name }: DeletedPersonalData): string {
        return `${service_name}`;
      }
    },
    {
      title: (
        <div>
          <Input
            name="user_id"
            type="text"
            theme={inputAdminTheme}
            placeholder="ID пользователя"
            value={userFilter}
            onChange={changeUserFilterHandle}
          />
        </div>
      ),
      dataIndex: "user_id",
      render({ user_id }: DeletedPersonalData): string {
        return `${user_id}`;
      }
    },
    {
      title: (
        <div>
          <Input
            name="act_number"
            type="text"
            theme={inputAdminTheme}
            placeholder="Номер реестра"
            value={actNumberFilter}
            onChange={changeActNumberFilterHandle}
          />
        </div>
      ),
      dataIndex: "act_number",
      render({ act_number }: DeletedPersonalData): string {
        return `${act_number}`;
      }
    },
    {
      title: "Cсылка на реестр",
      dataIndex: "diadocLink",
      render({ diadocLink }: DeletedPersonalData) {
        return (
          <a href={diadocLink || ""} target="_blank" rel="noreferrer">
            Перейти в реестр
          </a>
        );
      }
    },
    {
      title: "Восстановление",
      dataIndex: "action",
      width: 1,
      render({ service_id, user_id, restored, id }: PersonalDataDeleteOrders) {
        return (
          <Button
            disabled={restored}
            size="xs"
            theme={buttonEditTheme}
            onClick={() => restorePersonalData(user_id, service_id, id)}
          >
            {restored ? "Восстановлено" : "Восстановить"}
          </Button>
        );
      }
    }
  ];

  return (
    <>
      <div className={styles.header}>
        <Wrapper pb={20}>
          <Text size="h3" theme="black">
            Заявки на удаление персональных данных
          </Text>
        </Wrapper>
      </div>
      <div className={styles.wrapper}>
        <Wrapper pt={26}>
          <Table columns={columns} data={deletedPersonalData} />
        </Wrapper>
        {!isLoading && deletedPersonalData.length === 0 && (
          <Wrapper pt={16}>
            <Warning color="black">Ничего не найдено</Warning>
          </Wrapper>
        )}
        <Wrapper pt={32}>
          <div className={styles.bottomBlock}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
          </div>
        </Wrapper>
      </div>
    </>
  );
};

const mapStateToProps = ({ personalDataDeleteOrders: { isLoading, data, services, error } }: StoreTypes) => ({
  isLoading,
  deletedPersonalData: data,
  services,
  errors: error
});

const mapDispatchToProps = {
  getPersonalDataDeleteOrders: deletePDOrdersRequest,
  restorePersonalData: restorePersonalDataRequest,
  getServices: getPersonalDataDeleteOrdersServicesRequest,
  resetErrorsAndStatus: resetErrorAndStatus
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PersonalDataDeleteOrdersPage);
