import { LegalEntity } from "store/legalEntitiesList/types";
import { Employee } from "../../../store/employees/types";
import moment from "moment";

export const columns = [
  {
    title: "Название",
    dataIndex: "name",
    render: ({ name }: LegalEntity): string => name
  },
  {
    title: "ИНН",
    dataIndex: "inn",
    render: ({ inn }: LegalEntity): string => inn
  },
  {
    title: "Дата создания",
    dataIndex: "createdDate",
    render: ({ created_at }: Employee): string => (created_at ? moment(created_at).format("DD.MM.YYYY") : "")
  }
];

export const deleteRowsColumn = [
  {
    title: "№",
    dataIndex: "n",
    render: (employee: LegalEntity, index: string): string => index
  },
  {
    title: "Название",
    dataIndex: "name",
    render: ({ name }: LegalEntity): string => name
  },
  {
    title: "ИНН",
    dataIndex: "inn",
    render: ({ inn }: LegalEntity): string => inn
  }
];
