import React, { FC, ReactElement, useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import EmployeeRoles from "constant/eployee-roles";
import { buttonAddAndRemoveTheme, buttonTheme } from "components/ui/switch-themes";
import { Popup } from "../../ui/popup";
import styles from "./add-roles.module.scss";
import { Link as SlimButton } from "../../ui/link";
import { Button } from "../../ui/button";
import { StoreTypes } from "../../../store/types";
import { Role } from "../../../store/roles/types";
import { Loading } from "../../ui/loading";
import Warning from "../../ui/warning";
import { uniqify } from "../../../helpers/uniqify";

type ErrorType = {
  [key: string]: any;
};

type Props = {
  isEdit?: boolean;
  setClient: (arg: boolean) => void;
  setEmployeeDC?: (arg: boolean) => void;
  isOpen: boolean;
  setOpen: (arg: boolean) => void;
  addedRoles: Array<Role>;
  setFieldValue: (field: string, value: Array<Role>) => void;
  setFieldTouched: (name: string, value: boolean) => void;
};

type StoreProps = {
  roles: Array<Role>;
  isLoadingRoles: boolean;
  error: ErrorType | null;
};

const AddRoles: FC<Props & ReduxProps> = ({
  setEmployeeDC,
  setClient,
  isOpen,
  setOpen,
  roles,
  setFieldValue,
  addedRoles,
  isLoadingRoles,
  error,
  setFieldTouched
}) => {
  const [displayRoles, setRoles] = useState<Role[]>(roles);
  const [userRoles, setUserRoles] = useState<Role[]>(addedRoles);
  /// todo типизировать
  const [roleTypes, setRoleTypes] = useState<any>();

  const discharge = !userRoles?.length;

  useEffect(() => {
    if (userRoles?.length) {
      setRoleTypes(userRoles[0].type);
    } else setRoleTypes(undefined);
  }, [userRoles]);

  const setBlockedRoles = (
    newStateRole: Role[],
    renderRole: string | null,
    userRole: string | null
  ): boolean | undefined => {
    let status;
    if (!newStateRole?.length) {
      status = true;
    } else if (newStateRole?.length) {
      status = false;
      /// Если раскоменировать будет возможность
      // добавлять две роли одного подразделения
      // status = renderRole === userRole;
    }
    return status;
  };

  const filterRoles = (): void => {
    if (addedRoles?.length && roles?.length) {
      const newArr = roles?.filter((item: Role) => addedRoles?.every(elem => item.id !== elem.id));
      setRoles(newArr);
    } else {
      setRoles(roles);
    }
  };

  useEffect(() => {
    filterRoles();
  }, [roles, addedRoles]);

  useEffect(() => {
    setUserRoles([]);
    if (addedRoles?.length > 0) {
      const isClient = addedRoles?.some(item => item.name === "client");
      setClient(isClient);
    }
    if (setEmployeeDC) {
      const isEmployee = addedRoles?.some(
        (role: Role) => role.name === EmployeeRoles.adminDC || role.name === EmployeeRoles.employeeDC
      );
      setEmployeeDC(isEmployee);
    }
    if (Array.isArray(addedRoles)) {
      setUserRoles(addedRoles);
    }
  }, [addedRoles]);

  useEffect(() => {
    if (isOpen) {
      setFieldTouched("roles", true);
    }
  }, [isOpen]);

  const addRolesHandler = (roleAdd: Role): void => {
    const newRoles = [...displayRoles];
    const currentIndex = newRoles.indexOf(roleAdd);
    newRoles.splice(currentIndex, 1);
    setRoles(newRoles);
    setUserRoles([...userRoles, roleAdd]);
  };

  const deleteRolesHandler = (roleDel: Role): void => {
    const newRoles = [...userRoles];
    const currentIndex = newRoles.indexOf(roleDel);
    newRoles.splice(currentIndex, 1);
    setRoles([...displayRoles, roleDel]);
    setUserRoles(newRoles);
  };

  const addedRolesPush = (): void => {
    setFieldValue("roles", [...userRoles]);
    setOpen(false);
  };

  const rollbackRoles = (): void => {
    setRoles(roles);
    setUserRoles([]);
  };

  const closePopup = (): void => {
    filterRoles();
    setUserRoles(addedRoles);
    setOpen(false);
  };

  const renderRoles =
    Array.isArray(roles) && roles.length
      ? uniqify<Role>(displayRoles)
          .sort((a, b) => (a.display_name > b.display_name ? 1 : -1))
          .map(
            (roleRender): ReactElement => {
              return (
                <div className={styles.role} key={roleRender.id}>
                  <SlimButton
                    theme={setBlockedRoles(userRoles, roleRender.type, roleTypes) ? buttonTheme : "gray"}
                    blocked={!setBlockedRoles(userRoles, roleRender.type, roleTypes)}
                    textAlign="left"
                    onClick={(): void => addRolesHandler(roleRender)}
                    isButton
                    key={roleRender.id}
                  >
                    {roleRender.display_name}
                  </SlimButton>
                </div>
              );
            }
          )
      : null;

  const renderAddedRoles =
    userRoles &&
    Array.isArray(userRoles) &&
    uniqify<Role>(userRoles).map(
      (roleAdded): ReactElement => {
        return (
          <div className={styles.role} key={roleAdded.id}>
            <SlimButton
              theme={buttonTheme}
              textAlign="left"
              onClick={(): void => deleteRolesHandler(roleAdded)}
              isButton
            >
              {roleAdded.display_name}
            </SlimButton>
          </div>
        );
      }
    );

  return (
    <Popup isOpen={isOpen} onClick={closePopup}>
      <div className={styles.row}>
        <div className={styles.title}>Доступные роли</div>
        <div className={styles.title}>Добавленные роли</div>
      </div>
      <div className={styles.addRoles}>
        {error?.roles && <Warning color="red">{error?.roles}</Warning>}
        <Loading isLoading={isLoadingRoles} />
        <div className={styles.listContainer}>
          <div className={styles.column1}>
            <div className={styles.list}>{renderRoles}</div>
          </div>
          <div className={styles.column2}>
            <div className={styles.list}>{renderAddedRoles}</div>
          </div>
        </div>
      </div>

      <div className={styles.btnContainer}>
        <div className={styles.button}>
          <Button
            theme={buttonAddAndRemoveTheme}
            disabled={discharge}
            fullWidth={false}
            size="small"
            onClick={addedRolesPush}
          >
            Ок
          </Button>
        </div>
        <div className={styles.button}>
          <Button
            theme={buttonAddAndRemoveTheme}
            disabled={discharge}
            fullWidth={false}
            size="small"
            onClick={rollbackRoles}
          >
            Сбросить
          </Button>
        </div>
      </div>
    </Popup>
  );
};

const mapStateToProps = ({ roles }: StoreTypes): StoreProps => ({
  roles: roles.roles,
  isLoadingRoles: roles.isLoadingRoles,
  error: roles.error
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddRoles);
