export const TYPES_PERSONAL_DATA = {
  PERSONAL_DATA_LIST_LOAD: "PERSONAL_DATA_LIST_LOAD",
  PERSONAL_DATA_LIST_LOAD_STOP: "PERSONAL_DATA_LIST_LOAD_STOP",
  PERSONAL_DATA_LIST_REQUEST: "PERSONAL_DATA_LIST_REQUEST",
  PERSONAL_DATA_LIST_SUCCESS: "PERSONAL_DATA_LIST_SUCCESS",
  PERSONAL_DATA_LIST_ERROR: "PERSONAL_DATA_LIST_ERROR",

  DELETE_PERSONAL_DATA_REQUEST: "DELETE_PERSONAL_DATA_REQUEST",
  DELETE_PERSONAL_DATA_SUCCESS: "DELETE_PERSONAL_DATA_SUCCESS",
  DELETE_PERSONAL_DATA_ERROR: "DELETE_PERSONAL_DATA_ERROR",

  PERSONAL_DATA_BY_ID_REQUEST: "PERSONAL_DATA_BY_ID_REQUEST",
  PERSONAL_DATA_BY_ID_SUCCESS: "PERSONAL_DATA_BY_ID_SUCCESS",

  SET_PERSONAL_DATA_LIST_ROWS: "SET_PERSONAL_DATA_LIST_ROWS",

  FILTER_PERSONAL_DATA_LIST_ROWS: "FILTER_PERSONAL_DATA_LIST_ROWS",
  PERSONAL_DATA_LIST_STATUS: "PERSONAL_DATA_LIST_STATUS",
  PERSONAL_DATA_LIST_CLEAR_ROWS: "PERSONAL_DATA_LIST_CLEAR_ROWS"
} as const;
