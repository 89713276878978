import { put, takeLatest, call, select } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { notificationErrors } from "components/ui/notification/notification-errors";
import declOfNum from "helpers/declensionOfNum";
import {
  attachCarsRequest,
  attachUsersRequest,
  createLegalEntityRequest,
  detachCarsRequest,
  detachUsersRequest,
  getLegalCarsLoadStop,
  getLegalCarsRequest,
  getLegalCarsSuccess,
  getLegalUsersLoadStop,
  getLegalUsersRequest,
  getLegalUsersSuccess,
  legalEntityInfoLoadStop,
  legalEntityInfoRequest,
  legalEntityInfoSuccess,
  serverErrorLegalEntity,
  setLegalEntityEditMode,
  setOpenLEModalWindow,
  updateLegalEntityRequest
} from "./actions";
import { TYPES_LE } from "./constants";
import { LegalEntityApi } from "../../services/api/legalEntity";

// Создание ЮЛ
function* createLegalEntity(action: ReturnType<typeof createLegalEntityRequest>): SagaIterator {
  try {
    const { payload } = action;
    const response = yield call(LegalEntityApi.create, payload);
    yield put(legalEntityInfoSuccess(response));
    yield put(setOpenLEModalWindow(true));
  } catch (e) {
    if ([422].includes(e.status) && e.data.errors.inn) {
      yield put(serverErrorLegalEntity(e.data.errors.inn));
    } else {
      notificationErrors({});
    }
  }
}

// Обновление ЮЛ
function* updateLegalEntity(action: ReturnType<typeof updateLegalEntityRequest>): SagaIterator {
  try {
    const { id } = yield select(store => store.legalEntity.editLegalEntity);
    const { payload } = action;
    yield call(LegalEntityApi.update, id, payload);
    yield put(setOpenLEModalWindow(true));
  } catch (e) {
    if ([422].includes(e.status) && e.data.errors.inn) {
      yield put(serverErrorLegalEntity(e.data.errors.inn));
    } else {
      notificationErrors({});
    }
  }
}

// Получение подробной информации о ЮЛ по id
function* getLegalEntityInfo(action: ReturnType<typeof legalEntityInfoRequest>): SagaIterator {
  try {
    const { payload } = action;
    const response = yield call(LegalEntityApi.info, payload);
    yield put(setLegalEntityEditMode());
    yield put(legalEntityInfoSuccess(response));
  } catch (e) {
    notificationErrors({});
  } finally {
    yield put(legalEntityInfoLoadStop());
  }
}

// Получение базы пользователей
function* getUsers(action: ReturnType<typeof getLegalUsersRequest>): SagaIterator {
  try {
    const { payload } = action;
    const response = yield call(LegalEntityApi.users, payload);
    yield put(getLegalUsersSuccess(response));
  } catch (e) {
    notificationErrors({});
  } finally {
    yield put(getLegalUsersLoadStop());
  }
}

function* attachUserToLegalEntity(action: ReturnType<typeof attachUsersRequest>): SagaIterator {
  try {
    const { payload } = action;
    const { id } = yield select(store => store.legalEntity.editLegalEntity);
    const { legal } = yield call(LegalEntityApi.attachUsers, id, payload);
    notificationErrors({
      title: "Пользователи прикреплены",
      message: `К юридическому лицу успешно ${declOfNum(payload.length, [
        `прикреплен ${payload.length} пользователь`,
        `прикреплено ${payload.length} пользователя`,
        `прикреплено ${payload.length} пользователей`
      ])}`,
      type: "success"
    });
    yield put(legalEntityInfoSuccess(legal));
  } catch (e) {
    notificationErrors({});
  }
}

function* detachUserToLegalEntity(action: ReturnType<typeof detachUsersRequest>): SagaIterator {
  try {
    const { payload } = action;
    const { id } = yield select(store => store.legalEntity.editLegalEntity);
    const {legal} = yield call(LegalEntityApi.detachUsers, id, payload);
    notificationErrors({
      title: "Пользователи откреплены",
      message: `От юридического лица успешно ${declOfNum(payload.length, [
        `откреплен ${payload.length} пользователь`,
        `откреплено ${payload.length} пользователя`,
        `откреплено ${payload.length} пользователей`
      ])}`,
      type: "success"
    });
    yield put(legalEntityInfoSuccess(legal));
    yield put(getLegalUsersRequest({ pagination: { count: 10, page: 1 }, filters: { legalEntity: id } }));
  } catch (e) {
    notificationErrors({});
  }
}

// Получение базы ТС
function* getCars(action: ReturnType<typeof getLegalCarsRequest>): SagaIterator {
  try {
    const { payload } = action;
    const response = yield call(LegalEntityApi.cars, payload);
    yield put(getLegalCarsSuccess(response));
  } catch (e) {
    notificationErrors({});
  } finally {
    yield put(getLegalCarsLoadStop());
  }
}

function* attachCarToLegalEntity(action: ReturnType<typeof attachCarsRequest>): SagaIterator {
  try {
    const { payload } = action;
    const { id } = yield select(store => store.legalEntity.editLegalEntity);
    const {legal} = yield call(LegalEntityApi.attachCars, id, payload);
    notificationErrors({
      title: "ТС прикреплены",
      message: `К юридическому лицу успешно прикреплено ${payload.length} ТС`,
      type: "success"
    });
    yield put(legalEntityInfoSuccess(legal));
  } catch (e) {
    notificationErrors({});
  }
}

function* detachCarToLegalEntity(action: ReturnType<typeof detachCarsRequest>): SagaIterator {
  try {
    const { payload } = action;
    const { id } = yield select(store => store.legalEntity.editLegalEntity);
    const { legal } = yield call(LegalEntityApi.detachCars, id, payload);
    notificationErrors({
      title: "ТС откреплены",
      message: `От юридического лица успешно откреплено ${payload.length} ТС`,
      type: "success"
    });
    yield put(legalEntityInfoSuccess(legal));
    yield put(getLegalCarsRequest({ pagination: { count: 10, page: 1 }, filters: { legalEntity: id } }));
  } catch (e) {
    notificationErrors({});
  }
}

function* legalEntitySaga(): SagaIterator {
  yield takeLatest(TYPES_LE.LEGAL_ENTITY_CREATE_REQUEST, createLegalEntity);
  yield takeLatest(TYPES_LE.LEGAL_ENTITY_UPDATE_REQUEST, updateLegalEntity);
  yield takeLatest(TYPES_LE.LEGAL_ENTITY_INFO_REQUEST, getLegalEntityInfo);
  yield takeLatest(TYPES_LE.GET_LEGAL_USERS_REQUEST, getUsers);
  yield takeLatest(TYPES_LE.ATTACH_USERS_REQUEST, attachUserToLegalEntity);
  yield takeLatest(TYPES_LE.DETACH_USERS_REQUEST, detachUserToLegalEntity);
  yield takeLatest(TYPES_LE.GET_LEGAL_CARS_REQUEST, getCars);
  yield takeLatest(TYPES_LE.ATTACH_CARS_REQUEST, attachCarToLegalEntity);
  yield takeLatest(TYPES_LE.DETACH_CARS_REQUEST, detachCarToLegalEntity);
}

export { legalEntitySaga };
