import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { resetErrorAndStatus } from "store/actions";
import AddedForm from "components/admin/added-dealership/added-form";
import { StoreTypes } from "store/types";
import { dealershipInfo } from "store/dealers/actions";

type Props = {
  children?: React.ReactElement;
  isEdit?: boolean;
};

const AddedDealership: FC<ReduxProps & Props> = ({ resetErrorsAndStatus, children }) => {
  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
    };
  }, [resetErrorsAndStatus]);

  return <>{children || <AddedForm />}</>;
};

const mapStateToProps = ({ dealers }: StoreTypes) => ({
  dataDealership: dealers.editDealership
});

const mapDispatchToProps = {
  resetErrorsAndStatus: resetErrorAndStatus,
  getDealersInfo: dealershipInfo
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddedDealership);
