import React, { FC, useMemo, useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { loginHistoryRequest } from "store/loginHistory/actions";
import { LoginType } from "store/loginHistory/types";
import { StoreTypes } from "store/types";
import { Button } from "components/ui";
import styles from "./сonnections.module.scss";
import { Popup } from "../ui/popup";
import ShowButton from "./show-button";
import Connection from "./connection";

type StateProps = {
  loginHistory: Array<LoginType> | [];
  userInfoIsLoaded: boolean;
};

type Props = {
  id: string | null;
};

const Connections: FC<ReduxProps & Props> = ({
  id,
  loginHistoryRequest: getLoginHistory,
  loginHistory,
  userInfoIsLoaded
}) => {
  const [isOpen, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(3);

  useEffect(() => {
    if (userInfoIsLoaded && id) {
      getLoginHistory(id);
    }
  }, [userInfoIsLoaded, id]);

  const showButton = useMemo(() => loginHistory.length > 2 && quantity <= loginHistory.length, [
    loginHistory,
    quantity
  ]);

  const filtered = useMemo(() => loginHistory.filter((item, i) => i < quantity), [quantity, loginHistory]);
  const startList = loginHistory.filter((item, i) => i > loginHistory.length - 4);

  const renderStartList = useMemo(() => {
    return <Connection connections={startList} isOpen={isOpen} />;
  }, [startList, isOpen]);

  const renderList = useMemo(() => {
    return <Connection connections={filtered} isOpen={isOpen} />;
  }, [filtered]);

  const showBtnHandler = (): void => {
    setQuantity(8);
    setOpen(true);
  };

  return (
    <div className={styles.connections}>
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <div className={styles.title}>История подключений</div>
          <ShowButton type="desktop" onClick={showBtnHandler} />
        </div>
      </div>
      {renderStartList}
      <ShowButton type="mobile" onClick={showBtnHandler} />
      <Popup
        isOpen={isOpen}
        onClick={(): void => {
          setQuantity(3);
          setOpen(false);
        }}
      >
        <div className={styles.popupTitle}>История подключений</div>
        {renderList}
        {showButton && (
          <Button
            theme="link"
            onClick={(): void => {
              setQuantity(quantity + 7);
            }}
          >
            Смотреть все
          </Button>
        )}
      </Popup>
    </div>
  );
};

const mapStateToProps = ({ users, loginHistory }: StoreTypes): StateProps => ({
  loginHistory: loginHistory.loginHistory,
  userInfoIsLoaded: users.userInfoIsLoaded
});

const mapDispatchToProps = {
  loginHistoryRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Connections);
