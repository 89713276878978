import React, { FC, memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import { AUTH_ROUTES_URL } from "constant/routers";
import { registerSendSmsPhone, authResetAll, updateStepRegister } from "store/auth/actions";
import { PHONE_MASK } from "constant/phone-mask";
import { formatPhoneNumber } from "helpers/format-phone-number";
import { handlerPaste } from "helpers/handlePastePhone";
import { getAuthServerError } from "store/auth/selectors/getAuthErrorSelector";
import { getSSO } from "store/auth/selectors/getSSOSelector";
import { isHyundaiTheme, labelPhone, sizeTitleAuth, titleCompanyTheme } from "components/ui/switch-themes";
import { Button, Text, Wrapper, Link, Input, Checkbox } from "components/ui";
import history from "services/history";
import { authLogo } from "components/ui/helpersComponents";
import { getAuthData } from "store/auth/selectors/getAuthDataSelector";
import { getAuthUpdateUser } from "store/auth/selectors/getAuthUpdateUserSelector";
import { STEP_REGISTER } from "constant/step-auth";
import { InvisibleSmartCaptcha } from "@yandex/smart-captcha";
import { useLocation } from "react-router-dom";
import Row from "../../../ui/row";
import Accept from "../../accept";
import styles from "./register.module.scss";

const validationSchema = yup.object().shape({
  phone: yup
    .string()
    .required("Необходимо заполнить данные")
    .transform(value => value.replace(/[^\d]/g, ""))
    .min(11, "Номер телефона должен состоять из 11 символов"),
  checked: yup.boolean().oneOf([true], "Поставьте свое согласие")
});

const RegisterTel: FC = () => {
  const dispatch = useDispatch();
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [validCaptchaSuccess, setValidCaptchaSuccess] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const error = useSelector(getAuthServerError);
  const { registerViaSSO: isRegisterSSO, isPhoneRequired: isPhoneRequiredSSO } = useSelector(getSSO);
  const { phone: phoneUser } = useSelector(getAuthData);
  const isUpdateUser = useSelector(getAuthUpdateUser);

  const formik = useFormik({
    initialValues: {
      phone: searchParams.get("phone") ?? "",
      checked: false
    },
    validationSchema,
    onSubmit: () => {
      setValidCaptcha(true);
    }
  });

  const handleReset = () => {
    setValidCaptcha(false);
    setResetCaptcha(prev => prev + 1);
  };

  // todo сделть разлогиневане по роуту
  window.addEventListener("popstate", () => dispatch(authResetAll()), false);

  const handleRegistration = (formik: any) => {
    const value = formatPhoneNumber(String(formik.values.phone));
    if (isRegisterSSO && phoneUser && phoneUser !== value) {
      formik.setFieldError("phone", "Данный номер не пренадлежит этой учетной записи");
    } else {
      dispatch(registerSendSmsPhone({ username: value, phone: value, type: "phone", token: captchaToken }));
    }
  };

  useEffect(() => {
    if (validCaptchaSuccess) {
      handleRegistration(formik);
    }
    handleReset();
    setValidCaptchaSuccess(false);
  }, [validCaptchaSuccess]);

  useEffect(() => {
    if (error) formik.setFieldError("phone", error);
    return () => {
      window.removeEventListener("popstate", () => dispatch(authResetAll()), false);
    };
  }, [error]);

  useEffect(() => {
    if (isUpdateUser && !isRegisterSSO) {
      dispatch(updateStepRegister(STEP_REGISTER.REGISTER));
    }
  }, [isUpdateUser, isRegisterSSO]);

  const toggleTitle = isPhoneRequiredSSO || isUpdateUser || isRegisterSSO;
  const disabledButton =
    !formik.values.checked || Object.values(formik.values).includes("") || !!Object.keys(formik.errors).length;

  return (
    <div className="text-center">
      <form onSubmit={formik.handleSubmit}>
        {authLogo}
        <Wrapper pb={60}>
          <Text size={sizeTitleAuth} uppercase={!isHyundaiTheme} align="center" theme={titleCompanyTheme}>
            {toggleTitle ? "Введите телефон" : "Регистрация"}
          </Text>
        </Wrapper>
        <Wrapper pb={32}>
          <Input
            name="phone"
            type="tel"
            label={labelPhone}
            placeholder="Введите Ваш номер телефона"
            mask={PHONE_MASK}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            errorMessages={formik.errors.phone && formik.touched.phone ? formik.errors.phone : null}
            invalid={Boolean(formik.errors.phone && formik.touched.phone)}
            onPaste={(elem: React.ClipboardEvent<HTMLInputElement>): void => handlerPaste(elem, formik.setFieldValue)}
          />
        </Wrapper>
        <Wrapper pb={32}>
          <Row>
            <div>
              <Checkbox
                invalid={!formik.values.checked && !!formik.errors.checked && formik.touched.checked}
                errorMessages={
                  !formik.values.checked && !!formik.errors.checked && formik.touched.checked
                    ? formik.errors.checked
                    : null
                }
                checked={formik.values.checked}
                onChange={() => formik.setFieldValue("checked", !formik.values.checked)}
                name="checked"
                style={{ marginTop: "30px" }}
              />
            </div>
            <Accept color={titleCompanyTheme} />
          </Row>
        </Wrapper>
        <Wrapper pb={48}>
          <Button fullWidth type="submit" disabled={disabledButton}>
            {isRegisterSSO || isPhoneRequiredSSO ? "Продолжить" : "Зарегистрироваться"}
          </Button>
        </Wrapper>
        {!isPhoneRequiredSSO ? (
          <Wrapper pb={48}>
            <Link href="/" size="big" isButton onClick={(): void => history.push(AUTH_ROUTES_URL.SIGN_IN)}>
              Уже есть аккаунт? Войти
            </Link>
          </Wrapper>
        ) : null}
        <div className={styles.captcha}>
          <InvisibleSmartCaptcha
            key={resetCaptcha}
            sitekey={process.env.REACT_APP_YANDEX_CAPTCHA_ID ?? ""}
            onSuccess={token => {
              setCaptchaToken(token);
              setValidCaptchaSuccess(true);
            }}
            visible={validCaptcha}
            onChallengeHidden={() => handleReset()}
          />
        </div>
      </form>
    </div>
  );
};

export default memo(RegisterTel);
