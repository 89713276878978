import { DealerCenter } from "store/dealer-center/types";
import moment from "moment";

export const columns = [
  {
    title: "Название",
    dataIndex: "name",
    render: ({ display_name: displayName }: DealerCenter): string => displayName
  },
  {
    title: "Адрес",
    dataIndex: "address",
    render: ({ address }: DealerCenter): string => address || ""
  },
  {
    title: "Администратор",
    dataIndex: "admin",
    render: ({ admin }: DealerCenter): string => admin || ""
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    render: ({ phone }: DealerCenter): string => phone || ""
  },
  {
    title: "Ссылка",
    dataIndex: "link",
    render: ({ link }: DealerCenter): string => link || ""
  },
  {
    title: "Дата создания",
    dataIndex: "createdDate",
    render: ({ created_at }: DealerCenter): string => (created_at ? moment(created_at).format("DD.MM.YYYY") : "")
  }
];

export const deleteRowsColumn = [
  {
    title: "№",
    dataIndex: "n",
    render: (employee: DealerCenter, index: string): string => index
  },
  {
    title: "Название",
    dataIndex: "name",
    render: ({ display_name: displayName }: DealerCenter): string => displayName
  },
  {
    title: "Адрес",
    dataIndex: "address",
    render: ({ address }: DealerCenter): string => address || ""
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    render: ({ phone }: DealerCenter): string => phone || ""
  },
  {
    title: "Ссылка",
    dataIndex: "link",
    render: ({ link }: DealerCenter): string => link || ""
  }
];
