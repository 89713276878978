import { TYPES_DC } from "store/dealer-center/constants";
import { GetDealerCenterParams, DealerCenterType, DealerCenter, DeleteDCPayloadType } from "./types";

export const dealerCenterLoad = () => ({
  type: TYPES_DC.DEALER_CENTER_LOAD
});

export const dealerCenterLoadStop = () => ({
  type: TYPES_DC.DEALER_CENTER_LOAD_STOP
});

export const dealerCenterRequest = (body: GetDealerCenterParams) => ({
  type: TYPES_DC.DEALER_CENTER_REQUEST,
  payload: body
});

export const dealerCenterSuccess = (payload: DealerCenterType) => ({
  type: TYPES_DC.DEALER_CENTER_SUCCESS,
  payload
});

export const dealerCenterError = (error: { [key: string]: string }) => ({
  type: TYPES_DC.DEALER_CENTER_ERROR,
  payload: error
});

export const deleteDealerCenterRequest = (payload: DeleteDCPayloadType) => ({
  type: TYPES_DC.DELETE_DEALER_CENTER_REQUEST,
  payload
});

export const deleteDealerCenterSuccess = (payload: number) => ({
  type: TYPES_DC.DELETE_DEALER_CENTER_SUCCESS,
  payload
});

export const clearDealersRows = () => ({
  type: TYPES_DC.DEALERS_CLEAR_ROWS
});

export const dealerCenterByIdRequest = (id: number | string) => ({
  type: TYPES_DC.DEALER_CENTER_BY_ID_REQUEST,
  payload: id
});

export const dealerCenterByIdSuccess = (payload: DealerCenter | null) => ({
  type: TYPES_DC.DEALER_CENTER_BY_ID_SUCCESS,
  payload
});

export const setDcRows = (payload: Array<string | number>) => ({
  type: TYPES_DC.SET_DC_ROWS,
  payload
});

export const filterDcRows = (payload: number) => ({
  type: TYPES_DC.FILTER_DC_ROWS,
  payload
});

export const deleteDealerCenterError = (error: { [key: string]: string }) => ({
  type: TYPES_DC.DELETE_DEALER_CENTER_ERROR,
  payload: error
});

export const dealerCenterStatus = (status: { [key: string]: string } | null) => ({
  type: TYPES_DC.DEALER_CENTER_STATUS,
  payload: status
});
