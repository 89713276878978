import React, { FC, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { connect, ConnectedProps } from "react-redux";
import { authResetAll } from "store/auth/actions";
import AddedForm from "components/admin/added-dealership/added-form";
import AddedUsers from "pages/admin/add-users";
import AddedLegalForm from "components/admin/added-legal/added-legal-form";
import AddedThirdPartyForm from "components/admin/added-third-party/added-third-party-form";
import AddedPersonalDataForm from "components/admin/added-personal-data/added-personal-data-form";
import Layout from "../../components/admin/layout";
import DealersPage from "./dealers/dealers-page";
import UsersPage from "./users/users-page";
import MarketingMailingsPage from "./marketing-mailings";
import EmailPage from "./email/email-page";
import Employees from "./employees";
import TeamName from "../../constant/team-name";
import AddedDealership from "./added-dealership";
import AddMarketingMailings from "./add-marketing-mailings/add-marketing-mailings";
import LegalEntityPage from "./legalEnities";
import AddedLegalEntity from "./added-legal";
import PersonalDataPage from "./deleted-personal-data";
import PersonalDataDeleteOrdersPage from "./delete-personal-data-orders";
import ServicesPage from "./services";
import EditService from "./editService";
import ThirdPartiesPage from "./third-parties";
import AddedThirdParty from "./added-third-party";
import PersonalDataListPage from "./personal-data-list";
import AddedPersonalData from "./added-personal-data";
import AddedService from "./added-service";
import PhoneBlacklist from "./phone-blacklist";

const AdminPage: FC<ReduxProps> = ({ resetUser }: ReduxProps) => {
  const [stateTimer, setStateTimer] = useState<boolean>(false);

  const handleOnIdle = (getTime: any) => {
    const time = getTime;
    if (time === 0 && stateTimer) {
      resetUser();
      localStorage.clear();
    }
  };

  useEffect(() => {
    setStateTimer(true);
    return (): void => setStateTimer(false);
  }, []);

  const { getRemainingTime } = useIdleTimer({
    timeout: 3600000,
    onIdle: () => handleOnIdle(getRemainingTime()),
    startOnMount: stateTimer,
    debounce: 500
  });

  return (
    <Layout>
      <Switch>
        <Route exact path="/admin/employees/dc">
          <Employees title="Сотрудники дилерских центров" teamName={TeamName.DC} />
        </Route>
        <Route exact path="/admin/employees/main">
          <Employees title="Сотрудники ХМСНГ" teamName={TeamName.MAIN} />
        </Route>
        <Route exact path="/admin/employees/dc/add-employee">
          <AddedUsers teamName={TeamName.DC} />
        </Route>
        <Route exact path="/admin/employees/main/add-employee">
          <AddedUsers teamName={TeamName.MAIN} />
        </Route>
        <Route exact path="/admin/employees/dc/edit-employee/:id">
          <AddedUsers teamName={TeamName.DC} isEdit />
        </Route>
        <Route exact path="/admin/employees/main/edit-employee/:id">
          <AddedUsers teamName={TeamName.MAIN} isEdit />
        </Route>
        <Route exact path="/admin/users" component={UsersPage} />
        <Route exact path="/admin/users/added" component={AddedUsers} />
        <Route exact path="/admin/users/added/:id">
          <AddedUsers isEdit teamName={TeamName.ALL} />
        </Route>
        <Route exact path="/admin/dealers" component={DealersPage} />
        <Route exact path="/admin/legal" component={LegalEntityPage} />
        <Route exact path="/admin/dealers/added" component={AddedDealership} />
        <Route exact path="/admin/dealers/added/:id">
          <AddedDealership>
            <AddedForm isEdit />
          </AddedDealership>
        </Route>
        <Route exact path="/admin/legal/added" component={AddedLegalEntity} />
        <Route exact path="/admin/legal/added/:id">
          <AddedLegalEntity>
            <AddedLegalForm isEdit />
          </AddedLegalEntity>
        </Route>
        <Route exact path="/admin/marketing-mailings" component={MarketingMailingsPage} />
        <Route exact path="/admin/deleted-personal-data" component={PersonalDataPage} />
        <Route exact path="/admin/delete-personal-data-orders" component={PersonalDataDeleteOrdersPage} />
        <Route exact path="/admin/services" component={ServicesPage} />
        <Route exact path="/admin/service/:id">
          <EditService />
        </Route>
        <Route exact path="/admin/services/added">
          <AddedService />
        </Route>
        <Route exact path="/admin/marketing-mailings/create" component={AddMarketingMailings} />
        <Route exact path="/admin/email" component={EmailPage} />
        <Route exact path="/admin/third-parties" component={ThirdPartiesPage} />
        <Route exact path="/admin/third-parties/added" component={AddedThirdParty} />
        <Route exact path="/admin/third-parties/added/:id">
          <AddedThirdParty>
            <AddedThirdPartyForm isEdit />
          </AddedThirdParty>
        </Route>
        <Route exact path="/admin/personal-data-list" component={PersonalDataListPage} />
        <Route exact path="/admin/personal-data-list/added" component={AddedPersonalData} />
        <Route exact path="/admin/personal-data-list/added/:id">
          <AddedPersonalData>
            <AddedPersonalDataForm isEdit />
          </AddedPersonalData>
        </Route>
        <Route exact path="/admin/phone-blacklist" component={PhoneBlacklist} />
        <Redirect exact from="/admin" to="/admin/employees" />
        <Redirect exact from="/admin/employees" to="/admin/employees/dc" />
      </Switch>
    </Layout>
  );
};

const mapDispatchToProps = {
  resetUser: authResetAll
};

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AdminPage);
