import { TYPES_ROLES } from "store/roles/constants";
import { ActionsType, Store } from "./types";
import { RESET_ERROR_AND_STATUS } from "../constants";

const initialState: Store = {
  isLoadingRoles: false,
  error: null,
  roles: []
};

export const rolesReducer = (state: Store = initialState, actions: ActionsType): Store => {
  switch (actions.type) {
    case TYPES_ROLES.ROLES_LOAD: {
      return { ...state, isLoadingRoles: true, error: null };
    }
    case TYPES_ROLES.ROLES_LOAD_STOP: {
      return { ...state, isLoadingRoles: false };
    }
    case TYPES_ROLES.ROLES_SUCCESS: {
      return { ...state, roles: actions.payload };
    }
    case TYPES_ROLES.ROLES_ERROR: {
      return { ...state, error: { ...state.error, ...actions.payload } };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null };
    }
    default: {
      return state;
    }
  }
};
