import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, Table, Wrapper } from "components/ui";
import SearchForm from "components/admin/search-form";
import { Loading } from "components/ui/loading";
import Pagination from "components/ui/pagination/pagination";
import Select from "components/ui/select";
import getPrettyPhone from "helpers/pretty-phone-format";
import { StoreTypes } from "store/types";
import selectOptions from "constant/quantity-rows-per-admin-page";
import Row from "components/ui/row";
import debounce from "helpers/debounce";
import PopupConfirm from "components/ui/popup-confirm";
import declOfNum from "helpers/declensionOfNum";
import { paginationTheme } from "components/ui/switch-themes";
import styles from "./legalEntityCarBase.module.scss";
import { columnsCar, columnsPopup } from "../columns";
import { attachCarsRequest, getLegalCarsRequest } from "../../../../store/legalEntity/actions";
import setRowsHandler from "../../../../helpers/setRowsData";

const LegalEntityCarBase: FC<ReduxProps> = ({
  getCars,
  baseCarsData: { data: baseCars, last_page: lastPage },
  attachCars,
  isLoading
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedRows, setRows] = useState<Array<string | number>>([]);
  const [selectedRowsCount, setSelectedRowsCount] = useState(selectedRows.length);

  const [attachedCars, setAttachedCars] = useState<Array<any>>([]);
  const [isOpen, setOpen] = useState(false);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    setAttachedCars(setRowsHandler(attachedCars, selectedRows, baseCars));
    setClear(false);
  }, [selectedRows]);

  useEffect(() => {
    setSelectedRowsCount(selectedRows.length);
  }, [isOpen]);

  const attachCarsHandler = (): void => {
    attachCars(attachedCars.map(car => car.id));
    setAttachedCars([]);
    setRows([]);
    setSelectedRowsCount(0);
    setClear(true);
    setOpen(false);
  };

  return (
    <div>
      <Wrapper pt={40}>
        <PopupConfirm
          isOpen={isOpen}
          action={attachCarsHandler}
          setOpen={setOpen}
          title={`Вы  хотите прикрепить  ${selectedRowsCount} ТС?`}
          desc=""
        >
          <Table columns={columnsCar} data={attachedCars} />
        </PopupConfirm>
        <Row justifyContent="spaceBetween">
          <div>
            <Button size="small" disabled={!attachedCars.length} onClick={(): void => setOpen(true)}>
              Прикрепить к ЮЛ
            </Button>
          </div>
          <SearchForm
            rowsPerPage={rowsPerPage}
            action={getCars}
            placeholder="Поиск по VIN"
            currentPage={currentPage}
            typeData="Car"
            disableAutosuggest
          />
        </Row>
        <Wrapper pt={26}>
          <Loading isLoading={isLoading}>
            <Table
              onClear={clear}
              columns={columnsCar}
              data={baseCars}
              rowSelection={(rows): void => setRows(rows)}
              selectedRows={selectedRows}
            />
          </Loading>
        </Wrapper>
        <Wrapper pb={32} pt={32}>
          <div className={styles.select_wrap}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
            <Select options={selectOptions} onClick={setRowsPerPage} />
          </div>
        </Wrapper>
      </Wrapper>
    </div>
  );
};

const mapStateToProps = ({ legalEntity }: StoreTypes) => ({
  baseCarsData: legalEntity.cars,
  isLoading: legalEntity.isCarsLoading,
  editing: legalEntity.edit
});

const mapDispatchToProps = {
  getCars: getLegalCarsRequest,
  attachCars: attachCarsRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(LegalEntityCarBase);
