export const TYPES_PD = {
  PERSONAL_DATA_INFO_REQUEST: "PERSONAL_DATA_INFO_REQUEST",
  PERSONAL_DATA_INFO_SUCCESS: "PERSONAL_DATA_INFO_SUCCESS",
  PERSONAL_DATA_INFO_LOAD_STOP: "PERSONAL_DATA_INFO_LOAD_STOP",
  PERSONAL_DATA_INFO_CLEAR: "PERSONAL_DATA_INFO_CLEAR",

  SET_PERSONAL_DATA_EDIT_MODE: "SET_PERSONAL_DATA_EDIT_MODE",

  PERSONAL_DATA_CREATE_REQUEST: "PERSONAL_DATA_CREATE_REQUEST",
  PERSONAL_DATA_CREATE_SUCCESS: "PERSONAL_DATA_CREATE_SUCCESS",
  PERSONAL_DATA_CREATE_ERROR: "PERSONAL_DATA_CREATE_ERROR",

  PERSONAL_DATA_UPDATE_REQUEST: "PERSONAL_DATA_UPDATE_REQUEST",
  PERSONAL_DATA_UPDATE_SUCCESS: "PERSONAL_DATA_UPDATE_SUCCESS",
  PERSONAL_DATA_UPDATE_ERROR: "PERSONAL_DATA_UPDATE_ERROR",

  MODAL_OPEN_PERSONAL_DATA: "MODAL_OPEN_PERSONAL_DATA",

  SERVER_ERROR_PERSONAL_DATA: "SERVER_ERROR_PERSONAL_DATA"
} as const;
