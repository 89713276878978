export const TYPES_PERSONAL_DATA_DELETE_ORDERS = {
  GET_PERSONAL_DATA_DELETE_ORDERS_REQUEST_WITH_DEBOUNCE: "GET_PERSONAL_DATA_DELETE_ORDERS_REQUEST_WITH_DEBOUNCE",

  GET_PERSONAL_DATA_DELETE_ORDERS_REQUEST: "GET_PERSONAL_DATA_DELETE_ORDERS_REQUEST",
  GET_PERSONAL_DATA_DELETE_ORDERS_SUCCESS: "GET_PERSONAL_DATA_DELETE_ORDERS_SUCCESS",
  GET_PERSONAL_DATA_DELETE_ORDERS_ERROR: "GET_PERSONAL_DATA_DELETE_ORDERS_ERROR",

  GET_PERSONAL_DATA_DELETE_ORDERS_SERVICES_REQUEST: "GET_PERSONAL_DATA_DELETE_ORDERS_SERVICES_REQUEST",
  GET_PERSONAL_DATA_DELETE_ORDERS_SERVICES_SUCCESS: "GET_PERSONAL_DATA_DELETE_ORDERS_SERVICES_SUCCESS",
  GET_PERSONAL_DATA_DELETE_ORDERS_SERVICES_ERROR: "GET_PERSONAL_DATA_DELETE_ORDERS_SERVICES_ERROR",

  RESTORE_PERSONAL_DATA_REQUEST: "RESTORE_PERSONAL_DATA_REQUEST",
  RESTORE_PERSONAL_DATA_REQUEST_SUCCESS: "RESTORE_PERSONAL_DATA_REQUEST_SUCCESS",
  RESTORE_PERSONAL_DATA_REQUEST_ERROR: "RESTORE_PERSONAL_DATA_REQUEST_ERROR"
} as const;
