import { TYPES_PERSONAL_DATA_DELETE_ORDERS } from "./constants";
import { GetPersonalDataDeleteOrdersParams } from "./types";

export const deletePDOrdersRequestWithDebounce = (params: GetPersonalDataDeleteOrdersParams) => ({
  type: TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_REQUEST_WITH_DEBOUNCE,
  payload: params
});

export const deletePDOrdersRequest = (params: GetPersonalDataDeleteOrdersParams) => ({
  type: TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_REQUEST,
  payload: params
});

export const deletePDOrdersSuccess = (data: any) => ({
  type: TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_SUCCESS,
  payload: data
});

export const deletePDOrdersError = (error: { [key: string]: string }) => ({
  type: TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_ERROR,
  payload: error
});

export const getPersonalDataDeleteOrdersServicesRequest = () => ({
  type: TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_SERVICES_REQUEST
});

export const getPersonalDataDeleteOrdersServicesSuccess = (services: any) => ({
  type: TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_SERVICES_SUCCESS,
  payload: services
});

export const getPersonalDataDeleteOrdersServicesError = (error: { [key: string]: string }) => ({
  type: TYPES_PERSONAL_DATA_DELETE_ORDERS.GET_PERSONAL_DATA_DELETE_ORDERS_SERVICES_ERROR,
  payload: error
});
export const restorePersonalDataRequest = (userId: string, serviceId: string, id:string) => ({
  type: TYPES_PERSONAL_DATA_DELETE_ORDERS.RESTORE_PERSONAL_DATA_REQUEST,
  payload: {
    userId,
    serviceId,
    id
  }
});

export const restorePersonalDataSuccess = (services: any) => ({
  type: TYPES_PERSONAL_DATA_DELETE_ORDERS.RESTORE_PERSONAL_DATA_REQUEST_SUCCESS,
  payload: services
});

export const restorePersonalDataError = (error: { [key: string]: string }) => ({
  type: TYPES_PERSONAL_DATA_DELETE_ORDERS.RESTORE_PERSONAL_DATA_REQUEST_ERROR,
  payload: error
});
