import moment from "moment";
import "moment/locale/ru";

const getDate = (date: string | null): string | null => {
  if (!date) {
    return date;
  }
  const currentDate = moment().format("L");
  const connectedDate = moment(date).format("L");
  if (connectedDate === currentDate) {
    const today = moment()
      .calendar()
      .split(" ")
      .slice(0, -1)
      .join(" ");
    const time = moment(date).format("HH:mm");

    return `${today} ${time}`;
  }
  return moment(date).format("DD.MM.YYYY, HH:mm");
};

export default getDate;
