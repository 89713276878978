import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import genesisFavicon from "../assets/icons/genesis.png";
import hyundaiFavicon from "../assets/icons/hyundai.png";

export const HamletTags: FC = () => {
  const theme = process.env.REACT_APP_COMPANY === "hyundai";

  return (
    <div>
      {theme ? (
        <Helmet>
          <title>Hyundai ID</title>
          <link rel="icon" href={hyundaiFavicon} />
          <link rel="apple-touch-icon" href={hyundaiFavicon} />
        </Helmet>
      ) : (
        <Helmet>
          <title>Genesis ID</title>
          <link rel="icon" href={genesisFavicon} />
          <link rel="apple-touch-icon" href={genesisFavicon} />
        </Helmet>
      )}
    </div>
  );
};
