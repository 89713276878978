export const TYPES_EMPLOYEES = {
  EMPLOYEES_LOAD: "EMPLOYEES_LOAD",
  EMPLOYEES_LOAD_STOP: "EMPLOYEES_LOAD_STOP",
  EMPLOYEES_REQUEST: "EMPLOYEES_REQUEST",
  EMPLOYEES_SUCCESS: "EMPLOYEES_SUCCESS",
  EMPLOYEES_ERROR: "EMPLOYEES_ERROR",
  EMPLOYEES_STATUS: "EMPLOYEES_STATUS",

  DELETE_EMPLOYEE_REQUEST: "DELETE_EMPLOYEE_REQUEST",
  DELETE_EMPLOYEE_SUCCESS: " DELETE_EMPLOYEE_SUCCESS",
  DELETE_EMPLOYEE_ERROR: "DELETE_EMPLOYEE_ERROR",

  EMPLOYEES_BY_ID_REQUEST: "EMPLOYEES_BY_ID_REQUEST",
  EMPLOYEES_BY_ID_SUCCESS: "EMPLOYEES_BY_ID_SUCCESS",

  EMPLOYEE_UPDATE_REQUEST: "EMPLOYEE_UPDATE_REQUEST",

  EMPLOYEE_UPDATE_ROLES_SUCCESS: "EMPLOYEE_UPDATE_ROLES_SUCCESS",

  SET_EMPLOYEES_ROWS: "SET_EMPLOYEES_ROWS",
  UPDATE_TEAMS: "UPDATE_TEAMS",

  FILTER_EMPLOYEES_ROWS: "FILTER_EMPLOYEES_ROWS",
  CLIENT_REQUEST: "CLIENT_REQUEST",

  CREATE_BAN_DELETION_REQUEST: "CREATE_BAN_DELETION_REQUEST",
  CREATE_BAN_DELETION_SUCCESS: "CREATE_BAN_DELETION_SUCCESS",

  UPDATE_BAN_DELETION_REQUEST: "UPDATE_BAN_DELETION_REQUEST",
  UPDATE_BAN_DELETION_SUCCESS: "UPDATE_BAN_DELETION_SUCCESS",

  DELETE_BAN_DELETION_REQUEST: "DELETE_BAN_DELETION_REQUEST",
  DELETE_BAN_DELETION_SUCCESS: "DELETE_BAN_DELETION_SUCCESS",
  DELETE_BAN_DELETION_ERROR: "DELETE_BAN_DELETION_ERROR",

  SET_BAN_DELETION_ROWS: "SET_BAN_DELETION_ROWS",
  FILTER_BAN_DELETION_ROWS: "FILTER_BAN_DELETION_ROWS"
} as const;
