import axios from "axios";

export type AuthLoginTypes = {
  email?: string | null;
  username: string | null;
  type?: LoginTypes | null;
  token: string | null;
};

export type AuthEmailTypes = {
  password: string | null;
  token: string | null;
};

export type AuthTokenTypes = {
  token: string;
};

export type regSocialNetworkTypes = {
  userId: string;
};

export type regSocialNetworkResponseTypes = {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
};

export type AuthPasswordTypes = AuthLoginTypes & {
  password: string | null;
  token: string | null;
};

export type LoginTypes = "email" | "phone";

type AuthTokenResponse = {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
};

type AuthCodeTypes = {
  email?: string | null;
  username: string | null;
  type: LoginTypes | null;
  code: string;
};

interface SendAddedPhoneTypes {
  phone: string | null;
  type: string | null;
}
interface UpdatePhoneTypes extends SendAddedPhoneTypes {
  email: string | null;
  code: string | null;
}

type AddedPhoneTypes = {
  code?: number;
  message: string;
  status: string;
};

type AuthLoginResponse = {
  code?: string | number;
  phone: string;
  sms_code_ttl: string;
  status: string;
};

type VerifiedEmailResponse = {
  status: string;
  message: string;
};

type ResponseAuthSSO = {
  [key: string]: { url: string };
};

type ResponseSessionToken = { token: string };

export const Authentication = {
  authn: (body: AuthLoginTypes): Promise<AuthLoginResponse> => {
    return axios.post("/front/authentication", body);
  },
  token: (body: AuthTokenTypes): Promise<AuthLoginResponse> => {
    return axios.get(`/front/marketing-token?token=${body.token}`);
  },
  regSocialNetwork: (body: regSocialNetworkTypes): Promise<regSocialNetworkResponseTypes> => {
    return axios.post(`/oauth/create-by-user-social/${body.userId}`);
  },
  password: (body: AuthPasswordTypes): Promise<AuthTokenResponse> => {
    return axios.post("/front/authentication-password", body);
  },
  emailCheck: (email: string): Promise<boolean> => {
    return axios.get(`/front/check-email/?email=${email}`);
  },
  code: (body: AuthCodeTypes): Promise<AuthTokenResponse> => {
    return axios.post("/front/authentication-sms", body);
  },
  openAuth: (params: any): Promise<{ code: string; state: string }> => {
    return axios.get(`/oauth/authorize/token`, {
      params
    });
  },
  phone: (body: SendAddedPhoneTypes): Promise<AddedPhoneTypes> => {
    return axios.post("/front/send-code", body);
  },
  addedPhone: (body: any): Promise<AddedPhoneTypes> => {
    return axios.post("/front/update_phone", body);
  },
  verify: (url: string): Promise<Partial<VerifiedEmailResponse>> => {
    return axios.get(`/front/verify/${url}`);
  },
  refreshAuth: (refreshToken: { refresh_token: string }): Promise<AuthTokenResponse> => {
    return axios.post("/front/authn/refresh", refreshToken);
  },
  authSSO: (): Promise<ResponseAuthSSO> => {
    return axios.get("/front/sso_providers");
  },
  verification: (body: { email: string }): Promise<any> => {
    return axios.post("/front/send-verification-email", body);
  },
  logOut: (): Promise<any> => axios.post(`/front/logout`),
  sessionToken: (): Promise<ResponseSessionToken> => {
    return axios.get("/front/token");
  }
};
