import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import { connect, ConnectedProps } from "react-redux";
import { sendCodeRequest, updateUserPhoneRequest, usersStatus } from "store/users/actions";
import { StoreTypes } from "store/types";
import { Button, Input, Popup, Wrapper } from "components/ui";
import { labelCode, labelPhone } from "components/ui/switch-themes";
import { authUpdateTtl, serverError } from "store/auth/actions";
import { useFormik } from "formik";
import { PHONE_MASK } from "constant/phone-mask";
import { formatPhoneNumber } from "helpers/format-phone-number";
import { handlerPaste } from "helpers/handlePastePhone";
import { resetErrorAndStatus } from "store/actions";
import Timer from "components/ui/timer/timer";
import { InvisibleSmartCaptcha } from "@yandex/smart-captcha";
import styles from "./change-phone.module.scss";

const validationSchema = yup.object().shape({
  isPhone: yup.string(),
  phone: yup
    .string()
    .transform(value => value.replace(/[^\d]/g, ""))
    .min(11, "Номер телефона должен состоять из 11 символов")
    .when("isPhone", {
      is: "phone",
      then: yup.string().required("Необходимо заполнить данные"),
      otherwise: yup.string().notRequired()
    }),
  code: yup
    .string()
    .transform(value => value.replace(/[^\d]/g, ""))
    .min(4, "Код должен состоять из 4 символов")
    .max(4, "Код должен состоять из 4 символов")
    .when("isPhone", {
      is: "code",
      then: yup.string().required("Необходимо ввести код"),
      otherwise: yup.string().notRequired()
    })
});

type DispatchProps = {
  updateUserPhoneRequest: (phone: string) => void;
};

type Props = {
  id: string;
  isLoading: boolean;
  status: {
    [key: string]: string;
  } | null;
};

const ChangePhone: FC<Props & ReduxProps> = ({
  updateUserPhoneRequest: onSubmitCode,
  sendCodeRequest: onSubmitPhone,
  isLoading,
  warning,
  status,
  resetStatus,
  updateTime
}) => {
  const [isNumberPopupOpen, setIsNumberPopupOpen] = useState(false);
  const [inputTypes, setInputTypes] = useState<string>("phone");
  const [isCodePopupOpen, setIsCodePopupOpen] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [showTimer, setShowTimer] = useState(false);

  const [validCaptcha, setValidCaptcha] = useState(false);
  const [validCaptchaSuccess, setValidCaptchaSuccess] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const handleReset = () => {
    setValidCaptcha(false);
    setResetCaptcha(prev => prev + 1);
  };

  const handleSendCode = (formik: any) => {
    resetStatus();
    const data = formatPhoneNumber(String(formik.values.phone));
    warning(null);
    updateTime("120");
    setNewPhoneNumber(data);
    onSubmitPhone({ phone: data, type: "phone", username: data, token: captchaToken });
  };

  const formik = useFormik({
    initialValues: {
      isPhone: inputTypes,
      phone: "",
      code: ""
    },
    validationSchema,
    onSubmit: values => {
      if (inputTypes === "phone") {
        setValidCaptcha(true);
      } else if (inputTypes === "code") {
        const data = String(values.code);
        warning(null);
        onSubmitCode({ code: data, username: newPhoneNumber });
      }
    }
  });

  useEffect(() => {
    if (validCaptchaSuccess) {
      handleSendCode(formik);
    }
    handleReset();
    setValidCaptchaSuccess(false);
  }, [validCaptchaSuccess]);

  useEffect(() => {
    if (isNumberPopupOpen) {
      setInputTypes("phone");
      formik.setFieldValue("isPhone", "phone");
      formik.setFieldTouched("code", false);
    } else {
      setInputTypes("code");
      formik.setFieldValue("isPhone", "code");
      formik.setFieldTouched("phone", false);
    }
  }, [isNumberPopupOpen]);
  const closeNumberPopup = (): void => {
    setIsNumberPopupOpen(false);
  };
  const closeCodePopup = (): void => {
    setIsCodePopupOpen(false);
  };
  useEffect(() => {
    return () => setShowTimer(false);
  }, []);
  useEffect(() => {
    if (status?.sendCode === "Код отправлен" && isNumberPopupOpen) {
      closeNumberPopup();
      resetStatus();
      setIsCodePopupOpen(true);
      updateTime("120");
    }
    if (status?.updateUserPhone === "Номер телефона обновлен") {
      closeCodePopup();
      resetStatus();
    }
  }, [status]);
  return (
    <div className={styles.changePhoneContainer}>
      <Button theme="link" onClick={(): void => setIsNumberPopupOpen(true)}>
        Cменить номер телефона
      </Button>
      <InvisibleSmartCaptcha
        key={resetCaptcha}
        sitekey={process.env.REACT_APP_YANDEX_CAPTCHA_ID ?? ""}
        onSuccess={token => {
          setCaptchaToken(token);
          setValidCaptchaSuccess(true);
        }}
        visible={validCaptcha}
        onChallengeHidden={() => handleReset()}
      />
      <Popup isOpen={isNumberPopupOpen} onClick={closeNumberPopup}>
        <div className={styles.row}>
          <div className={styles.title}>Укажите новый номер телефона</div>
        </div>
        <div className={styles.addRoles}>
          <form onSubmit={formik.handleSubmit} className={styles.formStyle}>
            <Input
              name="phone"
              type="tel"
              placeholder="Введите Ваш номер телефона"
              mask={PHONE_MASK}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              errorMessages={formik.errors.phone && formik.touched.phone ? formik.errors.phone : null}
              invalid={Boolean(formik.errors.phone && formik.touched.phone)}
              onPaste={(elem: React.ClipboardEvent<HTMLInputElement>): void => handlerPaste(elem, formik.setFieldValue)}
            />
            <div className={styles.submitBtnContainer}>
              <Button disabled={isLoading} type="submit">
                Продолжить
              </Button>
            </div>
          </form>
        </div>
      </Popup>
      <Popup isOpen={isCodePopupOpen} onClick={closeCodePopup}>
        <div className={styles.row}>
          <div className={styles.title}>Прослушайте сообщение оператора и введите 4 цифры в поле</div>
        </div>
        <div className={styles.addRoles}>
          <form onSubmit={formik.handleSubmit} className={styles.formStyle}>
            <Input
              name="code"
              placeholder="Введите код"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.code}
              errorMessages={formik.errors.code && formik.touched.code ? formik.errors.code : null}
              invalid={Boolean(formik.errors.code && formik.touched.code)}
              onPaste={(elem: React.ClipboardEvent<HTMLInputElement>): void => handlerPaste(elem, formik.setFieldValue)}
            />
            <Wrapper pb={32} className={styles.submitBtnContainer}>
              <Timer
                setShowTimer={() => setShowTimer(!showTimer)}
                start={showTimer}
                onClick={() => setValidCaptcha(true)}
              />
            </Wrapper>
            <div className={styles.submitBtnContainer}>
              <Button disabled={!(formik.isValid && formik.dirty) || isLoading} type="submit">
                Сохранить
              </Button>
            </div>
          </form>
        </div>
      </Popup>
    </div>
  );
};

const mapStateToProps = ({ users: { user, isLoading }, users }: StoreTypes): Props => ({
  id: user.id,
  isLoading,
  status: users.status
});

const mapDispatchToProps = {
  sendCodeRequest,
  updateUserPhoneRequest,
  warning: serverError,
  setStatus: usersStatus,
  resetStatus: resetErrorAndStatus,
  updateTime: authUpdateTtl
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ChangePhone);
