import axios from "axios";
import { DeletedPersonalDataType, GetDeletedPersonalDataParams } from "../../store/deleted-personal-data/types";

export const PersonalDataDeleteOrdersApi = {
  getPersonalDataDeleteOrders: (body: Partial<GetDeletedPersonalDataParams>): Promise<DeletedPersonalDataType> =>
    axios.post(`/front/personal-data/request-to-delete-list`, body),

  getServices: (): Promise<any> => axios.get(`/front/service-list`),
  restoreUserInService: (userId: string, serviceId: string): Promise<any> =>
    axios.post(`/front/user/${userId}/restore/${serviceId}`)
};
