import { ThirdPartiesEntity } from "store/third-parties-list/types";
import { Service } from "store/services/types";

export const columns = [
  {
    title: "Название",
    dataIndex: "name",
    render: ({ name }: ThirdPartiesEntity): string => name
  },
  {
    title: "Поручение / Передача",
    dataIndex: "assigment",
    render: ({ assigment }: ThirdPartiesEntity): string => assigment
  },
  {
    title: "Адрес",
    dataIndex: "address",
    render: ({ address }: ThirdPartiesEntity): string => address
  },
  {
    title: "Email",
    dataIndex: "email",
    render: ({ email }: ThirdPartiesEntity): string => email
  },
  {
    title: "Список сервисов",
    dataIndex: "services",
    render: ({ services }: ThirdPartiesEntity): string =>
      services?.length > 0
        ? (services as Array<Service>).map((service: Service): string => service.name).join(", ")
        : ""
  }
];

export const deleteRowsColumn = [
  {
    title: "№",
    dataIndex: "n",
    render: (entity: ThirdPartiesEntity, index: string): string => index
  },
  {
    title: "Название",
    dataIndex: "name",
    render: ({ name }: ThirdPartiesEntity): string => name
  },
  {
    title: "Поручение / Передача",
    dataIndex: "assigment",
    render: ({ assigment }: ThirdPartiesEntity): string => assigment
  },
  {
    title: "Адрес",
    dataIndex: "address",
    render: ({ address }: ThirdPartiesEntity): string => address
  },
  {
    title: "Email",
    dataIndex: "email",
    render: ({ email }: ThirdPartiesEntity): string => email
  },
  {
    title: "Список сервисов",
    dataIndex: "services",
    render: ({ services }: ThirdPartiesEntity): string =>
      services?.length > 0
        ? (services as Array<Service>).map((service: Service): string => service.name).join(", ")
        : ""
  }
];
