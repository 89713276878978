import { TYPES_THIRD_PARTIES } from "./constants";

export const thirdPartiesLoad = () => ({
  type: TYPES_THIRD_PARTIES.THIRD_PARTIES_LOAD
});

export const thirdPartiesLoadStop = () => ({
  type: TYPES_THIRD_PARTIES.THIRD_PARTIES_LOAD_STOP
});

export const thirdPartiesRequest = (body: any) => ({
  type: TYPES_THIRD_PARTIES.THIRD_PARTIES_REQUEST,
  payload: body
});

export const thirdPartiesSuccess = (payload: any) => ({
  type: TYPES_THIRD_PARTIES.THIRD_PARTIES_SUCCESS,
  payload
});

export const thirdPartiesError = (error: { [key: string]: string }) => ({
  type: TYPES_THIRD_PARTIES.THIRD_PARTIES_ERROR,
  payload: error
});

export const deleteThirdPartyRequest = (payload: any) => ({
  type: TYPES_THIRD_PARTIES.DELETE_THIRD_PARTY_REQUEST,
  payload
});

export const deleteThirdPartySuccess = (payload: string) => ({
  type: TYPES_THIRD_PARTIES.DELETE_THIRD_PARTY_SUCCESS,
  payload
});

export const clearThirdPartiesRows = () => ({
  type: TYPES_THIRD_PARTIES.THIRD_PARTIES_CLEAR_ROWS
});

export const thirdPartyByIdRequest = (id: number | string) => ({
  type: TYPES_THIRD_PARTIES.THIRD_PARTY_BY_ID_REQUEST,
  payload: id
});

export const thirdPartyByIdSuccess = (payload: any | null) => ({
  type: TYPES_THIRD_PARTIES.THIRD_PARTY_BY_ID_SUCCESS,
  payload
});

export const setThirdPartiesRows = (payload: Array<string | number>) => ({
  type: TYPES_THIRD_PARTIES.SET_THIRD_PARTIES_ROWS,
  payload
});

export const filterThirdPartiesRows = (payload: number) => ({
  type: TYPES_THIRD_PARTIES.FILTER_THIRD_PARTIES_ROWS,
  payload
});

export const deleteThirdPartyError = (error: { [key: string]: string }) => ({
  type: TYPES_THIRD_PARTIES.DELETE_THIRD_PARTY_ERROR,
  payload: error
});

export const thirdPartiesStatus = (status: { [key: string]: string } | null) => ({
  type: TYPES_THIRD_PARTIES.THIRD_PARTIES_STATUS,
  payload: status
});
