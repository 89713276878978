import axios from "axios";
import { CreateDealersData } from "store/dealers/types";
import { DealerCenterType } from "store/dealer-center/types";
import { RequestParams } from "store/roles/constants";
import { Role } from "store/roles/types";
import { GetEmployeesParams } from "store/employees/types";
import {
  AttachUsersParams,
  CreateLegalEntityData,
  DetachUsersParams,
  GetUsersParams
} from "../../store/legalEntity/types";
import { WithPagination } from "../../store/types";
import { UserType } from "../../store/users/types";

type LegalEntityInfo = {
  id: string | number;
  name: string;
  inn: string;
  cars: [];
  users: [];
  created_at: string;
  updated_at: string;
};

export const LegalEntityApi = {
  create: (body: CreateLegalEntityData): Promise<LegalEntityInfo> => axios.post("/front/legal", body),

  update: (legalId: string, body: Partial<CreateLegalEntityData>): Promise<any> =>
    axios.put(`/front/legal/${legalId}`, body),

  info: (id: string): Promise<LegalEntityInfo> => axios.get(`/front/legal/${id}`),

  users: (body: GetUsersParams): Promise<WithPagination<UserType>> => axios.post(`/front/client`, body),

  attachUsers: (legalId: string, userIds: Array<string>): Promise<any> =>
    axios.post(`/front/legal/${legalId}/attach-users`, { userIds }),

  detachUsers: (legalId: string, userIds: Array<string>): Promise<any> =>
    axios.post(`/front/legal/${legalId}/detach-users`, { userIds }),

  cars: (body: GetUsersParams): Promise<WithPagination<UserType>> => axios.post(`/front/cars`, body),

  attachCars: (legalId: string, carIds: Array<string>): Promise<any> =>
    axios.post(`/front/legal/${legalId}/attach-cars`, { carIds }),

  detachCars: (legalId: string, carIds: Array<string>): Promise<any> =>
    axios.post(`/front/legal/${legalId}/detach-cars`, { carIds })
};
